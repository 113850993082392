import { OnInit, Component, Inject } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import { Usuario } from '../../model/usuario';
import { UsuarioService } from '../../service/usuario.service';
import { UtilService } from '../../util/util.service';
import { UsuarioSemCadastroService } from '../../service/usuario-sem-cadastro.service';

import { PubSub } from 'pubsub-js';
import { FormBuilder } from '@angular/forms';
import { RespostaPergunta } from '../../model/resposta-pergunta';

declare var UIkit: any;

@Component({
    selector: 'usuario-component',
    templateUrl: './usuarios.component.html',
    styleUrls: ['./usuarios.style.css']
})
export class UsuariosComponent implements OnInit {

    public usuarios: Usuario[];
    private modificacao: boolean = false;
    private inputHandicap: number = null;
    public qtdCompetidores: number;
    public respostaPergunta : RespostaPergunta[] = [];

    constructor(private usuarioService: UsuarioService,
                private utilService: UtilService,
                @Inject(Router) private router: Router,
                private activatedRoute: ActivatedRoute,
                private formBuilder: FormBuilder,
                private usuarioSemCadastroService : UsuarioSemCadastroService) {}

    ngOnInit(): void {
        this.buscarCompetidores();
    }

    private buscarCompetidores(): void {
        this.usuarioService.buscaCompetidoresPendentes().subscribe(usuarios => {
            this.usuarios = usuarios;
            this.usuarios.forEach(usuario => {
                this.modificaEndereco(usuario);
                usuario.data_nascimento = new Date(usuario.data_nascimento).toLocaleDateString();
            });
            this.buscaCompetidoresSemCadastro();
        });
    }

    private modificaEndereco(usuario){
        usuario.cep = usuario.endereco.cep;
        usuario.estado = usuario.endereco.estado;
        usuario.cidade = usuario.endereco.cidade;
        usuario.bairro = usuario.endereco.bairro;
        usuario.logradouro = usuario.endereco.logradouro;
        usuario.numero = usuario.endereco.numero;
    }

    buscaCompetidoresSemCadastro() : void{
        this.usuarioSemCadastroService.buscaPendentes()
        .subscribe(usc => {
            usc.forEach(u => u.data_nascimento = new Date(u.data_nascimento).toLocaleDateString());
            if(this.usuarios.length > 0){
                this.usuarios = this.usuarios.concat(usc);
            }else{
                this.usuarios = usc;
            }
            this.qtdCompetidores = this.usuarios.length;
            PubSub.publish('QTD', this.qtdCompetidores);
        });
    }

    public liberar(usuario: Usuario) {
        this.utilService.mensagemConfirmacao(`Deseja liberar o cadastro de ${usuario.nome}?`, (result) => {
            if (result) {
                usuario.ativo = true;
                usuario.pendente = false;
                let mensagem = 'Usuario ativado com sucesso!';
                if(usuario.semCadastro){
                    this.alterarUsuarioSemCadastro(usuario.id_usuario, usuario, 1, mensagem);
                }else{
                    this.alterarUsuario(usuario.id_usuario, usuario, 1, mensagem);
                }
            }
        })
    }

    public negar(usuario: Usuario) {
        this.utilService.mensagemConfirmacao(`Deseja negar o cadastro de ${usuario.nome}?`, (result) => {
            if (result) {
                usuario.ativo = false;
                usuario.competidor = false;
                let mensagem = 'Usuario negado com sucesso!';
                if(usuario.semCadastro){
                    this.alterarUsuarioSemCadastro(usuario.id_usuario, usuario, 1, mensagem);
                }else{
                    usuario.pendente = true;
                    this.alterarUsuario(usuario.id_usuario, usuario, 1, mensagem);
                }
            }
        })
    }

    public mensagemSucesso(mensagem : string){
        this.utilService.mensagemSucesso(mensagem, () => {
            this.buscarCompetidores();
        });
    }

    private verificaHandicapDoisDigitos(usuario: Usuario): boolean {
        return usuario.handicap >= 10;
    }

    private acionarModificacao(): void {
        if (this.modificacao) {
            this.modificacao = false;
        } else {
            this.modificacao = true;
        }
    }

    private modificarHandicap(usuario: Usuario): void {
        this.utilService.mensagemConfirmacao(`Deseja mudar o handicap de ${usuario.nome} de ${usuario.handicap} para ${this.inputHandicap} ?`, (result) => {
            if (result) {
                usuario.handicap = this.inputHandicap;
                let mensagem = 'Handicap Alterado Com sucesso!';
                if(usuario.semCadastro){
                    this.alterarUsuarioSemCadastro(usuario.id_usuario, usuario, 2, mensagem);
                }else{
                    this.alterarHandicapUsuario(usuario, mensagem);
                }
                this.inputHandicap = null;
            }
        });
    }

    private alterarHandicapUsuario(usuario : Usuario, mensagem: string){
        usuario.pendente = true;
        this.usuarioService.put(usuario.id_usuario, usuario)
        .subscribe(() => {
            this.utilService.mensagemSucesso(mensagem);
        }, erro => this.utilService.mensagemErro("Erro - " + erro.error.mensagem));
    }

    private alterarUsuario(id_usuario : number, usuario : Usuario,
    tipo : number, mensagem : string) : void{
        this.usuarioService.competidorAvaliado(id_usuario, usuario)
        .subscribe(usuario => {
            if(tipo == 1){
                this.mensagemSucesso(mensagem)
            }else if(tipo == 2){
                this.utilService.mensagemSucesso(mensagem);
            }
        });
    }

    private alterarUsuarioSemCadastro(id_usuario : number, usuario : Usuario,
    tipo : number, mensagem : string) : void{
        this.usuarioSemCadastroService.alterar(id_usuario, usuario)
        .subscribe(usuario => {
            if(tipo == 1){
                this.mensagemSucesso(mensagem)
            }else if(tipo == 2){
                this.utilService.mensagemSucesso(mensagem);
            }
        }, erro => this.utilService.mensagemErro(erro.erro.mensagem));
    }

    public selecionaRespostaPergunta(respostaPergunta : RespostaPergunta[]){
        this.respostaPergunta = respostaPergunta;
    }
}