import { Evento } from './evento';
import { Usuario } from './usuario';

export class Campeonato {
    id_campeonato: number;
    id_organizador: number;
    ativo: boolean;
    campeonato_finalizado: boolean;
    data_criacao: string;
    data_inicial: string;
    data_final: string;
    data_modificacao: string;
    nome: string;
    descricao: string;
    porcentagem_premiacao: number;
    preco_inscricao: number;
    eventos: Evento[];
    organizador: Usuario;
    imagem_exibicao: string;
    arquivo_imagem: string;
    imagem_old: string;
    thumbnail: string;
    maximo_inscricoes : number;
    quantidade_resultados: number;
}