import { Component, OnInit, Inject, ViewChild, ElementRef } from "@angular/core";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { Router, ActivatedRoute } from "@angular/router";
import { UtilService } from "../../../util/util.service";
import { UsuarioService } from "../../../service/usuario.service";
import { UsuarioLogin } from '../../../model/usuario_login';

import { PubSub } from 'pubsub-js';
import { Usuario } from '../../../model/usuario';

@Component({
  selector: 'app-formulario-login-component',
  templateUrl: './formulario-login.component.html',
  moduleId: module.id,
  styleUrls: ['./formulario-login.style.css']
})

export class FormularioLoginComponent implements OnInit {

  loginForm: FormGroup;
  loginContaForm: FormGroup;
  mostrarSenha: boolean = false;
  @ViewChild('senha') inputSenha: ElementRef;
  usuario: UsuarioLogin = new UsuarioLogin();

  public controlLogin : boolean = true;
  public tituloPagina : string = 'Entrar';
  public usuarioConta : Usuario;
  public spinner : boolean = false;

  constructor(private formBuilder: FormBuilder,
    @Inject(Router) private router: Router,
    private utilService: UtilService,
    private usuarioService: UsuarioService,
    private route: ActivatedRoute) {

    utilService.mudaTitulo('RSNC Brasil - Entrar');
    this.initForm();
  }

  ngOnInit(): void {
    this.utilService.clearCookies();
  }

  public initForm(){
    this.loginForm = this.formBuilder.group({
      login: [this.usuario.login,
      [
        Validators.required,
        Validators.minLength(5),
        Validators.maxLength(50),
        Validators.pattern('')
      ]
      ],
      senha: [this.usuario.senha,
      [
        Validators.required,
        Validators.minLength(5),
        Validators.maxLength(50)
      ]
      ]
    });
  }

  public initFormLoginConta(){
    this.loginContaForm = this.formBuilder.group({
      login: [this.usuarioConta.login,
      [
        Validators.required,
        Validators.minLength(5),
        Validators.maxLength(50),
        Validators.pattern('')
      ]
      ],
      email: [this.usuarioConta.email,
      [
        Validators.required,
        Validators.maxLength(100)
      ]
      ]
    });
  }

  public login(): void {
    if (this.loginForm.invalid) {
      this.utilService.showErrors(this.loginForm);
      return;
    }
    this.usuario.login = this.loginForm.get('login').value;
    this.usuario.senha = this.loginForm.get('senha').value;

    this.usuarioService.logar(this.usuario).subscribe(result => {
      this.insertCookies(result);
      this.buscaHandcap(result.id);

      PubSub.publish('LOGADO', true);
      this.utilService.mensagemSucesso('Bem vindo, ' + result.nome + '!', () => {
        let returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
        this.router.navigateByUrl(returnUrl);
      });
    });
  }

  private buscaHandcap(idUsuarioLogado: string) {
    this.usuarioService.getHandcap(idUsuarioLogado).subscribe (result => {
       this.utilService.setHandicapCompetidor(result.handcap);
    }); 
  }

  public cadastro() {
    this.utilService.irPara(this.router, '/comum/cadastro-usuario/');
  }

  private containsRole(role: string): boolean {
    return this.utilService.getRoles().findIndex(r => r === role) >= 0;
  }
 
  private insertCookies(data: any): void {
    this.utilService.saveCookies(data);
  }

  public irPara(route: string): void {
    this.utilService.goTo(this.router, route);
  }

  changeShowPassword(value: any) {
    this.mostrarSenha = value;
    this.inputSenha.nativeElement.type = this.mostrarSenha ? 'text' : 'password';
  }

  public esqueceuConta(){
    this.usuarioConta = new Usuario();
    this.initFormLoginConta();
    this.controlLogin = false;
    this.tituloPagina = 'Encontrar sua Conta';
    this.loginForm = null;
  }

  public voltarLogin(){
    this.initForm();
    this.controlLogin = true;
    this.tituloPagina = 'Entrar';
    this.usuarioConta = null;
    this.loginContaForm = null;
  }

  public confirmarAlteracao(){
    this.usuarioService.validarEmail(this.usuarioConta.email).subscribe(retorno => {
      if(retorno){
        this.usuarioService.validarLogin(this.usuarioConta.login).subscribe(login => {
          if(login){
            this.spinner = true;
            this.usuarioService.recuperarSenhaPorEmailLogin(this.usuarioConta.email, this.usuarioConta.login)
            .subscribe(atualiza => {
              let mensagem : string;
              this.spinner = false;
              if(atualiza.status){
                mensagem = "Senha atualizada com sucesso!\nA nova senha foi enviada para o email informado.";
              }else{
                mensagem = "Não foi possivel atualizar a senha!";
              }
              this.utilService.mensagemInfo(mensagem);
              this.voltarLogin();
            }, erro => {
              this.utilService.mensagemErro(erro.error.mensagem);
              this.spinner = false;
            }); 
          }else{
            this.utilService.mensagemErro("Login invalido!");
          }
        });               
      }else{
        this.utilService.mensagemErro("Email invalido!");
      }
    }, (err) => {
      this.utilService.mensagemErro("Erro : Não foi possivel encontrar sua conta!",
      () => this.voltarLogin());
    });
  } 

}  