import { Component, OnInit, Inject, ElementRef, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { RedefinirSenha } from '../../../model/redefinir-senha';
import { UtilService } from '../../../util/util.service';
import { Router } from '@angular/router';
import { UsuarioService } from '../../../service/usuario.service';

@Component({
  selector: 'app-redefinir-senha',
  templateUrl: './redefinir-senha.component.html',
  styleUrls: ['./redefinir-senha.component.css']
})
export class RedefinirSenhaComponent implements OnInit {

  public form : FormGroup;
  public redefinir : RedefinirSenha = new RedefinirSenha();
  public mostrarSenha: boolean = false;
  public mostrarNovaSenha: boolean = false;
  public mostrarNovaConfirmarSenha: boolean = false;
  public confirmar_senha: string;
  

  @ViewChild('senha') inputSenha: ElementRef;
  @ViewChild('novaSenha') inputNovaSenha: ElementRef;
  @ViewChild('confirmarNovaSenha') inputConfirmarNovaSenha: ElementRef;
  
  constructor(@Inject(FormBuilder) private formGrup : FormBuilder,
              @Inject(UtilService) public utilService : UtilService,
              @Inject(Router) private router : Router,
              private usuarioService : UsuarioService) {

    const nova_senha = new FormControl('', [
      Validators.required,
      Validators.minLength(5),
      Validators.maxLength(50)
    ]);

    this.form = this.formGrup.group({
      senha : [this.redefinir.senha, 
        [Validators.required,
        Validators.minLength(5),
        Validators.maxLength(50)]],
      nova_senha : nova_senha,
      confirmar_nova_senha : [this.redefinir.confirmar_nova_senha, 
        [Validators.required,
        Validators.maxLength(50),
        UtilService.confirmar(nova_senha)]]
    });

  }

  ngOnInit() {
  }

  public changeShowPassword(value: any) {
    this.mostrarSenha = value;
    this.inputSenha.nativeElement.type = this.mostrarSenha ? 'text' : 'password';
  }

  public changeShowNewPassword(value: any) {
    this.mostrarNovaSenha = value;
    this.inputNovaSenha.nativeElement.type = this.mostrarNovaSenha ? 'text' : 'password';
  }

  public changeShowNewConfirmPassword(value: any) {
    this.mostrarNovaConfirmarSenha = value;
    this.inputConfirmarNovaSenha.nativeElement.type = this.mostrarNovaConfirmarSenha ? 'text' : 'password';
  }

  public redireciona() {
    this.utilService.irPara(this.router, '/comum/inicio/');
  }

  public salvar(){
    this.redefinir.id_usuario = this.utilService.getIdLogado();

    this.usuarioService.redefinirSenha(this.redefinir).subscribe(redefinir => {
      if(!redefinir.statusSenha){
        this.utilService.mensagemInfo(redefinir.mensagem);
      }else{
        this.utilService.mensagemInfo(redefinir.mensagem, ()=>{
          this.redireciona();
        });
      }
    }, (err) => {
      this.utilService.mensagemErro("Erro : Não foi possivel redefinir a senha!");
    });
  }

}
