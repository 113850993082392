import { Component, OnInit, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { CavaloService } from '../../../service/cavalo.service';
import { UtilService } from '../../../util/util.service';
import { Cavalo } from '../../../model/cavalo';
import { Paginacao } from '../../../model/paginacao';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

@Component({
  selector: 'app-lista-cavalos',
  templateUrl: './lista-cavalos.component.html',
  styleUrls: ['../../comum.style.css','./lista-cavalos.component.css']
})

export class ListaCavalosComponent implements OnInit {

  public cavalos : Cavalo[] = [];
  public spinner : boolean = true;
  public quantidadeCavalos = 0;
  public filtro : {
    nome : string,
    offset : number,
    limit : number,
    ativo: boolean,
    pagina: number
  } = {
    nome : '',
    offset : 0,
    limit : 500,
    ativo : true,
    pagina: 1
  };
  public numPagina: number = 0;
  public paginacao: Paginacao = new Paginacao();
  public debounceCavalos: Subject<string> = new Subject<string>();

  constructor(private utilService : UtilService,
              private calavoService : CavaloService,
              @Inject(Router) private router : Router) { }

  ngOnInit() {
    this.initDebounceCavalo();
    this.initPaginacao();
    this.buscaCavalos();
  }

  private initDebounceCavalo(){
    this.debounceCavalos.pipe(debounceTime(300))
    .subscribe(nome => {
      this.filtro.nome = nome;
      this.buscaCavalos();
    });
  }

  private buscaCavalos(){
    this.spinner = true;
    this.getFiltro();
    this.calavoService.buscaFiltro(this.filtro)
    .subscribe(retorno => {
      this.spinner = false;
      this.cavalos = retorno.cavalos;
      this.quantidadeCavalos = retorno.quantidade;
      this.quantidadePagina();
    }, erro => {
      this.spinner = false;
      this.utilService.mensagemErro(erro.error.mensagem);
    });
  }

  public getNomeSelecionado(nome : string){
    this.initPaginacao();
    this.debounceCavalos.next(nome);
  }

  public irParaEditarCavalo(cavalo : Cavalo){
    this.utilService.irPara(this.router, '/comum/editar-cavalo', {
      "id_cavalo" : cavalo.id_cavalo
    });
  }

  public selecionaStatus(status: string){
    this.filtro.ativo = status == 'true' ? true : false;
    if(this.filtro.offset != 0){
      this.initPaginacao();
    }
    this.buscaCavalos();
  }

  private initPaginacao(){
    this.paginacao.limit = 50;
    this.paginacao.offset = 0;
    this.paginacao.pagina = 1;
  }

  public getFiltro(){
    this.filtro.offset = this.paginacao.offset;
    this.filtro.limit = this.paginacao.limit;
    this.filtro.pagina = this.paginacao.pagina;
  }

  private quantidadePagina(){
    let paginas = 0;
    if(this.quantidadeCavalos % this.paginacao.limit == 0){
      paginas = (this.quantidadeCavalos / this.paginacao.limit);
    }else{
      paginas = (this.quantidadeCavalos / this.paginacao.limit) + 1;
    }
    this.numPagina = Math.floor(paginas);
  }

  public getPaginacao(paginacao:Paginacao){
    this.paginacao.limit = paginacao.limit;
    this.paginacao.offset = paginacao.offset;
    this.paginacao.pagina = paginacao.pagina;
    this.buscaCavalos();
  }

  public excluirCavalo(cavalo, id_cavalo: number){
    this.utilService.mensagemConfirmacao("Deseja realmente excluir o cavalo "+cavalo.nome+"?",
    resp => {
      if(resp){
        this.calavoService.deleta(id_cavalo)
        .subscribe(() => {
          this.utilService.mensagemSucesso("Operação realizada com sucesso!");
          this.buscaCavalos();
        }, erro => this.utilService.mensagemErro("Erro - " + erro.error.mensagem));
      }
    });
  }
  
}
