import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

import { CampeonatoService } from '../../service/campeonato.service';

import { Campeonato } from '../../model/campeonato';
import { ParametrosBusca } from '../../model/parametros_busca';

@Injectable()
export class CampeonatosResolver implements Resolve<Observable<Campeonato[]>> {

    options: ParametrosBusca = new ParametrosBusca();

    constructor(private service: CampeonatoService) { }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<Campeonato[]> {

        this.options.offset = route.queryParams.offset;
        this.options.limit = route.queryParams.limit;
        return this.service.buscaPaginado(this.options);
    }
}