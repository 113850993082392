import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CompetidoresSelecionadosComponent } from './competidores-selecionados.component';

@NgModule({
    declarations: [CompetidoresSelecionadosComponent],
    imports: [
        CommonModule
    ],
    exports: [CompetidoresSelecionadosComponent]
})
export class CompetidoresSelecionadosModule {}