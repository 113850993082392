import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { DirectiveModule } from '../../directive/directive.module';
import { RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';
import { ModeracaoCavaloComponent } from './moderacao-cavalo/moderacao-cavalo.component';

@NgModule({
    declarations: [
        ModeracaoCavaloComponent
    ],
    imports: [
        CommonModule,
        BrowserModule,
        DirectiveModule,
        RouterModule
    ],
    exports: [ModeracaoCavaloComponent],
    providers: []
})
export class ModeracaoModule {}