import { NgModule } from '@angular/core';
import { UsuariosComponent } from './usuarios.component';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { UsuariosResolver } from './usuarios.resolver';
import { UsuariosService } from './usuarios.service';
import { ListaInscricoesUsuarioComponent } from './lista-inscricoes-usuario/lista-inscricoes-usuario.component';
import { PaginationListaInscricoesComponent } from './lista-inscricoes-usuario/pagination-lista-inscricoes/pagination-lista-inscricoes.component';
import { EditarUsuarioComponent } from './editar-usuario/editar-usuario.component';
import { BrowserModule } from '@angular/platform-browser';
import { DirectiveModule } from '../../directive/directive.module';
import { TextMaskModule } from 'angular2-text-mask';
import { ListaCompetidoresComponent } from './lista-competidores/lista-competidores.component';
import { ListaCompetidoresSemCadastroComponent } from './lista-competidores-sem-cadastro/lista-competidores-sem-cadastro/lista-competidores-sem-cadastro.component';
import { RouterModule } from '@angular/router';
import { ListaUsuariosComponent } from './lista-usuarios/lista-usuarios/lista-usuarios.component';

@NgModule({
    declarations: [
        UsuariosComponent,
        ListaInscricoesUsuarioComponent,
        PaginationListaInscricoesComponent,
        EditarUsuarioComponent,
        ListaCompetidoresComponent,
        ListaCompetidoresSemCadastroComponent,
        ListaUsuariosComponent
    ],
    imports: [
        CommonModule,
        FormsModule,
        BrowserModule,
        ReactiveFormsModule,
        TextMaskModule, 
        DirectiveModule,
        RouterModule
    ],
    exports: [UsuariosComponent],
    providers: [UsuariosResolver, UsuariosService]
})
export class UsuariosModule {}