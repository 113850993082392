import { Component, OnInit } from '@angular/core';
import { UtilService } from '../../../util/util.service';
import { CampeonatoService } from '../../../service/campeonato.service';
import { EventoService } from '../../../service/evento.service';
import { ResultadoCampeonatoService } from '../../../service/resultado-campeonato.service';
import { ResultadoEventoService } from '../../../service/resultado-evento.service';
import { Campeonato } from '../../../model/campeonato';
import { Evento } from '../../../model/evento';
import { AnoHipico } from '../../../model/ano-hipico';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { ResultadoCampeonato } from '../../../model/resultado-campeonato';
import { ResultadoEvento } from '../../../model/resultado-evento';
import { Router } from '@angular/router';

@Component({
  selector: 'app-resultado-geral-list',
  templateUrl: './resultado-geral-list.component.html',
  styleUrls: ['../../comum.style.css', './resultado-geral-list.component.css']
})
export class ResultadoGeralListComponent implements OnInit {

  anoHipico: AnoHipico = new AnoHipico();
  form: FormGroup;
  campeonato: Campeonato = null;
  campeonatos: Campeonato[] = [];
  evento: Evento = null;
  eventos: Evento[] = [];
  statusPesquisa: boolean = false;
  data = '-07-01';
  resultadosCampeonato: ResultadoCampeonato[] =[];
  resultadosEvento: ResultadoEvento[] = [];

  constructor(private utilService: UtilService,
              private campeonatoService: CampeonatoService,
              private eventoService: EventoService,
              private resultadoCampeonatoService: ResultadoCampeonatoService,
              private resultadoEventoService: ResultadoEventoService,
              private formBuild: FormBuilder,
              private router: Router) { }

  ngOnInit() {
    this.initForm();
  }

  private initForm(){
    this.form = this.formBuild.group({
      inicio: new FormControl(this.anoHipico.inicio,
        [Validators.required, Validators.minLength(4),Validators.maxLength(4)]),
      fim: new FormControl(this.anoHipico.fim,
        [Validators.required, Validators.minLength(4), Validators.maxLength(4)])
    });
  }

  private limpar(){
    this.anoHipico = new AnoHipico();
    this.statusPesquisa = false;
    this.initCampeonato();
    this.initEvento();
  }

  private pesquisar(){
    if(this.form.invalid){
      this.utilService.showErrors(this.form);
      this.statusPesquisa = false;
      this.initCampeonato();
      this.initEvento();
      return;
    }

    if(Number(this.anoHipico.inicio) >= Number(this.anoHipico.fim)){
      this.utilService.mensagemErro("O ano fim precisa ser maior que o ano de inicio!");
      return false;
    }
    this.statusPesquisa = true;
    this.buscaCampeonatoPorAnoHipico();
  }

  private buscaCampeonatoPorAnoHipico(){
    let inicio = this.anoHipico.inicio + this.data;
    let fim = this.anoHipico.fim + this.data;
    this.campeonatoService.buscaPorAnoHipico(inicio, fim)
    .subscribe(
      retorno => this.campeonatos = retorno,
      erro => this.utilService.mensagemErro(erro.error.mensagem)
    );
  }

  private selecionaCampeonato(index: any){
    if(index == 'null'){
      this.campeonato = null;
      this.resultadosCampeonato = [];
      this.initEvento();
      this.buscaEventoPorAnoHipico();
      return;
    }
    this.campeonato = this.campeonatos[index];
    this.buscaEvento();
    this.buscaResultadoCampeonato();
  }

  private initCampeonato(){
    this.campeonato = null;
    this.campeonatos = [];
    this.resultadosCampeonato = [];
  }

  private buscaEvento(){
    this.eventoService.buscaPorIdCampeonato(this.campeonato.id_campeonato)
    .subscribe(
      retorno => this.eventos = retorno,
      erro => this.utilService.mensagemErro(erro.error.mensagem)
    );
  }

  private buscaEventoPorAnoHipico(){
    let inicio = this.anoHipico.inicio + this.data;
    let fim = this.anoHipico.fim + this.data;
    this.eventoService.buscaPorAnoHipico(inicio, fim)
    .subscribe(
      retorno => this.eventos = retorno,
      erro => this.utilService.mensagemErro(erro.error.mensagem)
    )
  }

  private selecionaEvento(index: any){
    if(index == 'null'){
      this.resultadosEvento = [];
      this.evento = null;
      return;
    }
    this.evento = this.eventos[index];
    this.buscaResultadoEvento();
  }

  private buscaResultadoCampeonato(){
    this.resultadoCampeonatoService.buscaPorIdCampeonato(this.campeonato.id_campeonato)
    .subscribe(
      retorno => {
        this.resultadosCampeonato = retorno;
        if(this.resultadosCampeonato && this.resultadosCampeonato.length > 0){
          this.getArquivoExibicao(this.resultadosCampeonato);
        }
      },
      erro => this.utilService.mensagemErro(erro.error.mensagem)
    );
  }

  private initEvento(){
    this.evento = null;
    this.eventos = [];
    this.resultadosEvento = [];
  }

  private buscaResultadoEvento(){
    this.resultadoEventoService.buscaPorIdEvento(this.evento.id_evento)
    .subscribe(
      retorno => {
        this.resultadosEvento = retorno;
        if(this.resultadosEvento && this.resultadosEvento.length > 0){
          this.getArquivoExibicao(this.resultadosEvento);
        }
      },
      erro => this.utilService.mensagemErro(erro.error.mensagem)
    );
  }

  private getArquivoExibicao(resultados: any[]){
    resultados.forEach(resultado => resultado.arquivo_exibicao = this.utilService.buscaPdf(resultado));
  }

  public excluirResultadoCampeonato(id_resultado_campeonato: number){
    this.utilService.mensagemConfirmacao("Deseja realmente excluir o registro?",
    resp => {
      if(resp){
        this.resultadoCampeonatoService.excluir(id_resultado_campeonato)
        .subscribe(
          () => {
            this.utilService.mensagemSucesso('Operação realizada com sucesso!');
            this.buscaResultadoCampeonato();
          },
          erro => this.utilService.mensagemErro(erro.error.mensagem)
        );
      }
    });
  }

  public editarResultadoCampeonato(id_resultado_campeonato: number){
    this.router.navigate(['comum/campeonato/' + this.campeonato.id_campeonato + '/editar-resultado/' + id_resultado_campeonato]);
  }

  public excluirResultadoEvento(id_resultado_evento: number){
    this.utilService.mensagemConfirmacao("Deseja realmente excluir o registro?",
    resp => {
      if(resp){
        this.resultadoEventoService.excluir(id_resultado_evento)
        .subscribe(
          () => {
            this.utilService.mensagemSucesso('Operação realizada com sucesso!');
            this.buscaResultadoEvento();
          },
          erro => this.utilService.mensagemErro(erro.error.mensagem)
        );
      }
    });
  }

  public editarResultadoEvento(id_resultado_evento: number){
    this.router.navigate(['comum/evento/' + this.evento.id_evento + '/editar-resultado/' + id_resultado_evento]);
  }
}
