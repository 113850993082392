import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SearchOrganizadorComponent } from './search-organizador.component';

@NgModule({
    declarations: [SearchOrganizadorComponent],
    imports: [
        CommonModule
    ],
    exports: [SearchOrganizadorComponent]
})
export class SearchOrganizadorModule {}