// @ts-ignore
import { Regra } from '../model/regra';
import { ErroRegra } from '../model/erro-regra';
import { Cavalo } from '../model/cavalo';
import { UtilService } from '../util/util.service';
import { RegraAssociacao } from '../model/regra-associacao';

/**
 * Responsável por validar as regras de uma divisão
 * Ex: 
 * <br>
 * ```
 let valido: boolean = new RegraValidadorService(idade, handicap)
                                .addRegras(prova.divisao.regras)
                                .valida();
 ```
 */
export class RegraValidadorService {

    private regras: Regra[] = [];
    private regrasAssociacao : RegraAssociacao[] = [];

    constructor(private idade: number, private handicap: number, private sexo: string,
                 private cavalo : Cavalo = null, private qtdInscricaoCavalo : number = null,
                 private numeroCompetidor : number, private utilService : UtilService) {}

    /**
     * adiciona as regras que serão validadas quando o método valida for invocado
     * ex: validador.addRegras(regras) ou validador.addRegras([regra])
     * @param regras 
     */
    public addRegras(regras: Regra[]): RegraValidadorService {
        this.regras = this.regras.concat(regras);
        return this;
    }

    /**
     * valida as regras adicionadas
     * @returns um array contendo os erros referentes as regras não atendidas
     */
    public valida(): ErroRegra[] {
        // criamos variáveis locais para que o eval consiga acesso, pois a expressão
        // não utiliza o this, por exemplo: idade > 20 && idade < 50
        let idade = this.idade;
        let handicap = this.handicap;
        let sexo = this.sexo;
        let handicap_rebatedor = this.handicap;
        let handicap_apartador = this.handicap;        

        let data = null;
        let idade_cavalo = null;
        let maximo_cavalo_corre_prova = null;
        let potro_futuro = null;
        //SE FOR INSERIDO MAIS REGRAS APLICADAS NO CAVALO, TEM QUE MUDAR O PARAMETRO DE AVALIAÇÃO
        if(this.cavalo!= null ){
            // data = new Date(this.cavalo.nascimento);
            // idade_cavalo = this.calculoIdadeBaseAnoHipico(data.getFullYear(), data.getMonth(), data.getDate());
            idade_cavalo = this.utilService.getIdadeBaseAnoHipico(this.cavalo.nascimento);
            maximo_cavalo_corre_prova = Number(this.qtdInscricaoCavalo);
            potro_futuro = this.cavalo.potro_futuro? this.cavalo.potro_futuro : false;
            potro_futuro = potro_futuro.toString();
        }

        let erros:ErroRegra[] = [];
        //validando as regras da divisão
        this.regras.forEach(regra => {
            let valido;
            let regrar;
            
            if(idade_cavalo == null){
                if(regra.regra_aplicante == 1){
                    if(this.numeroCompetidor == regra.numero_competidor){
                        valido = eval(regra.expressao);
                        if (!valido) {
                            regrar = new ErroRegra();
                            regrar.descricao = regra.descricao;
                            erros.push(regrar);
                        }
                    }
                }else{
                    regrar = new ErroRegra();
                    regrar.descricao = regra.descricao;
                    erros.push(regrar);
                }
                
            }else{
                if(this.numeroCompetidor == regra.numero_competidor){
                    valido = eval(regra.expressao);
                    if (!valido) {
                        regrar = new ErroRegra();
                        regrar.descricao = regra.descricao;
                        erros.push(regrar);
                    }
                }
            }
            
        });
        
        return erros;
    }

    private getAge(year, month, day) {
        var now = new Date()	
        var age = now.getFullYear() - year
        var mdif = now.getMonth() - month + 1 //0=jan	
        
        if(mdif < 0)
        {
            --age
        }
        else if(mdif == 0)
        {
            var ddif = now.getDate() - day
            
            if(ddif < 0)
            {
                --age
            }
        }
        
	    return age
    }


    public calculoIdadeBaseAnoHipico(year, month, day) {
        var now = new Date()	
        var age = now.getFullYear();
        var monthNow = now.getMonth();
        var dayNow = now.getDate();
        
        if((month > 6) || (month == 6 && day > 1)){
            if((monthNow > month) || (monthNow == month && dayNow >= day)){
              age -= 1;
            }
        }
    
        let idade = age -  year;
    
        var mdif = 6 - month + 1 //0=jan
        
        if(mdif < 0)
        {
          --idade
        }
        else if(mdif == 0)
        {
            var ddif = 1 - day
            
            if(ddif < 0)
            {
                --idade
            }
        }
    
        return idade
    }

    public addRegraAssocicao(regras : RegraAssociacao[]){
        this.regrasAssociacao = this.regrasAssociacao.concat(regras);
    }

    public validaAssociacao(){
        let idade = this.idade;
        let id_regra_associacao = null;

        this.regrasAssociacao.forEach(ra => {
            let valido;
            valido = eval(ra.expressao);
            if (valido) {
                id_regra_associacao = ra.id_regra_associacao;
            }
        });

        return {id_regra_associacao : id_regra_associacao};
    }

}
