import {Component, Inject, OnInit, Input, OnChanges} from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { UtilService } from '../../util/util.service';
import { Evento } from '../../model/evento';
import { EventoService } from '../../service/evento.service';
import { UsuarioService } from '../../service/usuario.service';
import { ProvaService } from '../../service/prova.service';
import { Prova } from '../../model/prova';
import { FotoEvento } from '../../model/foto-evento';
import { FotoEventoService } from '../../service/foto-evento.service';
import { inject } from '@angular/core/testing';
import { CavaloService } from '../../service/cavalo.service';


declare var $: any;
declare var UIkit: any;

@Component({
  selector: 'app-evento-component',
  moduleId: module.id,
  templateUrl: './evento.component.html',
  styleUrls: ['./evento.style.css']
})

export class EventoComponent implements OnInit, OnChanges {

  public evento: Evento = new Evento();
  private id_evento: number;
  public provas : Prova[] = [];
  public imagemModal: any;
  private is_organizador: any;
  public organizador : boolean = false;
  

  constructor(@Inject(Router) private router: Router,
              @Inject(ActivatedRoute) private route: ActivatedRoute,
              @Inject(EventoService) private eventoService: EventoService,
              @Inject(UsuarioService) private usuarioService: UsuarioService,
              @Inject(CavaloService) private cavaloService: CavaloService,
              public utilService: UtilService,
              public provaService : ProvaService,
              private fotoEventoService: FotoEventoService) {
    this.getEvento();
  }

  ngOnInit() {
  }

  ngOnChanges(): void {
  }

  private getEvento() {
    this.route.params.subscribe(params => {
      this.id_evento = params['id_evento'];
      this.buscaEvento();
    });
  }

  private buscaEvento(){
    this.eventoService.buscaPorId2(this.id_evento).subscribe(evento => {
      this.evento = evento;
      this.organizador = this.utilService.getIdLogado() === evento.id_organizador ? true : false;
      this.provas = evento.provas;
      this.evento.arquivo_imagem = this.utilService.buscaImagem(this.evento);
      this.is_organizador = this.verificaOrganizadorEvento();
    });
  }

  public redireciona(id_evento) {
    if (this.utilService.getIdLogado()) {
        this.provaService.addProvas(this.provas);
        this.utilService.irPara(this.router, `/comum/inscricoes/${id_evento}`);
    } else {
      this.utilService.mensagemConfirmacao('É preciso estar logado para realizar uma inscrição.\nGostaria de realizar o login?', (result) => {
        if (result) {
          this.utilService.irPara(this.router, '/comum/login');
        }
      });
    }
  }

  public abreModalImagem(event){
    this.imagemModal = event.arquivo_imagem;
    UIkit.modal('#modal-media-evento-image', { bgClose : false}).show();
  }

  public addGoogleCalendar(){
    let dataInicial = this.utilService.removeCharacter('-', this.evento.data_inicial).substr(0,8);
    let dataFinal = this.utilService.removeCharacter('-', this.evento.data_final).substr(0,8);

    let url = `https://calendar.google.com/calendar/r/eventedit?text=${this.evento.titulo}&dates=${dataInicial}/${dataFinal}&details=${this.evento.descricao}%0A&location=${this.evento.localizacao}&trp=false&sprop=website:${this.evento.website}&ctz=Atlantic/Azores&sf=true`;
    window.open(url, "_blank");
  }

  public redPagListaInscritos(id_evento : number) : string{
    return `/comum/evento/${id_evento}/lista_inscritos`;
  } 

  public abreEdicaoEvento(){
    this.utilService.irPara(this.router, '/comum/editar-evento', {
      "id_evento" : this.evento.id_evento
    });
  }

  public excluirEvento(): void {
    this.utilService.mensagemConfirmacao('Deseja excluir o evento ' + this.evento.titulo + ' ?', (confirmar) => {
      if(confirmar){
        this.eventoService.deleta(this.evento.id_evento).subscribe(
          success => {
            this.utilService.mensagemSucesso("Evento excluído com sucesso!");
            this.utilService.irPara(this.router, '/comum/eventos');
          },
          err => {
            this.utilService.mensagemErro(err.error.mensagem);
          }
        );
      }
    })
  }

  public verificaOrganizadorEvento() {
    this.usuarioService.verificaOrganizadorEvento(this.id_evento, this.evento.id_organizador).subscribe(retorno => {
      this.is_organizador = retorno.retorno;
    });
  }

  private selecionaCampeonatoDoEventoReplicado() {
    UIkit.modal('#modalSelecionaCampeonato',{bgClose: false}).show();
  }

  public baixaXML(evento) {
    this.eventoService.exportarXml(evento.id_evento).subscribe(x => {
      let retorno = JSON.parse(x);
      let nomeArquivo = this.utilService.geraNomeArquivo(evento.titulo);
      let dadosExcel = this.utilService.preparaExcelCompetidorTrio(retorno.dadosTrio);
      if(dadosExcel.length > 0){
        let cabecalhoExcel = this.utilService.cabecalhoExcelCompetidorTrio();
        this.utilService.downloadExcel(cabecalhoExcel, dadosExcel, "inscricoes_" + nomeArquivo + "_trio.xls");
      }
      this.utilService.download("inscricoes_" + nomeArquivo + ".xml", retorno.data);
    }, erro =>{
      if(typeof erro.error == 'string'){
        erro.error = JSON.parse(erro.error);
      }
      this.utilService.mensagemErro(erro.error.mensagem);
    });
  }

  public irParaCadastroFoto(id_evento: number){
    this.router.navigate(['/comum/evento/' + id_evento +"/cadastro-foto-evento"]);
  }

  public irParaEditarFoto(fotoEvento: FotoEvento){
    this.router.navigate(['/comum/evento/' + fotoEvento.id_evento +"/editar-foto-evento/" + fotoEvento.id_foto_evento]);
  }

  public irParaCadastroResultado(){
    this.router.navigate(['/comum/evento/' + this.evento.id_evento +"/cadastro-resultado"]);
  }

  public irParaEditarResultado(){
    this.router.navigate(['/comum/evento/resultados/' + this.evento.id_evento]);
  }

  public excluirFotoEvento(id_foto_evento: number){
    this.fotoEventoService.excluir(id_foto_evento)
    .subscribe(
      () => {
        this.utilService.mensagemSucesso("Operação realizada com sucesso!");
        this.getEvento();
      },
      erro => this.utilService.mensagemErro("Erro - " + erro.error.mensagem)
    );
  }

  public finalizaInscricao(){
    this.utilService.mensagemConfirmacao("Deseja realmente finalizar o período de inscrição?", 
    resp => {
      if(resp){
        this.eventoService.finalizaInscricao(this.id_evento, this.utilService.getDataAgora())
        .subscribe(() => {
          this.utilService.mensagemSucesso('Operacao realizada com sucesso.');
          this.buscaEvento();
        }, erro => this.utilService.mensagemErro(erro.error.mensagem));
      }
    });
  }
  
  public gerarXmlCavalo(evento){
    this.cavaloService.XmlCavalo(evento.id_evento)
    .subscribe(retorno =>{
      if(retorno){
        this.utilService.download("cavalos.xml",retorno);
      }
    },erro=> {
      if(typeof erro.error == 'string'){
        erro.error = JSON.parse(erro.error);
      }
      this.utilService.mensagemErro(erro.error.mensagem)
    });

  }

  public exportarCompetidores(){
    this.usuarioService.exportarCompetidoresXml()
    .subscribe(retorno => {
      if(retorno){
        this.utilService.download("handicaps.xml",retorno);
      }
    }, erro => {
      if(typeof erro.error == 'string'){
        erro.error = JSON.parse(erro.error);
      }
      this.utilService.mensagemErro(erro.error.mensagem);
    });
  }
}