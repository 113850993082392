import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { NotificationsOrganizadorComponent } from './notifications-organizador.component';

@NgModule({
    declarations: [NotificationsOrganizadorComponent],
    imports: [
        CommonModule
    ],
    exports: [NotificationsOrganizadorComponent]
})
export class NotificationsOrganizadorModule {}