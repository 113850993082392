import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { RegraAssociacao } from '../model/regra-associacao';
import { AssociacaoCompetidor } from '../model/associacao-competidor';

@Injectable()
export class AssociacaoCompetidorService {

    private associacao_competidor_api_url = `${environment.api_url}/associacao-competidor`;

    constructor(private http: HttpClient) {}

    public buscaTodos(filtro : any): Observable<any> {
      return this.http.get<any>(`${this.associacao_competidor_api_url}-busca-todos?filtro=${JSON.stringify(filtro)}`);
    }

    public buscaPorId(id: number): Observable<AssociacaoCompetidor> {
      return this.http.get<AssociacaoCompetidor>(`${this.associacao_competidor_api_url}/${id}`);
    }

    public insere(associacaoCompetidor: AssociacaoCompetidor): Observable<AssociacaoCompetidor> {
      return this.http.post<AssociacaoCompetidor>(`${this.associacao_competidor_api_url}/inserir`, associacaoCompetidor);
    }

    public buscaTodosregra(): Observable<RegraAssociacao[]> {
      return this.http.get<RegraAssociacao[]>(`${environment.api_url}/regras-associacao-busca-todos`);
    }

    public alterar(id: number, associacaoCompetidor: AssociacaoCompetidor):
    Observable<AssociacaoCompetidor> {
      return this.http.put<AssociacaoCompetidor>(`${this.associacao_competidor_api_url}/alterar/${id}`,
      associacaoCompetidor);
    }

    public deleta(id: number): any {
      return this.http.delete<any>(`${this.associacao_competidor_api_url}/${id}`);
    }
    
    public efetuarPagementos(associacoes: AssociacaoCompetidor[], status: boolean): Observable<AssociacaoCompetidor[]>{
      return this.http.post<AssociacaoCompetidor[]>(`${this.associacao_competidor_api_url}/efetuar-pagamentos`, {associacoes, status});
    }
}
