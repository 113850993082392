import { Component, OnInit } from '@angular/core';
import { UsuarioService } from '../../../../app/service/usuario.service';
import { Usuario } from '../../../../app/model/usuario';
import { UtilService } from '../../../util/util.service';

@Component({
  selector: 'app-lista-competidores',
  templateUrl: './lista-competidores.component.html',
  styleUrls: ['./lista-competidores.component.css']
})
export class ListaCompetidoresComponent implements OnInit {

  public competidores : Usuario[] = [];
  public filtro : {
    nome : string,
    cpf : string,
    limit : number,
    offset : number,
    ativo : boolean
  } = {
    nome : null,
    cpf : null,
    limit : null,
    offset : null,
    ativo : true
  }

  public spinner : boolean = false;
  constructor(private usuarioService : UsuarioService,
              public utilService : UtilService) { }

  ngOnInit() {
    this.getCompetidores();
  }

  public getCompetidores()
  {
    this.spinner = true;
    this.usuarioService.buscaCompetidoresPorFiltro(this.filtro).subscribe(competidores => {
      this.spinner = false;
      this.competidores = competidores;
    }, erro => {
      this.utilService.mensagemErro(erro.error.mensagem);
      this.spinner = false;
    })
  }

  public getNomeSelecionado(nome){
    if (nome && nome.length >= 3) {
      this.filtro.nome = nome;
    } else {
      this.filtro.nome = '';
    }
    this.getCompetidores();
  }
  
  public getCpfSelecionado(cpf){
    if (cpf && cpf.length >= 3) {
      this.filtro.cpf = cpf;
    } else {
      this.filtro.cpf = '';
    }
    this.getCompetidores();
  }

  public exportarCompetidores(){
    this.usuarioService.exportarCompetidoresXml()
    .subscribe(retorno => {
      this.utilService.download("handicaps.xml",retorno);
    }, erro => {
      if(typeof erro.error == 'string'){
        erro.error = JSON.parse(erro.error);
      }
      this.utilService.mensagemErro(erro.error.mensagem);
    });
  }
}
