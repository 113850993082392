import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { environment } from '../../environments/environment';
import { InscricaoCompetidor } from '../model/inscricao_competidor';

@Injectable()
export class InscricaoCompetidorService {

    private inscricao_competidor_api_url = `${environment.api_url}/inscricoes-competidor`;

    constructor(private http: HttpClient) {}

    public buscaTodos(): Observable<InscricaoCompetidor[]> {
        return this.http.get<InscricaoCompetidor[]>(`${this.inscricao_competidor_api_url}`);
    }

    public buscaPorId(id: number): Observable<InscricaoCompetidor> {
        return this.http.get<InscricaoCompetidor>(`${this.inscricao_competidor_api_url}/${id}`);
    }

    public buscaInscricoesEmDuplasPorProvaId(id : number) : Observable<any[]>{
        return this.http.get<any[]>(`${this.inscricao_competidor_api_url}/busca-competidores-em-dupla/${id}`);
    }

    public buscaInfoInscricaoPotroFuturoPorProvaCavaloEvento(id_prova, id_cavalo, id_evento : number) :Observable<any>{
        return this.http.get<any>(`${this.inscricao_competidor_api_url}/buscar-informacao-potro-futuro`,{
            params : {
                'id_prova' : id_prova.toString(),
                'id_cavalo' : id_cavalo.toString(),
                'id_evento' : id_evento.toString()
            }
        })
    }

    public removePagamento(inscricao_competidor: InscricaoCompetidor): Observable<InscricaoCompetidor> {
        return this.http.put<InscricaoCompetidor>(`${this.inscricao_competidor_api_url}/remove-pagamento`, inscricao_competidor);
    }

    public removeListaPagamento(inscricao_competidor: InscricaoCompetidor[]): Observable<InscricaoCompetidor[]> {
        return this.http.put<InscricaoCompetidor[]>(`${this.inscricao_competidor_api_url}/remove-lista-pagamento`, inscricao_competidor);
    }

    public efetuarPagamento(listaInscricaoCompetidor : InscricaoCompetidor[]) : Observable<InscricaoCompetidor[]>{
        return this.http.put<InscricaoCompetidor[]>(`${this.inscricao_competidor_api_url}/efetuar-pagamento`,
         listaInscricaoCompetidor);
    }
    
    public insere(inscricao_competidor: InscricaoCompetidor): Observable<any> {
        return this.http.post<any>(`${this.inscricao_competidor_api_url}`, inscricao_competidor);
    }

    public altera(id: number, inscricao_competidor: InscricaoCompetidor): Observable<InscricaoCompetidor> {
        return this.http.put<InscricaoCompetidor>(`${this.inscricao_competidor_api_url}/alterar/${id}`, inscricao_competidor);
    }

    public deleta(id: number): Observable<InscricaoCompetidor> {
        return this.http.put<InscricaoCompetidor>(`${this.inscricao_competidor_api_url}/${id}`, undefined);
    }

    /*Busca o valor da inscrição, mas quando a inscrição já foi feita */
    public buscaPrecoDaInscricao(prova : any, id_competidor : number, id_evento : number, id_inscricao : number) : Observable<any>{
        return this.http.get<any>(`${this.inscricao_competidor_api_url}-busca-preco-inscricao-competidor`, {
            params : {
                prova : JSON.stringify(prova),
                id_competidor : id_competidor.toString(),
                id_evento : id_evento.toString(),
                id_inscricao : id_inscricao .toString()
            }
        })
    }

    /*Busca o valor da inscrição, mas quando a inscrição está sendo feita */
    public buscaValorDaInscricao(idProvas : any[], competidor, id_evento : number ) : Observable<any>{
        return this.http.get<any>(`${this.inscricao_competidor_api_url}-busca-valor-inscricao-competidor`,{
            params : {
                idProvas : JSON.stringify(idProvas),
                competidor : JSON.stringify(competidor),
                id_evento : id_evento.toString()
            }
        });
    }

    public buscaPorCadastrador(id_usuario : number, id_evento : number) : Observable<any>{
        return this.http.get<any>(`${this.inscricao_competidor_api_url}-busca-por-cadastrador`,{
            params : {
                id_usuario : id_usuario.toString(),
                id_evento : id_evento.toString()
            }
        });
    }

    public buscaPorCompetidor(id_usuario : number, id_evento : number) : Observable<any>{
        return this.http.get<any>(`${this.inscricao_competidor_api_url}-busca-por-competidor`,{
            params : {
                id_usuario : id_usuario.toString(),
                id_evento : id_evento.toString()
            }
        });
    }
}