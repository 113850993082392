import {Component, Inject, OnInit, Input} from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { UtilService } from '../../../util/util.service';
import { Noticia } from '../../../model/noticia';
import { NoticiaService } from '../../../service/noticia.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import {Portuguese} from 'flatpickr/dist/l10n/pt.js';
import flatpickr from 'flatpickr';
import { environment } from '../../../../environments/environment';

declare var $: any;
declare var UIkit: any;

@Component({
    selector: 'app-noticia-component',
    moduleId: module.id,
    templateUrl: './cadastro-noticia.component.html',
    styleUrls: ['./cadastro-noticia.style.css']
})

export class CadastroNoticiaComponent implements OnInit {

    public noticia: Noticia = new Noticia();
    public formCadastro: FormGroup;
    public tituloPagina: string = "Inserir notícia";

    private selectedFile: File = null;
    public imageToShow: any;
    public tipoCadastro : boolean = false;
    public nomeArquivo : string = null;
    private imagem_exibicao: string = null;
    public exibir_arquivo: boolean = false;

    constructor(@Inject(Router) private router: Router,
                @Inject(ActivatedRoute) private route: ActivatedRoute,
                @Inject(FormBuilder) private formBuilder: FormBuilder,
                @Inject(UtilService) private utilService: UtilService,
                @Inject(NoticiaService) private noticiaService: NoticiaService) {

            utilService.mudaTitulo('RSNC Brasil - Cadastro de Notícia');
    }

    ngOnInit() {
        this.initForm();
        let id_noticia = parseInt(this.route.snapshot.params["id_noticia"]);
        if(!isNaN(id_noticia)){
            this.noticia.id_noticia = id_noticia;
            this.tituloPagina = "Editar Notícia";
            this.buscaNoticia();
        }else{
            this.noticia.id_tipo_arquivo = 1;
            this.tipoCadastro = true;
            this.carregaUploadImagem();
        }
    }

    public initForm(){
        this.formCadastro = this.formBuilder.group({
            id: [this.noticia.id_noticia, []],
            titulo: [this.noticia.titulo, [
                Validators.required,
                Validators.minLength(3),
                Validators.maxLength(100)
            ]],
            texto: [this.noticia.texto, [
                Validators.required,
                Validators.minLength(5)
            ]],
            imagem_exibicao: [this.noticia.imagem_exibicao, [
                Validators.required
            ]],
            ativa: [this.noticia.ativa]
        });
    }

    public selecionaTipoCadastro(tipo : number) : void{
        if(tipo == 1 && this.noticia.id_tipo_arquivo != tipo){
            this.carregaUploadImagem();
            this.nomeArquivo = null;
        }
        this.noticia.id_tipo_arquivo = tipo;
        this.setImagemExibicao(null);
        this.imageToShow = null;
    }

    private carregaUploadImagem(){
        let escopo = this;
        setTimeout(function(){ escopo.uploadImagem() }, 1000);
    }

    public salvar() {
        this.noticia.id_autor = this.utilService.getIdLogado();
        if (this.formCadastro.invalid) {
            this.utilService.destacarErros(this.formCadastro);
            return false;
        }
        this.requisicao();
    }

    private requisicao() {
        if (this.noticia.id_noticia) {
            this.noticiaService.altera(this.noticia.id_noticia, this.noticia).subscribe(noticia => {
                this.utilService.mensagemSucesso("Notícia alterada com sucesso!");
                this.redireciona();
            },(erro) => this.utilService.mensagemErro(erro.error.mensagem));
        } else {
            this.noticia.id_tipo_noticia = 1;
            this.noticiaService.buscaProximaNoticia().subscribe(id => {
                this.noticia.id_referencia_noticia = id[0].id_noticia;
                if(this.noticia.ativa != true) {
                    this.noticia.ativa = false;
                }
                this.noticiaService.insere(this.noticia).subscribe(noticia => {
                    this.utilService.mensagemSucesso("Notícia inserida com sucesso!");
                    this.redireciona();
                }, (erro) => this.utilService.mensagemErro(erro.error.mensagem));
            });
        }
    }

    private redireciona() {
        this.utilService.irPara(this.router, '/comum/noticias/');
    }

    private uploadImagem(){
        var bar = <HTMLInputElement>document.getElementById('js-progressbar');
        let uploadURL = this.noticiaService.upload_imagem_api_url;
        const formCadastroEvento = this;
        
        UIkit.upload('.js-upload', {

            url: uploadURL,
            multiple: false,
            mime: '.jpg, .png, .jpeg, .gif, .bmp, .tif, .tiff|image/*',

            loadStart: function (e) {
                bar.removeAttribute('hidden');
                bar.max = e.total;
                bar.value = e.loaded;
            },

            progress: function (e) {
                bar.max = e.total;
                bar.value = e.loaded;
            },

            loadEnd: function (e) {
                let response = JSON.parse(e.srcElement.response);
                formCadastroEvento.atribuiValorImagem(response);
                bar.max = e.total;
                bar.value = e.loaded;
            },
            
            completeAll: function (e) {
                setTimeout(function () {
                    bar.setAttribute('hidden', 'hidden');
                }, 1000);
            },

            fail: function(){
                alert('Formato de arquivo não suportado');
            }

        });

    }

    public fileChange(event : any){
        let file = event.target.files[0];
        this.nomeArquivo = file.name;
        this.upload(file);
    }

    public upload(file){
        let formData = new FormData();
        formData.append('file', file)
        this.noticiaService.uploadPDF(formData).subscribe(retorno => {
            if(this.noticia.imagem_exibicao && this.noticia.imagem_exibicao.includes(this.noticia.titulo)){
                this.noticia.imagem_old = this.noticia.imagem_exibicao;
            }
            this.setImagemExibicao(retorno.path);
        })
    }

    public atribuiValorImagem(response: any):void{
        if(this.noticia.imagem_exibicao && this.noticia.imagem_exibicao.includes(this.noticia.titulo)){
            this.noticia.imagem_old = this.noticia.imagem_exibicao;
        }
        this.setImagemExibicao(response.path);
        this.imageToShow = response.img;
    }

    public setImagemExibicao(valor : string) : void{
        this.exibir_arquivo = false;
        this.noticia.imagem_exibicao = valor;
        this.formCadastro.controls['imagem_exibicao'].setValue(valor);
    }

    public buscaNoticia(){
        this.noticiaService.buscaPorId(this.noticia.id_noticia)
        .subscribe(retorno => {
            this.noticia = retorno;
            this.noticia.imagem_old = this.noticia.imagem_exibicao;
            this.imagem_exibicao = this.noticia.imagem_exibicao;
            this.formCadastro.controls['imagem_exibicao'].setValue(this.imagem_exibicao);
            this.getImgNoticia(this.noticia);
            if(this.noticia.id_tipo_arquivo == 1){
                this.carregaUploadImagem();
            }
        }, erro => this.utilService.mensagemErro("Erro - " + erro.error.message));
    }

    getImgNoticia(noticia: Noticia){
        if(noticia.id_tipo_arquivo == 1){
            noticia.arquivo_imagem = this.utilService.buscaImagem(noticia);
        }else if(noticia.id_tipo_arquivo == 3){
            noticia.arquivo_imagem = this.utilService.buscaPdf(noticia);
        }
        this.exibir_arquivo = true;
    }
}
