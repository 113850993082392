import {Component, OnInit, Inject, ChangeDetectorRef} from '@angular/core';
import { UtilService } from '../util/util.service';
import { Router } from '@angular/router';
import { UsuarioService } from '../service/usuario.service';
import { environment } from '../../environments/environment';

import { PubSub } from 'pubsub-js';
import { Usuario } from '../model/usuario';
import { UsuarioSemCadastroService } from '../service/usuario-sem-cadastro.service';
import { Keepalive } from '@ng-idle/keepalive';
import { Idle, DEFAULT_INTERRUPTSOURCES } from '@ng-idle/core';
import { Observable } from 'rxjs';
import 'rxjs/add/observable/interval';

declare var UIkit: any;
declare var $: any;

@Component({
  selector: 'app-comum-component',
  moduleId: module.id,
  templateUrl: './comum.component.html',
  styleUrls: ['./comum.style.css']
})

export class ComumComponent implements OnInit {
  
  private competidores: Usuario[];
  public offcanvasMenu: any;
  private idleState = 'Not started.';
  private timedOut = false;
  private lastPing?: Date = null;
  private title = 'angular-idle-timeout';
  public isLogado = false;
  public idUsuario : number;
  private timer: any;
  public qtdVerificacoesPendentes: number;

  constructor(public utilService: UtilService,
              @Inject(Router) private router: Router,
              private usuarioService: UsuarioService, 
              private ref: ChangeDetectorRef,
              private usuarioSemCadastroService : UsuarioSemCadastroService,
              private idle: Idle,
              private keepalive: Keepalive) {}

  ngOnInit() {
    this.offcanvasMenu = UIkit.offcanvas('#offcanvas-slide');
    this.isLogado = this.utilService.isLogado();
    this.idUsuario = this.utilService.getIdLogado();
    this.verificaQuantidadeDeCompetidoresPendentes();
    if(this.isLogado && this.utilService.isAdm()){
      this.inatividade();
      this.pendentes();
    }
    PubSub.subscribe('QTD', (msg, data) => this.qtdVerificacoesPendentes = data);
    PubSub.subscribe('LOGADO', (msg, data) => {
      this.isLogado = data;
      this.inatividade();
      this.pendentes();
    });
  }

  connect(): void {
    let source = new EventSource(`${environment.api_url}/usuarios-busca-informacoes`);
    source.onmessage = e => {
      this.competidores = JSON.parse(e.data);
      this.qtdVerificacoesPendentes = this.competidores.length;
      this.ref.detach();
      this.ref.detectChanges();
      this.ref.reattach();
    };
  }
  
  private verificaQuantidadeDeCompetidoresPendentes(): void {
    this.usuarioService.buscaCompetidoresPendentes().subscribe(usuarios => {
      this.qtdVerificacoesPendentes = usuarios.length;
      this.buscaPendentesSemCadastro();
    })
  }

  private buscaPendentesSemCadastro(){
    this.usuarioSemCadastroService.buscaPendentes()
    .subscribe(usc => {
      if(this.qtdVerificacoesPendentes){
        this.qtdVerificacoesPendentes = this.qtdVerificacoesPendentes > 0 ?
         this.qtdVerificacoesPendentes + usc.length : usc.length;
      }else{
        this.qtdVerificacoesPendentes = usc.length;
      }
    });
  }

  public login() {
    this.utilService.clearCookies();
    this.utilService.irPara(this.router, '/comum/login');
  }

  public listaDeInscricao(){
    this.utilService.irPara(this.router, '/comum/lista-inscricoes');
  }

  public sair(): void {
    this.utilService.mensagemConfirmacao('Deseja fazer o logout do Portal RSNC - Brazil?', (result) => {
      if (result) {
        this.utilService.clearCookies();
        this.isLogado = false;
        this.utilService.irPara(this.router, '/comum/inicio');
      }
    });
  }

  private refirecionarParaCompetidoresPendentes() {
    this.utilService.irPara(this.router, '/comum/competidores-pendentes')
  }
  
  private verificaNotificacoesDoisDigitos(): boolean {
    return this.qtdVerificacoesPendentes >= 10;
  }

  private modificaQtdCompetidoresPendentes(evento): void {
    this.qtdVerificacoesPendentes = evento;
  }

  private inatividade(){
    // console.log('inatividade .........')
    // sets an idle timeout of 900 seconds/ 15 min, for testing purposes.
    this.idle.setIdle(900);

    // sets a timeout period of 10 seconds. after 10 seconds of inactivity, the user will be considered timed out.
    this.idle.setTimeout(10);
    // sets the default interrupts, in this case, things like clicks, scrolls, touches to the document
    this.idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);

    this.idle.onIdleEnd.subscribe(() => { 
      this.idleState = 'No longer idle.';
      this.reset();
    });
    
    this.idle.onTimeout.subscribe(() => {
      this.timedOut = true;
      this.utilService.mensagemInfo("Tempo esgotado!", () => {
        this.utilService.clearCookies();
        this.isLogado = false;
        this.utilService.irPara(this.router, '/comum/login');
      });
    });
    
    this.idle.onIdleStart.subscribe(() => {
      this.utilService.mensagemInfo('Você ficou inativo!');
    });
    
    this.idle.onTimeoutWarning.subscribe((countdown) => {
      this.utilService.mensagemInfo('Você atingirá o tempo limite em '+ countdown+' segundos!');
    });

    // sets the ping interval to 15 seconds
    this.keepalive.interval(840);

    this.keepalive.onPing.subscribe(() => this.lastPing = new Date());

    this.reset();
  }

  private reset() {
    this.idle.watch();
    this.idleState = 'Started.';
    this.timedOut = false;
  }

  private pendentes(){
    if(this.verificaPerfil()){
      this.timer = Observable.interval(180000)
      .subscribe((val) => {
        if(this.verificaPerfil()){
          this.verificaQuantidadeDeCompetidoresPendentes();
        }
      });
    }
  }

  private verificaPerfil():boolean{
    return this.utilService.isLogado() && this.utilService.isAdm();
  }
}
