import { PerguntaHandicap } from './pergunta_handicap';
import { RespostaHandicap } from './resposta_handicap';

export class RespostaPergunta{
    id_pergunta : number;
    id_resposta : number;
    id_usuario : number;
    sem_cadastro : boolean;
    pergunta : PerguntaHandicap;
    resposta : RespostaHandicap;
}