import { Component, Input, Inject, OnInit, EventEmitter, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Treinador } from "../../../model/treinador";
import { UtilService } from '../../../util/util.service';
import { Router } from '@angular/router';
import { TreinadoresService } from '../../../service/treinador.service';

declare var UIkit: any;

@Component({
    selector: 'app-treinador-modal',
    templateUrl: './treinador-modal.component.html',
    styleUrls: ['./treinador-modal.style.css']
})
export class TreinadorModalComponent implements OnInit{
    
    @Input() treinador: Treinador;
    @Output() deleteEvent = new EventEmitter<Treinador>();

    constructor(@Inject(Router) private router: Router,
                public utilService: UtilService,
                private treinadorService: TreinadoresService){}
    
    ngOnInit(){
    }

    public abreEdicaoTreinador(treinador: Treinador){
        this.utilService.irPara(this.router, `/comum/editar-treinador/${treinador.id_treinador}`);
        UIkit.modal('#modal-treinador').hide();
    }

    public excluirTreinador(treinador: Treinador){
        this.utilService.mensagemConfirmacao(`Deseja remover o treinador ${treinador.nome}`, confirma => {
            if(confirma){
                this.treinadorService.deleta(treinador.id_treinador).subscribe(success => {
                    this.utilService.mensagemSucesso('Treinador removido com sucesso');
                    this.deleteEvent.emit();
                },err => {
                    this.utilService.mensagemErro(err.error.mensagem);
                });
                UIkit.modal('#modal-treinador').hide();
            }
        });
    }

    public abreModalImagem(treinador: Treinador){
        if(!treinador.arquivo_imagem){
            treinador.arquivo_imagem = this.utilService.buscaImagem(treinador);
        }
        UIkit.modal('#modal-media-treinador',{
            bgClose: false
        }).show();
    }
}