import { Component, OnInit, Output, EventEmitter, Inject, Input } from '@angular/core';
import { Router } from '@angular/router';

import { UtilService } from '../../../../util/util.service';
import { Campeonato } from '../../../../model/campeonato';
import { Evento } from '../../../../model/evento';
import { Prova } from '../../../../model/prova';
import { ProvaService } from '../../../../service/prova.service';

@Component({
    selector: 'escolha-quantidade-de-competidores',
    templateUrl: './escolha-quantidade-de-competidores.component.html',
    styleUrls: ['./escolha-quantidade-de-competidores.style.css', './../../inscricoes.style.css']
})
export class EscolhaQuantidadeDeCompetidoresComponent implements OnInit {

    @Input() pagina: number;

    @Input() campeonato: Campeonato = new Campeonato();
    @Input() evento: Evento = new Evento();
    
    @Output() qtdCompetidoresEmitter: EventEmitter<number> = new EventEmitter<number>();
    @Output() paginaEmitter: EventEmitter<number> = new EventEmitter<number>();
    
    public individual : boolean = false;
    public dupla : boolean = false;
    public trio : boolean = false;
    public provas : Prova[] = [];

    constructor(private utilService: UtilService,
                private provaService : ProvaService,
                @Inject(Router) private router: Router) {}

    ngOnInit(): void {
        this.provas = this.provaService.getProvas();
        this.provas.forEach(prova => {
            if(prova.tipo_prova == 1 || prova.draw == true) {
                this.individual = true;
            }
            if(prova.tipo_prova == 2){
                this.dupla = true;
            }
            if(prova.tipo_prova == 3){
                this.trio = true;
            }
        });
    }

    public setNumeroInscritos(numero: number): void {
        this.qtdCompetidoresEmitter.emit(numero);
    }

    public prosseguir(): void {
        this.paginaEmitter.emit(1);
    }

    public paginaAnterior(): void {
        this.utilService.voltarParaChamador(this.router, ['/comum/evento/'+this.evento.id_evento]);
    }
}