import { Injectable, Inject } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';

import { Usuario } from '../../model/usuario';
import { UsuarioService } from '../../service/usuario.service';
import { UtilService } from '../../util/util.service';

@Injectable()
export class UsuarioInscricaoResolver implements Resolve<Observable<Usuario>> {

    constructor(private service: UsuarioService,
                private utilService: UtilService,
                @Inject(Router) private router: Router){}
                
    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<Usuario> {
        
        let usuario_logado = this.utilService.getIdLogado();

        if (usuario_logado){
            return this.service.buscaPorId(this.utilService.getIdLogado());
        } else {
            this.utilService.mensagemErro('Usuario não está logado', () => {
                this.utilService.irPara(this.router, '/comum/eventos');
            });
        }
    }

}