import { Component, OnInit } from '@angular/core';
import { UtilService } from '../../util/util.service';
import { EventoService } from '../../service/evento.service';
import { DivisaoService } from '../../service/divisao.service';
import { InscricaoService } from '../../service/inscricao.service';
import { HistoricoGeralFilter } from '../../filter/historico-geral.filter';
import { Select } from '../../model/select';
import { CampeonatoService } from '../../service/campeonato.service';

@Component({
  selector: 'app-historico-geral',
  templateUrl: './historico-geral.component.html',
  styleUrls: ['./historico-geral.component.css', '../../comum/comum.style.css']
})
export class HistoricoGeralComponent implements OnInit {

  public campeonatos: Select[] = [];
  public eventos: Select[] = [];
  public divisoes: Select[] = [];
  public filtroHistorico: HistoricoGeralFilter = new HistoricoGeralFilter();
  public participantes : Select[] = [];

  constructor(public utilService: UtilService,
              private campeonatoService: CampeonatoService,
              private eventoService: EventoService,
              private divisaoService: DivisaoService,
              private inscricaoService: InscricaoService) { }

  ngOnInit() {
    this.filtroHistorico.participante = "Competidor";
    this.utilService.montaDatePicker('data');
    this.initParticipante();
    this.buscaCampeonato();
    this.buscaEvento();
    this.buscaDivisao();
  }

  private initParticipante(){
    this.participantes = [
      {label: "Competidor", value: "Competidor"},
      {label: "Cavalo", value: "Cavalo"}
    ]
  }

  private buscaCampeonato(){
    this.campeonatoService.buscaTodos(null, null)
    .subscribe(
      campeonatos => this.campeonatos = Select.converterParaDropDown(campeonatos, "nome", "id_campeonato")
    );
  }

  private buscaEvento(){
    this.eventoService.buscaTodos()
    .subscribe(
      eventos => this.eventos = Select.converterParaDropDown(eventos, "titulo", "id_evento")
    );
  }

  private buscaDivisao(){
    this.divisaoService.buscaTodos()
    .subscribe(
      divisoes => this.divisoes = Select.converterParaDropDown(divisoes, "nome", "id_divisao")
    );
  }

  public selecionaDivisao(valor: string){
    this.filtroHistorico.id_divisao = valor == '' ? null : Number(valor);
  }

  public selecionaParticipante(participante: string){
    this.filtroHistorico.participante = participante;
  }

  public selecionaCampeonato(valor: string){
    this.filtroHistorico.id_campeonato = valor == '' ? null : Number(valor);
  }

  public pesquisar(){

  }

  public limpar(){
    this.filtroHistorico.id_campeonato = null;
    this.filtroHistorico.data_evento = null;
    this.filtroHistorico.id_divisao = null;
  }

}
