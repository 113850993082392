import { Injectable, Inject } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';

import { environment } from '../../environments/environment';
import { Noticia } from '../model/noticia';
import { UtilService } from '../util/util.service';
import { Router } from '@angular/router';
import { ParametrosBusca } from '../model/parametros_busca';

@Injectable()
export class NoticiaService {

    private noticia_api_url = `${environment.api_url}/noticias`;
    public upload_imagem_api_url = `${environment.api_url}/file/upload`;
    public upload_pdf_api_url = `${environment.api_url}/file/upload-pdf`;
    private DEFAULT_IMG = 'noticia_default_img.jpg';

    constructor(private http: HttpClient,
                private utilService: UtilService,
                @Inject(Router) private router: Router,) {}

    public buscaTodos(): Observable<Noticia[]> {
      return this.http.get<Noticia[]>(`${this.noticia_api_url}`);
    }

    public buscaParaExibicao(): Observable<Noticia[]> {
      return this.http.get<Noticia[]>(`${this.noticia_api_url}/para_exibicao`);
    }

    public buscaPorId(id: number): Observable<Noticia> {
      return this.http.get<Noticia>(`${this.noticia_api_url}/${id}`);
    }

    public insere(noticia: Noticia): Observable<any> {
      return this.http.post<any>(`${this.noticia_api_url}`, noticia);
    }

    // Método utilizado para criar uma notícia com base em campeonato ou evento existente
    public criaNoticia(noticia: Noticia): Observable<any> {
      return this.http.post<any>(`${this.noticia_api_url}/cria-noticia`, noticia);
    }

    public altera(id: number, noticia: Noticia): Observable<any> {
      return this.http.put<any>(`${this.noticia_api_url}/${id}`, noticia);
    }

    public deleta(id: number): any {
      return this.http.delete<any>(`${this.noticia_api_url}/${id}`);
    }

    public deletaPorIdReferencia(id_referencia: number, id_tipo: number): any {
      return this.http.delete<any>(`${this.noticia_api_url}/remover-por-referencia${id_referencia}`, {
        params : {
          id_tipo : id_tipo.toString()
        }
      });
    }

    public uploadImagem(formData: FormData): Observable<any> {
      return this.http.post(`${environment.api_url}/upload-imagem`, formData);
    }

    public uploadPDF(formData: FormData): Observable<any> {
      return this.http.post(`${this.upload_pdf_api_url}`, formData);
    }

    redirecionaOrigem(tipo_noticia, id_referencia) {
      if(tipo_noticia == 1) {
        this.redirecionaNoticia(id_referencia);
      } else if(tipo_noticia == 2) {
        this.redirecionaCampeonato(id_referencia);
      } else if(tipo_noticia == 3) {
        this.redirecionaEvento(id_referencia);
      }
    }

    public redirecionaNoticia(id_noticia) {
      this.utilService.irPara(this.router, '/comum/noticia/' + id_noticia);
    }

    public redirecionaCampeonato(id_campeonato) {
      this.utilService.irPara(this.router, `/comum/campeonatos/detalhes/${id_campeonato}`);  
    }

    public redirecionaEvento(id_evento) {
      this.utilService.irPara(this.router, '/comum/evento/' + id_evento);
    }

    public buscaProximaNoticia(): Observable<Noticia[]> {
      return this.http.get<any>(`${this.noticia_api_url}/busca-proxima-noticia`);
    }

    public buscaTodosFiltro(filtro, options: ParametrosBusca): Observable<Noticia[]> {
      return this.http.post<Noticia[]>(`${this.noticia_api_url}/buscar-filtro`, filtro, {
        params: {
          'offset': options.offset.toString(),
          'limit': options.limit.toString()
        }
      });
    }

    public buscaQuantidadeRegistros(filtro, options: ParametrosBusca): Observable<number> {
      return this.http.post<number>(`${this.noticia_api_url}/buscar-quantidade-registros`, filtro, {
        params: {
          'offset': options.offset.toString(),
          'limit': options.limit.toString()
        }
      });
    }

    public ativaDesativa(id_noticia: number, ativa: boolean): Observable<Noticia> {
      return this.http.post<Noticia>(`${this.noticia_api_url}/ativa-desativa`, {
        params: {
          'id_noticia': id_noticia,
          'ativa': ativa
        }
      });
    }
}
