import { Regra } from './regra';
import { ProvaRacas } from './prova_racas';

export class Divisao {
    id_divisao: number;
    nome: string;
    ativo: boolean;
    nao_pontuar: boolean;
    nao_premiar: boolean;
    tempo_divisao: number;
    rebatedor_apartador: boolean;
    potro_futuro : boolean;
    regras: Regra[];
    nao_exigir_cadastro: boolean;
    id_raca: number;
    is_todos_contra_todos: boolean;
    id_tipo_inscricao: number;
    status : number;
    draw : boolean;
    preco_inscricao : string;
    somatorio_minimo : number;
    somatorio_maximo : number;
    numero_maximo_inscricao_competidor : number;
    qtd_maxima_competidor : number;
    qtd_maxima_inscricao_dupla : number;
    qtd_maxima_inscricao_trio : number;
    qtd_maxima_inscricao_cavalo : number;
    porcentagem_premiacao : string;
    status_atualizacao : boolean;
    taxa_administrativa : string;
    racasPontuar: ProvaRacas[];
    tempo_diferencia : number;
    incremento_premiacao : string;
    limite_inscricao: number;
}