import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { Treinador } from '../../model/treinador';
import { TreinadoresService } from '../../service/treinador.service';

@Injectable()
export class TreinadoresResolver implements Resolve<Observable<Treinador[]>> {

    constructor(private service: TreinadoresService){}

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<Treinador[]> {
        
        return this.service.buscaTodos();
    }
}