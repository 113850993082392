import { OnInit, Component, OnDestroy, OnChanges, Inject, Input, Output, EventEmitter } from '@angular/core';
import { UtilService } from '../../util/util.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { CampeonatoService } from '../../service/campeonato.service';
import { CampeonatoBuscaService } from './campeonatosBusca.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ParametrosBusca } from '../../model/parametros_busca';
import { Campeonato } from '../../model/campeonato';
import { EventoService } from '../../../app/service/evento.service';
import { UsuarioService } from '../../../app/service/usuario.service';

declare var $: any
declare var UIkit: any;

@Component({
    selector: 'app-campeonatos',
    templateUrl: './campeonatos.component.html',
    styleUrls: ['./campeonatos.style.css']
})
export class CampeonatosComponent implements OnInit, OnDestroy, OnChanges {

    public filtro: {
        nome: string,
        data_inicial: Date,
        data_final: Date,
        campeonato_finalizado: boolean
    } = {
        nome: null,
        data_inicial: null,
        data_final: null,
        campeonato_finalizado: null
    };

    public filtroEvento : {
        nome : string,
        data_inicial : string
    } = {
        nome : null,
        data_inicial : null
    }

    public formFiltro: FormGroup;
    public parametrosBusca = new ParametrosBusca();
    options: ParametrosBusca = new ParametrosBusca();
    campeonatos: Campeonato[] = [];

    public podeVoltar: boolean;
    public podeProsseguir = true;
    
    private botoesEsquerda: any[] = new Array(3);
    private botaoCentro: any;
    private botoesDireita: any[] = new Array(3);

    public botoes: any[] = new Array();

    private paginasDireita: number;
    private paginasEsquerda: number;
    private pagina = 1;
    private totalPaginas: number;

    private paginasLaterais = 2;
    private quantidadeRegistros: number;

    constructor(private formBuilder: FormBuilder,
        public utilService: UtilService,
        private campeonatoBuscaService: CampeonatoBuscaService,
        private activatedRoute: ActivatedRoute,
        private router: Router,
        private campeonatoService: CampeonatoService,
        private eventoService : EventoService,
        private usuarioService : UsuarioService) {
        
            this.pagina = 1;
            this.parametrosBusca.pagina = this.pagina;
            this.options.offset = 0;
            this.options.limit = 5;
            this.filtrar();
        }

    ngOnInit(): void {
    }

    onChanges() {
    }
    
    ngOnChanges(changes: import("@angular/core").SimpleChanges): void {
    }

    ngOnDestroy(): void {
    }

    /* ----- SEARCH ----- */

    public filtrar(): void {
        let filtro
        filtro = this.filtro;

        filtro.nome = $('#nome').val();
        
        let check_ativo = $('#campeonato_ativo').is(":checked");
        let check_finalizado = $('#campeonato_finalizado').is(":checked");

        this.options.offset = 0;
        this.options.offset = (this.pagina-1) * this.options.limit;

        if(check_ativo && filtro.campeonato_finalizado) {
            filtro.campeonato_finalizado = null;
        } else if (check_ativo) {
            filtro.campeonato_finalizado = false;
        } else if (!check_ativo && !filtro.campeonato_finalizado) {
            filtro.campeonato_finalizado = null;
        }
        
        this.campeonatoBuscaService.filtro = filtro;

        this.campeonatoService.buscaTodosFiltro2({
            "filtro": filtro
        }, this.options).subscribe(campeonatos => {
                if (campeonatos) {
                    this.getImgCampeonato(campeonatos);
                    this.getThumbnailCampeonato(campeonatos);
                    this.campeonatos = campeonatos; 
                    this.campeonatos.forEach(campeonato => {
                        campeonato.eventos = [];
                        this.eventoService.buscaEventosDeUmCampeonato(campeonato.id_campeonato)
                            .subscribe(eventos => campeonato.eventos = eventos);
                        this.usuarioService.buscaPorId(campeonato.id_organizador)
                            .subscribe(organizador => campeonato.organizador = organizador);
                    });
                }
            });
        this.campeonatoService.buscaQuantidadeRegistros({
            "filtro": filtro
        }, this.options).subscribe(quantidade => {
            this.quantidadeRegistros = quantidade[0].count;
            this.geraBotoes();
            this.mudarInfoBtns();
        })
    }
    
    reiniciaPagina() {
        this.pagina = 1;
    }

    public limpar(): void {
        this.options.offset = 0;
        this.pagina = 1;
        
        this.campeonatoBuscaService.filtro = {
            nome: null,
            data_inicial: null,
            data_final: null,
            campeonato_finalizado: null
        };

        $('#nome').val('');

        this.campeonatoService.buscaTodosFiltro2({
            "filtro": this.campeonatoBuscaService.filtro
        }, this.options).subscribe(campeonatos => {
                if (campeonatos) {
                    this.getImgCampeonato(campeonatos);
                    this.getThumbnailCampeonato(campeonatos);
                    this.campeonatos = campeonatos;
                    this.campeonatos.forEach(campeonato => {
                        this.eventoService.buscaEventosDeUmCampeonato(campeonato.id_campeonato)
                            .subscribe(eventos => campeonato.eventos = eventos);
                        this.usuarioService.buscaPorId(campeonato.id_organizador)
                            .subscribe(organizador => campeonato.organizador = organizador);
                    });
                }
            });
        
        this.campeonatoService.buscaQuantidadeRegistros({
            "filtro": this.campeonatoBuscaService.filtro
        }, this.options).subscribe(quantidade => {
            this.quantidadeRegistros = quantidade[0].count;
            this.geraBotoes();
            this.mudarInfoBtns();
        })
    }

    private geraBotoes() {
        this.botoes = [];
        for(let i = 0; i < this.quantidadeRegistros/5; i++ ) {
            this.botoes.push(i+1);
        }
        this.totalPaginas = this.botoes.length;
        this.parametrosBusca.totalPaginas = this.totalPaginas;
    }

    public irParaPagina(pagina) :void {
        
        this.options.offset = (pagina-1)*5;

        this.campeonatoService.buscaTodosFiltro2({
            "filtro": this.campeonatoBuscaService.filtro
        }, this.options).subscribe(campeonatos => {
                if (campeonatos) {
                    this.getImgCampeonato(campeonatos);
                    this.getThumbnailCampeonato(campeonatos);
                    this.campeonatos = campeonatos;
                    this.campeonatos.forEach(campeonato => {
                        this.eventoService.buscaEventosDeUmCampeonato(campeonato.id_campeonato)
                            .subscribe(eventos => campeonato.eventos = eventos);
                        this.usuarioService.buscaPorId(campeonato.id_organizador)
                            .subscribe(organizador => campeonato.organizador = organizador);
                    });
                }
            });
    }
    
    private getThumbnailCampeonato(campeonatos: Campeonato[]){
        campeonatos.forEach(campeonato => {
            campeonato.thumbnail =  this.utilService.buscaThumbnail(campeonato);
        });
    }
    
    private getImgCampeonato(campeonatos: Campeonato[]){
        campeonatos.forEach(campeonato => {
            campeonato.arquivo_imagem =  this.utilService.buscaImagem(campeonato);
        });
    }


    /* ----- ACCORDION ----- */

    irParaDetalhes(id: any) {
        this.utilService.irPara(this.router, `/comum/campeonatos/detalhes/${id}`);   
    }

    public criarEvento(id_campeonato) {
        //this.utilService.irPara(this.router, '/comum/cadastro-evento/' + id_campeonato);
        this.utilService.irPara(this.router, '/comum/cadastro-evento/', {
            'idCampeonato': id_campeonato
        });
    }

    private redirecionaEditar(id_campeonato: number): void {
        this.utilService.irPara(this.router, '/comum/editar-campeonato/' + id_campeonato);
    }

    private excluirCampeonato(id_campeonato: number): void {
        this.campeonatoService.buscaPorId2(id_campeonato).subscribe(campeonato => {
            this.utilService.mensagemConfirmacao(`Deseja excluir o campeonato ${campeonato.nome}`, (result) => {
                if (result) {
                    this.campeonatoService.deleta(id_campeonato).subscribe(campeonato => {
                        this.filtrar();
                    });
                    this.irParaPrimeiraPagina();
                }
            });
        });
    }

    /* ----- INFORMACOES-CAMPEONATO ----- */

    public redireciona(id_campeonato) {
        this.utilService.irPara(this.router, `/comum/campeonatos/detalhes/${id_campeonato}`);        
    }

    private abreImgExpandida() {
        UIkit.modal('#modal-imagem-campeonato',{
            bgClose: false
        }).show();
    }

    /* ----- PAGINATION ----- */

    private geraBotoesPaginacao() {
        let paginas = this.parametrosBusca.totalPaginas;
        for(let i=0; i<paginas; i++) {
            this.botoes[i] = i+1;
        }
    }

    private configurarPagination() {    
        this.podeIrParaPaginaAnterior();
        this.podeIrParaProximaPagina();
    }

    private mudarInfoBtns() {

        if(this.pagina > 1) {
            this.podeVoltar = true;
        } else {
            this.podeVoltar = false;
        }

        if(this.pagina < this.totalPaginas) {
            this.podeProsseguir = true;
        } else {
            this.podeProsseguir = false;
        }
    }

    public podeIrParaPaginaAnterior(): void {
        this.podeVoltar = this.parametrosBusca.pagina != 1;
    }

    private podeIrParaProximaPagina(): void {
        this.podeProsseguir = (this.parametrosBusca.pagina != this.parametrosBusca.totalPaginas) &&
                               (this.parametrosBusca.totalPaginas > 0);
    }

    public irParaPrimeiraPagina(): void {
        this.pagina = 1;
        this.parametrosBusca.pagina = 1;
        this.parametrosBusca.offset = 0;
        this.configurarPagination();
        this.filtrar();
    }

    public irParaPaginaAnterior(): void {
        this.pagina--;
        this.parametrosBusca.pagina--;
        this.parametrosBusca.offset -= this.parametrosBusca.limit;
        this.configurarPagination();
        this.filtrar();
    }

    private pularParaPaginaEspecifica(pagina: number): void {
        this.pagina = pagina;
        this.parametrosBusca.offset = (pagina * this.parametrosBusca.limit) - this.parametrosBusca.limit;
        this.configurarPagination();
        this.filtrar();
    }

    public irParaProximaPagina(): void {
        this.parametrosBusca.pagina++;
        this.pagina++;
        this.parametrosBusca.offset += this.parametrosBusca.limit;
        this.configurarPagination();
        this.filtrar();
    }

    public irParaUltimaPagina(): void {
        this.pagina = this.totalPaginas;
        this.parametrosBusca.pagina = this.parametrosBusca.totalPaginas;
        this.parametrosBusca.offset = (this.parametrosBusca.totalPaginas * this.parametrosBusca.limit) - this.parametrosBusca.limit;
        this.configurarPagination();
        this.filtrar();
    }

    private exibeBotao(botao) {
        let exibe = false;

        if(botao <= this.pagina + this.paginasLaterais 
            && botao >= this.pagina - this.paginasLaterais) {
            exibe = true;
        } else if((this.pagina - this.paginasLaterais) <= 0 && (botao == 4 || botao == 5)) {
            exibe = true;
        } else if((this.pagina + this.paginasLaterais) >= this.totalPaginas 
            && (botao >= (this.totalPaginas - this.paginasLaterais*2))) {
            exibe = true;
        }
        
        return exibe;
    }

    private replicarCampeonato(campeonato: Campeonato): void {
        this.utilService.irPara(this.router, '/comum/cadastro-campeonato/', {
            'idCampeonatoReplicado': campeonato.id_campeonato
        });
    }

}