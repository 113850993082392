import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { DirectiveModule } from '../../directive/directive.module';
import { FinanceiroPessoalComponent } from './financeiro-pessoal/financeiro-pessoal.component';
import { RouterModule } from '@angular/router';

@NgModule({
    declarations: [
        FinanceiroPessoalComponent
    ],
    imports: [
        CommonModule,
        BrowserModule,
        DirectiveModule,
        RouterModule
    ],
    exports: [FinanceiroPessoalComponent],
    providers: []
})
export class UsuarioModule {}