import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { environment } from '../../../environments/environment';
import { Campeonato } from '../../model/campeonato';
import { ParametrosBusca } from '../../model/parametros_busca';

@Injectable()
export class CampeonatoBuscaService {
    
    public campeonatos: Campeonato[] = [];
    public paginas: number;
    options: ParametrosBusca = new ParametrosBusca();
    
    public filtro: {
        nome: string,
        data_inicial: Date,
        data_final: Date,
        campeonato_finalizado: boolean
    } = {
        nome: null,
        data_inicial: null,
        data_final: null,
        campeonato_finalizado: null
    };

    constructor(private http: HttpClient) {
        this.options.offset = 0;
        this.options.limit = 5;
    }
}
