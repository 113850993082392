export class ResultadoCampeonato {
    
    id_resultado_campeonato: number;
    id_campeonato: number;
    id_cadastrador: number;
    id_tipo_arquivo: number;
    titulo: string;
    descricao: string;
    arquivo_exibicao: string;
    arquivo_old: string;
    data_criacao: string;
    data_modificacao: string;
}