import { Component, OnInit, HostListener, Inject } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { UtilService } from './util/util.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {

  constructor(private titleService: Title,
              private utilService : UtilService) {
    this.titleService.setTitle("RSNC - Brazil");
  }

  ngOnInit() {}

  ngOnDestroy(){
    this.utilService.clearCookies();
  }

  //forma de limpar o cookie do usuario, assim, 
  //ele precisa fazer o login novamente.
  // @HostListener('window:beforeunload', ['$event'])
  // beforeunloadHandler(event) {
  //   this.utilService.clearCookies();
  // }

}
