import { Usuario } from './usuario';
import { Raca } from './raca';
import { UnidadeFederativa } from './unidade-federativa';

export class Cavalo {
    id_cavalo: number;
    ativo: boolean = false;
    nascimento: string;
    nome: string;
    id_proprietario: number;
    nome_proprietario : string;
    proprietario: Usuario;
    registro: string;
    rsnc: boolean = false;
    site: boolean = false;
    id_raca: number;
    raca: string;
    sexo_animal: string;
    id_unidade_federativa: number;
    cidade: string;
    unidadeFederativa: UnidadeFederativa;
    potro_futuro : boolean;
    inscricoes : any[] = [];
    inscricoes_potro_futuro : any[] = [];
    pendente: boolean;
}