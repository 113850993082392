import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

import { Usuario } from '../../../../model/usuario';
import { Cavalo } from '../../../../model/cavalo';
import { Prova } from '../../../../model/prova';
import { Campeonato } from '../../../../model/campeonato';
import { Evento } from '../../../../model/evento';

@Component({
    selector: "inscricao-por-competidores",
    templateUrl: "./inscricao-por-competidores.component.html",
    styleUrls: ["./inscricao-por-competidores.style.css", './../../inscricoes.style.css']
})
export class InscricaoPorCompetidoresComponent implements OnInit {

    @Input() pagina: number;
    @Output() paginaEmitter: EventEmitter<number> = new EventEmitter<number>();
    @Output() paginaAnteriorEmitter: EventEmitter<number> = new EventEmitter<number>();

    @Input() campeonato: Campeonato;
    @Input() evento: Evento;
    @Input() numeroDeInscritos: number;

    private listaDeProvasDoEventoPorCompetidor: Prova[];
    private listaDeCompetidoresInscritos: Usuario[];

    @Output() listaDeCompetidoresInscritosEmitter: EventEmitter<Usuario[]> = new EventEmitter<Usuario[]>();
    @Output() listaDeCavalosInscritosEmitter: EventEmitter<Cavalo[]> = new EventEmitter<Cavalo[]>();
    @Output() listaDeProvasSelecionadasEmitter: EventEmitter<Prova[]> = new EventEmitter<Prova[]>();

    constructor() {}

    ngOnInit(): void {}

    private emitePagina(pagina: number) {
        this.paginaEmitter.emit(pagina);
    }

    private emitePaginaAnterior(paginaAnterior: number) {
        this.paginaAnteriorEmitter.emit(paginaAnterior);
    }

    private emitirListaDeCompetidoresInscritos(listaDeCompetidoresInscritos: Usuario[]): void {
        this.listaDeCompetidoresInscritos = listaDeCompetidoresInscritos;
        this.listaDeCompetidoresInscritosEmitter.emit(listaDeCompetidoresInscritos);
    }

    private emitirListaDeCavalosInscritos(listaDeCavalosInscritos: Cavalo[]): void {
        this.listaDeCavalosInscritosEmitter.emit(listaDeCavalosInscritos);
    }

    private emiteListaDeProvasSelecionadas(listaDeProvasSelecionadas: Prova[]): void {
        this.listaDeProvasSelecionadasEmitter.emit(listaDeProvasSelecionadas);
    }
}