import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';

import { Raca } from '../../../../../../model/raca';
import { UtilService } from '../../../../../../util/util.service';
import { RacaService } from '../../../../../../service/raca.service';

@Component({
  selector: 'modal-cadastro-raca',
  templateUrl: './modal-cadastro-raca.component.html',
  styleUrls: ['./modal-cadastro-raca.component.css']
})
export class ModalCadastroRacaComponent implements OnInit {

  public raca: Raca = new Raca();
  public formCadastro: FormGroup;

  constructor(private formBuilder: FormBuilder,
              private utilService: UtilService,
              private racaService: RacaService){

      this.formCadastro = this.formBuilder.group({
        descricao: [this.raca.descricao, [
          Validators.required,
          Validators.minLength(1),
          Validators.maxLength(200)
        ]],
        abreviacao: [this.raca.abreviacao, [
          Validators.required,
          Validators.minLength(1),
          Validators.maxLength(5)
        ]],
      });
  }

  ngOnInit(){}

  public insereRaca(): void {

    if (this.formCadastro.invalid) {
      this.utilService.showErrors(this.formCadastro);
      this.utilService.destacarErros(this.formCadastro);
      return;
    }
  
    this.utilService.mensagemConfirmacao("Confirmar cadastro de Raça?", (result) => {
      if (result) {                
        this.racaService.insere(this.raca).subscribe(() => {
          this.utilService.mensagemSucesso("Raça cadastrada com sucesso!");
          this.raca = new Raca();
          this.formCadastro.markAsUntouched();
        });
      }
    }); 
  }

}
