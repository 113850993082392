import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';

import { environment } from '../../environments/environment';
import { Cavalo } from '../model/cavalo';
import { FiltrarTreinadorPorNome } from '../comum/treinadores/filtrar-treinador-por-nome.pipe';
import { Moderacao } from '../model/moderacao';

@Injectable()
export class CavaloService {

    private cavalo_api_url = `${environment.api_url}/cavalos`;

    constructor(private http: HttpClient){}

    public buscaTodos(filtro: any): Observable<Cavalo[]> {
        return this.http.get<Cavalo[]>(`${this.cavalo_api_url}?filtro=${JSON.stringify(filtro)}`);
    }

    public buscaFiltro(filtro: any): Observable<any> {
        return this.http.get<any>(`${this.cavalo_api_url}/filtro?filtro=${JSON.stringify(filtro)}`);
    }

    public buscaTodosQuantidadeInscricao(filtro: any) : Observable<Cavalo[]> {
        return this.http.get<Cavalo[]>(`${this.cavalo_api_url}/todos-quantidade-inscricao`,{
            params : {
                'filtro' : JSON.stringify({
                    'nome' : filtro.nome,
                    'id_evento' : filtro.id_evento
                })
            }
        });
    }
    
    public buscaPorId(id: number): Observable<Cavalo> {
        return this.http.get<Cavalo>(`${this.cavalo_api_url}/${id}`);
    }

    public buscaQuantidadeDeInscricaoCavaloNaProva(id_cavalo: number, id_prova : number): Observable<any> {
        return this.http.get<any>(`${this.cavalo_api_url}-busca-quantidade-inscricao-na-prova`,{
            params : {
                id_cavalo : id_cavalo.toString(),
                id_prova : id_prova.toString()
            }
        });
    }

    public insere(cavalo: Cavalo): Observable<any> {
        return this.http.post<any>(`${this.cavalo_api_url}`, cavalo);
    }

    public altera(id: number, cavalo: Cavalo): Observable<any> {
        return this.http.put<any>(`${this.cavalo_api_url}/${id}`, cavalo);
    }

    public deleta(id: number): Observable<any> {
        return this.http.delete<any>(`${this.cavalo_api_url}/${id}`);
    }

    public buscaPendente(): Observable<Cavalo[]> {
        return this.http.get<Cavalo[]>(`${this.cavalo_api_url}-pendente`);
    }

    public XmlCavalo(id: number): Observable<any>{
        return this.http.get(`${this.cavalo_api_url}/gerar-xml-cavalo/${id}`, {responseType: 'text'});
    }

    public moderar(moderacao: Moderacao): Observable<any>{
        return this.http.post<any>(`${this.cavalo_api_url}/moderacao`, moderacao);
    }
}