import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from '../../environments/environment';
import { Observable } from 'rxjs';
import { TipoInscricao } from '../model/tipo_inscricao';

@Injectable()
export class TipoInscricaoService {

    private tipo_inscricao_api_url = `${environment.api_url}/tipos-inscricao`;

    constructor(private http: HttpClient){}

    public buscaTodos(): Observable<TipoInscricao[]> {
        return this.http.get<TipoInscricao[]>(`${this.tipo_inscricao_api_url}`);
    }
    
    public buscaPorId(id: number): Observable<TipoInscricao> {
        return this.http.get<TipoInscricao>(`${this.tipo_inscricao_api_url}/${id}`);
    }

    public insere(tipoInscricao: TipoInscricao): Observable<TipoInscricao> {
        return this.http.post<TipoInscricao>(`${this.tipo_inscricao_api_url}`, tipoInscricao);
    }

    public altera(id: number, tipoInscricao: TipoInscricao): Observable<TipoInscricao> {
        return this.http.post<TipoInscricao>(`${this.tipo_inscricao_api_url}/${id}`, tipoInscricao);
    }

    public deleta(id: number): Observable<TipoInscricao> {
        return this.http.delete<TipoInscricao>(`${this.tipo_inscricao_api_url}/${id}`);
    }

}