import {Component, Inject, OnInit, Input} from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Noticia } from '../../model/noticia';
import { UtilService } from '../../util/util.service';
import { NoticiaService } from '../../service/noticia.service';

declare var $: any;

@Component({
  selector: 'app-noticia-component',
  moduleId: module.id,
  templateUrl: './noticia.component.html',
  styleUrls: ['./noticia.style.css']
})

export class NoticiaComponent implements OnInit {

  public noticia: Noticia = new Noticia;
  private id_noticia: number;
  private tipo_noticia: string;

  constructor(@Inject(Router) private router: Router,
              @Inject(ActivatedRoute) private route: ActivatedRoute,
              @Inject(NoticiaService) private noticiaService: NoticiaService,
              public utilService: UtilService) {}

  ngOnInit() {
    this.getNoticia();
  }

  private getNoticia() {
    this.route.params.subscribe(params => {
      this.id_noticia = params['id_noticia'];

      this.noticiaService.buscaPorId(this.id_noticia).subscribe(noticia => {
        this.noticia = noticia;
        this.verificaTipoNoticia();        
        this.getImgNoticia(noticia);
      });
    });
  }

  getImgNoticia(noticia: Noticia){
    if(noticia.id_tipo_arquivo == 1){
      noticia.arquivo_imagem = this.utilService.buscaImagem(noticia);
    }else if(noticia.id_tipo_arquivo == 3){
      noticia.arquivo_imagem = this.utilService.buscaPdf(noticia);
    }
  }

  verificaTipoNoticia() {
    if(this.noticia.id_tipo_noticia == 1) {
      this.tipo_noticia = 'notícia';
    } else if(this.noticia.id_tipo_noticia == 2) {
      this.tipo_noticia = 'campeonato';
    } else if(this.noticia.id_tipo_noticia == 3) {
      this.tipo_noticia = 'evento';
    }
  }

  naoENoticia() {
    if(this.noticia.id_tipo_noticia != 1) {
      return true;
    }
  }

  ativaDesativa() {
    this.noticiaService.ativaDesativa(this.noticia.id_noticia, !this.noticia.ativa).subscribe(noticia => {
      this.noticia.ativa = noticia.ativa;
    });
  }
}
