import { OnInit, Component, Inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import 'rxjs/add/operator/map';
import { filter } from 'rxjs/operators';

import { EventoService } from '../../../service/evento.service';
import { Evento } from '../../../model/evento';
import { Prova } from '../../../model/prova';
import { InscricaoService } from '../../../service/inscricao.service';
import { UtilService } from '../../../util/util.service';
import { UsuariosInscritos } from '../../../model/prova.usuarios-inscritos';
import { InscricaoCompetidorService } from '../../../service/inscricao-competidor.service';
import { InscricaoCompetidor } from '../../../model/inscricao_competidor';



declare var $: any;

@Component({
    selector: 'lista-inscritos',
    templateUrl: './lista-inscritos.component.html',
    styleUrls: ['./lista-inscritos.style.css']
})
export class ListaInscritosComponent implements OnInit {

    private id_evento: number;
    private evento: Evento;
    private provas: Prova[];
    private inscritos: any[];
    public spinner : boolean = true;
    public provasEInscritos: any[] = [];
    public numPaginas: number;
    public quantidadePagina : number = 0;
    public divisao: any[] = [];
    public testeEvento: number;
    public provaSelecionada: any;
    private teste: any[];

    public usuariosInscritos: UsuariosInscritos = new UsuariosInscritos();
    private listaDeUsuariosSelecionadosParaPagamento: any[] = [];

    private provasInscritos: [] = [];
    private cadastradores: any[] = [];
    public filtroDraw: {
        tipo_inscricao: number,
        diferente: boolean,
        tipo_prova : number,
        id_prova: number
    } = {
        tipo_inscricao: 1,
        diferente: true,
        tipo_prova : null,
        id_prova: null
    }
    public inscricaoDraw: any[] = [];

    /*PAGINACAO
    
    public parametrosBusca = new ParametrosBusca();
    options: ParametrosBusca = new ParametrosBusca();
    campeonatos: Campeonato[] = [];

    public podeVoltar: boolean;
    public podeProsseguir = true;
    
    private botoesEsquerda: any[] = new Array(3);
    private botaoCentro: any;
    private botoesDireita: any[] = new Array(3);

    public botoes: any[] = new Array();

    private paginasDireita: number;
    private paginasEsquerda: number;
    private pagina = 1;
    private totalPaginas: number;

    private paginasLaterais = 2;
    private quantidadeRegistros: number;

    */

    constructor(private activetedRoute: ActivatedRoute,
        private eventoService: EventoService,
        private inscricaoService: InscricaoService,
        private inscricaoCompetidorService: InscricaoCompetidorService,
        private utilService: UtilService,
        @Inject(Router) private router : Router ) { }

    public filtro: {
        id_cadastrador : number,
        pagamento : boolean,
        tipo_inscricao : number,
        id_divisao : number
    } = {
        id_cadastrador : null,
        pagamento : null,
        tipo_inscricao : 1,
        id_divisao: null
    }

    ngOnInit(): void {
        this.filtro.tipo_inscricao = 1;
        this.id_evento = this.activetedRoute.snapshot.params.id_evento;
        if(!isNaN(this.id_evento)){
            this.testeEvento = this.id_evento;
            this.eventoService.buscaPorId(this.id_evento).subscribe(evento => {
                if(this.utilService.isAdm() ||
                  (this.utilService.getIdLogado() == evento.id_organizador)){
                    this.evento = evento;
                    this.provas = this.evento.provas;
                    this.initPaginacao();
                    this.filtrar();
                    this.buscarTotalDeProvasPorEvento();
                }else{
                    this.AcessoNegado();
                }
            });
        }else{
            this.irParaInicio();
        }
    }

    public initPaginacao(){
        this.usuariosInscritos.limit = 10;
        this.usuariosInscritos.offset = 0;
    }

    public initFiltroDivisao(){
        this.filtro.id_cadastrador = null;
        this.filtro.pagamento = null;
        this.filtro.tipo_inscricao = 1;
    }

    buscarTotalDeProvasPorEvento() {
        this.inscricaoService.buscaTotalDeProvasPorEvento(this.id_evento)
        .subscribe(numProvas => {
            this.quantidadePagina = numProvas.qtdProvas;
            this.divisao = numProvas.divisao;
            if(this.divisao.length > 0){
                this.adicionaTodosDivisao(); 
            }
            this.paginacao();
        });
    }

    public adicionaTodosDivisao(){
        this.divisao.push({nome: 'TODOS', id_divisao: null});
    }

    public paginacao(){
        let aux = Number(this.quantidadePagina);
        if (aux % 10 > 0) {
            aux = (aux / 10) + 1;
        } else {
            aux = aux / 10;
        }
        this.numPaginas = Math.floor(aux);
    }

    getCadastradores(prova) {       
        this.inscricaoService.buscaCadastradorInscricaoPorIdProva(prova.id_prova)
        .subscribe( retorno => this.cadastradores = retorno);
    }

    pegaCadastradorSelecionado(event, prova){
        let valor = event.target.value;
        if(valor === 'Todos'){
            valor = null;
        }

        this.filtro.id_cadastrador = valor;
        this.filtrarPorPagamentoECadastrador(prova);
    }

    pegaPagamentoSelecionado(event, prova){
        let valor = event.target.value;
        if(valor === 'Todos'){
            valor = null;
            this.filtro.pagamento = valor;
        }else{
            this.filtro.pagamento = valor === 'true' ? true : false;
        }
        this.filtrarPorPagamentoECadastrador(prova);
    }

    pegaProvaSelecionada(id_divisao: any) {
        this.filtro.id_divisao = id_divisao == 'null' || !id_divisao ? null : id_divisao;
        this.initPaginacao();
        this.filtrar();
    }

    public filtrar() {
        this.spinner = true;
        this.initFiltroDivisao();
        this.listaDeUsuariosSelecionadosParaPagamento = [];
        this.inscricaoService.buscaPorEventoComFiltro(this.id_evento, this.usuariosInscritos.limit,
        this.usuariosInscritos.offset, this.filtro)
        .subscribe(provasEInscritos => {
            this.provasEInscritos = provasEInscritos.retorno;
            this.quantidadePagina = provasEInscritos.quantidade;
            this.getPrecoInscricaoCompetidor();
            this.paginacao();
            this.spinner = false;
        }, erro => {
            this.utilService.mensagemErro(erro.error.mensagem);
            this.spinner = false;
        });
    }

    getPrecoInscricaoCompetidor(){
        this.provasEInscritos.forEach(p => {
            let objeto = {
                id_prova : p.id_prova,
                nao_exigir_cadastro : p.divisao.nao_exigir_cadastro
            }
            p.inscritos.forEach(ic => {
                ic.competidores.forEach(competidor => {
                    this.inscricaoCompetidorService.buscaPrecoDaInscricao(objeto, competidor.id_usuario, this.id_evento, ic.id_inscricao)
                    .subscribe( valor => {
                        competidor['precoInscricao'] = valor.precoInscricaoFinal;
                    });
                });
            });
        });
    }

    public filtrarPorPagamentoECadastrador(prova){
        this.inscricaoService.buscaInscritosPorIdProvaComFiltro(prova, this.filtro)
        .subscribe( retorno => {
            this.provasEInscritos.forEach(p => {
                if(p.id_prova == prova.id_prova && retorno.inscritos.length > 0){
                   p.inscritos = retorno.inscritos;
                   p.quantidade_competidores = retorno.quantidade_competidores;
                   let objeto = { id_prova : p.id_prova,
                     nao_exigir_cadastro : p.divisao.nao_exigir_cadastro}
                    p.inscritos.forEach(ic => {
                        ic.competidores.forEach(competidor => {
                            this.inscricaoCompetidorService
                            .buscaPrecoDaInscricao(objeto, competidor.id_usuario, this.id_evento, ic.id_inscricao)
                            .subscribe( valor => {
                                competidor['precoInscricao'] = valor.precoInscricaoFinal;
                            });
                        })
                    });
                }else if(p.id_prova == prova.id_prova && retorno.inscritos.length == 0){
                    p.inscritos = retorno.inscritos;
                    p.quantidade_competidores = retorno.quantidade_competidores;
                }
            });
        });
    }

    atualizaLista(evento) {
        this.usuariosInscritos.offset =  evento.offset;
        this.usuariosInscritos.limit = evento.limit;
        this.filtrar();
    }

    addListaDeUsuariosParaPagamento(inscricaoCompetidor: InscricaoCompetidor[], competidor : any, id_prova) {

        if ($(`#checkboxInput${competidor.id_inscricao_competidor}`).prop('checked') ) {
            competidor.inscricao_paga = true;
            inscricaoCompetidor.forEach(inscricao => {
                if(inscricao.id_inscricao_competidor == competidor.id_inscricao_competidor ){
                    inscricao['id_prova'] = id_prova;
                    this.listaDeUsuariosSelecionadosParaPagamento.push(inscricao);
                }
            });
            
        } else {
            this.listaDeUsuariosSelecionadosParaPagamento = this.listaDeUsuariosSelecionadosParaPagamento
            .filter(inscri => inscri.id_inscricao_competidor != competidor.id_inscricao_competidor);
        }
    }
    
    RemoverDaListaDePagamentos(inscricaoCompetidor: InscricaoCompetidor[], competidor : any) {
        if (competidor.inscricao_paga != false) {
            this.utilService.mensagemConfirmacao('Deseja realmente remover o pagamento?', (teste) => {
                if(teste){
                    inscricaoCompetidor.forEach(inscricao => {
                        if(inscricao.id_inscricao_competidor == competidor.id_inscricao_competidor ){
                            inscricao.inscricao_paga = false;
                            this.inscricaoCompetidorService.removePagamento(inscricao)
                            .subscribe(retorno => {
                                this.utilService.mensagemSucesso('Pagamento removido com sucesso.');
                                competidor.inscricao_paga = false;                                
                            }, error => this.utilService.mensagemErro("Erro - Não foi possivel remover o pagamento."));
                        }
                    });
                }
    
            });
        }
    }

    efetuarPagamento(prova) {
        this.utilService.mensagemConfirmacao('Deseja realmente efetuar o pagamento?' ,(teste) =>{
            if(teste){
                this.inscricaoService.efetuarPagamento(this.listaDeUsuariosSelecionadosParaPagamento)
                .subscribe(retorno => {
                    if(retorno){
                        this.utilService.mensagemSucesso('Pagamento efetuado com sucesso.');
                        this.listaDeUsuariosSelecionadosParaPagamento = [];
                        this.filtrarPorPagamentoECadastrador(prova);
                    }
                });
            }
        });

    }

    listaDePagamento(id_prova) {
        let retorno = this.listaDeUsuariosSelecionadosParaPagamento
        .filter(inscricao => inscricao.id_prova == id_prova);
        return retorno.length;
    }

    marcarTodos(inscricaoCompetidor: any[], id_prova) {
        let escopo = this;
        inscricaoCompetidor.forEach(ic => {
            ic.competidores.forEach(competidor => {
                $(`#checkboxInput${competidor.id_inscricao_competidor}`).each(
                    function () {
                        if ($(this).prop("checked", false)) {
                            $(this).prop("checked", true);
                            for(let inscricaoComp of ic.inscricao_competidor){
                                let aux = false;
    
                                escopo.listaDeUsuariosSelecionadosParaPagamento.forEach(el => {
                                    if ((el.id_inscricao == inscricaoComp.id_inscricao) &&
                                        (el.id_inscricao_competidor == inscricaoComp.id_inscricao_competidor)) {
                                        aux = true;
                                    }
                                });
                                if (!aux && inscricaoComp.inscricao_paga == false) {
                                    inscricaoComp.inscricao_paga = true;
                                    inscricaoComp['id_prova'] = id_prova;
                                    escopo.listaDeUsuariosSelecionadosParaPagamento.push(inscricaoComp);
                                }
                            }
                        }
                    }
                ); 
            });
        });
    }

    desmarcarTodos(inscricaoCompetidor: any[]) {
        let escopo = this;

        inscricaoCompetidor.forEach(ic => {
            ic.competidores.forEach(competidor => {
                $(`#checkboxInput${competidor.id_inscricao_competidor}`).each(
                    function () {
                        if ($(this).prop("checked")) {
                            $(this).prop("checked", false);
                            for(let inscricaoComp of ic.inscricao_competidor){
                                let aux = null;
                                escopo.listaDeUsuariosSelecionadosParaPagamento
                                    .map(el => {
                                        if (el.id_inscricao == inscricaoComp.id_inscricao && 
                                            el.id_inscricao_competidor == inscricaoComp.id_inscricao_competidor) {
                                            aux = el;
                                        }
                                    });
        
                                if (aux != null) {
                                    escopo.listaDeUsuariosSelecionadosParaPagamento
                                    .splice(escopo.listaDeUsuariosSelecionadosParaPagamento.indexOf(aux), 1);
                                    ic.inscricao_competidor.inscricao_paga = false;
                                    inscricaoComp.inscricao_paga = false;
                                }
                            }
                        }
                    }
                );
            })
        })
    }

    maisInformacoes(competidores : any){
        if(competidores.length > 0){
            let conteudo : string = null;
            competidores.forEach(competidor => {
                let mensagem = "Nome : "+competidor.nome_competidor;
                mensagem += ", Handicap : "+competidor.handicap+", Sexo : "+this.utilService.getSexo(competidor.sexo);
                mensagem += ", Email : "+competidor.email;
                conteudo = conteudo ? conteudo + "\n\n"+ mensagem : mensagem;

            });
            this.utilService.mensagemInfo(conteudo);
        }
        
    }

    public removerInscriao(inscricao: any, prova: any, status: any){
        let mensagem = "Deseja realmente remover a inscrição("+inscricao.id_inscricao+")?";
        this.utilService.mensagemConfirmacao(mensagem, (resp) => {
            if(resp){
                this.inscricaoService.deleta(inscricao.id_inscricao).subscribe(retorno => {
                    if(retorno){
                        mensagem = "Inscrição removida com sucesso!";
                        this.utilService.mensagemInfo(mensagem);
                        this.filtrarPorPagamentoECadastrador(prova);
                        if(status == true || status == "true"){
                            this.buscaInscricaoDraw(prova.id_prova, prova.tipo_prova);
                        }
                    }
                }, (error) => {this.utilService.mensagemErro("Erro - Não foi possivel remover a inscrição.")});
            }
        });
    }

    public AcessoNegado(){
        this.utilService.mensagemErroComTitulo('Acesso negado', 'Esse perfil não possui o nível de autorização ' +
        'necessário para acessar essa funcionalidade.', () => {
            this.irParaInicio();
        });
    }

    public irParaInicio(){
        this.router.navigate(['/comum/inicio']);
    }

    public buscaInscricaoDraw(id_prova: number, tipo_prova: number){
        this.filtroDraw.tipo_prova = tipo_prova;
        this.filtroDraw.id_prova = id_prova;
        this.buscaInscricaoDrawPorGrupo();
    }

    public buscaInscricaoDrawPorGrupo(){
        this.inscricaoService.buscaPorIdProvaDraw(this.filtroDraw.id_prova, this.filtroDraw)
        .subscribe( 
            retorno => this.inscricaoDraw = retorno,
            erro => this.utilService.mensagemErro(erro.error.mensagem)
        );
    }

    public pagamentoCompetidor(inscricaoCompetidores : InscricaoCompetidor[], competidor: any){
        let inscricaoCompetidor = inscricaoCompetidores
        .filter(ic => ic.id_competidor = competidor.id_usuario)[0];
        if(inscricaoCompetidor.inscricao_paga){
          this.removerPagamento(inscricaoCompetidor, competidor);
        }else if(!inscricaoCompetidor.inscricao_paga){
          this.efetuarPagamentoIndividual(inscricaoCompetidor, competidor);
        }
    
      }
    
      public efetuarPagamentoIndividual(inscricaoCompetidor : InscricaoCompetidor, competidor: any){
        this.utilService.mensagemConfirmacao("Deseja realmente efetuar o pagamento?",
        (res) => {
          if(res){
            inscricaoCompetidor.inscricao_paga = true;
            competidor.inscricao_paga = true;
            this.inscricaoCompetidorService.efetuarPagamento([inscricaoCompetidor])
            .subscribe(retorno => {
                this.utilService.mensagemSucesso('Pagamento efetuado com sucesso.');
                this.buscaInscricaoDrawPorGrupo();
            }, () => {
              this.utilService.mensagemErro("Erro - Não foi possivel efetuado o pagamento.");
              inscricaoCompetidor.inscricao_paga = false;
              competidor.inscricao_paga = false;
            });
          }
        });
      }
    
      public removerPagamento(inscricaoCompetidor : InscricaoCompetidor, competidor: any){
        this.utilService.mensagemConfirmacao("Deseja realmente remover o pagamento?",
        (res) => {
          if(res){
            inscricaoCompetidor.inscricao_paga = false;
            competidor.inscricao_paga = false;
            this.inscricaoCompetidorService.removePagamento(inscricaoCompetidor)
            .subscribe(retorno => {
              this.utilService.mensagemSucesso('Pagamento removido com sucesso.');
              this.buscaInscricaoDrawPorGrupo();
            }, () => {
              this.utilService.mensagemErro("Erro - Não foi possivel remover o pagamento.");
              inscricaoCompetidor.inscricao_paga = true;
              competidor.inscricao_paga = true;
            });
          }      
        });
    }

    public selecionaProva(prova : any){
        this.provaSelecionada = prova;
    }

    public removerInscriaoDraw(inscrito: any){
        this.removerInscriao(inscrito, this.provaSelecionada, true);
    }
}