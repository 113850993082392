import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';

import { Raca } from '../../../model/raca';
import { RacaService } from '../../../service/raca.service';
import { UtilService } from '../../../util/util.service';

@Injectable()
export class RacaCavaloResolver implements Resolve<Observable<Raca[]>> {

    constructor(private service: RacaService,
                private utilService: UtilService){}
                
    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<Raca[]> {
        return this.service.buscaTodos();
    }

}