import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ResultadoGeralRoutingModule } from './resultado-geral-routing.module';
import { ResultadoGeralListComponent } from './list/resultado-geral-list.component';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';

@NgModule({
  declarations: [ResultadoGeralListComponent],
  imports: [
    CommonModule,
    ResultadoGeralRoutingModule,
    BrowserModule,
    ReactiveFormsModule,
    FormsModule
  ]
})
export class ResultadoGeralModule { }
