import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { ReactiveFormsModule, FormsModule } from "@angular/forms";
import { DirectiveModule } from "../../directive/directive.module";
import { EditarAssociadoComponent } from './editar-associado/editar-associado.component';
import { ListaAssociadosComponent } from './lista-associados/lista-associados.component';
import { RouterModule } from '@angular/router';
import { PaginacaoModule } from '../paginacao/paginacao.module';

@NgModule({
    declarations: [ EditarAssociadoComponent, ListaAssociadosComponent],
    imports : [
        CommonModule,
        ReactiveFormsModule,
        DirectiveModule,
        FormsModule,
        RouterModule,
        PaginacaoModule
    ]
})
export class AssociadosModule {}