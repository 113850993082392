import { NgModule } from '@angular/core';
import { EscolhaTipoDeProvaComponent } from './escolha-tipo-de-prova.component';
import { CommonModule } from '@angular/common';

@NgModule({
    declarations: [EscolhaTipoDeProvaComponent],
    imports: [
        CommonModule
    ],
    exports: [EscolhaTipoDeProvaComponent]
})
export class EscolhaTipoDeProvaModule {}