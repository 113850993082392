import { Component, OnInit, Inject } from '@angular/core';
import { UtilService } from '../../../util/util.service';
import { ActivatedRoute, Router } from '@angular/router';
import { AssociacaoCompetidorService } from '../../../service/associacao-competidor';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { AssociacaoCompetidor } from '../../../model/associacao-competidor';
import { RegraAssociacao } from '../../../model/regra-associacao';
import { RegraAssociacaoService } from '../../../service/regra-associacao.service';
import { PaginationOrganizadorComponent } from '../../organizador/pagination-organizador/pagination-organizador.component';

@Component({
  selector: 'app-editar-associado',
  templateUrl: './editar-associado.component.html',
  styleUrls: ['./editar-associado.component.css']
})
export class EditarAssociadoComponent implements OnInit {

  public titulo_pagina : string = "Editar filiado";
  public idAssociacaoCompetidor : number;
  public form : FormGroup;
  public associacaoCompetidor : AssociacaoCompetidor;
  public regrasAssociacao : RegraAssociacao[] = [];
  public erro : boolean = true;

  public pagamento : any[] = [];

  constructor(private utilService : UtilService,
              @Inject(ActivatedRoute) private route : ActivatedRoute,
              @Inject(Router) private router : Router,
              private associacaoCompetidorService : AssociacaoCompetidorService,
              private regraAssociacaoService : RegraAssociacaoService,
              @Inject(FormBuilder) private formBuilder : FormBuilder) { }

  ngOnInit() {
    this. initPagamento();
    this.idAssociacaoCompetidor = parseInt(this.route.snapshot.queryParamMap.get("id_associacao_competidor"));
    if(this.idAssociacaoCompetidor && !isNaN(this.idAssociacaoCompetidor)){
      this.buscaAssociacaoCompetidor();
      this.buscaRegraAssociacao();
    }else{
      this.erro = false;
    }
  }

  public initPagamento(){
    this.pagamento = [
      {descricao : 'Pago', valor : true},
      {descricao : 'Não Pago', valor : false}
    ]
  }

  public buscaAssociacaoCompetidor(){
    this.erro = true;
    this.associacaoCompetidorService.buscaPorId(this.idAssociacaoCompetidor)
    .subscribe(retorno => {
      this.associacaoCompetidor = retorno;
      this.initLocaleString();
      this.initForm();
      this.erro = false;
    });
  }

  private initLocaleString(){
    this.associacaoCompetidor.data_associacao = new Date(this.associacaoCompetidor.data_associacao)
    .toLocaleString();
    this.associacaoCompetidor.data_validacao = new Date(this.associacaoCompetidor.data_validacao)
    .toLocaleString();
  }

  private buscaRegraAssociacao(){
    this.regraAssociacaoService.buscaTodos()
    .subscribe( retorno => {
      this.regrasAssociacao = retorno;
    })
  }

  private initForm(){
    this.form = this.formBuilder.group({
      nome_competidor : [this.associacaoCompetidor.nome_competidor,
        [Validators.required, Validators.minLength(5)]],
      nome_cadastrador : [this.associacaoCompetidor.nome_cadastrador,
         [Validators.required, Validators.minLength(5)]],
      titulo_evento : [this.associacaoCompetidor.titulo_evento,
        [Validators.required, Validators.minLength(5)]],
      email : [this.associacaoCompetidor.email],
      id_regra_associacao : [this.associacaoCompetidor.id_regra_associacao,
        [Validators.required]],
      telefone : [this.associacaoCompetidor.telefone],
      data_associacao : [this.associacaoCompetidor.data_associacao],
      data_validacao : [this.associacaoCompetidor.data_validacao],
      associacao_competidor_paga : [this.associacaoCompetidor.associacao_competidor_paga,
        [Validators.required]]
    })
  }

  public selecionaRegraAssociacao(id_regra_associacao : number){
    this.associacaoCompetidor.id_regra_associacao = id_regra_associacao;
  }

  public selecionaPagamento(valor : any){
    this.associacaoCompetidor.associacao_competidor_paga = valor == 'true' ? true : false;
  }

  public salvar(){
    if (this.form.invalid) {
      this.utilService.destacarErros(this.form);
      return false;
    }
    this.utilService.mensagemConfirmacao("Deseja realmente alterar à filiação?",
    (resp) => {
      if(resp){
        this.associacaoCompetidorService
        .alterar(this.associacaoCompetidor.id_associacao_competidor, this.associacaoCompetidor)
        .subscribe(retorno =>{
          if(this.associacaoCompetidor.id_regra_associacao == retorno.id_regra_associacao){
            this.utilService.mensagemSucesso("Filiação alterada com sucesso!");
            this.voltar();
          }else{
            this.utilService.mensagemInfo("Não foi possivel alterada à filiação!");
          }
        }, () => this.utilService.mensagemErro("Erro - Não foi possivel alterar à filiação."));
      }
    });
  }

  public voltar(){
    this.utilService.irPara(this.router, '/comum/lista-filiados');
  }

}
