import { OnInit, Component, OnDestroy, OnChanges } from '@angular/core';
import { Subscription } from 'rxjs';
import { PubSub } from 'pubsub-js';

import { Evento } from '../../model/evento';
import { EventoService } from '../../service/evento.service';
import { UtilService } from '../../util/util.service';
import { ParametrosBusca } from '../../model/parametros_busca';
import { OrganizadorBuscaService } from './organizadorBusca.service';

declare var $: any

@Component({
    selector: 'app-organizador',
    templateUrl: './organizador.component.html',
    styleUrls: ['./organizador.style.css']
})
export class OrganizadorComponent implements OnInit, OnDestroy, OnChanges {

    private idOrganizador: number;
    public eventosOrganizados: Evento[];
    private eventoSubscription: Subscription;
    private totalRegistrosSubscription: Subscription;
    public parametrosBusca: ParametrosBusca = new ParametrosBusca();

    constructor(private eventoService: EventoService,
                private utilService: UtilService,
                private organizadorBuscaService: OrganizadorBuscaService) {}

    ngOnInit(): void {
        this.idOrganizador = this.utilService.getIdLogado();
        this.initParametrosBusca();
        this.organizadorBuscaService.parametrosBusca = this.parametrosBusca;
    }

    onChanges() {
        this.parametrosBusca = this.organizadorBuscaService.parametrosBusca;
    }
    
    ngOnChanges(changes: import("@angular/core").SimpleChanges): void {
    }

    ngOnDestroy(): void {
        this.eventoSubscription.unsubscribe();
        this.totalRegistrosSubscription.unsubscribe();
    }

    private async initParametrosBusca() {
        this.parametrosBusca.ascendente = true;
        this.parametrosBusca.filtro = undefined;
        this.parametrosBusca.limit = 5;
        this.parametrosBusca.offset = 0;
        this.parametrosBusca.orderBy = 'id_evento';
        this.parametrosBusca.pagina = 1;
        this.totalRegistrosSubscription = this.buscaTotalRegistros();
    }

    private buscaTotalRegistros(): Subscription{
        return this.eventoService.buscaTotalRegistrosPorOrganizador(this.idOrganizador)
            .subscribe(total => {
                this.parametrosBusca.totalRegistros = total;
                let totalPag = this.parametrosBusca.totalRegistros / this.parametrosBusca.limit;
                if (totalPag % 1 == 0) {
                    this.parametrosBusca.totalPaginas = totalPag;
                } else {
                    this.parametrosBusca.totalPaginas = Math.floor(totalPag) + 1;
                }
                PubSub.publish('PARAMETROS', this.parametrosBusca);
                this.eventoSubscription = this.buscaListaEventos(this.parametrosBusca);
            });
    }

    private buscaListaEventos(parametros: ParametrosBusca): Subscription {
        return this.eventoService
            .buscaEventosPorOrganizador(this.idOrganizador, parametros)
            .subscribe(eventos => {
                eventos.forEach(evento => {
                    evento.arquivo_imagem =  this.utilService.buscaThumbnail(evento);
                })
                this.eventosOrganizados = eventos;
            });
    }

    public atualizaParametros(parametros: ParametrosBusca) {
        this.parametrosBusca = parametros;
        this.buscaListaEventos(this.parametrosBusca);
    }

    public aplicaFiltro() {
        this.organizadorBuscaService.parametrosBusca.filtro = {
            titulo: $('#titulo').val()
        }
        this.atualizaParametros(this.organizadorBuscaService.parametrosBusca);
    }
}