import { BrowserModule, Title } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { TextMaskModule } from 'angular2-text-mask';
import { NgxMaskModule } from 'ngx-mask'
import { NgxCurrencyModule } from "ngx-currency";

import { AppComponent } from './app.component';
import { ComumModule } from './comum/comum.module';
import { ComumComponent } from './comum/comum.component';
import { ComumService } from './comum/comum.service';
import { routing } from './app.route';
import { Interceptor } from './interceptor/http.interceptor';
import { AuthGuard } from './auth/auth';
import { AuthGuardOrganizador } from './auth/authOrganizador';
import { AuthGuardBasico } from './auth/authBasico';
import { HttpModule } from '@angular/http';
import { AuthGuardLogado } from './auth/authLogado';
import { NgIdleKeepaliveModule } from '@ng-idle/keepalive';

@NgModule({
  declarations: [
    AppComponent,
    ComumComponent
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    HttpModule,
    ComumModule,
    TextMaskModule,
    routing,
    NgxMaskModule.forRoot(),
    NgxCurrencyModule,
    NgIdleKeepaliveModule.forRoot(),
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: Interceptor,
      multi: true
    },
    ComumService,
    Title,
    AuthGuard,
    AuthGuardOrganizador,
    AuthGuardBasico,
    AuthGuardLogado
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
