import { Component, OnInit, Inject } from '@angular/core';
import { UtilService } from '../../../../util/util.service';
import { UsuarioService } from '../../../../service/usuario.service';
import {FormBuilder } from '@angular/forms';
import { Usuario } from '../../../../model/usuario';
import { Router } from '@angular/router';
import { Perfil } from '../../../../model/perfil';
import { PerfilService } from '../../../../service/perfil.service';
import { InscricaoService } from '../../../../service/inscricao.service';
import { Inscricao } from '../../../../model/inscricao'
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

declare var $: any;
declare var mask: any;
declare var UIkit: any;
@Component({
  selector: 'app-lista-usuarios',
  templateUrl: './lista-usuarios.component.html',
  styleUrls: ['../../../comum.style.css', './lista-usuarios.component.css']
})

export class ListaUsuariosComponent implements OnInit {
  public titulo_pagina: string = 'Usuários';
  public usuario: Usuario = null;
  public numPaginas: number = 0;
  public paginas = [];
  public usuarios: Usuario[] = [];
  public ultimaInscricao : Inscricao;
  private usuarioPaginacao: {
    limit: number,
    offset: number,
    pagina: number
  } = {
    limit: null,
    offset: null,
    pagina: null
  }
  public filtro: {
    nome: string,
    cpf: string,
    status: boolean
  } = {
    nome: '',
    cpf: '',
    status: true
  }
  public perfis : Perfil[] = [];
  public spinner : boolean = false;
  public erros = [];
  public qtdUsuario: number;
  public pagina: number = 1;
  public debounceNome: Subject<string> = new Subject<string>();
  public debounceCPF: Subject<string> = new Subject<string>();

  constructor(@Inject(UtilService) public utilService: UtilService,
              private usuarioService: UsuarioService,
              @Inject(FormBuilder) private formBuilder: FormBuilder,
              private router: Router,
              private perfilService : PerfilService,
              private inscricaoService : InscricaoService) { }

  ngOnInit() {
    this.intiDebounceNome();
    this.intiDebounceCPF();
    this.spinner = true;
    this.initUsuarioPaginacao();
    this.initFiltro();
    this.buscaComFiltro();
  }

  public initFiltro(){
    this.filtro.nome = null;
    this.filtro.cpf = null;
  }

  public initUsuarioPaginacao(){
    this.usuarioPaginacao.offset = 0;
    this.usuarioPaginacao.limit = 500;
    this.usuarioPaginacao.pagina = 1;
  }

  public initPaginacao(){
    this.paginas = [];
    if (this.qtdUsuario % this.usuarioPaginacao.limit > 0) {
      this.numPaginas = Math.floor(this.qtdUsuario / this.usuarioPaginacao.limit) + 1;
    } else {
      this.numPaginas = (this.qtdUsuario / this.usuarioPaginacao.limit);
    }
    for (var i = 1; i <= this.numPaginas; i++) {
      this.paginas.push({
        'key': i,
        'paginaValue': i
      });
    }

  }

  private intiDebounceNome(){
    this.debounceNome.pipe(debounceTime(300))
    .subscribe(nome => {
      this.filtro.nome = nome;
      this.buscaComFiltro();
    })
  }

  private intiDebounceCPF(){
    this.debounceCPF.pipe(debounceTime(300))
    .subscribe(cpf => {
      this.filtro.cpf = cpf;
      this.buscaComFiltro();
    })
  }

  public buscaComFiltro(){
    this.spinner = true;
    this.usuarioService.buscaComFiltro(this.filtro, this.usuarioPaginacao.limit, this.usuarioPaginacao.offset)
      .subscribe(retorno => {
        this.spinner = false;
        this.usuarios = retorno.usuarios;
        this.qtdUsuario = retorno.quantidade;
        this.initPaginacao();
      },(error) => {
        this.spinner = false;
        this.utilService.mensagemErro('Erro - Não foi possivel buscar os usuários!')
      });
  }

  public getNomeSelecionado(nome: any){
    this.usuarioPaginacao.offset = 0;
    this.debounceNome.next(nome);
  }

  public getCpfSelecionado(cpf: any){
    this.usuarioPaginacao.offset = 0;
    this.debounceCPF.next(cpf);
  }

  public selecionarStatus(status: any){
    this.filtro.status = status == 'null' ? null : status == 'true' ? true : false;
    this.usuarioPaginacao.offset = 0;
    this.buscaComFiltro();
  }

  buscarAnterior() {
    let aux = Number(this.usuarioPaginacao.offset);
    if (aux != 0) {
      this.usuarioPaginacao.offset = Number(this.usuarioPaginacao.offset) - Number(this.usuarioPaginacao.limit);
      this.buscaComFiltro();
    }
  }

  buscarProximo() {
    let aux = Number(this.usuarioPaginacao.offset);
    if (aux < this.numPaginas) {
      this.usuarioPaginacao.offset = Number(this.usuarioPaginacao.offset) + Number(this.usuarioPaginacao.limit);
      this.pagina++;
      this.buscaComFiltro();
    }
  }

  pulaPagina(pagina: any) {
    let aux = this.usuarioPaginacao.offset;
    this.usuarioPaginacao.offset = (Number(pagina) - 1) * (this.usuarioPaginacao.limit);
    if (aux > this.usuarioPaginacao.offset) {
      this.pagina--;
    } else {
      this.pagina++;
    }
    this.buscaComFiltro();
  }

  public excluirUsuario(id_usuario: number){
    this.utilService.mensagemConfirmacao("Deseja realmente excluir usuário?",
    resp => {
      if(resp){
        this.usuarioService.excluir(id_usuario)
        .subscribe(() => {
          this.utilService.mensagemSucesso("Usuário excluido com sucesso!");
          this.buscaComFiltro();
        },
        erro => this.utilService.mensagemErro("Erro - " + erro.error.mensagem));
      }
    });
  }
}
