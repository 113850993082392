import { OnInit, Component, Input, Output, EventEmitter, Inject } from '@angular/core';
import { Router } from '@angular/router';

import { Evento } from '../../../model/evento';
import { UtilService } from '../../../util/util.service';
import { CampeonatoService } from '../../../service/campeonato.service';
import { Campeonato } from '../../../model/campeonato';
import { PubSub } from 'pubsub-js';

declare var UIkit: any;

@Component({
    selector: 'app-modal-replicar-evento',
    templateUrl: './modal-replicar-evento.component.html',
    styleUrls: ['./modal-replicar-evento.style.css']
})
export class ModalReplicarEventoComponent implements OnInit {

    @Input() evento: Evento;
    
    public campeonatos: Campeonato[];
    public idCampeonatoSelecionado: number;

    constructor(@Inject(Router) private router: Router,
                private utilService: UtilService,
                private campeonatoService: CampeonatoService){}

    ngOnInit(): void {
        this.campeonatoService.buscaCampeonatosAtivos().subscribe(campeonatos => {
            this.campeonatos = campeonatos;
        })
    }

    public pegaCampeonatoSelecionado(id_campeonato_selecionado: number): void {
        this.idCampeonatoSelecionado = id_campeonato_selecionado;
    }

    public redirecionarParaTelaDeReplica(): void {
        /*this.utilService.irPara(this.router, `/comum/cadastro-evento/${this.idCampeonatoSelecionado}`, {
            'idEventoReplicado' : this.evento.id_evento
        });*/
        if(this.idCampeonatoSelecionado) {
            this.utilService.irPara(this.router, `/comum/cadastro-evento`, {
                'idEventoReplicado' : this.evento.id_evento,
                'idCampeonato': this.idCampeonatoSelecionado});
                UIkit.modal('#modalSelecionaCampeonato').hide();
        } else {
            this.utilService.irPara(this.router, '/comum/cadastro-evento', {
                'idEventoReplicado' : this.evento.id_evento});
                UIkit.modal('#modalSelecionaCampeonato').hide();
        }
        /*this.utilService.irPara(this.router, `/comum/cadastro-evento`, {
            'idEventoReplicado' : this.evento.id_evento,
            'idCampeonato': this.idCampeonatoSelecionado
        });*/
    }

}