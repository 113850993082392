import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';

import { environment } from '../../environments/environment';
import { Divisao } from '../model/divisao';
import { JsonPipe } from '@angular/common';
import { ParametrosBusca } from '../model/parametros_busca';

@Injectable()
export class DivisaoService {

    private divisao_api_url = `${environment.api_url}/divisoes`;

    constructor(private http: HttpClient) {
    }

    public buscaTodos(): Observable<Divisao[]> {
      return this.http.get<Divisao[]>(`${this.divisao_api_url}`);
    }

    public buscaPorId(id: number): Observable<Divisao> {
      return this.http.get<Divisao>(`${this.divisao_api_url}/${id}`);
    }

    public insere(divisao: Divisao) {
      return this.http.post<any>(`${this.divisao_api_url}`, divisao);
    }

    public altera(id: number, divisao: Divisao): Observable<Divisao> {
      return this.http.put<any>(`${this.divisao_api_url}/${id}`, divisao);
    }

    public deleta(id: number): Observable<Divisao> {
      return this.http.delete<any>(`${this.divisao_api_url}/${id}`);
    }

    public salvar(divisao: Divisao): Observable<any> {
      return this.http.post<any>(`${this.divisao_api_url}/salvar`, divisao);
    }

    public buscaDivisoesComFiltro(filtro, limit : number, offset : number) : Observable<Divisao[]>{
      return this.http.get<Divisao[]>(`${this.divisao_api_url}/busca-com-filtro`,{
        params : {
          'limit' : limit.toString(),
          'offset' : offset.toString(),
          'filtro' : JSON.stringify({
            'nome' : filtro.nome
          })
        }
      })
    }

    public buscaPorEvento(id_evento: number): Observable<Divisao[]> {
      return this.http.get<Divisao[]>(`${this.divisao_api_url}/buscaPorEvento/${id_evento}`);
    }

    public buscaTodosFiltro(filtro, options: ParametrosBusca = null): Observable<Divisao[]> {
      return this.http.post<Divisao[]>(`${this.divisao_api_url}/buscar-filtro`, filtro, {
        params: {
          'offset': options.offset.toString(),
          'limit': options.limit.toString()
        }
      });
    }

    public buscaQuantidadeRegistros(filtro, options: ParametrosBusca): Observable<number> {
      return this.http.post<number>(`${this.divisao_api_url}/buscar-quantidade-registros`, filtro, {
        params: {
          'offset': options.offset.toString(),
          'limit': options.limit.toString()
        }
      });
    }

    public validarNome(nome) : Observable<boolean>{
      return this.http.post<boolean>(`${this.divisao_api_url}-validar-nome`, {nome});
    }

    public buscaDivisoesFiltrado(filtro: String): Observable<Divisao[]> {
      return this.http.post<Divisao[]>(`${environment.api_url}/divisoesRt/buscaDivisoesFiltrado`, {
        params: {
          'filtro': filtro
        }
      });
    }
}
