import { Injectable } from '@angular/core';
import { DomSanitizer, Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { FormGroup, AbstractControl, ValidatorFn, ValidationErrors } from '@angular/forms';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { RouteStack } from '../model/route-stack';
import { UsuarioJwt } from '../model/usuario-jwt';
import { JwtHelperService } from '@auth0/angular-jwt';
import { LOCALSTORAGE_TOKEN_KEY, HANDICAP_COMPETIDOR } from './constantes';
import { Usuario } from '../model/usuario';
import {Portuguese} from 'flatpickr/dist/l10n/pt.js';
import flatpickr from 'flatpickr';
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs';
import { UsuarioService } from '../service/usuario.service';

declare var swal: any;
const jwtService = new JwtHelperService();

@Injectable()
export class UtilService {
  
  private pilhaDeRotas = [] as any[];
  private routeStack = [] as RouteStack[];

  private DEFAULT_IMG = 'default_img.jpeg';
  
  private usuarioService : UsuarioService;
  private mesHipico = 6; // 0 = janeiro; 6=julho 
  private diaHipico = 1; // 1 de julho
  private upload_pdf_api_url = `${environment.api_url}/file/upload-pdf`;

  constructor(private sanitizer: DomSanitizer,
              private titleService: Title,
              private http: HttpClient) {
  }

  public getTipoCompetidor(competidor: Usuario): string {
    if (competidor.isApartador) return "Apartador";
    return "Rebatedor";
  }

  public getIdade(data: string): number {
    let nascimento = new Date(data);
    let diff = Date.now() - nascimento.getTime();
    let idadeDate = new Date(diff);
    return idadeDate.getUTCFullYear() - 1970;
  }

  public getIdadeBaseAnoHipico(data : string) : number {
    let dataNascimento = new Date(data);
    return this.calculoIdadeUsuarioComBaseAnoHipico(dataNascimento.getFullYear(),
    dataNascimento.getMonth(), dataNascimento.getDate());
  }

  public calculoIdadeUsuarioComBaseAnoHipico(year, month, day) {
    var now = new Date()	
    var age = now.getFullYear();
    var monthNow = now.getMonth();
    var dayNow = now.getDate();
    var control = false;

    if(monthNow < this.mesHipico){
      age -= 1;
    }

    if((month > this.mesHipico) || (month == this.mesHipico && day > this.diaHipico)){
      if((monthNow > month) || (monthNow == month && dayNow >= day)){
        age -= 1;
        control = true;
      }
    }

    let idade = age -  year;
    var mdif = this.mesHipico - (month + 1) //0=jan
    
    if(mdif < 0 && !control){
      --idade
    }else if(mdif == 0){
      var ddif = this.diaHipico - day
      if(ddif < 0){
        --idade
      }
    }

    return idade
  }


  public getSexo(sexo: string): string {
    if (sexo == 'm' || sexo == 'M') {
        return "Masculino";
    }
    return "Feminino";
  }

  public getSexoCavalo(sexo : string) : string{
    let retorno : string = '----';
    switch (sexo.toLocaleLowerCase()) {
      case 'm': retorno = 'Macho'; break;
      case 'f': retorno = 'Fêmea'; break;
      case 'c': retorno = 'Puro Castrado'; break;
      case 'mc': retorno = 'Mestiço Castrado'; break;
      case 'mac': retorno = 'Macho Castrado'; break;
      default: break;
    }
    return retorno;
  }

  public mudaTitulo(titulo) {
    this.titleService.setTitle(titulo);
  }

  preparaUrlSegura(url) {
      return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }

  static confirmar(inputControl: AbstractControl): ValidatorFn {
    
    return (control: AbstractControl): ValidationErrors | null => {
      const valid = inputControl.value === control.value;
      //const valid = requisicaoVerificaLoginExistente(control.value)
      return valid ? null : { confirmar: true };
    };
  }

  validarCpf(inputControl : string) : ValidationErrors | null {
    return (control: AbstractControl): ValidationErrors | null => {
      //const valid = inputControl.value === control.value;
      if(control.value == undefined || control.value == null){
        control.setValue("as");
        
      }
      const valid = this.usuarioService.validarCpf(inputControl);
      return valid ? null : { confirmar: true };
    };
  }

  validarEmail(inputControl : AbstractControl) : ValidationErrors | null {
    return (control: AbstractControl): ValidationErrors | null => {
      if(control.value == undefined){
        control.setValue("as");
      }
      const valid = true;
      return valid ? null : { confirmar: true };
    };
  }

  validarLogin(inputControl : string) : ValidationErrors | null {
    return (control: AbstractControl): ValidationErrors | null => {
      if(control.value == undefined){
        control.setValue("as");
      }
      const valid = this.usuarioService.validarLogin(control.value);
      return valid ? null : { confirmar: true };
    };
  }

  reduzTexto(texto, tamanho) {
    var textoReduzido = this.cortaTexto(texto, tamanho);
    if( texto && texto.length > tamanho) {
      textoReduzido += '...';
    }
    return textoReduzido;
  }

  cortaTexto(texto, tamanho) {
      if(texto && texto.length > tamanho) {
        return texto.substr(0, tamanho);
      } else {
        return texto;
      }
  }

  public irPara(router: Router, rota: string, parametros: any = null) {
      if (parametros) {
        router.navigate([rota], {
          queryParams : parametros
        });
      } else {
        router.navigate([rota]);
      }
      this.pilhaDeRotas.push({
        rota: rota,
        chamador: router.url,
        parametros: parametros ? parametros : []
      });
  }

  public voltarParaChamador(router: Router, padrao: any[]) {
    if (!this.isPilhaVazia()) {
      const desempilhado = this.pilhaDeRotas.pop();
      router.navigate([decodeURI(desempilhado.chamador)]);
    } else {
      router.navigate(padrao);
    }
  }

  public esvaziarPilha() {
    this.pilhaDeRotas = [];
  }

  public isPilhaVazia() {
    return !this.pilhaDeRotas || !this.pilhaDeRotas.length;
  }

  public destacarErros(form: FormGroup) {
    if (!form) {
      throw new Error('O FormGroup não deve estar nulo!');
    }
    Object.keys(form.controls).forEach(key => {
      form.get(key).markAsTouched();
    });
  }

  public isEmpty(obj) {
    return Object.keys(obj).length === 0;
}

  public mensagemErroComTitulo(titulo: string = 'Ops...', mensagem: string = 'Ocorreu um erro desconhecido!',//mensagem de erro front end 
    callback: Function = () => {
    }) {
    swal({
      title: titulo,
      text: mensagem,
      icon: 'error',
      button: 'Entendi',
    }).then(callback);
  }
  
  public mensagemErro(mensagem: string = 'Ocorreu um erro desconhecido!',
    callback: Function = () => {
    }) {
    swal({
      title: 'Oops...',
      text: mensagem,
      icon: 'error',
      button: 'Entendi',
    }).then(callback);
  }

  public mensagemSucesso(mensagem: string, callback: Function = () => {
  }) {
    this.validarMensagem(mensagem);
    swal({
      title: 'Sucesso!',
      text: mensagem,
      icon: 'success',
      button: 'Feito',
    }).then(callback);
  }

  public mensagemInfo(mensagem: string, callback: Function = () => {
  }) {
    this.validarMensagem(mensagem);
    swal({
      title: 'Informação!',
      text: mensagem,
      icon: 'info',
      button: 'Entendi',
    }).then(callback);
  }

  public mensagemConfirmacao(mensagem: string, callback: Function) {
    this.validarMensagem(mensagem);
    this.validarCallback(callback);
    swal({
      title: 'Confirmar?',
      text: mensagem,
      icon: 'warning',
      closeOnClickOutside: false,
      buttons: {
        cancel: {
          text: 'Não',
          value: false,
          visible: true,
          closeModal: true,
        },
        confirm: {
          text: 'Sim',
          value: true,
          visible: true,
          closeModal: true
        }
      }
    }).then(callback);
  }

  private validarMensagem(mensagem: string) {
    if (!mensagem) {
      throw new Error('É obrigatório definir uma mensagem');
    }
  }

  private validarCallback(callback: Function) {
    if (!callback) {
      throw new Error('É obrigatório definir um callback');
    }
  }

  public isMobile() {
    if(/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|ipad|iris|kindle|Android|Silk|lge |maemo|midp|mmp|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows (ce|phone)|xda|xiino/i.test(navigator.userAgent) 
        || /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(navigator.userAgent.substr(0,4))) { 
        return true;
    }
    return false;
  }

  public setHandicapCompetidor(handcapUser){
    localStorage.setItem(HANDICAP_COMPETIDOR, JSON.stringify(handcapUser));//buscar o handicap para colocar no local storange
  }

  public saveCookies(dados) {
    localStorage.setItem(LOCALSTORAGE_TOKEN_KEY, JSON.stringify(dados));
  }

  public clearCookies(): void {
    localStorage.clear();
  }

  public isLogado(): boolean {
    const item = localStorage.getItem(LOCALSTORAGE_TOKEN_KEY);
    return !!item;
  }

  public getToken(): string {
    if (this.isLogado()) {
      const obj = JSON.parse(localStorage.getItem(LOCALSTORAGE_TOKEN_KEY));
      return obj.token;
    }
    return null;
  }

  public isAdm(): boolean {
    if (this.isLogado()) {
      const obj = JSON.parse(localStorage.getItem(LOCALSTORAGE_TOKEN_KEY));
      return 'administrador' === obj.perfil;
    }
    return false;
  }

  public isOrganizador(): boolean {
    if (this.isLogado()) {
      const obj = JSON.parse(localStorage.getItem(LOCALSTORAGE_TOKEN_KEY));
      return 'organizador' === obj.perfil;
    }
    return false;
  }

  public isBasico(): boolean {
    if (this.isLogado()) {
      const obj = JSON.parse(localStorage.getItem(LOCALSTORAGE_TOKEN_KEY));
      return 'basico' === obj.perfil;
    }
    return false;
  }

  public getDadosLogado(): UsuarioJwt {
    if (this.isLogado()) {
      const jwt = localStorage.getItem(LOCALSTORAGE_TOKEN_KEY);
      const decoded = jwtService.decodeToken(jwt) as UsuarioJwt;
      return decoded;
    }
    return null;
  }

  public getNome(): string {
    if (this.isLogado()) {
      const jwt = localStorage.getItem(LOCALSTORAGE_TOKEN_KEY);
      const decoded = jwtService.decodeToken(jwt) as UsuarioJwt;
      return decoded.nome;
    }
    return null;
  }

  public getPrimeiroNome(): string {
    if (this.isLogado()) {
      const jwt = localStorage.getItem(LOCALSTORAGE_TOKEN_KEY);
      const decoded = jwtService.decodeToken(jwt) as UsuarioJwt;
      var primeiroNome = decoded.nome.split(' ')[0];
      return primeiroNome;
    }
    return null;
  }
 //fazendo a busca do handicap
  public getHandicapUsu(): string {
    if (this.isLogado()) {
       let handcap = localStorage.getItem(HANDICAP_COMPETIDOR);
       if (handcap != null) {
        return 'Handcap : '+handcap; 
    }
    }
    return '';
  }
  //
  public getRoles(): string[] {
    if (this.isLogado()) {
      const jwt = localStorage.getItem(LOCALSTORAGE_TOKEN_KEY);
      const decoded = jwtService.decodeToken(jwt) as UsuarioJwt;
      return decoded.roles;
    }
    return null;
  }

  public getIdLogado(): number {
    if (this.isLogado()) {
      const jwt = localStorage.getItem(LOCALSTORAGE_TOKEN_KEY);
      const decoded = jwtService.decodeToken(jwt) as UsuarioJwt;
      return decoded.id;
    }
    return null;
  }

  public showErrors(form: any): void {
    if (!form) {
      throw new Error('[showErrors] O FormGroup não deve estar nulo!');
    }
    Object.keys(form.controls).forEach(key => {
      const field = form.get(key);
      if (field['controls']) {
        this.showErrors(field);
      } else {
        field.markAsTouched();
      }
    });
  }

  public goTo(router: Router, route: string, ...params: any[]): void {
    if (params && params.length) {
      router.navigate(this.separateArrays(route, params));
    } else {
      router.navigate([route]);
    }
    this.routeStack.push({
      route: route,
      caller: router.url,
      params: params ? params : []
    } as RouteStack);
  }

  private separateArrays(route: string, params): string[] {
    const array = [];

    array.push(route);

    if (typeof params === 'string') {
      array.push(params);
      return array;
    }

    for (let i = 0; i < params.length; i++) {
      array.push(params[i]);
    }

    return array;
  }

  public validaPorcentagemMaxima(valor) {
    if(valor > 100) {
      return false;
    }
    return true;
  }

  static validaData(stringData) {
    if(stringData != null) {
      var dateParts = stringData.split("/");
      var dataTransformada =  dateParts[1] + '/' + dateParts[0] + '/' + dateParts[2]
      return Date.parse(dataTransformada);
    }
  }

  public validaDataControl(control: AbstractControl): ValidationErrors | null {
    const dataValida = UtilService.validaData(control.value)
    return dataValida ? null : { validaDataControl: true };
  }

  public montaDatePicker(nome_campo, dataDefault: string = null): void {
      const fp = flatpickr("#" + nome_campo,{
          disableMobile: true,
          //minDate: 'today',
          locale: Portuguese,
          altFormat: 'd/m/Y',
          ariaDateFormat: 'd/m/Y',
          dateFormat: 'd/m/Y',
          defaultDate: dataDefault,
      });
  }

  public montaDatePickerHorario(nome_campo, dataDefault: string = null): void {
      const fp = flatpickr("#" + nome_campo,{
          disableMobile: true,
          enableTime: true,
          //minDate: 'today',
          locale: Portuguese,
          altFormat: 'd/m/Y H:i',
          ariaDateFormat: 'd/m/Y H:i',
          dateFormat: 'd/m/Y H:i',
          defaultDate: dataDefault,
          time_24hr: true
      });
  }

  public montaDatePickerFiltro(nome_campo): void {
      const fp = flatpickr("#" + nome_campo,{
          locale: Portuguese,
          altFormat: 'd/m/Y',
          ariaDateFormat: 'd/m/Y',
          dateFormat: 'd/m/Y',
      });
  }

  public ajustaDataHorario(stringData) {
    return stringData = stringData.substring(0, stringData.length - 3);
  }

  public converteData(stringData) {
    var dateParts = stringData.split("/");
    var dataTransformada =  dateParts[1] + '/' + dateParts[0] + '/' + dateParts[2];
    return Date.parse(dataTransformada);
  }

  public converteNewData(stringData) {
    var dateParts = stringData.split(" ");
    dateParts = dateParts[0].split("/");
    var dataTransformada =  dateParts[1] + '/' + dateParts[0] + '/' + dateParts[2];
    return new Date(dataTransformada);
    
  }

  public maskTelefone(rawValue) {
    const numbers = rawValue.match(/\d/g);
    let numberLength = 0;
    if (numbers) {
        numberLength = numbers.join('').length;
    }
    if (numberLength <= 10) {
        return ['(', /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];
    } else {
        return ['(', /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];
    }
  }

  public removeCharacter(str_to_remove: string, str:string) {
    let reg = new RegExp(str_to_remove);
    while(str.includes(str_to_remove)){
      str = str.replace(reg, '');
    }
    return str;
  }

  download(nome_arquivo, conteudo) {
    var element = document.createElement('a');
    element.setAttribute('href', 'data:text/xml;charset=utf-8,' + encodeURIComponent(conteudo));
    element.setAttribute('download', nome_arquivo);
  
    element.style.display = 'none';
    document.body.appendChild(element);
  
    element.click();
  
    document.body.removeChild(element);
  }

  removeCaracteresEspeciais(str) {
    return str.replace(/[^\w\s]/gi, '')
  }

  substituiEspacosUnderline(str) {
    return str.replace(/ /g, '_');
  }

  substituiCaractereEspecial (str) {

    var defaultDiacriticsRemovalMap = [
      {'base':'A', 'letters':/[\u0041\u24B6\uFF21\u00C0\u00C1\u00C2\u1EA6\u1EA4\u1EAA\u1EA8\u00C3\u0100\u0102\u1EB0\u1EAE\u1EB4\u1EB2\u0226\u01E0\u00C4\u01DE\u1EA2\u00C5\u01FA\u01CD\u0200\u0202\u1EA0\u1EAC\u1EB6\u1E00\u0104\u023A\u2C6F]/g},
      {'base':'AA','letters':/[\uA732]/g},
      {'base':'AE','letters':/[\u00C6\u01FC\u01E2]/g},
      {'base':'AO','letters':/[\uA734]/g},
      {'base':'AU','letters':/[\uA736]/g},
      {'base':'AV','letters':/[\uA738\uA73A]/g},
      {'base':'AY','letters':/[\uA73C]/g},
      {'base':'B', 'letters':/[\u0042\u24B7\uFF22\u1E02\u1E04\u1E06\u0243\u0182\u0181]/g},
      {'base':'C', 'letters':/[\u0043\u24B8\uFF23\u0106\u0108\u010A\u010C\u00C7\u1E08\u0187\u023B\uA73E]/g},
      {'base':'D', 'letters':/[\u0044\u24B9\uFF24\u1E0A\u010E\u1E0C\u1E10\u1E12\u1E0E\u0110\u018B\u018A\u0189\uA779]/g},
      {'base':'DZ','letters':/[\u01F1\u01C4]/g},
      {'base':'Dz','letters':/[\u01F2\u01C5]/g},
      {'base':'E', 'letters':/[\u0045\u24BA\uFF25\u00C8\u00C9\u00CA\u1EC0\u1EBE\u1EC4\u1EC2\u1EBC\u0112\u1E14\u1E16\u0114\u0116\u00CB\u1EBA\u011A\u0204\u0206\u1EB8\u1EC6\u0228\u1E1C\u0118\u1E18\u1E1A\u0190\u018E]/g},
      {'base':'F', 'letters':/[\u0046\u24BB\uFF26\u1E1E\u0191\uA77B]/g},
      {'base':'G', 'letters':/[\u0047\u24BC\uFF27\u01F4\u011C\u1E20\u011E\u0120\u01E6\u0122\u01E4\u0193\uA7A0\uA77D\uA77E]/g},
      {'base':'H', 'letters':/[\u0048\u24BD\uFF28\u0124\u1E22\u1E26\u021E\u1E24\u1E28\u1E2A\u0126\u2C67\u2C75\uA78D]/g},
      {'base':'I', 'letters':/[\u0049\u24BE\uFF29\u00CC\u00CD\u00CE\u0128\u012A\u012C\u0130\u00CF\u1E2E\u1EC8\u01CF\u0208\u020A\u1ECA\u012E\u1E2C\u0197]/g},
      {'base':'J', 'letters':/[\u004A\u24BF\uFF2A\u0134\u0248]/g},
      {'base':'K', 'letters':/[\u004B\u24C0\uFF2B\u1E30\u01E8\u1E32\u0136\u1E34\u0198\u2C69\uA740\uA742\uA744\uA7A2]/g},
      {'base':'L', 'letters':/[\u004C\u24C1\uFF2C\u013F\u0139\u013D\u1E36\u1E38\u013B\u1E3C\u1E3A\u0141\u023D\u2C62\u2C60\uA748\uA746\uA780]/g},
      {'base':'LJ','letters':/[\u01C7]/g},
      {'base':'Lj','letters':/[\u01C8]/g},
      {'base':'M', 'letters':/[\u004D\u24C2\uFF2D\u1E3E\u1E40\u1E42\u2C6E\u019C]/g},
      {'base':'N', 'letters':/[\u004E\u24C3\uFF2E\u01F8\u0143\u00D1\u1E44\u0147\u1E46\u0145\u1E4A\u1E48\u0220\u019D\uA790\uA7A4]/g},
      {'base':'NJ','letters':/[\u01CA]/g},
      {'base':'Nj','letters':/[\u01CB]/g},
      {'base':'O', 'letters':/[\u004F\u24C4\uFF2F\u00D2\u00D3\u00D4\u1ED2\u1ED0\u1ED6\u1ED4\u00D5\u1E4C\u022C\u1E4E\u014C\u1E50\u1E52\u014E\u022E\u0230\u00D6\u022A\u1ECE\u0150\u01D1\u020C\u020E\u01A0\u1EDC\u1EDA\u1EE0\u1EDE\u1EE2\u1ECC\u1ED8\u01EA\u01EC\u00D8\u01FE\u0186\u019F\uA74A\uA74C]/g},
      {'base':'OI','letters':/[\u01A2]/g},
      {'base':'OO','letters':/[\uA74E]/g},
      {'base':'OU','letters':/[\u0222]/g},
      {'base':'P', 'letters':/[\u0050\u24C5\uFF30\u1E54\u1E56\u01A4\u2C63\uA750\uA752\uA754]/g},
      {'base':'Q', 'letters':/[\u0051\u24C6\uFF31\uA756\uA758\u024A]/g},
      {'base':'R', 'letters':/[\u0052\u24C7\uFF32\u0154\u1E58\u0158\u0210\u0212\u1E5A\u1E5C\u0156\u1E5E\u024C\u2C64\uA75A\uA7A6\uA782]/g},
      {'base':'S', 'letters':/[\u0053\u24C8\uFF33\u1E9E\u015A\u1E64\u015C\u1E60\u0160\u1E66\u1E62\u1E68\u0218\u015E\u2C7E\uA7A8\uA784]/g},
      {'base':'T', 'letters':/[\u0054\u24C9\uFF34\u1E6A\u0164\u1E6C\u021A\u0162\u1E70\u1E6E\u0166\u01AC\u01AE\u023E\uA786]/g},
      {'base':'TZ','letters':/[\uA728]/g},
      {'base':'U', 'letters':/[\u0055\u24CA\uFF35\u00D9\u00DA\u00DB\u0168\u1E78\u016A\u1E7A\u016C\u00DC\u01DB\u01D7\u01D5\u01D9\u1EE6\u016E\u0170\u01D3\u0214\u0216\u01AF\u1EEA\u1EE8\u1EEE\u1EEC\u1EF0\u1EE4\u1E72\u0172\u1E76\u1E74\u0244]/g},
      {'base':'V', 'letters':/[\u0056\u24CB\uFF36\u1E7C\u1E7E\u01B2\uA75E\u0245]/g},
      {'base':'VY','letters':/[\uA760]/g},
      {'base':'W', 'letters':/[\u0057\u24CC\uFF37\u1E80\u1E82\u0174\u1E86\u1E84\u1E88\u2C72]/g},
      {'base':'X', 'letters':/[\u0058\u24CD\uFF38\u1E8A\u1E8C]/g},
      {'base':'Y', 'letters':/[\u0059\u24CE\uFF39\u1EF2\u00DD\u0176\u1EF8\u0232\u1E8E\u0178\u1EF6\u1EF4\u01B3\u024E\u1EFE]/g},
      {'base':'Z', 'letters':/[\u005A\u24CF\uFF3A\u0179\u1E90\u017B\u017D\u1E92\u1E94\u01B5\u0224\u2C7F\u2C6B\uA762]/g},
      {'base':'a', 'letters':/[\u0061\u24D0\uFF41\u1E9A\u00E0\u00E1\u00E2\u1EA7\u1EA5\u1EAB\u1EA9\u00E3\u0101\u0103\u1EB1\u1EAF\u1EB5\u1EB3\u0227\u01E1\u00E4\u01DF\u1EA3\u00E5\u01FB\u01CE\u0201\u0203\u1EA1\u1EAD\u1EB7\u1E01\u0105\u2C65\u0250]/g},
      {'base':'aa','letters':/[\uA733]/g},
      {'base':'ae','letters':/[\u00E6\u01FD\u01E3]/g},
      {'base':'ao','letters':/[\uA735]/g},
      {'base':'au','letters':/[\uA737]/g},
      {'base':'av','letters':/[\uA739\uA73B]/g},
      {'base':'ay','letters':/[\uA73D]/g},
      {'base':'b', 'letters':/[\u0062\u24D1\uFF42\u1E03\u1E05\u1E07\u0180\u0183\u0253]/g},
      {'base':'c', 'letters':/[\u0063\u24D2\uFF43\u0107\u0109\u010B\u010D\u00E7\u1E09\u0188\u023C\uA73F\u2184]/g},
      {'base':'d', 'letters':/[\u0064\u24D3\uFF44\u1E0B\u010F\u1E0D\u1E11\u1E13\u1E0F\u0111\u018C\u0256\u0257\uA77A]/g},
      {'base':'dz','letters':/[\u01F3\u01C6]/g},
      {'base':'e', 'letters':/[\u0065\u24D4\uFF45\u00E8\u00E9\u00EA\u1EC1\u1EBF\u1EC5\u1EC3\u1EBD\u0113\u1E15\u1E17\u0115\u0117\u00EB\u1EBB\u011B\u0205\u0207\u1EB9\u1EC7\u0229\u1E1D\u0119\u1E19\u1E1B\u0247\u025B\u01DD]/g},
      {'base':'f', 'letters':/[\u0066\u24D5\uFF46\u1E1F\u0192\uA77C]/g},
      {'base':'g', 'letters':/[\u0067\u24D6\uFF47\u01F5\u011D\u1E21\u011F\u0121\u01E7\u0123\u01E5\u0260\uA7A1\u1D79\uA77F]/g},
      {'base':'h', 'letters':/[\u0068\u24D7\uFF48\u0125\u1E23\u1E27\u021F\u1E25\u1E29\u1E2B\u1E96\u0127\u2C68\u2C76\u0265]/g},
      {'base':'hv','letters':/[\u0195]/g},
      {'base':'i', 'letters':/[\u0069\u24D8\uFF49\u00EC\u00ED\u00EE\u0129\u012B\u012D\u00EF\u1E2F\u1EC9\u01D0\u0209\u020B\u1ECB\u012F\u1E2D\u0268\u0131]/g},
      {'base':'j', 'letters':/[\u006A\u24D9\uFF4A\u0135\u01F0\u0249]/g},
      {'base':'k', 'letters':/[\u006B\u24DA\uFF4B\u1E31\u01E9\u1E33\u0137\u1E35\u0199\u2C6A\uA741\uA743\uA745\uA7A3]/g},
      {'base':'l', 'letters':/[\u006C\u24DB\uFF4C\u0140\u013A\u013E\u1E37\u1E39\u013C\u1E3D\u1E3B\u017F\u0142\u019A\u026B\u2C61\uA749\uA781\uA747]/g},
      {'base':'lj','letters':/[\u01C9]/g},
      {'base':'m', 'letters':/[\u006D\u24DC\uFF4D\u1E3F\u1E41\u1E43\u0271\u026F]/g},
      {'base':'n', 'letters':/[\u006E\u24DD\uFF4E\u01F9\u0144\u00F1\u1E45\u0148\u1E47\u0146\u1E4B\u1E49\u019E\u0272\u0149\uA791\uA7A5]/g},
      {'base':'nj','letters':/[\u01CC]/g},
      {'base':'o', 'letters':/[\u006F\u24DE\uFF4F\u00F2\u00F3\u00F4\u1ED3\u1ED1\u1ED7\u1ED5\u00F5\u1E4D\u022D\u1E4F\u014D\u1E51\u1E53\u014F\u022F\u0231\u00F6\u022B\u1ECF\u0151\u01D2\u020D\u020F\u01A1\u1EDD\u1EDB\u1EE1\u1EDF\u1EE3\u1ECD\u1ED9\u01EB\u01ED\u00F8\u01FF\u0254\uA74B\uA74D\u0275]/g},
      {'base':'oi','letters':/[\u01A3]/g},
      {'base':'ou','letters':/[\u0223]/g},
      {'base':'oo','letters':/[\uA74F]/g},
      {'base':'p','letters':/[\u0070\u24DF\uFF50\u1E55\u1E57\u01A5\u1D7D\uA751\uA753\uA755]/g},
      {'base':'q','letters':/[\u0071\u24E0\uFF51\u024B\uA757\uA759]/g},
      {'base':'r','letters':/[\u0072\u24E1\uFF52\u0155\u1E59\u0159\u0211\u0213\u1E5B\u1E5D\u0157\u1E5F\u024D\u027D\uA75B\uA7A7\uA783]/g},
      {'base':'s','letters':/[\u0073\u24E2\uFF53\u00DF\u015B\u1E65\u015D\u1E61\u0161\u1E67\u1E63\u1E69\u0219\u015F\u023F\uA7A9\uA785\u1E9B]/g},
      {'base':'t','letters':/[\u0074\u24E3\uFF54\u1E6B\u1E97\u0165\u1E6D\u021B\u0163\u1E71\u1E6F\u0167\u01AD\u0288\u2C66\uA787]/g},
      {'base':'tz','letters':/[\uA729]/g},
      {'base':'u','letters':/[\u0075\u24E4\uFF55\u00F9\u00FA\u00FB\u0169\u1E79\u016B\u1E7B\u016D\u00FC\u01DC\u01D8\u01D6\u01DA\u1EE7\u016F\u0171\u01D4\u0215\u0217\u01B0\u1EEB\u1EE9\u1EEF\u1EED\u1EF1\u1EE5\u1E73\u0173\u1E77\u1E75\u0289]/g},
      {'base':'v','letters':/[\u0076\u24E5\uFF56\u1E7D\u1E7F\u028B\uA75F\u028C]/g},
      {'base':'vy','letters':/[\uA761]/g},
      {'base':'w','letters':/[\u0077\u24E6\uFF57\u1E81\u1E83\u0175\u1E87\u1E85\u1E98\u1E89\u2C73]/g},
      {'base':'x','letters':/[\u0078\u24E7\uFF58\u1E8B\u1E8D]/g},
      {'base':'y','letters':/[\u0079\u24E8\uFF59\u1EF3\u00FD\u0177\u1EF9\u0233\u1E8F\u00FF\u1EF7\u1E99\u1EF5\u01B4\u024F\u1EFF]/g},
      {'base':'z','letters':/[\u007A\u24E9\uFF5A\u017A\u1E91\u017C\u017E\u1E93\u1E95\u01B6\u0225\u0240\u2C6C\uA763]/g}
    ];
    for(var i=0; i<defaultDiacriticsRemovalMap.length; i++) {
      str = str.replace(defaultDiacriticsRemovalMap[i].letters, defaultDiacriticsRemovalMap[i].base);
    }
    return str;
  }

  geraNomeArquivo(str) {
    str = this.substituiCaractereEspecial(str);
    str = this.substituiEspacosUnderline(str);
    return str.toLowerCase();
  }
  
  public buscaImagem(obj: any) :string {
    if(obj.imagem_exibicao){
      return `${environment.api_url}/busca-imagem/${obj.imagem_exibicao}`;
    }else if(obj.arquivo_exibicao){
      return `${environment.api_url}/busca-imagem/${obj.arquivo_exibicao}`;
    }else if(obj.endereco){
      return `${environment.api_url}/busca-imagem/${obj.endereco}`;
    }else{
      return `${environment.api_url}/busca-imagem/${this.DEFAULT_IMG}`;
    }
  }

  public buscaPdf(objeto : any){
    if(objeto.imagem_exibicao){
      return `${environment.api_url}/busca-pdf/${objeto.imagem_exibicao}`
    }else if(objeto.arquivo_exibicao){
      return `${environment.api_url}/busca-pdf/${objeto.arquivo_exibicao}`
    }else{
      return `${environment.api_url}/busca-pdf/${this.DEFAULT_IMG}`;
    }
  }

  public buscaPdfArquivo(objeto : any){
    if(objeto.imagem_exibicao){
      return `${environment.api_url}/busca-pdf/${objeto.imagem_exibicao}`
    }else{
      return `${environment.api_url}/busca-pdf/${this.DEFAULT_IMG}`;
    }
  }
  
  public buscaThumbnail(obj: any) :string {
    if(obj.imagem_exibicao){
      return `${environment.api_url}/busca-thumbnail/${obj.imagem_exibicao}`;
    }else{
      return `${environment.api_url}/busca-thumbnail/${this.DEFAULT_IMG}`;
    }
  }

  public uploadImagem(formData: FormData): Observable<any> {
    return this.http.post(`${environment.api_url}/file/upload`, formData);
  }

  public verificaInicioInscricao(data_inicio_inscricoes : string) : boolean{
    let retorno = false;
    let dataAtual = new  Date();
    if(new Date(data_inicio_inscricoes) <= dataAtual){
      retorno = true;
    }
    return retorno;
  }

  public verificaEncerramentoInscricao(data_fim_inscricoes : string) : boolean{
    let retorno = false;
    let dataAtual = new  Date();
    if(new Date(data_fim_inscricoes) <= dataAtual){
      retorno = true;
    }
    return retorno;
  }

  public verificaMaximoInscricoesEvento(maximo_inscricoes:number, quantidade_inscricoes: number) : boolean{
    let retorno = false;
    if(maximo_inscricoes  && (maximo_inscricoes <= quantidade_inscricoes)){
      retorno = true;
    }
    return retorno;
  }

  public validaCpf(cpf: string): boolean {
    if (cpf == null) {
      return false;
    }
    cpf = cpf.replace(".","").replace(".","").replace("-","");
    if (cpf.length != 11) {
        return false;
    }
    if ((cpf == '00000000000') || (cpf == '11111111111') || (cpf == '22222222222')
        || (cpf == '33333333333') || (cpf == '44444444444') || (cpf == '55555555555')
        || (cpf == '66666666666') || (cpf == '77777777777') || (cpf == '88888888888')
        || (cpf == '99999999999')) {
        return false;
    }
    let numero: number = 0;
    let caracter: string = '';
    let numeros: string = '0123456789';
    let j: number = 10;
    let somatorio: number = 0;
    let resto: number = 0;
    let digito1: number = 0;
    let digito2: number = 0;
    let cpfAux: string = '';
    cpfAux = cpf.substring(0, 9);
    for (let i: number = 0; i < 9; i++) {
      caracter = cpfAux.charAt(i);
      if (numeros.search(caracter) == -1) {
        return false;
      }
      numero = Number(caracter);
      somatorio = somatorio + (numero * j);
      j--;
    }
    resto = somatorio % 11;
    digito1 = 11 - resto;
    if (digito1 > 9) {
      digito1 = 0;
    }
    j = 11;
    somatorio = 0;
    cpfAux = cpfAux + digito1;
    for (let i: number = 0; i < 10; i++) {
      caracter = cpfAux.charAt(i);
      numero = Number(caracter);
      somatorio = somatorio + (numero * j);
      j--;
    }
    resto = somatorio % 11;
    digito2 = 11 - resto;
    if (digito2 > 9) {
      digito2 = 0;
    }
    cpfAux = cpfAux + digito2;
    if (cpf != cpfAux) {
      return false;
    }
    else {
      return true;
    }
  }

  public uploadPDF(formData: FormData): Observable<any> {
    return this.http.post(`${this.upload_pdf_api_url}`, formData);
  }

  public getDataAgora() : string{
    let now = new Date();
    let data =  now.getFullYear() + '-' + (now.getMonth() + 1) + '-' + now.getDate();
    let hora = now.getHours() + ':' + now.getMinutes();
    return data + ' ' + hora;
  }

  public downloadExcel(titulos: string[], dados: any[], titulo: string){
    let csv = '<html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-microsoft-com:office:excel" xmlns="http://www.w3.org/TR/REC-html40">';
    csv += '<meta http-equiv="content-type" content="application/vnd.ms-excel; charset=UTF-8">';
    csv += '<style>';
    csv += '  table {border: 2px solid black;font-family:Calibri;font-size:9pt;color:black;margin: 2px;}';
    csv += '  th    {font-weight:900}';
    csv += '  td    {font-weight:300;}';
    csv += '  .text {ms-number-format:"\@";}';
    csv += '</style>';
    csv += '<body>';
    csv += '<table>';
    csv += '<thead s>';
    csv += '<tr>';
    titulos.forEach( col => {
      csv += '<th style="text-align:center; width:70;background-color:silver;border:1px solid black;">' + col.toUpperCase() + '</th>';
    });
    csv += '</tr>';
    csv += '</thead>';
    csv += '<tbody>';

    dados.forEach(dado => {
      csv += '<tr>';
      for (const key in dado) {
        csv += '<td style="text-align:center;border:1px solid black; width: 100;">'+ dado[key] +'</td>';
      }
      csv += '</tr>';
    })
    csv += '</tbody>';
    csv += '</table>';
    csv += '</body>';
    csv += '</html>';

    if (window.Blob) {
      var textFileAsBlob = new Blob([csv], {
          type: 'text/plain;charset=utf-8;'
      });
      var downloadLink = document.createElement("a");
      downloadLink.download = titulo;
      downloadLink.innerHTML = "Download File";
      if (window.URL != null) {
          // o chrome permite que o link seja clicado sem inserir ele no DOM (fisicamente na pagina)
          //downloadLink.href = window.webkitURL.createObjectURL(textFileAsBlob);
          downloadLink.href = window.URL.createObjectURL(textFileAsBlob);
      } else {
          // O Firefox não permite clicar no link se nao existir na pagina, por isso precisa da funca para
          // pagar o mesmo após ser clicado
          downloadLink.href           = window.URL.createObjectURL(textFileAsBlob);
          downloadLink.style.display  = "none";
          document.body.appendChild(downloadLink);
      }
      ////////////////////////////////////
      // IE salva o arquivo deste jeito //
      ////////////////////////////////////
      if (navigator.msSaveBlob) {
          navigator.msSaveBlob(textFileAsBlob, titulo);
      ////////////////////////////////////////////////////////////////////
      // Firefox e Chrome permitem clicar no link para salvar o arquivo //
      ////////////////////////////////////////////////////////////////////
      } else {
          downloadLink.click();
      }
    }
  }

  public preparaExcelCompetidorTrio(dados: any[]){
    let retorno = [];
    if(dados.length > 0){
      dados.forEach(dado => {
        if(dado.competidores.length > 0){
          dado.competidores.forEach((competidor, index) => {
            retorno.push({
              nomeProva: index == 0 ? dado.nome : '',
              nomeUm: competidor[0].nome,
              nomeDois: competidor[1].nome,
              nomeTres: competidor[2].nome,
              handicapUm: competidor[0].handicap,
              handicapDois: competidor[1].handicap,
              handicapTres: competidor[2].handicap,
              cavaloUm: competidor[0].cavalo,
              cavaloDois: competidor[1].cavalo,
              cavaloTres: competidor[2].cavalo,
            });
          });
        }
      });
    }
    return retorno;
  }

  public cabecalhoExcelCompetidorTrio(){
    return ['Prova', "Competidor 1", "Competidor 2", "Competidor 3",
    "Handicap C1", "Handicap C2", "Handicap C3", "Cavalo C1", "Cavalo C2", "Cavalo C3"];
  }

  public dataAtualFormatada(data){
    let dia, mes, ano;
    ano = data.substring(0, 4);
    mes = data.substring(5,7)
    dia = data.substring(8,10)
    return dia+"/"+mes+"/"+ano;
}
}
