import { Component, OnInit } from '@angular/core';
import { UtilService } from '../../../util/util.service';
import { EventoService } from '../../../service/evento.service';
import { ResultadoEventoService } from '../../../service/resultado-evento.service';
import { ResultadoEvento } from '../../../model/resultado-evento';
import { Evento } from '../../../model/evento';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-cadastro-resultado-evento',
  templateUrl: './cadastro-resultado-evento.component.html',
  styleUrls: ['../../comum.style.css', './cadastro-resultado-evento.component.css']
})
export class CadastroResultadoEventoComponent implements OnInit {

  public titulo: string = 'Cadastro de Resultado do Evento'
  private id_evento: number;
  private id_resultado_evento: number;
  public resultadoEvento: ResultadoEvento = new ResultadoEvento();
  public evento: Evento;
  public form: FormGroup;
  public nomeArquivo: string;
  public imageToShow: string;
  public exibir_arquivo: boolean;
  public arquivoAntigo: string;

  constructor(public utilService: UtilService,
              private eventoService : EventoService,
              private resultadoEventoService: ResultadoEventoService,
              private formBuilder: FormBuilder,
              private activatedRoute: ActivatedRoute,
              private router: Router) { }

  ngOnInit() {
    this.initPagina();
    this.initForm();
  }

  private initPagina(){
    this.id_evento = this.activatedRoute.snapshot.params.id_evento;
    this.id_resultado_evento = this.activatedRoute.snapshot.params.id_resultado_evento;
    if(this.id_evento && !isNaN(this.id_evento)){
      this.buscaEvento();
    }

    if(this.id_resultado_evento && !isNaN(this.id_resultado_evento)){
      this.titulo = 'Editar Resultado do Evento'
      this.buscaResultadoEvento();
    }
  }

  private buscaResultadoEvento(){
    this.resultadoEventoService.buscaPorId(this.id_resultado_evento)
    .subscribe(
      retorno =>{
        this.resultadoEvento = retorno;
        this.arquivoAntigo = this.utilService.buscaPdf(retorno);
        this.resultadoEvento.arquivo_old = retorno.arquivo_exibicao;
        this.form.controls['arquivo_exibicao'].setValue(retorno.arquivo_exibicao);
      },
      erro => this.utilService.mensagemErro('Erro - ' + erro.error.mensagem)
    );
  }

  private buscaEvento(){
    this.eventoService.buscaPorId2(this.id_evento)
    .subscribe(
      retorno => this.evento = retorno,
      erro => this.utilService.mensagemErro('Erro - ' + erro.error.mensagem)
    );
  }

  private initForm(){
    this.form = this.formBuilder.group({
      titulo: new FormControl(this.resultadoEvento.titulo, [
        Validators.required,
        Validators.minLength(3),
        Validators.maxLength(150)
      ]),
      descricao: new FormControl(this.resultadoEvento.descricao, [
        Validators.required,
        Validators.minLength(3)
      ]),
      arquivo_exibicao: new FormControl(this.resultadoEvento.arquivo_exibicao, [
        Validators.required,
        Validators.maxLength(200)
      ])
    });
  }

  public fileChange(event : any){
    let file = event.target.files[0];
    this.nomeArquivo = file.name;
    this.upload(file);
  }

  public upload(file){
    let formData = new FormData();
    formData.append('file', file)
    this.utilService.uploadPDF(formData).subscribe(
      retorno => {
        if(this.resultadoEvento.arquivo_exibicao && this.resultadoEvento.arquivo_exibicao.includes(this.resultadoEvento.titulo)){
          this.resultadoEvento.arquivo_old = this.resultadoEvento.arquivo_exibicao;
        }
        this.setImagemExibicao(retorno.path);
      },
      erro => this.utilService.mensagemErro("Erro - " + erro.error.mensagem)
    );
  }

  public atribuiValorImagem(response: any):void{
    if(this.resultadoEvento.arquivo_exibicao && this.resultadoEvento.arquivo_exibicao.includes(this.resultadoEvento.titulo)){
        this.resultadoEvento.arquivo_old = this.resultadoEvento.arquivo_exibicao;
    }
    this.setImagemExibicao(response.path);
    this.imageToShow = response.img;
  }

  public setImagemExibicao(valor : string) : void{
    this.exibir_arquivo = false;
    this.resultadoEvento.arquivo_exibicao = valor;
    this.form.controls['arquivo_exibicao'].setValue(valor);
  }

  public salvar(){
    if(this.form.invalid){
      this.utilService.showErrors(this.form);
      return;
    }

    this.resultadoEvento.id_cadastrador = this.utilService.getIdLogado();
    this.resultadoEvento.id_tipo_arquivo = 3;
    if(!this.resultadoEvento.id_resultado_evento){
      this.resultadoEvento.id_evento = this.id_evento;
      this.inserir();
    }else{
      this.alterar();
    }
  }

  public inserir(){
    this.resultadoEventoService.inserir(this.resultadoEvento)
    .subscribe(
      () => {
        this.utilService.mensagemSucesso("Cadastro realizado com sucesso!");
        this.irParaEvento();
      },
      erro => this.utilService.mensagemErro("Erro - " + erro.error.mensagem)
    );
  }

  public alterar(){
    this.resultadoEventoService.alterar(this.resultadoEvento.id_resultado_evento, this.resultadoEvento)
    .subscribe(
      () => {
        this.utilService.mensagemSucesso("Operação realizada com sucesso!");
        this.irParaResultados();
      },
      erro => this.utilService.mensagemErro('Erro - ' + erro.error.mensagem)
    );
  }

  public irParaEvento(){
    this.router.navigate(['comum/evento/' + this.id_evento]);
  }

  public irParaResultados(){
    this.router.navigate(['comum/evento/resultados/' + this.id_evento]);
  }

  public voltar(){
    if(this.resultadoEvento.id_resultado_evento){
      this.irParaResultados();
    }else{
      this.irParaEvento();
    }
  }
}
