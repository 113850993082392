import { Usuario } from './usuario';
import { Prova } from './prova';
import { Divisao } from './divisao';
import { EventoRaca } from './evento-raca';
import { Campeonato } from './campeonato';
import { RegraEvento } from './regra_evento';
import { ErroRegra } from './erro-regra';
import { FotoEvento } from './foto-evento';

export class Evento {
    id_evento: number;
    titulo: string;
    descricao: string;
    id_organizador: number;
    telefone: string;
    website: string;
    localizacao: string;
    imagem_exibicao: string;
    data_inicial: string;
    data_final: string;
    data_inicio_inscricoes: string;
    data_fim_inscricoes: string;
    id_campeonato: number;
    localizacao_maps : string;
    nome_organizador : string;
    preco_inscricao: number;
    porcentagem_premiacao: number;
    maximo_inscricoes : number;
    maximo_competidores: number;
    maximo_inscricoes_competidor: number;
    maximo_inscricoes_duplas: number;
    maximo_inscricoes_trio: number;
    maximo_inscricoes_cavalo: number;
    quantidade_inscricoes: number;
    preco_inscricao_todos_contra_todos: number;
    porcentagem_premiacao_todos_contra_todos: number;
    incremento_premiacao_todos_contra_todos: number;
    tempo_passada_todos_contra_todos: number;
    maximo_inscricoes_todos_contra_todos: number;
    quantidade_premiados_todos_contra_todos: number;
    taxa_administrativa : number;
    regras: RegraEvento[];
    arquivo_imagem: string;
    imagem_old: string;
    thumbnail: string;
    organizador: Usuario;
    provas: Prova[];
    divisoes: Divisao[];
    racas_pontuar: EventoRaca[];
    campeonato: Campeonato;
    regrasNaoAtendidas: ErroRegra[] = [];
    finalizado: boolean;
    foto_evento: FotoEvento;
    quantidade_resultados: number;
    incremento_preco: number;
    
    public addErroRegra(erro: ErroRegra): void {
        if (!this.regrasNaoAtendidas) {
            this.regrasNaoAtendidas = [];
        }
        this.regrasNaoAtendidas.push(erro);
    }

    public removeErroRegra(erro: ErroRegra): void {
        this.regrasNaoAtendidas.splice(this.regrasNaoAtendidas.indexOf(erro), 1);
    }

    public isValida(): boolean {
        return this.regrasNaoAtendidas && this.regrasNaoAtendidas.length > 0;
    }
}