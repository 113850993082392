import {Component, Inject, OnInit} from '@angular/core';
import { Evento } from '../../model/evento';
import { Router } from '@angular/router';
import { UtilService } from '../../util/util.service';
import { EventoService } from '../../service/evento.service';
import { ProvaService } from '../../service/prova.service';
import { EventoRacaService } from '../../service/evento-raca.service';
import { DivisaoService } from '../../service/divisao.service';
import { CampeonatoService } from '../../service/campeonato.service';
import { FormGroup } from '@angular/forms';
import { ParametrosBusca } from '../../model/parametros_busca';
import { EventoBuscaService } from './eventoBusca.service';
import { RegraEventoService } from '../../../app/service/regra-evento.service';
import { UsuarioService } from '../../../app/service/usuario.service';
import { CavaloService } from '../../../app/service/cavalo.service';


declare var $: any;
declare var UIkit: any;

@Component({
  selector: 'app-eventos-component',
  moduleId: module.id,
  templateUrl: './eventos.component.html',
  styleUrls: ['./eventos.style.css']
})

export class EventosComponent implements OnInit {

  // ----- Paginação Alteração

  public filtro: {
    titulo: string,
    data_inicial: Date,
    data_final: Date,
    finalizado: boolean
  } = {
    titulo: null,
    data_inicial: null,
    data_final: null,
    finalizado: false
  };

  public formFiltro: FormGroup;
  public parametrosBusca = new ParametrosBusca();
  options: ParametrosBusca = new ParametrosBusca();
  eventos: Evento[] = [];

  public podeVoltar: boolean;
  public podeProsseguir = true;
  
  private botoesEsquerda: any[] = new Array(3);
  private botaoCentro: any;
  private botoesDireita: any[] = new Array(3);

  public botoes: any[] = new Array();

  private paginasDireita: number;
  private paginasEsquerda: number;
  private pagina = 1;
  private totalPaginas: number;

  private paginasLaterais = 2;
  private quantidadeRegistros: number;

  // ----- FimPaginaçao

  eventoParaReplicar: Evento;
  resetado: string = "";

  //public eventos: Evento[] = [];
  //public filtro: any = {};
  public imagemModal: any;
  public eventoSelecionado: Evento;

  constructor(@Inject(Router) private router: Router,
              private eventoService: EventoService,
              private utilService: UtilService,
              private provaService: ProvaService,
              private eventoBuscaService: EventoBuscaService,
              private eventoRacaService: EventoRacaService,
              private divisaoService: DivisaoService,
              private campeonatoService: CampeonatoService,
              private regraEventoService : RegraEventoService,
              private cavaloService: CavaloService,
              private usuarioService: UsuarioService) {
        
    this.pagina = 1;
    this.parametrosBusca.pagina = this.pagina;
    this.options.offset = 0;
    this.options.limit = 5;
    this.filtrar();
  }

  ngOnInit() {
  }

  public getEventos() {
    this.eventoService.buscaTodos().subscribe(eventos => {
      this.eventos = eventos;
      this.getImgEvento(eventos);
    });
  }

  public filtrar(): void {
    let filtro
    filtro = this.filtro;

    filtro.titulo = $('#titulo').val();

    this.options.offset = 0;
    this.options.offset = (this.pagina-1) * this.options.limit;
    
    this.eventoBuscaService.filtro = filtro;

    this.eventoService.buscaTodosFiltro2({
        "filtro": filtro
    }, this.options).subscribe(eventos => {
      if (eventos) {
        this.getImgEvento(eventos);
        this.getThumbnailEvento(eventos);
        this.eventos = eventos; 
      }
    });
    this.eventoService.buscaQuantidadeRegistros({
      "filtro": filtro
    }, this.options).subscribe(quantidade => {
      this.quantidadeRegistros = quantidade[0].count;
      this.geraBotoes();
      this.mudarInfoBtns();
    })
    
  }

  reiniciaPagina() {
    this.pagina = 1;
  }

  public limpar(): void {
    this.options.offset = 0;
    this.pagina = 1;
    
    this.eventoBuscaService.filtro = {
      titulo: null,
      data_inicial: null,
      data_final: null
    };

    $('#titulo').val('');

    this.eventoService.buscaTodosFiltro({
      "filtro": this.eventoBuscaService.filtro
    }, this.options).subscribe(eventos => {
      if (eventos) {
        this.getImgEvento(eventos);
        this.getThumbnailEvento(eventos);
        this.eventos = eventos;
      }
    });
    
    this.eventoService.buscaQuantidadeRegistros({
        "filtro": this.eventoBuscaService.filtro
    }, this.options).subscribe(quantidade => {
        this.quantidadeRegistros = quantidade[0].count;
        this.geraBotoes();
        this.mudarInfoBtns();
    })
  }

  private geraBotoes() {
    this.botoes = [];
    for(let i = 0; i < this.quantidadeRegistros/5; i++ ) {
      this.botoes.push(i+1);
    }
    this.totalPaginas = this.botoes.length;
    this.parametrosBusca.totalPaginas = this.totalPaginas;
  }

  public irParaPagina(pagina) :void {      
      this.options.offset = (pagina-1)*5;
      this.eventoService.buscaTodosFiltro({
        "filtro": this.eventoBuscaService.filtro
      }, this.options).subscribe(eventos => {
        if (eventos) {
          this.getImgEvento(eventos);
          this.getThumbnailEvento(eventos);
          this.eventos = eventos;
        }
      });
  }

  /* ----- PAGINATION ----- */

  private geraBotoesPaginacao() {
    let paginas = this.parametrosBusca.totalPaginas;
    for(let i=0; i<paginas; i++) {
      this.botoes[i] = i+1;
    }
  }

  private configurarPagination() {    
      this.podeIrParaPaginaAnterior();
      this.podeIrParaProximaPagina();
  }

  private mudarInfoBtns() {

    if(this.pagina > 1) {
      this.podeVoltar = true;
    } else {
      this.podeVoltar = false;
    }

    if(this.pagina < this.totalPaginas) {
      this.podeProsseguir = true;
    } else {
      this.podeProsseguir = false;
    }
  }

  private podeIrParaPaginaAnterior(): void {
    this.podeVoltar = this.parametrosBusca.pagina != 1;
  }

  private podeIrParaProximaPagina(): void {
    this.podeProsseguir = (this.parametrosBusca.pagina != this.parametrosBusca.totalPaginas) &&
                          (this.parametrosBusca.totalPaginas > 0);
  }

  public irParaPrimeiraPagina(): void {
    this.pagina = 1;
    this.parametrosBusca.pagina = 1;
    this.parametrosBusca.offset = 0;
    this.configurarPagination();
    this.filtrar();
  }

  public irParaPaginaAnterior(): void {
    this.pagina--;
    this.parametrosBusca.pagina--;
    this.parametrosBusca.offset -= this.parametrosBusca.limit;
    this.configurarPagination();
    this.filtrar();
  }

  public pularParaPaginaEspecifica(pagina: number): void {
    this.pagina = pagina;
    this.parametrosBusca.offset = (pagina * this.parametrosBusca.limit) - this.parametrosBusca.limit;
    this.configurarPagination();
    this.filtrar();
  }

  public irParaProximaPagina(): void {
    this.parametrosBusca.pagina++;
    this.pagina++;
    this.parametrosBusca.offset += this.parametrosBusca.limit;
    this.configurarPagination();
    this.filtrar();
  }

  public irParaUltimaPagina(): void {
    this.pagina = this.totalPaginas;
    this.parametrosBusca.pagina = this.parametrosBusca.totalPaginas;
    this.parametrosBusca.offset = (this.parametrosBusca.totalPaginas * this.parametrosBusca.limit) - this.parametrosBusca.limit;
    this.configurarPagination();
    this.filtrar();
  }

  private exibeBotao(botao) {
    let exibe = false;
    if(botao <= this.pagina + this.paginasLaterais 
      && botao >= this.pagina - this.paginasLaterais) {
      exibe = true;
    } else if((this.pagina - this.paginasLaterais) <= 0 && (botao == 4 || botao == 5)) {
      exibe = true;
    } else if((this.pagina + this.paginasLaterais) >= this.totalPaginas 
      && (botao >= (this.totalPaginas - this.paginasLaterais*2))) {
      exibe = true;
    }      
    return exibe;
  }

  private redireciona(id_evento) {
    this.utilService.irPara(this.router, '/comum/evento/' + id_evento);
  }

  public baixaXML(evento) {
    this.eventoService.exportarXml(evento.id_evento).subscribe(x => {
      let retorno = JSON.parse(x);
      let nomeArquivo = this.utilService.geraNomeArquivo(evento.titulo);
      let dadosExcel = this.utilService.preparaExcelCompetidorTrio(retorno.dadosTrio);
      if(dadosExcel.length > 0){
        let cabecalhoExcel = this.utilService.cabecalhoExcelCompetidorTrio();
        this.utilService.downloadExcel(cabecalhoExcel, dadosExcel, "inscricoes_" + nomeArquivo + "_trio.xls");
      }
      this.utilService.download("inscricoes_" + nomeArquivo + ".xml", retorno.data);
    }, erro =>{
      if(typeof erro.error == 'string'){
        erro.error = JSON.parse(erro.error);
      }
      this.utilService.mensagemErro(erro.error.mensagem);
    });
  }

  public abreEdicaoEvento(evento){
    this.provaService.buscaProvasDeUmEvento(evento.id_evento)
    .subscribe( provas => evento.provas = provas);
    this.regraEventoService.buscarRegrasPorEvento(evento.id_evento)
    .subscribe( regras => evento.regras = regras);
    this.utilService.irPara(this.router, '/comum/editar-evento', {
      "id_evento" : evento.id_evento
    });
  }

  public excluirEvento(evento){
    this.utilService.mensagemConfirmacao('Deseja excluir o evento ' + evento.titulo + ' ?', (confirmar) => {
      if(confirmar){
        this.eventoService.deleta(evento.id_evento).subscribe(
          success => {
            this.utilService.mensagemSucesso("Evento excluído com sucesso!");
            //this.getEventos();
            this.irParaPrimeiraPagina();
          },
          err => {
            this.utilService.mensagemErro(err.error.mensagem);
          }
        );
      }
    })
  }

  getImgEvento(eventos: Evento[]){
    eventos.forEach(evento => {
      evento.arquivo_imagem =  this.utilService.buscaImagem(evento);
    });
  }
    
  private getThumbnailEvento(eventos: Evento[]){
    eventos.forEach(evento => {
      evento.thumbnail =  this.utilService.buscaThumbnail(evento);
      });
  }

  private selecionaCampeonatoDoEventoReplicado(evento: Evento) {
    this.eventoSelecionado = evento;
    UIkit.modal('#modalSelecionaCampeonato', {
      bgClose : false
    }).show();
  }

  private atribuirValoresNovoEvento(evento: Evento) {
    this.utilService.mensagemConfirmacao(`Deseja replicar o evento ${evento.titulo}?`, (confirmar) => {
      if(!confirmar)  return;
      let novoEvento: Evento = new Evento();
  
      Object.keys(evento).forEach((key) => { 
        novoEvento[key] = evento[key]
      });
      novoEvento.data_inicial = new Date(novoEvento.data_inicial).toLocaleDateString();
      novoEvento.data_final = new Date(novoEvento.data_final).toLocaleDateString();
      novoEvento.data_inicio_inscricoes = new Date(novoEvento.data_inicio_inscricoes).toLocaleDateString();
      novoEvento.data_fim_inscricoes = new Date(novoEvento.data_fim_inscricoes).toLocaleDateString();
      novoEvento.id_evento = undefined;
      novoEvento.imagem_old = '';
      novoEvento.imagem_exibicao = '';
      novoEvento.provas = [];
  
      this.divisaoService.buscaPorEvento(evento.id_evento).subscribe(divisoes => {
        novoEvento.divisoes = divisoes;
        this.eventoRacaService.buscaPorEvento(evento.id_evento).subscribe( er => {
          novoEvento.racas_pontuar = er;
          this.replicaEvento(novoEvento);
        });
      });
    });
  }

  private replicaEvento(novoEventoReplicado: Evento): void {
    this.eventoService.insere(novoEventoReplicado).subscribe( (ev) => {
      this.utilService.irPara(this.router, `/comum/editar-evento/${ev.id_evento}`);
    });
  }

  private abreModalImagem(event){
    this.imagemModal = event.arquivo_imagem;
    UIkit.modal('#modal-media-evento',{
      bgClose: false
    }).show();
  }

  public redirecionaInscricao(id_evento : number) {
    if(this.utilService.isLogado()){
      this.provaService.buscaProvasDeUmEvento(id_evento)
      .subscribe(provas => {
        this.provaService.addProvas(provas);
        this.utilService.irPara(this.router, `/comum/inscricoes/${id_evento}`);
      }, erro => this.utilService.mensagemErro(erro.error.mensagem));
    }else{
      this.utilService.mensagemConfirmacao('É preciso estar logado para realizar uma inscrição.\nGostaria de realizar o login?', (result) => {
        if(result){
          this.utilService.irPara(this.router, '/comum/login');
        }
      });
    }
  }

  public selecionarFinalizado(status: string){
    this.filtro.finalizado = status == 'null' ? null : status == "true" ? true : false;
    this.options.offset = 0;
    this.filtrar();
  }

  public finalizarEvento(id_evento: number){
    this.utilService.mensagemConfirmacao("Dejesa realmente finalizar o evento?",
    resp => {
      if(resp){
        this.atualizarStatusFinalizado(id_evento, true);
      }
    })
  }

  public desfinalizarEvento(id_evento: number){
    this.utilService.mensagemConfirmacao("Dejesa realmente ativar o evento?",
    resp => {
      if(resp){
        this.atualizarStatusFinalizado(id_evento, false);
      }
    });
  }

  private atualizarStatusFinalizado(id_evento: number, status: boolean){
    this.eventoService.atualizarStatusFinalizado(id_evento, status)
    .subscribe(retorno => {
      this.utilService.mensagemSucesso("Operação realizada com sucesso!");
      this.filtrar();
    }, erro => this.utilService.mensagemErro("Erro - " + erro.error.mensagem ? erro.error.mensagem : erro.error.message));
  }

  public exportarCompetidores(){
    this.usuarioService.exportarCompetidoresXml()
    .subscribe(retorno => {
      if(retorno){
        this.utilService.download("handicaps.xml",retorno);
      }
    }, erro => {
      if(typeof erro.error == 'string'){
        erro.error = JSON.parse(erro.error);
      }
      this.utilService.mensagemErro(erro.error.mensagem);
    });
  }
  public gerarXmlCavalo(id_vento: number){
    this.cavaloService.XmlCavalo(id_vento)
    .subscribe(retorno =>{
      if(retorno){
        this.utilService.download("cavalos.xml",retorno);
      }
    },erro=> {
      if(typeof erro.error == 'string'){
        erro.error = JSON.parse(erro.error);
      }
      this.utilService.mensagemErro(erro.error.mensagem)
    });
  }
}