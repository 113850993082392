import { OnInit, Component, EventEmitter, Output, Input, Inject } from '@angular/core';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

import { Usuario } from '../../../../model/usuario';
import { Cavalo } from '../../../../model/cavalo';
import { UsuarioService } from '../../../../service/usuario.service';
import { CavaloService } from '../../../../service/cavalo.service';
import { UtilService } from '../../../../util/util.service';
import { Campeonato } from '../../../../model/campeonato';
import { Evento } from '../../../../model/evento';
import { InscricaoService } from '../../../../service/inscricao.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import flatpickr from 'flatpickr';
import { Portuguese } from 'flatpickr/dist/l10n/pt';
import { ProvaService } from '../../../../service/prova.service';
import { Router, ActivatedRoute } from '@angular/router';
import { RegraAssociacaoService } from '../../../../service/regra-associacao.service';
import { RegraAssociacao } from '../../../../model/regra-associacao';
import { RegraValidadorService } from '../../../../service/regra.validador.service';
import { AssociacaoCompetidor } from '../../../../model/associacao-competidor';
import { AssociacaoCompetidorService } from '../../../../service/associacao-competidor';
import { UsuarioSemCadastroService } from '../../../../service/usuario-sem-cadastro.service';

@Component({
    selector: 'busca-de-competidores',
    templateUrl: 'busca-de-competidores.component.html',
    styleUrls: ['./../../inscricoes.style.css', './busca-de-competidores.style.css']
})
export class BuscaDeCompetidoresComponent implements OnInit {

    @Input() numeroDeInscritos: number;    
    @Input() campeonato: Campeonato;
    @Input() evento: Evento;

    private listaProsseguirCavalos: boolean[] = [];
    public valueCompetidores: string = "";
    private listaValueCavalos: string[] = [];
    public competidores: Usuario[] = [];
    private listaCavalos: Cavalo[][] = [];
    private debounceCompetidores: Subject<string>;
    private listaDebouncesCavalos: Subject<any>[] = [];
    public listaDeCompetidoresInscritos: Usuario[] = [];
    private listaDeCavalosInscritos: Cavalo[] = [];
    public tipoCadastro : Number = 1;
    public idadeCompetidorAnoHipico : Number = 49;

    @Output() listaDeCompetidoresInscritosEmitter : EventEmitter<Usuario[]> = new EventEmitter<Usuario[]>();
    @Output() listaDeCavalosInscritosEmitter : EventEmitter<Cavalo[]> = new EventEmitter<Cavalo[]>();
    @Output() tipoCadastroCompetidorEmitter : EventEmitter<Boolean> = new EventEmitter<Boolean>();
    @Output() tipoCadastroEmitter : EventEmitter<Number> = new EventEmitter<Number>();

    private filtroCavalo : {
        nome : string,
        id_evento : number
    } = {
        nome : null,
        id_evento : null
    }

    private filtro : {
        nome : string,
        cpf : string,
        ativo : boolean
    } = {
        nome : null,
        cpf: null,
        ativo : true
    }

    public regrasAssociacao : RegraAssociacao[] = [];
    public cadastroCavalo : boolean = false;
    public idCompetidores : Object = new Object();

    constructor(private usuarioService: UsuarioService,
                private cavaloService: CavaloService,
                public utilService: UtilService,
                private inscricaoService : InscricaoService,
                @Inject(FormBuilder) private formBuilder : FormBuilder,
                private provaService : ProvaService,
                @Inject(ActivatedRoute) private route: ActivatedRoute,
                private regraAssociacaoService : RegraAssociacaoService,
                private associacaoCompetidorService : AssociacaoCompetidorService,
                @Inject(Router) private router : Router,
                private usuarioSemCadastroService : UsuarioSemCadastroService) {}



    ngOnInit(): void {
        this.filtroCavalo.id_evento = parseInt(this.route.snapshot.params.idEvento);
        this.initCompetidores();
        this.TipoCadastroCompetidor(false);
        this.buscaRegrasAssociacao();
    }

    public initCompetidores(){
        this.debounceCompetidores = new Subject<string>();
        this.debounceCompetidores.pipe(debounceTime(300)).subscribe(filtro => {
            this.filtro.nome = filtro;
            this.usuarioService.buscaCompetidoresPorFiltro(this.filtro).subscribe(competidores => {
                this.competidores = competidores.filter(competidor => {
                    return (!this.listaDeCompetidoresInscritos.find(comp => {
                        return comp.id_usuario == competidor.id_usuario;
                    })) && competidor.competidor == true && competidor.ativo == true;
                });
            })
        });
    }

    public initCompetidoresSemCadastro(){
        this.debounceCompetidores = new Subject<string>();
        this.debounceCompetidores.pipe(debounceTime(300)).subscribe(filtro => {
            this.usuarioSemCadastroService.buscaTodos(filtro).subscribe(competidores => {
                this.competidores = competidores.filter(competidor => {
                    return (!this.listaDeCompetidoresInscritos.find(comp => {
                        return comp.id_usuario == competidor.id_usuario;
                    })) && competidor.competidor == true && competidor.ativo == true;
                });
            })
        });
    }

    public getCompetidores(filtro: string): void {
        if (filtro && filtro.length >= 3) {
            this.debounceCompetidores.next(filtro);
        } else {
            this.competidores = [];
        }
    }

    public buscaRegrasAssociacao(){
        this.regraAssociacaoService.buscaTodos()
        .subscribe(retorno => {
            this.regrasAssociacao = retorno;
        });
    }

    private selecionaCompetidores(competidor: Usuario): void {
        this.valueCompetidores = null;
        this.idadeCompetidorAnoHipico = this.utilService.getIdadeBaseAnoHipico(competidor.data_nascimento);
        this.competidores = [] as Usuario[];
        competidor.numero_competidor = this.listaDeCompetidoresInscritos.length + 1;
        this.listaDeCompetidoresInscritos.push(competidor);
        this.listaValueCavalos.push("");
        this.listaCavalos.push([]);
        this.listaDebouncesCavalos.push(new Subject<any>());
        let indexComp = this.listaDeCompetidoresInscritos.indexOf(competidor);
        this.listaDebouncesCavalos[indexComp].pipe(debounceTime(300)).subscribe(filtro => {
            this.filtroCavalo.nome = filtro;
            this.cavaloService.buscaTodosQuantidadeInscricao(this.filtroCavalo).subscribe(cavalos => {
                this.listaCavalos[indexComp] = cavalos.filter(cavalo => {
                    return (!this.listaDeCavalosInscritos.find(cav => cav.id_cavalo == cavalo.id_cavalo));
                });
                
                if(this.cadastroCavalo == true && this.listaCavalos[indexComp].length == 0){
                    this.idCompetidores[indexComp] = true;
                }else{
                    this.idCompetidores[indexComp] = false;
                }
            });
        });

        this.inscricaoService.adicionarCompetidor(competidor);
        this.listaDeCompetidoresInscritosEmitter.emit(this.listaDeCompetidoresInscritos);
    }

    private descelecionaCompetidor(competidor: Usuario): void {
        this.descelecionaCavalos(competidor, competidor.cavalo);
        this.valueCompetidores = null;
        this.listaDeCompetidoresInscritos.splice(this.listaDeCompetidoresInscritos.indexOf(competidor), 1);
        this.inscricaoService.removerCompetidor(competidor);
        this.listaDeCompetidoresInscritosEmitter.emit(this.listaDeCompetidoresInscritos);
        
    }

    private descelecionaCavalos(competidor: Usuario, cavalo: Cavalo): void {
        let indexCompetidor = this.listaDeCompetidoresInscritos.indexOf(competidor);
        this.listaDeCompetidoresInscritos[indexCompetidor].cavalo = null;
        this.listaDeCavalosInscritos.splice(this.listaDeCavalosInscritos.indexOf(cavalo), 1);
        this.listaValueCavalos[indexCompetidor] = null;
        this.listaProsseguirCavalos[indexCompetidor] = false;
        this.listaDeCavalosInscritosEmitter.emit(this.listaDeCavalosInscritos);
        this.idCompetidores[indexCompetidor] = false;
    }

    private getCavalos(competidor: Usuario, filtro: string): void {
        let indexComp = this.listaDeCompetidoresInscritos.indexOf(competidor);
        if (filtro && filtro.length >= 3) {
            this.listaDebouncesCavalos[indexComp].next(filtro);
            this.cadastroCavalo = true;
        } else {
            this.listaCavalos[indexComp] = [];
            this.idCompetidores[indexComp] = false;
        }
    }

    private selecionaCavalos(competidor: Usuario, cavalo: Cavalo): void {
        if (!this.listaDeCavalosInscritos.find(cav => cav.id_cavalo == cavalo.id_cavalo)) {
            let indexCompetidor = this.listaDeCompetidoresInscritos.indexOf(competidor);
            this.listaDeCompetidoresInscritos[indexCompetidor].cavalo = cavalo;
            this.listaDeCavalosInscritos.push(cavalo);
            this.listaValueCavalos[indexCompetidor] = null;
            this.listaCavalos[indexCompetidor] = [] as Cavalo[];
            this.listaProsseguirCavalos[indexCompetidor] = true;
            this.listaDeCavalosInscritosEmitter.emit(this.listaDeCavalosInscritos);
            this.idCompetidores[indexCompetidor] = false;
        } else {
            this.utilService.mensagemInfo("Cavalo já foi selecionado para outro competidor!");
        }
    }

    public adicionarPotroFuturo(competidor){
        this.listaDeCompetidoresInscritos.forEach(comp => {
            if(comp.cavalo && competidor.id_usuario == comp.id_usuario){
                comp.cavalo.potro_futuro = comp.cavalo.potro_futuro == true ? false : true;
                (document.getElementById("checkboxInput"+comp.cavalo.id_cavalo) as HTMLInputElement).checked = comp.cavalo.potro_futuro;
            }else if(comp.cavalo && competidor.id_usuario != comp.id_usuario){
                (document.getElementById("checkboxInput"+comp.cavalo.id_cavalo) as HTMLInputElement).checked = false;
                comp.cavalo.potro_futuro = false;
            }
        });
    }

    public selecionarTipoCadastro(valor : number){
        if(valor == 1 && this.tipoCadastro == 2){
            this.tipoCadastro = 1;
            this.initCompetidores();
            this.TipoCadastroCompetidor(false);
        }else if(valor == 2 && this.tipoCadastro == 1){
            this.TipoCadastroCompetidor(true);
            this.tipoCadastro = 2;
            this.initCompetidoresSemCadastro();
        }
        this.removerCompetidorPorTipoCadastro();
        this.tipoCadastroEmitter.emit(this.tipoCadastro);
    }

    private TipoCadastroCompetidor(valor : boolean){
        this.provaService.adicionarTipoCadastro(valor);
    }

    private removerCompetidorPorTipoCadastro(){
        if(this.listaDeCompetidoresInscritos.length > 0){
            this.listaDeCompetidoresInscritos.forEach(competidor => {
                this.descelecionaCompetidor(competidor);
            });
        }
    }

    public associarCompetidor(competidor : Usuario){
        if(this.regrasAssociacao.length > 0){
            let idade = this.utilService.getIdadeBaseAnoHipico(competidor.data_nascimento);
            let valida =  new RegraValidadorService(idade, null, null, null, null, null, this.utilService);
            valida.addRegraAssocicao(this.regrasAssociacao);
            let id_regra_associacao = valida.validaAssociacao().id_regra_associacao;
            if(id_regra_associacao){
                this.inserirAssociacaoCompetidor(id_regra_associacao, competidor);
            }else{
                let mensagem = competidor.nome + " não se encaixa em nenhuma categoria de filiação";
                this.utilService.mensagemInfo(mensagem);
            }
        }else{
            this.utilService.mensagemInfo("Não foi encontrado nenhuma categoria de filiação!")
        }
    }

    public inserirAssociacaoCompetidor(id_regra_associacao : number, competidor : Usuario){
        let associarCompetidor = new AssociacaoCompetidor();
        associarCompetidor.id_regra_associacao = id_regra_associacao;
        associarCompetidor.id_usuario = competidor.id_usuario;
        associarCompetidor.id_evento = this.filtroCavalo.id_evento;
        associarCompetidor.id_cadastrador = this.utilService.getIdLogado();
        associarCompetidor.associacao_competidor_paga = false;

        this.associacaoCompetidorService.insere(associarCompetidor)
        .subscribe(retorno => {
            if(retorno.id_associacao_competidor){
                competidor.associacao_competidor = retorno;
                this.utilService.mensagemSucesso("Filiação do competidor realizada com sucesso!");
            }
        }, () => this.utilService.mensagemErro("Erro - Não foi possivel realizar a filiação do competidor."))
    }
    //
    public validaAssociacao(competidor : Usuario){
        if(this.regrasAssociacao.length > 0){
            let idade = this.utilService.getIdadeBaseAnoHipico(competidor.data_nascimento);
            let valida =  new RegraValidadorService(idade, null, null, null, null, null, this.utilService);
            valida.addRegraAssocicao(this.regrasAssociacao);
            let id_regra_associacao = valida.validaAssociacao().id_regra_associacao;
            if(id_regra_associacao){
                this.inserirAssociacaoCompetidor2(id_regra_associacao, competidor);
            }else{
                let mensagem = competidor.nome + " não se encaixa em nenhuma categoria de filiação";
                this.utilService.mensagemInfo(mensagem);
            }
        }else{
            this.utilService.mensagemInfo("Não foi encontrado nenhuma categoria de filiação!")
        }
    }

    public inserirAssociacaoCompetidor2(id_regra_associacao : number, competidor : Usuario){
        let associarCompetidor = new AssociacaoCompetidor();
        associarCompetidor.id_regra_associacao = id_regra_associacao;
        associarCompetidor.id_usuario = competidor.id_usuario;
        associarCompetidor.id_evento = this.filtroCavalo.id_evento;
        associarCompetidor.id_cadastrador = this.utilService.getIdLogado();
        associarCompetidor.associacao_competidor_paga = false;

        competidor.associacao_competidor = associarCompetidor;
        this.utilService.mensagemSucesso("Filiação do competidor realizada com sucesso!");
    }
}