import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';

import { environment } from '../../environments/environment';
import { Treinador } from '../model/treinador';
import { RequestOptions } from '@angular/http';

@Injectable()
export class TreinadoresService {

    private treinadores_api_url = `${environment.api_url}/treinadores`;
    public upload_imagem_api_url = `${environment.api_url}/file/upload`
    public DEFAULT_IMG = 'person_default_img.png';

    constructor(private http: HttpClient) { }

    public buscaTodos(filtro: string = null): Observable<Treinador[]> {
        return this.http.get<Treinador[]>(`${this.treinadores_api_url}` , {
            params: {
                'filtro': filtro ? filtro.toString() : ''
            } 
        });
    }

    public buscaPorId(id: number): Observable<Treinador> {
        return this.http.get<Treinador>(`${this.treinadores_api_url}/${id}`);
    }

    public insere(treinador: Treinador): Observable<Treinador> {
        return this.http.post<Treinador>(`${this.treinadores_api_url}`, treinador);
    }

    public altera(id: number, treinador: Treinador): Observable<any> {
        return this.http.put<any>(`${this.treinadores_api_url}/${id}`, treinador);
    }

    public deleta(id: number): Observable<any> {
        return this.http.delete<any>(`${this.treinadores_api_url}/${id}`);
    }

    /*public getImageFromService(treinador: Treinador) :string {
        if(treinador.imagem_exibicao){
            return `${environment.api_url}/file/treinador/${treinador.imagem_exibicao}`;
        }else{
            return `${environment.api_url}/file/treinador/${this.DEFAULT_IMG}`;
        }
    }

    public getThumbnailFromService(treinador: Treinador) :string {
        if(treinador.imagem_exibicao){
            return `${environment.api_url}/file/thumbnailTreinador/${treinador.imagem_exibicao}`;
        }else{
            return `${environment.api_url}/file/thumbnailTreinador/${this.DEFAULT_IMG}`;
        }
    }*/
}
