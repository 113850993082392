import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { NgxCurrencyModule } from 'ngx-currency';
import { NgxMaskModule } from 'ngx-mask';

import { ModalCadastroRacaModule } from './modal-cadastro-raca/modal-cadastro-raca.module';
import { ModalRacasPontuarComponent } from './modal-racas-pontuar.component';
import { ProvaRacasService } from '../../../../../service/prova-racas.service';
import { RacaService } from '../../../../../service/raca.service';

@NgModule({
  declarations: [ModalRacasPontuarComponent],
  imports: [
    CommonModule,
    ModalCadastroRacaModule,
    FormsModule,
    ReactiveFormsModule,
    NgxMaskModule,
    NgxCurrencyModule
  ], exports: [ModalRacasPontuarComponent],
  providers: [
    RacaService,
    ProvaRacasService]
})
export class ModalRacasPontuarModule { }
