import { Pipe, PipeTransform } from "@angular/core";
import { Treinador } from "../../model/treinador";
import { TreinadoresService } from '../../service/treinador.service';
import { UtilService } from '../../util/util.service';

@Pipe({ name: 'filtraTreinadorPorNome' })
export class FiltrarTreinadorPorNome implements PipeTransform {

    treinadores: Treinador[] = [];

    constructor(private service: TreinadoresService,
                private utilService: UtilService){}

    transform(treinadores: Treinador[], filtro: string) {

        filtro = filtro.trim().toLowerCase();
        
        if (filtro) {
            this.service.buscaTodos(filtro).subscribe(treinadores => {
                this.treinadores = treinadores;
                this.getTreinadoresThumbnail();
            });
            return this.treinadores;
        } else {
            return treinadores;
        }
    }

    getTreinadoresThumbnail(){
        this.treinadores.forEach(treinador => {
            treinador.thumbnail = this.utilService.buscaThumbnail(treinador);
        });
    }
    
}