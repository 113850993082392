import { PaginacaoComponent } from './paginacao/paginacao.component';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { DirectiveModule } from '../../directive/directive.module';
import { RouterModule } from '@angular/router';

@NgModule({
    declarations: [
        PaginacaoComponent
    ],
    imports: [
        CommonModule,
        BrowserModule,
        DirectiveModule,
        RouterModule
    ],
    exports: [PaginacaoComponent],
    providers: []
})
export class PaginacaoModule {}