import { Component, Input, OnInit, Inject, Output, EventEmitter } from '@angular/core';

import { Router } from '@angular/router';
import { UtilService } from '../../../../util/util.service';
import { Campeonato } from '../../../../model/campeonato';
import { CampeonatoService } from '../../../../service/campeonato.service';
import { ParametrosBusca } from '../../../../model/parametros_busca';
import { environment } from '../../../../../environments/environment';

@Component({
    selector: 'accordion-campeonatos',
    templateUrl: './accordion-campeonatos.component.html',
    styleUrls: ['./accordion-campeonatos.component.css']
})
export class AccordionCampeonatosComponent implements OnInit {

    @Input() campeonatos: Campeonato[] = [];
    @Output() campeonatosEmitter: EventEmitter<Campeonato[]> = new EventEmitter<Campeonato[]>();
    @Input() options: ParametrosBusca;

    imageToShow: any;

    constructor(@Inject(Router) private router: Router,
        @Inject(UtilService) private utilService: UtilService,
            private campeonatoService: CampeonatoService) {
    }

    ngOnInit() {
    }

    irParaDetalhes(id: any) {
        this.utilService.irPara(this.router, `/comum/campeonatos/detalhes/${id}`);   
    }

    public criarEvento(id_campeonato) {
        this.utilService.irPara(this.router, '/comum/cadastro-evento/' + id_campeonato);
    }

    private redirecionaEditar(id_campeonato: number): void {
        this.utilService.irPara(this.router, '/comum/editar-campeonato/' + id_campeonato);
    }

    private excluirCampeonato(id_campeonato: number): void {
        this.campeonatoService.buscaPorId(id_campeonato).subscribe(campeonato => {
            this.utilService.mensagemConfirmacao(`Deseja excluir o campeonato ${campeonato.nome}`, (result) => {
                if (result) {
                    this.campeonatoService.deleta(id_campeonato).subscribe(campeonato => {
                        this.campeonatoService.buscaPaginado(this.options).subscribe(campeonatos => {
                            this.getImgCampeonato(campeonatos);
                            this.getThumbnailCampeonato(campeonatos);
                            /*campeonatos.forEach(campeonato => {
                                this.getThumbnailExibicao(campeonato, campeonato.imagem_exibicao);
                            })*/
                            this.campeonatosEmitter.emit(campeonatos);
                        })
                    });
                }
            });
        });
    }

    /*private getThumbnailExibicao(campeonato, path){
        if(path){
            campeonato.thumbnail = `${environment.api_url}/file/thumbnailCampeonato/${path}`;
        }
    }*/
    
    private getThumbnailCampeonato(campeonatos: Campeonato[]){
        campeonatos.forEach(campeonato => {
            campeonato.thumbnail =  this.utilService.buscaThumbnail(campeonato);
        });
    }
    
    private getImgCampeonato(campeonatos: Campeonato[]){
        campeonatos.forEach(campeonato => {
            campeonato.arquivo_imagem =  this.utilService.buscaImagem(campeonato);
        });
    }

    private replicarCampeonato(campeonato: Campeonato): void {
        this.utilService.irPara(this.router, '/comum/cadastro-campeonato/', {
            'idCampeonatoReplicado': campeonato.id_campeonato
        });
    }

}