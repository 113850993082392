import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { ReactiveFormsModule, FormsModule } from "@angular/forms";
import { FormularioLoginComponent } from "./formulario-login/formulario-login.component";
import { DirectiveModule } from "../../directive/directive.module";
import { ModalEdicaoDivisaoModule } from './cadastro-evento/modal-edicao-divisao/modal-edicao-divisao.module';
import { RedefinirSenhaComponent } from './redefinir-senha/redefinir-senha.component';
import { CadastroAssociacaoComponent } from './cadastro-associacao/cadastro-associacao.component';
import { CadastroRacaComponent } from './cadastro-raca/cadastro/cadastro-raca.component';
import { CadastroRegraRegulamentoComponent } from './cadastro-regra-regulamento/cadastro-regra-regulamento.component';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { CadastroFotoEventoComponent } from './cadastro-foto-evento/cadastro-foto-evento.component';
import { CadastroResultadoEventoComponent } from './cadastro-resultado-evento/cadastro-resultado-evento.component';
import { CadastroResultadoCampeonatoComponent } from './cadastro-resultado-campeonato/cadastro-resultado-campeonato.component';

@NgModule({
    declarations: [
        FormularioLoginComponent,
        RedefinirSenhaComponent,
        CadastroAssociacaoComponent,
        CadastroRacaComponent,
        CadastroRegraRegulamentoComponent,
        CadastroFotoEventoComponent,
        CadastroResultadoEventoComponent,
        CadastroResultadoCampeonatoComponent
    ],
    imports : [
        CommonModule,
        ReactiveFormsModule,
        DirectiveModule,
        ModalEdicaoDivisaoModule,
        FormsModule,
        AngularEditorModule
    ]
})
export class FormulariosModule {}