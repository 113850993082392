import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { environment } from '../../environments/environment';
import { Http } from '@angular/http';

@Injectable()
export class PerguntaHandicapService {

  private pergunta_handicap_api_url = `${environment.api_url}/pergunta-handicap`;

  constructor(private httpClient: HttpClient,
      @Inject(Http) private http: Http) {}

  public buscarTodos(filtro: any): Observable<any> {
    return this.httpClient.post<any>(`${this.pergunta_handicap_api_url}/buscar-todos`, filtro);
  }

  public buscarTodosResposta(filtro: any): Observable<any> {
    return this.httpClient.post<any>(`${this.pergunta_handicap_api_url}/buscar-todos-resposta`, filtro);
  }

  public salvar(perguntaHandicap: PerguntaHandicapService) {
    try {
        return this.httpClient.post(`${this.pergunta_handicap_api_url}/salvar`, perguntaHandicap);
    } catch (error) {
        (error);
        return;
    }
  }

}
