import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { RegraRegulamento } from '../model/regra-regulamento';
import { Observable } from 'rxjs';
import { FiltroRegraRegulamento } from '../filter/regra-regulamento.filter';

@Injectable()
export class RegraRegulamentoService{

    private regra_regulamento_api = `${environment.api_url}/regra-regulamento`;
    public upload_imagem_api_url = `${environment.api_url}/file/upload-imagem-editor`;

    constructor(private http: HttpClient){}

    public inserir(regraRegulamento: RegraRegulamento): Observable<RegraRegulamento>{
        return this.http.post<RegraRegulamento>(`${this.regra_regulamento_api}`, regraRegulamento);
    }

    public alterar(id: number, regraRegulamento: RegraRegulamento): Observable<RegraRegulamento>{
        return this.http.put<RegraRegulamento>(`${this.regra_regulamento_api}/alterar/${id}`, regraRegulamento);
    }

    public deletar(id: number, regraRegulamento: RegraRegulamento): Observable<any>{
        return this.http.delete<any>(`${this.regra_regulamento_api}/${id}`);
    }

    public buscaPorId(id: number): Observable<RegraRegulamento>{
        return this.http.get<RegraRegulamento>(`${this.regra_regulamento_api}/${id}`);
    }

    public buscaFiltro(filtro: FiltroRegraRegulamento): Observable<any>{
        return this.http.post<any>(`${this.regra_regulamento_api}/busca-filtro`, filtro);
    }

    public desativarAtivar(id: number, status: boolean): Observable<RegraRegulamento>{
        return this.http.put<RegraRegulamento>(`${this.regra_regulamento_api}/desativar-ativar/${id}`, {status: status});
    }

    public excluir(id:number): Observable<any>{
        return this.http.delete<any>(`${this.regra_regulamento_api}/${id}`);
    }
}