import { OnInit, Component, Input, Output, EventEmitter } from '@angular/core';

import { Prova } from '../../../../../model/prova';
import { ErroRegra } from '../../../../../model/erro-regra';
import { Usuario } from '../../../../../model/usuario';
import { Cavalo } from '../../../../../model/cavalo';
import { VerificaRegraService } from '../../../../../service/verifica-regra.service';
import { Campeonato } from '../../../../../model/campeonato';
import { Evento } from '../../../../../model/evento';
import { InscricaoCompetidorService } from '../../../../../service/inscricao-competidor.service';
import { ProvaService } from '../../../../../service/prova.service';

@Component({
    selector: 'selecao-de-competidores-por-prova',
    templateUrl: './selecao-de-competidores-por-prova.component.html',
    styleUrls: ['./selecao-de-competidores-por-prova.style.css', './../../../inscricoes.style.css']
})
export class SelecaoDeCompetidoresPorProvaComponent implements OnInit {

    @Input() campeonato: Campeonato;
    @Input() evento: Evento;

    @Input() pagina: number;
    @Output() paginaEmitter: EventEmitter<number> = new EventEmitter<number>();
    @Output() paginaAnteriorEmitter: EventEmitter<number> = new EventEmitter<number>();

    @Input() numeroDeInscritos: number;
    @Input() listaDeProvasSelecionadas: Prova[];
    
    public listaDeCompetidoresInscritos: Usuario[];
    public listaDeCavalosInscritos: Cavalo[];

    @Output() listaDeCompetidoresInscritosEmitter: EventEmitter<Usuario[]> = new EventEmitter<Usuario[]>();
    @Output() listaDeCavalosInscritosEmitter: EventEmitter<Cavalo[]> = new EventEmitter<Cavalo[]>();
    @Output() listaDeProvasSelecionadasEmitter: EventEmitter<Prova[]> = new EventEmitter<Prova[]>();

    constructor(private verificaRegraService: VerificaRegraService,
                private inscricaoCompetidorService : InscricaoCompetidorService,
                private provaService :ProvaService ) {}

    ngOnInit(): void {}

    public existemErrosNasProvas(): boolean {
        let retorno: boolean;
        if (this.listaDeProvasSelecionadas) {
            this.listaDeProvasSelecionadas.forEach(prova => {
                if (prova.regrasNaoAtendidas.length > 0) {
                    retorno = true;
                }
            })
        }
        return retorno;
    }

    public recebeCompetidores(listaDeCompetidoresInscritos: Usuario[]) {
        this.listaDeCompetidoresInscritosEmitter.emit(listaDeCompetidoresInscritos);
        this.listaDeCompetidoresInscritos = listaDeCompetidoresInscritos;
        this.verificaRegraService.verificaRegras(this.listaDeProvasSelecionadas, this.listaDeCompetidoresInscritos);
    }

    public recebeCavalos(listaDeCavalosInscritos: Cavalo[]) {
        this.listaDeCavalosInscritosEmitter.emit(listaDeCavalosInscritos);
        this.listaDeCavalosInscritos = listaDeCavalosInscritos;
        this.verificaRegraService.verificaRegras(this.listaDeProvasSelecionadas, this.listaDeCompetidoresInscritos);
    }

    public prosseguir(pagina: number): void {
        this.calculaValorDaInscricao();
        this.listaDeProvasSelecionadas.forEach(p => this.validacaoInicialDasInscricoes(p));
        this.paginaAnteriorEmitter.emit(this.pagina);
        this.paginaEmitter.emit(pagina);
    }

    public regressar(pagina: number): void {
        this.paginaEmitter.emit(pagina);
    }

    public emiteListaDeProvasSelecionadas(listaDeProvas: Prova[]): void {
        this.listaDeProvasSelecionadasEmitter.emit(listaDeProvas);
    }

    private calculaValorDaInscricao(){

        let idProvasQtdInscricao = this.listaDeProvasSelecionadas.map(p => {
            return {
                id_prova : p.id_prova,
                qtdInscricao : p.qtdInscricao
            }
        });
        let id_evento = this.evento.id_evento;
        this.listaDeCompetidoresInscritos.forEach(comp => {
            this.inscricaoCompetidorService.buscaValorDaInscricao(idProvasQtdInscricao, comp, id_evento)
                .subscribe(valor => {
                    comp.valor_inscricao = valor;
                });
        });
    }

    private validacaoInicialDasInscricoes(prova){

        let obj = {
            id_prova : prova.id_prova,
            competidor : this.listaDeCompetidoresInscritos,
            id_evento : prova.id_evento
        }

        this.provaService.buscaInformacoesPorProvaECompetidor(obj)
        .subscribe(retorno => {
            if(prova.qtdInscricao == undefined || prova.qtdInscricao == null){
                prova.qtdInscricao = -1  ;
            }else if(retorno.valor == 0){
                prova.qtdInscricao = retorno.valor;
            }else if(retorno.valor > 0){
                //coloquei esse else, qlq coisa, pode remover ou  colocar o valor 0 no lugar
                prova.qtdInscricao = -1;
            }
        });
    }
}