export class Select{

    label: string;
    value: any;
    
    static converterParaDropDown(lista: any[], nomeCampo: any, valorCampo: any) {
        const items: any[] = [];
        items.push({label: '', value: ''});
        lista.forEach(item => {
            items.push({
                label: item[nomeCampo], value: item[valorCampo]
            });
        });
        return items;
    }

    static converterParaDropDownDefault(lista: any[], nomeCampo: any, valorCampo: any) {
        const items: any[] = [];
        items.push({label: '', value: null});
        lista.forEach(item => {
            items.push({
                label: item[nomeCampo], value: item[valorCampo]
            });
        });
        return items;
    }

}