import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { ListaRegraRegulamentoComponent } from './lista-regra-regulamento/lista-regra-regulamento/lista-regra-regulamento.component';
import { ReactiveFormsModule } from '@angular/forms';
import { RegraRegulamentoService } from '../../service/regra-regulamento.service';
import { PaginacaoModule } from '../paginacao/paginacao.module';

@NgModule({
    declarations: [
        ListaRegraRegulamentoComponent
    ],
    imports: [
        CommonModule,
        HttpClientModule,
        ReactiveFormsModule,
        PaginacaoModule,
        CommonModule
    ],
    providers: [RegraRegulamentoService]
})
export class RegraRegulamentoModule {}