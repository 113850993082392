import { Component, OnInit, Inject } from '@angular/core';

import { Campeonato } from '../../../../model/campeonato';
import { CampeonatoService } from '../../../../service/campeonato.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Evento } from '../../../../model/evento';
import { UtilService } from '../../../../util/util.service';
import { EventoService } from '../../../../../app/service/evento.service';
import { UsuarioService } from '../../../../../app/service/usuario.service';
import { ParametrosBusca } from '../../../../../app/model/parametros_busca';

declare var $ : any;
declare var UIkit : any;

@Component({
    templateUrl: './detalhes-campeonato.page.html',
    styleUrls: ['./detalhes-campeonato.page.css']
})
export class DetalhesCampeonatoPage implements OnInit {

    idCampeonato: number;
    campeonato: Campeonato = new Campeonato();

    public datas_iniciais = [];
    public nomeEventos: string[] = [];


    public pagina : number = 1;
    public botoes: any[] = new Array();
    public totalPaginas : number;
    public podeVoltar : boolean;
    public podeProsseguir : boolean = true;
    public paginasLaterais : number = 2;

    public parametrosBusca : ParametrosBusca = new ParametrosBusca();
    public options : ParametrosBusca = new ParametrosBusca();
    private quantidadeRegistros: number;

    public filtro : {
        id: number,
        nome : string,
        data_inicial : string
    } = {
        id: null,
        nome : null,
        data_inicial : null
    };
    public imagemModal: any;
    constructor(private activatedRoute: ActivatedRoute, 
                private service: CampeonatoService,
                public utilService: UtilService,
                @Inject(Router) private router: Router,
                private eventoService : EventoService,
                private usuarioService : UsuarioService){

        this.pagina = 1;
        this.parametrosBusca.pagina = this.pagina;
        this.options.offset = 0;
        this.options.limit = 5;        
        this.init();
    }
    
    ngOnInit(): void {
    }

    public init() {

        this.filtro.nome = null;

        this.idCampeonato = this.activatedRoute.snapshot.params.idCampeonato;
        this.service.buscaPorId2(this.idCampeonato).subscribe(campeonato => {
            this.campeonato = campeonato;
            this.filtro.id = this.idCampeonato;

            this.usuarioService.buscaPorId(this.campeonato.id_organizador)
            .subscribe(organizador => this.campeonato.organizador = organizador);

            this.eventoService.buscaTotalRegistrosPorIdCampeonato(this.campeonato.id_campeonato)
            .subscribe(total => {
                this.options.totalRegistros = total;
                this.quantidadeRegistros = total;
                this.geraBotoes();
            });

            this.eventoService.buscaEventosDeUmCampeonatoComFiltro(this.campeonato.id_campeonato,
            {
                "filtro": this.filtro
            }, this.options)
            .subscribe(eventos => {
                this.getImagem(eventos);
                this.campeonato.eventos = eventos;
            });
            
        });
    }

    public filtrar(){
        this.filtro.nome = $('#nome').val();

        this.options.offset = 0;
        this.options.offset = (this.pagina-1) * this.options.limit;
        this.eventoService.buscaEventosDeUmCampeonatoComFiltro(this.campeonato.id_campeonato,
        {
            "filtro": this.filtro
        }, this.options)
        .subscribe(eventos => {
            this.getImagem(eventos);
            this.campeonato.eventos = eventos;
        });

    }

    public irParaDetalhesEvento(id_evento : number){
        this.utilService.irPara(this.router, '/comum/evento/' +id_evento);
    }

    public limpar(){
        this.options.offset = 0;
        this.pagina = 1;
        this.filtro.nome = null;
        this.filtro.data_inicial = null;        
        $('#nome').val('');
        this.filtrar();
    }

    private getImagem(eventos: Evento[]){
        eventos.forEach(evento => evento.arquivo_imagem = this.utilService.buscaImagem(evento));
    }

    public abreModalImagem(event){
        this.imagemModal = event.arquivo_imagem;
        UIkit.modal('#modal-media-evento-image').show();
    }

    private redireciona(id_evento) {

        if (!this.utilService.isLogado()) {
            this.utilService.mensagemErroComTitulo('Acesso negado', 'Você precisa estar logado para fazer inscrições', () => {
                this.router.navigate(['/comum/login']);
            });
            return;
        }

        this.utilService.irPara(this.router, `/comum/inscricoes/${id_evento}`);
    }

    goToLink(url: string){
        window.open(url, "_blank");
    }

    /* ----- PAGINATION ----- */

    private geraBotoesPaginacao() {
        let paginas = this.parametrosBusca.totalPaginas;
        for(let i=0; i<paginas; i++) {
            this.botoes[i] = i+1;
        }
    }

    private geraBotoes() {
        this.botoes = [];
        for(let i = 0; i < this.quantidadeRegistros/5; i++ ) {
            this.botoes.push(i+1);
        }
        this.totalPaginas = this.botoes.length;
        this.parametrosBusca.totalPaginas = this.totalPaginas;
    }

    private configurarPagination() {    
        this.podeIrParaPaginaAnterior();
        this.podeIrParaProximaPagina();
    }

    private mudarInfoBtns() {

        if(this.pagina > 1) {
            this.podeVoltar = true;
        } else {
            this.podeVoltar = false;
        }

        if(this.pagina < this.totalPaginas) {
            this.podeProsseguir = true;
        } else {
            this.podeProsseguir = false;
        }
    }

    public podeIrParaPaginaAnterior(): void {
        this.podeVoltar = this.parametrosBusca.pagina != 1;
    }

    private podeIrParaProximaPagina(): void {
        this.podeProsseguir = (this.parametrosBusca.pagina != this.parametrosBusca.totalPaginas) &&
                               (this.parametrosBusca.totalPaginas > 0);
    }

    public irParaPrimeiraPagina(): void {
        this.pagina = 1;
        this.parametrosBusca.pagina = 1;
        this.parametrosBusca.offset = 0;
        this.configurarPagination();
        this.filtrar();
    }

    public irParaPaginaAnterior(): void {
        this.pagina--;
        this.parametrosBusca.pagina--;
        this.parametrosBusca.offset -= this.parametrosBusca.limit;
        this.configurarPagination();
        this.filtrar();
    }

    private pularParaPaginaEspecifica(pagina: number): void {
        this.pagina = pagina;
        this.parametrosBusca.offset = (pagina * this.parametrosBusca.limit) - this.parametrosBusca.limit;
        this.configurarPagination();
        this.filtrar();
    }

    public irParaProximaPagina(): void {
        this.parametrosBusca.pagina++;
        this.pagina++;
        this.parametrosBusca.offset += this.parametrosBusca.limit;
        this.configurarPagination();
        this.filtrar();
    }

    public irParaUltimaPagina(): void {
        this.pagina = this.totalPaginas;
        this.parametrosBusca.pagina = this.parametrosBusca.totalPaginas;
        this.parametrosBusca.offset = (this.parametrosBusca.totalPaginas * this.parametrosBusca.limit) - this.parametrosBusca.limit;
        this.configurarPagination();
        this.filtrar();
    }

    private exibeBotao(botao) {
        let exibe = false;

        if(botao <= this.pagina + this.paginasLaterais 
            && botao >= this.pagina - this.paginasLaterais) {
            exibe = true;
        } else if((this.pagina - this.paginasLaterais) <= 0 && (botao == 4 || botao == 5)) {
            exibe = true;
        } else if((this.pagina + this.paginasLaterais) >= this.totalPaginas 
            && (botao >= (this.totalPaginas - this.paginasLaterais*2))) {
            exibe = true;
        }
        
        return exibe;
    }

    public cadastrarResultado(){
        this.router.navigate(['/comum/campeonato/cadastro-resultado/' + this.campeonato.id_campeonato]);
    }

    public resultados(){
        this.router.navigate(['/comum/campeonato/resultados/' + this.campeonato.id_campeonato]);
    }
}