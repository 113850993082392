import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CadastroTreinadorComponent } from './cadastro-treinador.component';
import { TreinadoresService } from '../../../service/treinador.service';
import { ReactiveFormsModule } from '@angular/forms';
import { DirectiveModule } from '../../../directive/directive.module';

@NgModule({
    declarations: [ CadastroTreinadorComponent ],
    imports: [ 
        CommonModule, 
        ReactiveFormsModule, 
        DirectiveModule
    ],
    providers: [ TreinadoresService ]
})
export class CadastroTreinadorModule {}