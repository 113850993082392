import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { environment } from '../../environments/environment';
import { UnidadeFederativa } from '../model/unidade-federativa';

@Injectable()
export class UnidadeFederativaService {

    private unidade_federativa_api_url = `${environment.api_url}/unidades-federativas`;

    constructor(private http: HttpClient) {}

    public buscaTodos(): Observable<UnidadeFederativa[]> {
        return this.http.get<UnidadeFederativa[]>(`${this.unidade_federativa_api_url}`);
    }

    public buscaPorId(id: number): Observable<UnidadeFederativa> {
        return this.http.get<UnidadeFederativa>(`${this.unidade_federativa_api_url}/${id}`);
    }

}
