import { Directive, ElementRef, Renderer, HostListener, Input } from '@angular/core';

@Directive({
    selector: "[darkenOnHover]"
})
export class DarkenOnHoverDirective {

    @Input() brightness = "70%";
    constructor(private el: ElementRef, private render: Renderer) {}

    @HostListener('mouseover')
    darkenOn(){
        this.render.setElementStyle(this.el.nativeElement, 'filter', `brigthness(${this.brightness})`);
    }

    @HostListener('mouseleave')
    darkenOff(){
        this.render.setElementStyle(this.el.nativeElement, 'filter', `brigthness(100%)`);
    }
}