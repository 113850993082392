import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs';
import { FotoEvento } from '../model/foto-evento';

@Injectable()
export class FotoEventoService {

    private fotos_eventos_url_api = `${environment.api_url}/fotos-eventos`;
    public upload_imagem_api_url = `${environment.api_url}/file/upload-imagens`;

    constructor(private http: HttpClient){}

    public inserir(fotoEvento: FotoEvento) : Observable<FotoEvento>{
        return this.http.post<FotoEvento>(`${this.fotos_eventos_url_api}`, fotoEvento);
    }

    public inserirLista(fotosEvento: FotoEvento[]) : Observable<FotoEvento[]>{
        return this.http.post<FotoEvento[]>(`${this.fotos_eventos_url_api}/lista`, fotosEvento);
    }

    public buscaPorIdEvento(id_evento: number): Observable<FotoEvento[]> {
        return this.http.get<FotoEvento[]>(`${this.fotos_eventos_url_api}/busca-por-evento/${id_evento}`);
    }

    public buscaPorId(id: number) : Observable<FotoEvento> {
        return this.http.get<FotoEvento>(`${this.fotos_eventos_url_api}/${id}`);
    }

    public alterar(id: number, fotoEvento: FotoEvento) : Observable<FotoEvento> {
        return this.http.put<FotoEvento>(`${this.fotos_eventos_url_api}/${id}`, fotoEvento);
    }

    public excluir(id: number): Observable<void>{
        return this.http.delete<void>(`${this.fotos_eventos_url_api}/${id}`);
    }
}