import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { FinanceiroComponent } from './financeiro.component';

@NgModule({
    declarations: [FinanceiroComponent],
    imports: [
        CommonModule,
        ReactiveFormsModule,
        HttpClientModule
    ]
})
export class FinanceiroModule {}