import { Component, OnInit, Inject } from '@angular/core';
import { UsuarioService } from '../../../service/usuario.service';
import { UtilService } from '../../../util/util.service';
import { UsuariosInscritos } from '../../../model/prova.usuarios-inscritos';
import { ActivatedRoute, Router } from '@angular/router';
import { InscricaoService } from '../../../service/inscricao.service';
import { EventoService } from '../../../service/evento.service';
import { Evento } from '../../../model/evento';
import { Inscricao } from '../../../model/inscricao';

@Component({
  selector: 'app-lista-inscricoes-usuario',
  templateUrl: './lista-inscricoes-usuario.component.html',
  styleUrls: ['./lista-inscricoes-usuario.component.css']
})
export class ListaInscricoesUsuarioComponent implements OnInit {

  tituloDoEvento : string[] = [];
  provas : any[] = [];
  idUsuario : number;
  datas : string[] = [];
  numPaginas : number = 0;
  public quantidade : number = 0;
  public eventos : Evento[] = [];
  private usuarioInscricoes : UsuariosInscritos = new UsuariosInscritos();
  public divisoes : any = []

  public filtro : {
    id_prova : string
    data : string,
    tipo_inscricao : number,
    id_evento : number,
    limit : number,
    offset : number
  } = {
    id_prova : null,
    data : null,
    tipo_inscricao : null,
    id_evento : null,
    limit : null,
    offset : null
  }

  public filtroEvento : {
    data_fim_inscricoes : boolean
  } = {
    data_fim_inscricoes : true
  }

  constructor(private usuarioService : UsuarioService,
              private inscricaoService : InscricaoService,
              public utilService : UtilService,
              @Inject(ActivatedRoute) private activetedRoute : ActivatedRoute,
              @Inject(Router) private router : Router,
              private eventoService : EventoService) { }

  ngOnInit() {
    this.idUsuario = this.utilService.getIdLogado();
    this.filtro.tipo_inscricao = 1;
    this.initLimitOffset();
    this.buscaEventos();
  }

  public buscaEventos() : void{
    this.eventoService.buscaEventosPorCompetidorCadastrador(this.idUsuario, this.filtroEvento)
    .subscribe(eventos => {
      this.eventos = eventos;
    });
  }

  atualizaLista(usuarioInscricoes : UsuariosInscritos){
    this.usuarioInscricoes.limit = usuarioInscricoes.limit;
    this.usuarioInscricoes.offset = usuarioInscricoes.offset;
    this.buscaInscricoes();
  }

  public initLimitOffset(){
    this.usuarioInscricoes.limit = 10;
    this.usuarioInscricoes.offset = 0;
  }

  public initFiltro(){
    this.filtro.id_prova = null;
    this.filtro.id_prova = null;
    this.filtro.data = null;
  }

  pegaNumeroDePaginas(){
    let aux : number = this.quantidade;
    
    if(aux % 10 > 0){
      aux = (aux / 10) + 1;
    }else{
      aux = aux / 10;
    }

    this.numPaginas =  Math.floor(aux);
  }

  public buscaInscricoes(){
    this.filtro.limit = this.usuarioInscricoes.limit;
    this.filtro.offset = this.usuarioInscricoes.offset;
    this.usuarioService.buscarInscricoesQuePoderaoSerCanceladas(this.idUsuario,this.filtro)
    .subscribe(inscricoes => {
      this.provas = inscricoes.retorno;
      this.quantidade = inscricoes.quantidade;
      this.divisoes = inscricoes.nome_provas;
      this.pegaNumeroDePaginas();
      this.separaDataEDivisao();
      this.inserirTodos();
    });
  }

  public inserirTodos() : void{
    if(this.divisoes.length){
      this.divisoes.push({id_prova : null, nome : 'TODOS'});
    }
  }

  separaDataEDivisao(){
    let aux : string[] = [];
    this.provas.forEach(inscricao => {
      aux = inscricao.data_inicial.split('T');
      this. verificaData(aux[0]);
    });
  }

  pegaEventoSelecionado(id_evento : any){
    this.filtro.id_evento = id_evento;
    this.initFiltro();
    this.initLimitOffset();
    this.buscaInscricoes();
  }

  pegaProvaSelecionado(evento : any){
    this.initLimitOffset();
    if(evento != 'Selecione um evento' && evento != 'null'){
      this.filtro.id_prova = evento;
    }else{
      this.filtro.id_prova = null;
    }
    this.buscaInscricoes();
  }

  pegaDataSelecionado(evento : any){
    this.initLimitOffset();
    if(evento != 'Selecione uma data' && evento != 'TODOS'){
      this.filtro.data = evento;
    }else{
      this.filtro.data = null;
    }
    this.buscaInscricoes();
  }

  private verificaData(data : string){
    let aux : boolean = false;
    if(this.datas.length > 0){
      this.datas.filter(dt => {
        if(dt == data){
          aux = true;
        }
      });
  
      if(!aux){
        this.datas.push(data);
      }

    }else{
      this.datas.push(data);
    }

  }
  
  removerInscricao(inscricao : any){
    this.utilService.mensagemConfirmacao('Deseja realmente cancelar a inscrição('+inscricao.id_inscricao+')?',
     (info) => {
      if(info){
        this.inscricaoService.deleta(inscricao.id_inscricao)
        .subscribe(retorno => {
          if(retorno && retorno.excluido == true){
            this.utilService.mensagemSucesso("Inscrição removida com sucesso!", 
            () => this.buscaInscricoes());
          }else{
            this.utilService.mensagemInfo("Não foi possivel remover a inscrição.");
          }
        },() => this.utilService.mensagemErro("Erro - Não foi possivel remover a inscrição."));
      }
    });
  }

  existeListaProvas() {
    if(this.provas.length == 0) {
      return false;
    } else {
      return true;
    }
  }

  public irParaEditarInscricao(id_inscricao : number){
    this.utilService.irPara(this.router, '/comum/editar-inscricao', {
      "id_inscricao" : id_inscricao
    });
  }

  public verificaEdicaoInscricao(inscricao : Inscricao, tipo_prova: number) :boolean {
    let retorno = false;
    let idLogado = this.utilService.getIdLogado();
    let evento = this.eventos
    .filter(evento => evento.id_evento == inscricao.id_evento)[0];
    if((this.utilService.isAdm() ||
      (idLogado == evento.id_organizador) ||
      (idLogado == inscricao.id_cadastrador)) && 
      !(inscricao.draw == true && inscricao.tipo_inscricao == tipo_prova)){
      retorno = true;
    }
    return retorno;
  }

}
