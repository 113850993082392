import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';

import { environment } from '../../environments/environment';
import { RegraEvento } from '../model/regra_evento';

@Injectable()
export class RegraEventoService {

    private regra_evento_api_url = `${environment.api_url}/regras-evento`;

    constructor(private http: HttpClient) {}

    public buscaTodos(): Observable<RegraEvento[]> {
      return this.http.get<RegraEvento[]>(`${this.regra_evento_api_url}`);
    }

    public buscaPorId(id: number): Observable<RegraEvento> {
      return this.http.get<RegraEvento>(`${this.regra_evento_api_url}/${id}`);
    }

    public insere(regra_evento: RegraEvento): Observable<RegraEvento> {
      return this.http.post<RegraEvento>(`${this.regra_evento_api_url}`, regra_evento);
    }

    public altera(id: number, regra_evento: RegraEvento): Observable<RegraEvento> {
      return this.http.put<RegraEvento>(`${this.regra_evento_api_url}/${id}`, regra_evento);
    }

    public deleta(id: number): Observable<RegraEvento> {
      return this.http.delete<RegraEvento>(`${this.regra_evento_api_url}/${id}`);
    }

    /**
     * Deleta todas as regras relacionadas a um evento
     * @param id_evento 
     */
    public deletaRegrasEvento(id_evento: number): any {
      return this.http.delete<any>(`${this.regra_evento_api_url}/deleta-regras-evento/${id_evento}`);
    }

    public salvar(regra_evento: RegraEvento) {
      return this.http.post<RegraEvento>(`${this.regra_evento_api_url}/salvar`, regra_evento);
    }

    public buscarRegrasPorEvento(id_evento: number): Observable<RegraEvento[]> {
      return this.http.get<RegraEvento[]>(`${this.regra_evento_api_url}/buscar-por-evento/${id_evento}`)
    }
      
}
