import { Component, OnInit, Inject } from '@angular/core';
import { UtilService } from '../../../util/util.service';
import { RacaService } from '../../../service/raca.service';
import { Raca } from '../../../model/raca';
import { RacaFilter } from '../../../filter/raca.filter';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';
import { Paginacao } from '../../../model/paginacao';

@Component({
  selector: 'app-lista-racas',
  templateUrl: './lista-racas.component.html',
  styleUrls: ['./lista-racas.component.css', '../../../comum/comum.style.css']
})
export class ListaRacasComponent implements OnInit {

  public racas: Raca[] = [];
  public quantidadeRacas: number;
  private filtro: RacaFilter = new RacaFilter();
  private debounce: Subject<string> = new Subject<string>();
  public spinner: boolean = false;
  public paginacao: Paginacao = new Paginacao();
  public numPagina: number = 0;

  constructor(public utilService: UtilService,
              private racaService: RacaService,
              @Inject(Router) public router: Router) { }

  ngOnInit() {
    this.initPaginacao();
    this.getRaca();
    this.initDebounce();
  }

  private getFiltro(): void{
    this.filtro.limit = this.paginacao.limit;
    this.filtro.offset = this.paginacao.offset;
  }

  private initPaginacao(){
    this.paginacao.limit = 100;
    this.paginacao.offset = 0;
    this.paginacao.pagina = 1;
  }

  private initDebounce(){
    this.debounce.pipe(debounceTime(300))
    .subscribe(descricao => {
      this.filtro.descricao = descricao;
      this.getRaca();
    });
  }

  private getRaca(): void{
    this.spinner = true;
    this.getFiltro();
    this.racaService.buscaFiltro(this.filtro)
    .subscribe(retorno => {
      this.racas = retorno.racas;
      this.quantidadeRacas = retorno.quantidade;
      this.quantidadePagina();
      this.spinner = false;
    },
    erro => {
      this.spinner= false;
      this.utilService.mensagemErro("Erro - " + erro.error.message);
    });
  }

  public filtroRaca(descricao: string): void{
    this.initPaginacao();
    this.debounce.next(descricao);
  }

  public irParaEditarRaca(raca: Raca){
    this.utilService.irPara(this.router, '/comum/editar-raca', {
      "idRaca" : raca.id_raca
    });
  }

  public excluirRaca(raca: Raca){
    this.utilService.mensagemConfirmacao("Deseja realmente remover a raça " + raca.descricao + "?",
    (resp) => {
      if(resp){
        this.racaService.deleta(raca.id_raca)
        .subscribe(
          () => {
            this.utilService.mensagemSucesso("Raça removida com sucesso!");
            this.initPaginacao();
            this.getRaca();
          },
          erro => this.utilService.mensagemErro("Erro - " + erro.error.message)
        );
      }
    });
  }

  private quantidadePagina(){
    let paginas = 0;
    if(this.quantidadeRacas % this.paginacao.limit == 0){
      paginas = (this.quantidadeRacas / this.paginacao.limit);
    }else{
      paginas = (this.quantidadeRacas / this.paginacao.limit) + 1;
    }
    this.numPagina = Math.floor(paginas);
  }

  public getPaginacao(paginacao:Paginacao){
    this.paginacao.limit = paginacao.limit;
    this.paginacao.offset = paginacao.offset;
    this.paginacao.pagina = paginacao.pagina;
    this.getRaca();
  }
}
