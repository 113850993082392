import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SelecaoDeCompetidoresPorCompetidorComponent } from './selecao-de-competidores-por-competidor.component';
import { BuscaDeCompetidoresModule } from '../../../shared/busca-de-competidores/busca-de-competidores.module';

@NgModule({
    declarations: [SelecaoDeCompetidoresPorCompetidorComponent],
    imports: [
        CommonModule,
        BuscaDeCompetidoresModule
    ],
    exports: [SelecaoDeCompetidoresPorCompetidorComponent]
})
export class SelecaoDeCompetidoresPorCompetidorModule {}