import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/do';
import { catchError, finalize, map } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { UtilService } from '../util/util.service';
import { Router } from '@angular/router';

@Injectable()
export class Interceptor implements HttpInterceptor {

    constructor(public utilService: UtilService, public router: Router) { }

    // intercet all http requests
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        

        if (this.utilService.isLogado()) {
            request = request.clone({
                headers: request.headers.set('auth-token', this.utilService.getToken())
            });
        }
        // able to show friendly error when 
        // an error happen in every http request
        return next.handle(request).pipe(
            map(event => {
                return event;
            }),
            catchError(err => {


                let erro = err.error;

                let msg = 'Uma falha aconteceu na sua operação. Confirme o resultado da sua solicitação ou contacte o administrador do sistema.';
                let title = 'Alerta';

                if (erro['titulo'] && erro['mensagem']) {
                    msg = erro['mensagem'];
                    title = erro['titulo'];
                }

                switch (err.status) {
                    case 400:
                        break;
                    case 401:
                        if (this.utilService.isLogado()) {
                            this.utilService.clearCookies();
                        }
                        break;
                    default:
                        break;
                }
                if (err.status =! 0 ) 
                    this.utilService.mensagemErro(msg);
                return throwError(err);
            }),
            finalize(() => {
            })
        );
    }
}