import { HistoricoGeralComponent } from './historico-geral.component';
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { DirectiveModule } from '../../directive/directive.module';
import { RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';

@NgModule({
    declarations: [
        HistoricoGeralComponent
    ],
    imports: [
        CommonModule,
        BrowserModule, 
        DirectiveModule,
        RouterModule,
        FormsModule
    ],
    exports: [HistoricoGeralComponent],
    providers: []
})
export class HistoricoGeralModule{}