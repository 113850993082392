import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { InformacoesCampeonatoComponent } from './informacoes-campeonato.component';
import { RouterModule } from '@angular/router';

@NgModule({
    declarations: [InformacoesCampeonatoComponent],
    imports: [
        CommonModule,
        RouterModule
    ],
    exports: [InformacoesCampeonatoComponent]
})
export class InformacoesCampeonatoModule {}