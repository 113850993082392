import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { ProvaRacas } from '../model/prova_racas';
import { environment } from '../../environments/environment';

@Injectable()
export class ProvaRacasService {
      
      private prova_racas_api_url = `${environment.api_url}/prova_racas`;

      constructor(private http: HttpClient) {}

      public buscaRacasPontuarPorEventoDivisao(idEvento: number, idDivisao: number): Observable<ProvaRacas[]> {
            return this.http.get<ProvaRacas[]>(`${this.prova_racas_api_url}/${idEvento}/${idDivisao}`);
      }

      public get(): Observable<ProvaRacas[]> {
            return this.http.get<ProvaRacas[]>(`${this.prova_racas_api_url}`);
      }

      public getById(idProvaRacas: number): Observable<ProvaRacas> {
            return this.http.get<ProvaRacas>(`${this.prova_racas_api_url}/${idProvaRacas}`);
      }

      public post(provaRacas: ProvaRacas): Observable<ProvaRacas> {
            return this.http.post<ProvaRacas>(`${this.prova_racas_api_url}`, provaRacas);
      }

      public put(idProvaRacas: number, provaRacas: ProvaRacas): Observable<ProvaRacas> {
            return this.http.put<ProvaRacas>(`${this.prova_racas_api_url}/${idProvaRacas}`, provaRacas);

      }

      public delete(idProvaRacas: number): Observable<ProvaRacas> {
            return this.http.delete<ProvaRacas>(`${this.prova_racas_api_url}/${idProvaRacas}`);
      }
}