import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { EventoService } from '../../service/evento.service';
import { EventoBuscaService } from './eventoBusca.service';

@NgModule({
    declarations: [
    ],
    imports: [
        CommonModule,
        ReactiveFormsModule,
        HttpClientModule
    ],
    providers: [ 
        EventoService,
        EventoBuscaService
     ]
})
export class EventosModule {}