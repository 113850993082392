import { OnInit, Component, Input, Inject, AfterViewInit, AfterContentInit } from '@angular/core';

import { Usuario } from '../../../../model/usuario';
import { UtilService } from '../../../../util/util.service';
import { Campeonato } from '../../../../model/campeonato';
import { Evento } from '../../../../model/evento';
import { Prova } from '../../../../model/prova';
import { InscricaoCompetidorService } from '../../../../service/inscricao-competidor.service';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { ActivatedRoute } from '@angular/router';

@Component({
    selector: 'competidores-selecionados',
    templateUrl: './competidores-selecionados.component.html',
    styleUrls: ['./../../inscricoes.style.css', './competidores-selecionados.style.css']
})
export class CompetidoresSelecionadosComponent implements AfterContentInit, OnInit {

    @Input() listaDeCompetidoresInscritos: Usuario[];
    @Input() listaDeProvasSelecionadas : Prova[] = [];
    @Input() campeonato: Campeonato;
    @Input() evento: Evento;

    constructor(private utilService: UtilService,
                private inscricaoCompetidorService : InscricaoCompetidorService,
                @Inject(ActivatedRoute) private route: ActivatedRoute,) {}

    ngOnInit(): void {
        
    }

    ngAfterContentInit(){
        
	}

}