import { Component, OnInit, Output } from '@angular/core';
import { ProvaService } from '../../service/prova.service';
import { ActivatedRoute } from '@angular/router';
import { UtilService } from '../../util/util.service';
import { ProvaHistorico } from '../../model/prova.historico';
import { CampeonatoService } from '../../service/campeonato.service';
import { EventoService } from '../../service/evento.service';
import { Regra } from '../../model/regra';


@Component({
  selector: 'app-historico',
  templateUrl: './historico.component.html',
  styleUrls: ['./historico.component.css']
})
export class HistoricoComponent implements OnInit {

  eventos: any[] = [];
  datas: any[];
  provas: any[];
  copas: String[];
  id_usuario: number;
  provaHistorico: ProvaHistorico = new ProvaHistorico();
  numPagina: number = 1;

  public filtro: {
    copa: String,
    data: any
  } = {
      copa: null,
      data: null
  }

  public filtroEvento : {} = {};

  public regrasComp : Regra[] = []; 
  public regrasComp2 : Regra[] = []; 
  public regrasComp3 : Regra[] = []; 

  constructor(private provaService: ProvaService,
    public util: UtilService,
    private activatedRoute: ActivatedRoute,
    private campeonatoService: CampeonatoService,
    private eventoService : EventoService) { }
    
  ngOnInit() {
    this.id_usuario = this.util.getIdLogado();
    this.provaHistorico.limit = 10;
    this.provaHistorico.offset = 0;
    this.buscaEvento();
  }

  private buscaEvento(){
    this.eventoService.buscaEventosPorCompetidorCadastrador(this.id_usuario, this.filtroEvento)
    .subscribe(eventos => {
      this.eventos = eventos;
    });
  }

  private buscarProvas() {
    let filtro = this.filtro;
    this.provaService
    .buscaPorIdCadastradorCompetidor(this.id_usuario, this.provaHistorico.limit,
    this.provaHistorico.offset, filtro).subscribe(provas => {
      this.provas = provas.provas;
      this.numPagina = provas.total;
      this.paginacao();
    });
  }

  public paginacao(){
    let aux = Number(this.numPagina);
    if (aux % 10 > 0) {
      aux = (aux / 10) + 1;
    } else {
      aux = aux / 10;
    }
    this.numPagina = Math.floor(aux);
  }

  public pegaCopaSelecionada(event) {
    let aux: any = event.target.value;
    this.filtro.copa = 'TODOS' !== aux ? aux : null;
    if(this.filtro.data != null || this.filtro.copa != null){
      this.buscarProvas();
    }else{
      this.provas = [];
    }
  }

  public pegaDataSelecionado(event) {
    let aux: any = event.target.value;
    this.filtro.data = 'TODOS' !== aux ? aux : null;

    if(this.filtro.data != null || this.filtro.copa != null){
      this.buscarProvas();
    }else{
      this.provas = [];
    }
  }

  public maisInformacoes(prova : any){
    if(prova.regras){
      this.regrasComp  = prova.regras.filter( regra => regra.numero_competidor == 1);
      this.regrasComp2 = prova.regras.filter( regra => regra.numero_competidor == 2);
      this.regrasComp3 = prova.regras.filter( regra => regra.numero_competidor == 3);
    }
  }

}
