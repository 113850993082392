import { ProvaRacas } from '../../model/prova_racas';

export class DivisaoInformacoes{
    preco_inscricao : string;
    nome : string;
    id_divisao : number;
    porcentagem_premiacao : string;
    somatorio_minimo : number;
    somatorio_maximo : number;
    id_prova : number;
    draw : boolean;
    numero_maximo_inscricao_competidor : number;
    qtd_maxima_inscricao_dupla : number;
    qtd_maxima_inscricao_trio : number;
    qtd_maxima_inscricao_cavalo : number;
    taxa_administrativa : string;
    racasPontuar: ProvaRacas[];
    incremento_premiacao : string;
    maximo_competidores : number;
    tipo_inscricao : number;
    inscricao_bloqueada : boolean;
    limite_inscricao: number;
}