import { Injectable } from "@angular/core";
import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Usuario } from '../model/usuario';
import { UsuarioSemCadastroFilter } from '../filter/usuario-sem-cadastro.filter';

@Injectable()
export class UsuarioSemCadastroService{
    private api_usuario_sem_cadastro : string = `${environment.api_url}/usuario-sem-cadastro`;

    constructor(private http : HttpClient){}

    public inserir(usuarioSemCadastro : Usuario) : Observable<any>{
        return this.http.post<any>(`${this.api_usuario_sem_cadastro}/inserir`,usuarioSemCadastro);
    }

    public alterar(id_usuario : number, usuarioSemCadastro : Usuario) : Observable<Usuario>{
        return this.http.put<Usuario>(`${this.api_usuario_sem_cadastro}/alterar/${id_usuario}`,
        usuarioSemCadastro);
    }

    public buscaPorId(id: number): Observable<Usuario>{
        return this.http.get<Usuario>(`${this.api_usuario_sem_cadastro}/${id}`);
    }

    public buscaTodos(filtro : any) : Observable<Usuario[]>{
        return this.http.get<Usuario[]>(`${this.api_usuario_sem_cadastro}/busca-todos?filtro=${filtro}`);
    }

    public buscaPendentes() : Observable<Usuario[]>{
        return this.http.get<Usuario[]>(`${this.api_usuario_sem_cadastro}/busca-pendentes`);
    }

    public buscaParaInscricao(filtro : any) : Observable<Usuario[]>{
        return this.http.get<Usuario[]>(`${this.api_usuario_sem_cadastro}/busca-para-inscricao?filtro=${filtro}`)
    }

    public buscaFiltro(filtro: UsuarioSemCadastroFilter, limit: number, offset: number): Observable<any>{
        return this.http.get<any>(`${this.api_usuario_sem_cadastro}/busca-filtro?filtro=${JSON.stringify(filtro)}`,{
            params: { 'limit': limit.toString(), 'offset' : offset.toString() }
        });
    }

    public excluir(id): Observable<any>{
        return this.http.get<any>(`${this.api_usuario_sem_cadastro}/delete/${id}`);
    }
}