import { Regra } from './regra';

export class ProvaEditada{

    id_divisao : number;
    id_prova : number;
    id_evento : number;
    nome_divisao : string;
    porcentagem_premiacao : string;
    preco_inscricao : string;
    regras : Regra[] = [];
}