import { OnInit, Component, Input, Output, EventEmitter,  AfterContentInit} from '@angular/core';
import { Prova } from '../../../../model/prova';
import { Campeonato } from '../../../../model/campeonato';
import { Evento } from '../../../../model/evento';
import { ProvaService } from '../../../../service/prova.service';
import { UtilService } from '../../../../util/util.service';
import { Usuario } from '../../../../model/usuario';
import { InscricaoService } from '../../../../service/inscricao.service';

@Component({
    selector: 'lista-de-provas-selecionadas',
    templateUrl: './lista-de-provas-selecionadas.component.html',
    styleUrls: ['./lista-de-provas-selecionadas.style.css', './../../inscricoes.style.css']
})
export class ListaDeProvasSelecionadasComponent implements OnInit, AfterContentInit{

    @Input() campeonato: Campeonato;
    @Input() evento: Evento;
    @Input() prova: Prova;
    @Input() somenteVisualizacao: boolean;

    @Input() listaDeCompetidoresInscritos: Usuario[] = [];

    @Input() listaDeProvasSelecionadas: Prova[];
    @Output() listaDeProvasSelecionadasEmitter: EventEmitter<Prova[]> = new EventEmitter<Prova[]>();

    private qtdInscricao : number = 0;
    private listaProva : Prova[] = [];
    private numInscricoes : number = 0;
    private competidoresServiceCompartilhado : Usuario[] = [];

    constructor(private provaService : ProvaService,
                private utilService : UtilService,
                private inscricaoService : InscricaoService) {}

    ngOnInit(): void {
        this.competidoresServiceCompartilhado = this.inscricaoService.getCompetidores();
    }

    ngAfterContentInit(){
        this.competidoresServiceCompartilhado = this.inscricaoService.getCompetidores();
    }

    private removerProva(prova) {
        this.listaDeProvasSelecionadasEmitter.emit(this.listaDeProvasSelecionadas.filter(prov => prov.id_prova != prova.id_prova));
    }

    private adicinarInscricao(prova){
        
        let obj = {
            id_prova : prova.id_prova,
            competidor : this.competidoresServiceCompartilhado,
            id_evento : prova.id_evento
        }

        this.provaService.buscaInformacoesPorProvaECompetidor(obj)
            .subscribe(retorno => {
                if(prova.qtdInscricao < retorno && retorno != 0){
                    prova.qtdInscricao ++;
                }else{
                    if(prova.qtdInscricao == undefined){
                        prova.qtdInscricao = 1;
                    }else{
                        prova.qtdInscricao = retorno.valor;
                        if(retorno.valor == 0){
                            let mensagem = retorno.mensagem ? retorno.mensagem : "Já atingiu o limite de inscrição desse(s) competidor(es)!";
                            this.utilService.mensagemInfo(mensagem); 
                        }
                    }
                }
            });
    }

    private removeInscricao(prova){
        if(prova.qtdInscricao > 1){
            prova.qtdInscricao--;
        }
    }

    
}