import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';

import { Treinador } from '../../../model/treinador';
import { TreinadoresService } from '../../../service/treinador.service';
import { UtilService } from '../../../util/util.service';
import { UnidadeFederativa } from '../../../model/unidade-federativa';
import { UnidadeFederativaService } from '../../../service/unidade-federativa.service';
import { environment } from '../../../../environments/environment';

declare var UIkit: any;
declare var $: any;

@Component({
    selector: 'app-treinador-form',
    templateUrl: './cadastro-treinador.component.html',
    styleUrls: ['./cadastro-treinador.style.css'],
    moduleId: module.id
})
export class CadastroTreinadorComponent implements OnInit {

    public titulo: string = 'Cadastro de treinador';
    treinador: Treinador = new Treinador();
    formCadastroTreinador: FormGroup;
    private selectedFile: File = null;
    public ufs: UnidadeFederativa[] = [];
    public imageToShow: any;

    constructor(private service: TreinadoresService,
        @Inject(ActivatedRoute) private route: ActivatedRoute,
        private formBuilder: FormBuilder,
        private utilService: UtilService,
        private ufService: UnidadeFederativaService,
        @Inject(Router) private router: Router) {

        utilService.mudaTitulo('RSNC Brasil - Cadastro de treinador');

        this.formCadastroTreinador = this.formBuilder.group({
            nome: ['',
                [
                    Validators.required,
                    Validators.minLength(5),
                    Validators.maxLength(100)
                ]
            ],
            cidade: ['',
                [
                    Validators.required,
                    Validators.minLength(5),
                    Validators.maxLength(50)
                ]
            ],
            email: ['',
                [
                    Validators.required,
                    Validators.minLength(5),
                    Validators.maxLength(50)
                ]
            ],
            local: ['',
                [
                    Validators.required,
                    Validators.minLength(1),
                    Validators.maxLength(100)
                ]
            ],
            telefone: ['',
                [
                    Validators.required,
                    Validators.minLength(14),
                    Validators.maxLength(15)
                ]
            ],
            observacoes: ['',
                [
                    Validators.required
                ]
            ],
            imagem_exibicao: ['',
                [
                ]
            ]

        });

    }

    ngOnInit() {
        this.uploadImagem();
        this.getUfs();
        this.buscarInformacoesTreinador();
    }
    
    private getUfs(){
        this.ufService.buscaTodos().subscribe(ufs => {
            this.ufs = ufs;
        });
    }

    public handleFileInput(event) {
        this.selectedFile = event.target.files[0];
        this.createImageFromBlob(this.selectedFile);
    }

    insere() {

        // let uploadData = new FormData();

        // if (this.selectedFile) {
        //     uploadData.append('file', this.selectedFile, this.selectedFile.name);
        // }

        if (this.formCadastroTreinador.invalid) {
            this.utilService.showErrors(this.formCadastroTreinador);
            return;
        }

        // Object.keys(this.treinador).forEach((key) => { uploadData.append(key, this.treinador[key]) });

        this.requisicaoSalvar();

    }
    
    private requisicaoSalvar(){
        if(this.treinador.id_treinador){
            this.service.altera(this.treinador.id_treinador, this.treinador).subscribe(
            res => {
                this.utilService.mensagemSucesso("Treinador alterado com sucesso!");
                this.utilService.irPara(this.router, '/comum/treinadores/');
            },
            err => {
                this.utilService.mensagemErro(err.error.mensagem);
            });
        }else{
            this.service.insere(this.treinador).subscribe(
            res => {
                this.utilService.mensagemSucesso("Treinador inserido com sucesso!");
                this.utilService.irPara(this.router, '/comum/treinadores/');
            },
            err => {
                this.utilService.mensagemErro(err.error.mensagem);
            });
        }

    }

    public pegaUnidadeFederativa(event){
        let id_uf = event.target.value;
        this.treinador.id_unidade_federativa = id_uf;
    }

    private createImageFromBlob(image: Blob) {
        let reader = new FileReader();
        reader.addEventListener("load", () => {
           this.imageToShow = reader.result;
        }, false);
     
        if (image) {
           reader.readAsDataURL(image);
        }
    }

    private buscarInformacoesTreinador(){
        let idTreinador = parseInt(this.route.snapshot.paramMap.get("idTreinador"));
        if(idTreinador){
            this.titulo = 'Edição de treinador'
            this.service.buscaPorId(idTreinador).subscribe(t => {
                this.treinador = t;
                this.treinador.nome_old = this.treinador.nome;
                this.getThumbnailExibicao(t.imagem_exibicao);
            })
        }
    }

    private getThumbnailExibicao(path){
        if(path){
            this.imageToShow = `${environment.api_url}/file/thumbnailTreinador/${path}`;
        }
    }

    public atribuiValorImagem(response: any):void{
        if(this.treinador.imagem_exibicao && this.treinador.imagem_exibicao.includes(this.treinador.nome)){
            this.treinador.imagem_old = this.treinador.imagem_exibicao;
        }
        this.treinador.imagem_exibicao = response.path;
        this.imageToShow = response.img;
    }

    private uploadImagem(){
        var bar = <HTMLInputElement>document.getElementById('js-progressbar');
        let uploadURL = this.service.upload_imagem_api_url;
        const formCadastroEvento = this;
        
        UIkit.upload('.js-upload', {

            url: uploadURL,
            multiple: false,
            mime: '.jpg, .png, .jpeg, .gif, .bmp, .tif, .tiff|image/*',

            loadStart: function (e) {
                bar.removeAttribute('hidden');
                bar.max = e.total;
                bar.value = e.loaded;
            },

            progress: function (e) {
                bar.max = e.total;
                bar.value = e.loaded;
            },

            loadEnd: function (e) {
                let response = JSON.parse(e.srcElement.response);
                formCadastroEvento.atribuiValorImagem(response);
                bar.max = e.total;
                bar.value = e.loaded;
            },
            
            completeAll: function (e) {
                setTimeout(function () {
                    bar.setAttribute('hidden', 'hidden');
                }, 1000);
            },

            fail: function(){
                alert('Formato de arquivo não suportado');
            }

        });
    }
}