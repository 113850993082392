import { Component, OnInit, Inject } from '@angular/core';
import { UtilService } from '../../../../util/util.service';
import { UsuarioService } from '../../../../service/usuario.service';
import { CavaloService } from '../../../../service/cavalo.service';
import { ProvaService } from '../../../../service/prova.service';
import { Inscricao } from '../../../../model/inscricao';
import { Prova } from '../../../../model/prova';
import { Cavalo } from '../../../../model/cavalo';
import { Usuario } from '../../../../model/usuario';
import { Router, ActivatedRoute } from '@angular/router';
import { InscricaoService } from '../../../../service/inscricao.service';
import { InscricaoCompetidor } from '../../../../model/inscricao_competidor';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { VerificaRegraService } from '../../../../service/verifica-regra.service';
import { Regra } from '../../../../model/regra';
import { Evento } from '../../../../model/evento';
import { InscricaoCompetidorService } from '../../../../service/inscricao-competidor.service';
import { UsuarioSemCadastroService } from '../../../../service/usuario-sem-cadastro.service';
import { UsuarioSemCadastroInscricaoCompetidor } from '../../../../model/usuario-sem-cadastro-inscricao-competidor';
import { RegraAssociacao } from '../../../../model/regra-associacao';
import { RegraValidadorService } from '../../../../service/regra.validador.service';
import { AssociacaoCompetidor } from '../../../../model/associacao-competidor';
import { AssociacaoCompetidorService } from '../../../../service/associacao-competidor';
import { RegraAssociacaoService } from '../../../../service/regra-associacao.service';

@Component({
  selector: 'app-editar-inscricao',
  templateUrl: './editar-inscricao.component.html',
  styleUrls: ['./../../inscricoes.style.css','./editar-inscricao.component.css']
})
export class EditarInscricaoComponent implements OnInit {

  public titulo_pagina : string = "Editar Inscrição";
  public inscricao : Inscricao;
  public prova : Prova;
  public evento : Evento;
  public cavalos : Cavalo[] = [];
  public competidores : Usuario[] = [];
  public inscricao_competidor : InscricaoCompetidor[] = [];
  private idInscricao : number = null;
  private usuarioSemCadastroInscricaoCompetidores : UsuarioSemCadastroInscricaoCompetidor[] = [];

  private filtro : {
    nome : string,
    id_evento : number,
    nome_competidor : string
  } = {
    nome : null,
    id_evento : null,
    nome_competidor : null
  } 
  
  private debounceCompetidores: Subject<string> = new Subject<string>();
  private listaDebouncesCavalos: Subject<any>[] = [];
  public listaProsseguirCavalos: boolean[] = [];
  public numeroDeInscritos : number = 0;
  public tipoCadastro : number = 1;
  public listaDeCompetidores : Usuario[] = [];
  private listaDeCavalosInscritos: Cavalo[] = [];
  public valueCompetidores: string = "";
  private listaValueCavalos: string[] = [];
  private listaCavalos: Cavalo[][] = [];

  public regrasComp1: Regra[] = [];
  public regrasComp2: Regra[] = [];
  public regrasComp3: Regra[] = [];
  public regrasAssociacao : RegraAssociacao[] = [];

  constructor(public utilService : UtilService,
              private usuarioService : UsuarioService,
              private usuarioSemCadastroService : UsuarioSemCadastroService,
              private cavaloService : CavaloService,
              private provaService : ProvaService,
              private verificaRegraService: VerificaRegraService,
              private inscricaoCompetidorService : InscricaoCompetidorService,
              @Inject(Router) private router: Router,
              @Inject(ActivatedRoute) private route: ActivatedRoute,
              private inscricaoService : InscricaoService,
              private associacaoCompetidorService : AssociacaoCompetidorService,
              private regraAssociacaoService : RegraAssociacaoService) {
  }

  ngOnInit() {
    this.idInscricao = parseInt(this.route.snapshot.queryParamMap.get("id_inscricao"));
    if(!isNaN(this.idInscricao)){
      this.buscaInscricao();
      this.buscaRegrasAssociacao();
    }else{
     this.erroPagina();
    }
  }

  public erroPagina(){
    this.utilService.mensagemErro("Erro - Não foi possivel acessar a página.",
    () => this.irParaInicio());
  }

  buscaInscricao(){
    this.inscricaoService.buscaEditarInscricao(this.idInscricao).subscribe(retorno => {
      let idLogado = this.utilService.getIdLogado();
      if((retorno.inscricao.draw == true ) &&
        (retorno.inscricao.tipo_inscricao == retorno.prova.tipo_prova)){
        this.erroPagina();
      }else if(this.utilService.isAdm() ||
        (idLogado == retorno.evento.id_organizador) ||
        (idLogado == retorno.inscricao.id_cadastrador)){
        this.listaDeCompetidores = retorno.competidores;
        this.inscricao = retorno.inscricao;
        this.filtro.id_evento = retorno.inscricao.id_evento;
        this.inscricao_competidor = retorno.inscricao_competidor;
        this.prova = retorno.prova;
        this.evento = retorno.evento;
        this.numeroDeInscritos = retorno.inscricao_competidor.length;
        this.tipoCadastro = retorno.prova.divisao.nao_exigir_cadastro == true ? 0 : 1;
        this.inscricao_competidor.forEach(ic => ic.statusInscricao = false);
        if(retorno.prova.divisao.nao_exigir_cadastro){
          this.usuarioSemCadastroInscricaoCompetidores = this.listaDeCompetidores
          .map( comp => comp.usuarioSemCadastroInscricaoCompetidor);
          this.initBuscaCompetidorCavaloSemCadastro();
        }else{
          this.initBuscaCompetidorCavalo();
        }
        this.initlistaProsseguirCavalos();
        this.verificaRegras();
      }else{
        this.redireciona();
      }      
    }, erro => this.utilService
      .mensagemErro(erro.error.mensagem,
      () => this.irParaInicio())
    );
  }

  public initBuscaCompetidorCavalo(){
    this.debounceCompetidores.pipe(debounceTime(300)).subscribe(filtro => {
      this.usuarioService.buscaTodos(filtro).subscribe(competidores => {
        this.competidores = competidores.filter(competidor => {
          return (!this.competidores.find(comp => {
              return comp.id_usuario == competidor.id_usuario;
          })) && competidor.competidor == true && competidor.ativo == true && this.validaCompetidor(competidor);
        });
      });
    });
  }

  private validaCompetidor(competidor: Usuario){
    return this.listaDeCompetidores &&
    this.listaDeCompetidores.filter(lc => lc.id_usuario == competidor.id_usuario).length == 0;
  }

  public initBuscaCompetidorCavaloSemCadastro(){
    this.debounceCompetidores.pipe(debounceTime(300)).subscribe(filtro => {
        this.usuarioSemCadastroService.buscaTodos(filtro).subscribe(competidores => {
          this.competidores = competidores.filter(competidor => {
              return (!this.competidores.find(comp => {
                  return comp.id_usuario == competidor.id_usuario;
              })) && competidor.competidor == true && competidor.ativo == true;
          });
        })
    });
  }

  private initlistaProsseguirCavalos(){
    for (let index = 0; index < this.listaDeCompetidores.length; index++) {
      this.competidores = [] as Usuario[];
      this.valueCompetidores = null;
      this.listaDebouncesCavalos.push(new Subject<any>());
      this.listaDeCompetidores[index].numero_competidor = index + 1;
      if(this.listaDeCompetidores[index].cavalo){
        let ic = this.inscricao_competidor
        .filter(ic => ic.id_inscricao_competidor == this.listaDeCompetidores[index].id_inscricao_competidor)[0];
        this.listaDeCompetidores[index].cavalo.potro_futuro = ic.potro_futuro;
        this.listaDeCavalosInscritos.push(this.listaDeCompetidores[index].cavalo);
        this.listaCavalos.push([]);
        this.listaDebouncesCavalos[index].pipe(debounceTime(300)).subscribe(filtro => {
          this.filtro.nome = filtro;
          this.buscarCavalos(index);
        });
        this.listaProsseguirCavalos.push(true);
        this.listaValueCavalos.push("");
      }else{
        this.listaValueCavalos.push(null);
        this.listaCavalos.push([]);
        this.listaProsseguirCavalos.push(false);
      }
    }
  }

  public verificaRegras(){
    let tipo = this.prova.tipo_prova;
    if(this.prova.divisao.regras.length > 0){
      if(tipo == 1){
        this.regrasComp1 = this.prova.divisao.regras;
      }else{            
        if(tipo == 2 ){
          this.regraTipo2();
        }  
        if(tipo == 3){
          this.regraTipo3();
        }
      }
    }
  }

  regraTipo2(){
    let regras = this.prova.divisao.regras;
    regras.forEach(r => {
        if((r.numero_competidor == 1) || (r.numero_competidor == null) ){
            this.regrasComp1.push(r);
        }else{
            this.regrasComp2.push(r);
        }
    });
  }

  regraTipo3(){
      let regras = this.prova.divisao.regras;

      regras.forEach(r => {
        if((r.numero_competidor == 1) || (r.numero_competidor == null) ){
          this.regrasComp1.push(r);
        }

        if( r.numero_competidor == 2){
          this.regrasComp2.push(r);
        }

        if( r.numero_competidor == 3){
          this.regrasComp3.push(r);
        }
      });
  }

  public getCompetidores(filtro: string): void {
    if (filtro && filtro.length >= 3) {
      this.debounceCompetidores.next(filtro);
    } else {
      this.filtro.nome_competidor = null;
      this.competidores = [];
    }
  }

  private selecionaCompetidores(competidor: Usuario): void {
    this.valueCompetidores = null;
    this.competidores = [] as Usuario[];
    competidor.numero_competidor = this.listaDeCompetidores.length + 1;
    this.listaDeCompetidores.push(competidor);
    this.listaValueCavalos.push("");
    this.listaCavalos.push([]);
    this.listaDebouncesCavalos.push(new Subject<any>());
    let indexComp = this.listaDeCompetidores.indexOf(competidor);
    this.listaDebouncesCavalos[indexComp].pipe(debounceTime(300)).subscribe(filtro => {
      this.filtro.nome = filtro;
      this.buscarCavalos(indexComp);
    });
  }

  public buscarCavalos(indexComp : number){
    this.cavaloService.buscaTodosQuantidadeInscricao(this.filtro).subscribe(cavalos => {
      this.listaCavalos[indexComp] = cavalos.filter(cavalo => {
        return (!this.listaDeCavalosInscritos.find(cav => cav.id_cavalo == cavalo.id_cavalo));
      });
    });
  }

  private descelecionaCompetidor(competidor: Usuario): void {
    this.descelecionaCavalos(competidor, competidor.cavalo);
    this.valueCompetidores = null;
    this.listaDeCompetidores.splice(this.listaDeCompetidores.indexOf(competidor), 1);
  }

  private descelecionaCavalos(competidor: Usuario, cavalo: Cavalo): void {
    let indexCompetidor = this.listaDeCompetidores.indexOf(competidor);
    this.listaDeCompetidores[indexCompetidor].cavalo = null;
    this.listaDeCavalosInscritos.splice(this.listaDeCavalosInscritos.indexOf(cavalo), 1);
    this.listaValueCavalos[indexCompetidor] = null;
    this.listaProsseguirCavalos[indexCompetidor] = false;
  }

  private getCavalos(competidor: Usuario, filtro: string): void {
    let indexComp = this.listaDeCompetidores.indexOf(competidor);
    if (filtro && filtro.length >= 3) {
      this.listaDebouncesCavalos[indexComp].next(filtro);
    } else {
      this.listaCavalos[indexComp] = [];
    }
  }

  private selecionaCavalos(competidor: Usuario, cavalo: Cavalo): void {
    if (!this.listaDeCavalosInscritos.find(cav => cav.id_cavalo == cavalo.id_cavalo)) {
      let indexCompetidor = this.listaDeCompetidores.indexOf(competidor);
      this.listaDeCompetidores[indexCompetidor].cavalo = cavalo;
      this.listaDeCavalosInscritos.push(cavalo);
      this.listaValueCavalos[indexCompetidor] = null;
      this.listaCavalos[indexCompetidor] = [] as Cavalo[];
      this.listaProsseguirCavalos[indexCompetidor] = true;
    } else {
      this.utilService.mensagemInfo("Cavalo já foi selecionado para outro competidor!");
    }
  }

  public adicionarPotroFuturo(competidor){
      this.listaDeCompetidores.forEach(comp => {
        if(competidor.id_usuario == comp.id_usuario){
          comp.cavalo.potro_futuro = comp.cavalo.potro_futuro == true ? false : true;
          (document.getElementById("checkboxInput"+comp.cavalo.id_cavalo) as HTMLInputElement).checked = comp.cavalo.potro_futuro;
        }else {
          (document.getElementById("checkboxInput"+comp.cavalo.id_cavalo) as HTMLInputElement).checked = false;
          comp.cavalo.potro_futuro = false;
        }
      });
  }

  public salvar(){
    this.utilService.mensagemConfirmacao("Deseja realmente alterar a inscrição?",
    res => {
      if(res){
        this.verificaRegraService.verificaRegras([this.prova], this.listaDeCompetidores);
        if(this.prova.valida == true && this.prova.regrasNaoAtendidas.length == 0){
          this.inscricao_competidor.forEach(ic => ic.statusInscricao = false);
          if(this.prova.divisao.nao_exigir_cadastro){
            this.validaInscricaoSemCadastro();
          }else{
            this.validaInscricao();
          }
        }else if(this.prova.regrasNaoAtendidas.length > 0){
          let mensagem = "Não foi possivel editar a inscrição!";
          this.utilService.mensagemInfo(mensagem);
        }
      }
    });
  }

  public validaInscricaoSemCadastro(){
    let idCompetidores : number[] = [];
    this.inscricao_competidor.forEach( ic => {
      this.listaDeCompetidores.forEach(competidor => {
        let idCavalo = competidor.cavalo ? competidor.cavalo.id_cavalo : 1;
        if((ic.id_inscricao_competidor == competidor.id_inscricao_competidor) && 
          (idCavalo == ic.id_cavalo)){
          ic.statusInscricao = true;
          idCompetidores.push(competidor.id_usuario);
          this.modificaUsuarioSemCadastroInscricaoCompetidor(ic.id_inscricao_competidor,competidor.id_usuario);
        }
      });
    });

    let competidores = this.listaDeCompetidores
    .filter(comp => idCompetidores.indexOf(comp.id_usuario) == -1);

    if(idCompetidores.length != this.listaDeCompetidores.length){
      competidores.forEach(comp => {
        this.inscricao_competidor.forEach(ic => {
          if(!ic.statusInscricao){
            comp.id_inscricao_competidor = ic.id_inscricao_competidor;
            ic.id_cavalo = comp.cavalo ? comp.cavalo.id_cavalo :  1;
            ic.statusInscricao = true;
            this.modificaUsuarioSemCadastroInscricaoCompetidor(ic.id_inscricao_competidor, comp.id_usuario);
          }
        });
      });
      this.atulizarInscricaoSemCadastro();
    }else{
      this.nenhumaAlteracaoInscricao();
    }
  }

  public modificaUsuarioSemCadastroInscricaoCompetidor(id : number, id_usuario : number){
    this.usuarioSemCadastroInscricaoCompetidores.forEach( uic => {
      uic.id_usuario = uic.id_inscricao_competidor == id ? id_usuario : uic.id_usuario;
    });
  }

  public validaInscricao(){
    let idCompetidores = [];
    let idCompetidorCavalo = this.listaDeCompetidores.map(competidor => {
      idCompetidores.push(competidor.id_usuario);
      return {
        id_cavalo : competidor.cavalo ? competidor.cavalo.id_cavalo : 1,
        id_usuario : competidor.id_usuario,
        potro_futuro : competidor.cavalo ? competidor.cavalo.potro_futuro : false
      }
    });
    let competidoresValida = this.validaCompetidores(idCompetidorCavalo);
    if(competidoresValida.length > 0){
      this.verificaCompetidores(competidoresValida, idCompetidores);
    }else{
      this.nenhumaAlteracaoInscricao();
    }
  }

  public nenhumaAlteracaoInscricao(){
    let mensagem = 'Não teve nenhuma alteração na inscrição';
    this.utilService.mensagemInfo(mensagem, ()=> this.buscaInscricao());
  }

  public validaCompetidores(idCompetidorCavalo : any[]) : any[]{
    let competidoresValida = [];
    idCompetidorCavalo.forEach( cc => {
      let potro_futuro = false;
      let tipo = 0;
      //tipo => representa se o inscrição deve valida o competidor, cavalo ou os dois.
      // 0 - valida valida tudo
      // 1 - valida só o competidor
      // 2 - valida só o cavalo
      // 3 - não mudou nada
      // potro futuro => representa se teve alguma alteração no cavalo.
      this.inscricao_competidor.forEach(ic => {
        if(cc.id_usuario == ic.id_competidor && ic.statusInscricao == false){
          tipo = cc.id_cavalo != ic.id_cavalo && cc.id_cavalo != 1 ? 2 : 3;
          potro_futuro = ic.potro_futuro != cc.potro_futuro && 
          this.prova.divisao.potro_futuro == true && cc.potro_futuro == true ? true : false;
        }else if((cc.id_cavalo == ic.id_cavalo) && ic.statusInscricao == false){
          tipo = cc.id_usuario != ic.id_competidor ? 1 : 3; 
          potro_futuro = ic.potro_futuro != cc.potro_futuro && 
          this.prova.divisao.potro_futuro == true && cc.potro_futuro == true ? true : false;
        }
      });

      if(!(tipo == 3 && potro_futuro == false)){
        let competidor = this.listaDeCompetidores
        .filter(comp => cc.id_usuario == comp.id_usuario)[0];
        competidor['tipo'] = tipo;
        competidor['validaPotroFuturo'] = potro_futuro;
        competidoresValida.push(competidor);
      }
    });

    return competidoresValida;
  }

  public verificaCompetidores(competidores : any[], idCompetidores : number[]){
    let objeto = {
      id_prova : this.prova.id_prova,
      competidores : competidores,
      id_evento : this.prova.id_evento,
      idCompetidores : idCompetidores
    }
    this.provaService.revalidaCompetidores(objeto).subscribe(retorno => {
      if(retorno.valor > 0){
        this.alterarInscricaoCompetidor();
      }else{
        let mensagem = "A inscrição não pode ser alterada!" +
        "\n Competidor(es) ou cavalo(s) invalido(s).";
        mensagem = retorno.mensagem ? retorno.mensagem : mensagem;
        this.utilService.mensagemInfo(mensagem); 
      }
    });
  }

  private alterarInscricaoCompetidor(){
    let idCompetidores = [];
    this.listaDeCompetidores.forEach(competidor => {
      this.inscricao_competidor.forEach(ic => {
        if((ic.id_competidor == competidor.id_usuario) &&
          (ic.statusInscricao == false)){
          ic.id_cavalo = competidor.cavalo ? competidor.cavalo.id_cavalo : 1;
          ic.potro_futuro = this.prova.divisao.potro_futuro == true && competidor.cavalo ? 
          competidor.cavalo.potro_futuro : false;
          ic.handicap_competidor = competidor.handicap;
          ic.statusInscricao = true;
          idCompetidores.push(ic.id_competidor);
        }
      });
    });

    let competidoresSemIC = this.listaDeCompetidores
    .filter( comp => idCompetidores.indexOf(comp.id_usuario) == -1);

    if(competidoresSemIC.length > 0){
      competidoresSemIC.forEach(comp => {
        for (let index = 0; index < this.inscricao_competidor.length; index++) {
          if(this.inscricao_competidor[index].statusInscricao == false){
            this.inscricao_competidor[index].id_competidor = comp.id_usuario;
            this.inscricao_competidor[index].id_cavalo = comp.cavalo ? comp.cavalo.id_cavalo : 1;
            this.inscricao_competidor[index].handicap_competidor = comp.handicap;
            this.inscricao_competidor[index].potro_futuro = this.prova.divisao.potro_futuro == true &&
            comp.cavalo ? comp.cavalo.potro_futuro : false;
            this.inscricao_competidor[index].statusInscricao == true;
            break;
          }          
        }
      });
    }
    this.atualizarInscricaoCompetidor();
  }   

  public atualizarInscricaoCompetidor(){
    this.inscricao.id_cadastrador = this.utilService.getIdLogado();
    this.inscricaoService.editarInscricao(this.inscricao, this.inscricao_competidor)
    .subscribe(retorno => {
      if(retorno){
       this.inscricaoSucesso();
      }
    }, () => this.inscricaoErro());
  }

  public atulizarInscricaoSemCadastro(){
    this.inscricao.id_cadastrador = this.utilService.getIdLogado();
    this.inscricaoService
    .editarInscricaoSemCadastro(this.inscricao, this.inscricao_competidor,
    this.listaDeCompetidores, this.usuarioSemCadastroInscricaoCompetidores)
    .subscribe(retorno => {
      if(retorno){
        this.inscricaoSucesso();
      }
    }, () => this.inscricaoErro())

  }

  public inscricaoSucesso(){
    let mensagem = "Inscrição atualizacao com sucesso";
    this.utilService.mensagemInfo(mensagem, () => this.irParaInicio());
  }

  public inscricaoErro(){
    this.utilService.mensagemErro("Erro - Não foi possivel atualizar a inscrição!");
  }

  public irParaInicio(){
    this.utilService.irPara(this.router, `/comum/inicio`);
  }

  private redireciona() {
    this.utilService.mensagemErroComTitulo('Acesso negado', 'Você não tem permissão para alterar a inscrição',
    () => this.irParaInicio());
  }

  public buscaRegrasAssociacao(){
    this.regraAssociacaoService.buscaTodos()
    .subscribe(retorno => {
      this.regrasAssociacao = retorno;
    });
  }

  public validaAfiliacao(): boolean{
    var retorno = true;
    if(this.tipoCadastro == 1 && this.listaDeCompetidores.length == this.prova.tipo_prova){
      retorno = this.listaDeCompetidores.filter(competidor => !competidor.associacao_competidor).length > 0 ? true : false;
    }else if(this.tipoCadastro != 1){
      retorno = false;
    }
    return retorno;
  }

  public associarCompetidor(competidor : Usuario){
    if(this.regrasAssociacao.length > 0){
      let idade = this.utilService.getIdadeBaseAnoHipico(competidor.data_nascimento);
      let valida =  new RegraValidadorService(idade, null, null, null, null, null, this.utilService);
      valida.addRegraAssocicao(this.regrasAssociacao);
      let id_regra_associacao = valida.validaAssociacao().id_regra_associacao;
      if(id_regra_associacao){
        this.inserirAssociacaoCompetidor(id_regra_associacao, competidor);
      }else{
        let mensagem = competidor.nome + " não se encaixa em nenhuma categoria de filiação";
        this.utilService.mensagemInfo(mensagem);
      }
    }else{
      this.utilService.mensagemInfo("Não foi encontrado nenhuma categoria de filiação!")
    }
  }

  public inserirAssociacaoCompetidor(id_regra_associacao : number, competidor : Usuario){
    let associarCompetidor = new AssociacaoCompetidor();
    associarCompetidor.id_regra_associacao = id_regra_associacao;
    associarCompetidor.id_usuario = competidor.id_usuario;
    associarCompetidor.id_evento = this.filtro.id_evento;
    associarCompetidor.id_cadastrador = this.utilService.getIdLogado();
    associarCompetidor.associacao_competidor_paga = false;

    this.associacaoCompetidorService.insere(associarCompetidor)
    .subscribe(retorno => {
      if(retorno.id_associacao_competidor){
        competidor.associacao_competidor = retorno;
        this.utilService.mensagemSucesso("Filiação do competidor realizada com sucesso!");
      }
    }, () => this.utilService.mensagemErro("Erro - Não foi possivel realizar a filiação do competidor."))
  }

}
