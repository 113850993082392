import { Component, OnInit, Inject } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { EventoService } from '../../../service/evento.service';
import { UtilService } from '../../../util/util.service';
import { Evento } from './../../../model/evento';
import { Financeiro } from '../../../model/financeiro';
import { Inscricao } from '../../../model/inscricao';
import { InscricaoCompetidorService } from '../../../service/inscricao-competidor.service';
import { InscricaoCompetidor } from '../../../model/inscricao_competidor';
import { AssociacaoCompetidorService } from '../../../service/associacao-competidor';

@Component({
  selector: 'app-financeiro',
  templateUrl: './financeiro.component.html',
  styleUrls: ['./financeiro.component.css']
})
export class FinanceiroComponent implements OnInit {

  public filtro : {
    nome : string,
    tipo : string,
    id_evento : number
  } = {
    nome : null,
    tipo : null,
    id_evento : null
  }

  public evento : Evento = null;
  public financeiros : Financeiro[] = [];
  public resultado : any = null;
  public inscricoes : Inscricao[] = [];
  public nomeCompetidor : string = null;
  public associacoes : any[] = [];
  public valoresInscricoes : any;
  public valoresAssociacao : any;
  public custoIndividual : number = 0;
  public pagoIndividual : number = 0;
  public saldoIndividual : number = 0;
  public titulo_pagina : string = "Financeiro";
  public id_usuario : number;
  public custoTotal : number = 0;
  public pagoTotal : number = 0;
  public saldoTotal : number = 0;
  
  constructor(private eventoService : EventoService,
              public utilService : UtilService,
              public inscricaoCompetidorSerive : InscricaoCompetidorService,
              @Inject(ActivatedRoute) private route: ActivatedRoute,
              @Inject(Router) private router : Router,
              private inscricaoCompetidorService : InscricaoCompetidorService,
              private associacaoCompetidorService: AssociacaoCompetidorService) { }

  ngOnInit() {
    this.filtro.id_evento = parseInt(this.route.snapshot.queryParamMap.get("id_evento"));
    this.filtro.tipo = "cadastrador";
    if(!isNaN(this.filtro.id_evento)){
      this.buscaEvento();
    }else{
      this.irParaInicio();
    }
  }

  public buscaEvento(){
    this.eventoService.buscaPorId2(this.filtro.id_evento).subscribe(retorno => {
      if((this.utilService.getIdLogado() == retorno.id_organizador) ||
        (this.utilService.isAdm())){
        this.evento = retorno;
        this.titulo_pagina = "Financeiro - " + this.evento.titulo;
        this.buscaFinanceiro();
      }else{
        this.AcessoNegado();
      }
    }, erro => this.utilService.mensagemErro(erro.error.mensagem));
  }

  public buscaFinanceiro(){
    this.eventoService.buscaFinanceiro(this.filtro).subscribe(retorno => {
      this.financeiros = retorno.financeiro;
      this.resultado = retorno.resultado;
    });
  }

  public selecionarTipo(tipo : string){
    this.filtro.tipo = tipo;
    this.buscaFinanceiro();
  }

  public filtroUsuario(filtro : string){
    if(filtro.length > 2){
      this.filtro.nome = filtro;
    }else{
      this.filtro.nome = null;
    }
    this.buscaFinanceiro();
  }

  public buscaInscricaoCompetidor(id_usuario : number, nome : string){
    this.nomeCompetidor = nome;
    this.id_usuario = id_usuario;
    if(this.filtro.tipo == 'cadastrador'){
      this.buscaPorCadastrador(id_usuario);
    }else if(this.filtro.tipo == 'competidor'){
      this.buscaPorCompetidor(id_usuario);
    }
  }

  public buscaPorCadastrador(id_usuario : number){
    this.inscricaoCompetidorSerive.buscaPorCadastrador(id_usuario, this.filtro.id_evento)
    .subscribe(retorno => {
      this.inscricoes = retorno.inscricoes;
      this.associacoes = retorno.associacoes;
      this.valoresInscricoes = retorno.valoresInscricoes;
      this.valoresAssociacao = retorno.valoresAssociacao;
      this.calculaCustoIndividual(null);
    });
  }

  public buscaPorCompetidor(id_usuario : number){
    this.inscricaoCompetidorSerive.buscaPorCompetidor(id_usuario, this.filtro.id_evento)
    .subscribe(retorno => {
      this.inscricoes = retorno.inscricoes;
      this.associacoes = retorno.associacoes;
      this.valoresInscricoes = retorno.valoresInscricoes;
      this.valoresAssociacao = retorno.valoresAssociacao;
      this.calculaCustoIndividual(id_usuario);
    });
  }

  public calculaCustoIndividual(id_usuario : number){
    this.custoIndividual = 0;
    this.pagoIndividual = 0;
    this.saldoIndividual = 0;
    this.inscricoes.forEach(inscricao => {
      let competidor = [];
      if(this.filtro.tipo == 'competidor'){
        competidor = inscricao.competidores.filter( comp => comp.id_usuario == id_usuario);
      }else if(this.filtro.tipo == 'cadastrador'){
        competidor = inscricao.competidores;
      }
      this.custoIndividual += competidor.reduce((x,y) => x + y.custo, 0);
      this.pagoIndividual += competidor.reduce((x,y) => x + y.pago, 0);
    });
    this.pagoTotal = this.pagoIndividual + this.valoresAssociacao.pagoTotal;
    this.custoTotal =  this.custoIndividual + this.valoresAssociacao.custoTotal;
    this.saldoIndividual = this.pagoIndividual - this.custoIndividual;
    this.saldoTotal = this.pagoTotal - this.custoTotal;
  }

  public pagamentoCompetidor(inscricaoCompetidor : InscricaoCompetidor){
    if(inscricaoCompetidor.inscricao_paga){
      this.removerPagamento(inscricaoCompetidor);
    }else if(!inscricaoCompetidor.inscricao_paga){
      this.efetuarPagamento([inscricaoCompetidor]);
    }
  }

  public efetuarPagamento(inscricaoCompetidores : InscricaoCompetidor[]){
    let mensagem = "Deseja realmente efetuar";
    mensagem += inscricaoCompetidores.length == 1? 'o pagamento?' : 'os pagamentos?'; 
    this.utilService.mensagemConfirmacao(mensagem,
    (res) => {
      if(res){
        this.inscricaoCompetidorService.efetuarPagamento(inscricaoCompetidores)
        .subscribe(retorno => {
            this.utilService.mensagemSucesso('Pagamento efetuado com sucesso.');
            this.buscaInscricaoCompetidor(this.id_usuario, this.nomeCompetidor);
        }, () =>this.utilService.mensagemErro("Erro - Não foi possivel efetuar o(s) pagamento(s)."));
      }else{
        this.buscaInscricaoCompetidor(this.id_usuario, this.nomeCompetidor);
      }
    });
  }

  public removerPagamento(inscricaoCompetidor : InscricaoCompetidor){
    this.utilService.mensagemConfirmacao("Deseja realmente remover o pagamento?",
    (res) => {
      if(res){
        inscricaoCompetidor.inscricao_paga = false;
        this.inscricaoCompetidorService.removePagamento(inscricaoCompetidor)
        .subscribe(retorno => {
          this.utilService.mensagemSucesso('Pagamento removido com sucesso.');
          this.buscaInscricaoCompetidor(this.id_usuario, this.nomeCompetidor);
        }, () => {
          this.utilService.mensagemErro("Erro - Não foi possivel remover o pagamento.");
          inscricaoCompetidor.inscricao_paga = true;
        });
      }else{
        this.buscaInscricaoCompetidor(this.id_usuario, this.nomeCompetidor);
      }      
    });
  }

  public selecionaInscricaoCompetidor(status: boolean) : InscricaoCompetidor[]{
    let inscricao_competidor = []; 
    this.inscricoes.forEach(inscricao => {
      let competidores = [];
      if(this.filtro.tipo == 'cadastrador'){
        competidores = inscricao.competidores.filter(competidor => competidor.inscricao_competidor.inscricao_paga == status);
      }else if(this.filtro.tipo == 'competidor'){
        competidores = inscricao.competidores
        .filter(competidor => competidor.id_usuario == this.id_usuario && competidor.inscricao_competidor.inscricao_paga == status);
      }
      if(competidores.length > 0){
        competidores.forEach(comp => inscricao_competidor.push(comp.inscricao_competidor))
      }
    });
    return inscricao_competidor;
  }

  public efetuarPagamentos() : void{
    let inscricaoCompetidores = this.selecionaInscricaoCompetidor(false);
    if(inscricaoCompetidores.length != 0){
      this.efetuarPagamento(inscricaoCompetidores);
    }else{
      this.utilService.mensagemInfo('Nenhuma inscrição para ser paga.');
    }
  }

  public removerPagamentos() : void{
    let inscricaoCompetidores = this.selecionaInscricaoCompetidor(true);
    if(inscricaoCompetidores.length != 0){
      this.removerListaPagamento(inscricaoCompetidores);
    }else{
      this.utilService.mensagemInfo('Nenhuma inscrição para remover o pagamento.');
    }
  }

  public removerListaPagamento(inscricaoCompetidores): void{
    this.utilService.mensagemConfirmacao('Deseja realmente remover os pagamentos?',
    resp => {
      if(resp){
        this.inscricaoCompetidorService.removeListaPagamento(inscricaoCompetidores)
        .subscribe(
          retorno => {
            this.utilService.mensagemSucesso('Pagamentos removidos com sucesso.');
            this.buscaInscricaoCompetidor(this.id_usuario, this.nomeCompetidor);
          },
          erro => this.utilService.mensagemErro(erro.error.mensagem)
        );
      }
    })    
  }

  public AcessoNegado(){
    this.utilService.mensagemErroComTitulo('Acesso negado', 'Esse perfil não possui o nível de autorização ' +
    'necessário para acessar essa funcionalidade.', () => {
      this.irParaInicio();
    });
  }

  public irParaInicio(){
    this.router.navigate(['/comum/inicio']);
  }

  public pagarFiliacao(){
    this.utilService.mensagemConfirmacao("Deseja realmente pagar a(s) filiação(ões)?",
    res => {
      if(res){
        this.pagamentoFiliacao(true, "Pagamento realizado com sucesso!");
      }
    });
  }

  public removerPagamentoFiliacao(){
    this.utilService.mensagemConfirmacao("Deseja realmente remover o pagamento da(s) filiação(ões)?",
    res => {
      if(res){
        this.pagamentoFiliacao(false, "Operação realizado com sucesso!");
      }
    });
  }

  public pagamentoFiliacao(status: boolean, mensagem: string){
    this.associacaoCompetidorService.efetuarPagementos(this.associacoes, status)
    .subscribe(retorno => {
      this.utilService.mensagemSucesso(mensagem);
      this.buscaInscricaoCompetidor(this.id_usuario, this.nomeCompetidor);
    }, erro => this.utilService.mensagemErro("Erro - " + erro.error.message));
  }

  public financeiroXML(){
    let titulos = this.preparaTitulosXML();
    let dados = this.preparaFinanceiroXML();
    let titulo = "financeiro-" + this.evento.titulo;
    titulo += this.filtro.tipo == "cadastrador" ? "-cadastrador.xls" : "-competidor.xls";
    this.utilService.downloadExcel(titulos, dados, titulo);
  }

  public preparaFinanceiroXML(){
    let retorno: any = this.financeiros.map(financeiro => {
      return {
        id_usuario: financeiro.id_usuario,
        nome: financeiro.nome,
        quantidadeInscricao: financeiro.qtd_inscricoes,
        quantidadeFiliacao: financeiro.qtd_associacoes,
        custo: financeiro.custo,
        pago: financeiro.pago,
        saldo: financeiro.saldo
      }
    });
    retorno.push({
      id_usuario: 'TOTAL',
      nome: '',
      quantidadeInscricao: this.resultado.totalInscricoes,
      quantidadeFiliacao: this.resultado.totalAssociacoes,
      custo: this.resultado.totalCusto,
      pago: this.resultado.totalPago,
      saldo: this.resultado.totalSaldo
    });
    return retorno;
  }

  public preparaTitulosXML(){
    return ['Número', 'Nome', 'Inscrições', 'Filiações', 'Custo', 'Pago', 'Saldo'];
  }
}
