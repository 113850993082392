import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ResultadoEvento } from '../model/resultado-evento';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { ResultadoEventoFiltro } from '../filter/resultado-evento.filter';

@Injectable()
export class ResultadoEventoService {

    private resultado_evento_url_api = `${environment.api_url}/resultado-evento`;
    public upload_pdf_api_url = `${environment.api_url}/file/upload-pd`;

    constructor(private http: HttpClient){}

    public inserir(resultadoEvento: ResultadoEvento) : Observable<ResultadoEvento> {
        return this.http.post<ResultadoEvento>(`${this.resultado_evento_url_api}`, resultadoEvento);
    }

    public buscaPorId(id: number) : Observable<ResultadoEvento> {
        return this.http.get<ResultadoEvento>(`${this.resultado_evento_url_api}/${id}`);
    }

    public buscaPorFiltro(filtro: ResultadoEventoFiltro) : Observable<any> {
        return this.http.post<any>(`${this.resultado_evento_url_api}/filtro`, filtro);
    }

    public alterar(id: number, resultadoEvento: ResultadoEvento): Observable<ResultadoEvento> {
        return this.http.put<ResultadoEvento>(`${this.resultado_evento_url_api}/${id}`, resultadoEvento);
    }

    public excluir(id: number) : Observable<void>{
        return this.http.delete<void>(`${this.resultado_evento_url_api}/${id}`);
    }

    public buscaPorIdEvento(id: number) : Observable<ResultadoEvento[]> {
        return this.http.post<ResultadoEvento[]>(`${this.resultado_evento_url_api}/por-evento/`, {id: id});
    }
}