import { Component, Output, EventEmitter, OnInit} from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';

import { Raca } from '../../../model/raca';
import { UtilService } from '../../../util/util.service';
import { RacaService } from '../../../service/raca.service';

@Component({
    selector: 'app-cadastro-raca-modal',
    templateUrl: 'cadastro-raca-modal.component.html',
    styleUrls: ['./cadastro-raca-modal.style.css']
})
export class CadastroRacaModalComponent implements OnInit {

    public raca: Raca = new Raca();
    public formCadastro: FormGroup;
    @Output() racaEmitter: EventEmitter<boolean> = new EventEmitter<boolean>();

    ngOnInit(){}

    constructor(private formBuilder: FormBuilder,
                private utilService: UtilService,
                private racaService: RacaService){

        this.formCadastro = this.formBuilder.group({
            descricao: [this.raca.descricao, [
                Validators.required,
                Validators.minLength(1),
                Validators.maxLength(200)
            ]],
            abreviacao: [this.raca.abreviacao, [
                Validators.required,
                Validators.minLength(1),
                Validators.maxLength(5)
            ]],
        });

    }

    public insereRaca(): void {

        if (this.formCadastro.invalid) {
            this.utilService.showErrors(this.formCadastro);
            this.utilService.destacarErros(this.formCadastro);
            return;
        }

        this.utilService.mensagemConfirmacao("Confirmar cadastro de Raça?", (result) => {
            if (result) {                
                this.racaService.insere(this.raca).subscribe( res => {
                    this.racaEmitter.emit(true);
                    this.utilService.mensagemSucesso("Raça cadastrada com sucesso!");
                    this.raca = new Raca();
                    this.formCadastro.markAsUntouched();
                }, (erro) => this.utilService.mensagemErro(erro.error.mensagem))
            }
        });  
    }    

}