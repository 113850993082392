import { OnInit, Component, Output, EventEmitter, Input, OnDestroy } from '@angular/core';
import { PubSub } from 'pubsub-js';

import { ParametrosBusca } from '../../../model/parametros_busca';

@Component({
    selector: 'pagination-organizador',
    templateUrl: './pagination-organizador.component.html',
    styleUrls: ['./pagination-organizador.style.css', '../organizador.style.css']
})
export class PaginationOrganizadorComponent implements OnInit, OnDestroy {

    @Input() parametrosBusca: ParametrosBusca;
    @Output() paramEmitter: EventEmitter<ParametrosBusca> = new EventEmitter<ParametrosBusca>();

    public podeVoltar: boolean;
    public podeProsseguir: boolean;
    
    private botoesEsquerda: any[] = new Array(3);
    private botaoCentro: any;
    private botoesDireita: any[] = new Array(3);

    public botoes: any[] = new Array();

    private paginasDireita: number;
    private paginasEsquerda: number;

    private paginasLaterais = 2;

    constructor() {}

    ngOnInit(): void {
        PubSub.subscribe('PARAMETROS', (msg, data) => {
            this.parametrosBusca = data;
            //this.iniciarBtnArrays();
            this.geraBotoesPaginacao();
            this.configurarPagination();
        });
    }

    ngOnDestroy(): void {
        PubSub.unsubscribe('PARAMETROS');
    }

    private geraBotoesPaginacao() {
        let paginas = this.parametrosBusca.totalPaginas;
        for(let i=0; i<paginas; i++) {
            this.botoes[i] = i+1;
        }
    }

    private configurarPagination() {    
        this.podeIrParaPaginaAnterior();
        this.podeIrParaProximaPagina();
    }

    private mudarInfoBtns(direcao) {
        //ver a direcao
        if (direcao == 'direita') {

        } else if (direcao == 'esquerda') {

        } else if (direcao == 'primeira') {
 
        } else if (direcao == 'ultima') {

        } else {
            
        }
    }

    private podeIrParaPaginaAnterior(): void {
        this.podeVoltar = this.parametrosBusca.pagina != 1;
    }

    private podeIrParaProximaPagina(): void {
        this.podeProsseguir = (this.parametrosBusca.pagina != this.parametrosBusca.totalPaginas) &&
                               (this.parametrosBusca.totalPaginas > 0);
    }

    public irParaPrimeiraPagina(): void {
        this.parametrosBusca.pagina = 1;
        this.parametrosBusca.offset = 0;
        this.mudarInfoBtns('primeira');
        this.configurarPagination();
        this.paramEmitter.emit(this.parametrosBusca);
    }

    public irParaPaginaAnterior(): void {
        this.parametrosBusca.pagina--;
        this.parametrosBusca.offset -= this.parametrosBusca.limit;
        this.mudarInfoBtns('esquerda');
        this.configurarPagination();
        this.paramEmitter.emit(this.parametrosBusca);
    }

    public pularParaPaginaEspecifica(pagina: number): void {
        this.parametrosBusca.pagina = pagina;
        this.parametrosBusca.offset = (pagina * this.parametrosBusca.limit) - this.parametrosBusca.limit;
        this.mudarInfoBtns(pagina);
        this.configurarPagination();
        this.paramEmitter.emit(this.parametrosBusca);
    }

    public irParaProximaPagina(): void {
        this.parametrosBusca.pagina++;
        this.parametrosBusca.offset += this.parametrosBusca.limit;
        this.mudarInfoBtns('direita');
        this.configurarPagination();
        this.paramEmitter.emit(this.parametrosBusca);
    }

    public irParaUltimaPagina(): void {
        this.parametrosBusca.pagina = this.parametrosBusca.totalPaginas;
        this.parametrosBusca.offset = (this.parametrosBusca.totalPaginas * this.parametrosBusca.limit) - this.parametrosBusca.limit;
        this.mudarInfoBtns('ultima');
        this.configurarPagination();
        this.paramEmitter.emit(this.parametrosBusca);
    }

    private exibeBotao(botao) {
        let exibe = false;
        if(botao <= this.parametrosBusca.pagina + this.paginasLaterais 
            && botao >= this.parametrosBusca.pagina - this.paginasLaterais) {
            exibe = true;
        } else if((this.parametrosBusca.pagina - this.paginasLaterais) <= 0 && (botao == 4 || botao == 5)) {
            exibe = true;
        } else if((this.parametrosBusca.pagina + this.paginasLaterais) >= this.parametrosBusca.totalPaginas 
            && (botao >= (this.parametrosBusca.totalPaginas - this.paginasLaterais*2))) {
            exibe = true;
        }

        return exibe;
    }
}