import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { environment } from '../../environments/environment';
import { Inscricao } from '../model/inscricao';
import { InscricaoCompetidor } from '../model/inscricao_competidor';
import { Usuario } from '../model/usuario';
import { UsuarioSemCadastroInscricaoCompetidor } from '../model/usuario-sem-cadastro-inscricao-competidor';
import { Prova } from '../model/prova';

@Injectable()
export class InscricaoService {

    private inscricao_api_url = `${environment.api_url}/inscricoes`;

    private competidores : Usuario[] = [];

    constructor(private http: HttpClient) {}

    public adicionarCompetidor(competidor : Usuario){
        this.competidores.push(competidor);
    }

    public removerCompetidor(competidor : Usuario){
        this.competidores.splice(this.competidores.indexOf(competidor), 1);
    }

    public getCompetidores() : Usuario[]{
        return this.competidores;
    }

    public removerCompetidores(){
        if(this.competidores.length > 0){
            this.competidores = [];
        }
    }

    public buscaTodos(): Observable<Inscricao[]> {
        return this.http.get<Inscricao[]>(`${this.inscricao_api_url}`);
    }

    public buscaPorId(id: number): Observable<Inscricao> {
        return this.http.get<Inscricao>(`${this.inscricao_api_url}/${id}`);
    }

    public insere(provas: Prova[], competidores: any, id_cadastrador: number): Observable<any[]> {
        return this.http.post<any[]>(`${this.inscricao_api_url}`, {
            provas: JSON.stringify(provas),
            competidores: JSON.stringify(competidores),
            id_cadastrador: JSON.stringify(id_cadastrador)
        });
    }

    public insereVerificandoProva(inscricao : Inscricao, competidores) : Observable<any> {
        return this.http.post<Inscricao>(`${this.inscricao_api_url}-verifica-prova`,inscricao, {
            params : {
                competidores : JSON.stringify(competidores)
            }
        });
    }

    public altera(id: number, inscricao: Inscricao): Observable<Inscricao> {
        return this.http.put<Inscricao>(`${this.inscricao_api_url}/alterar/${id}`, inscricao);
    }
    
    public editarInscricao(inscricao: Inscricao, inscricaoCompetidor : InscricaoCompetidor[]):
    Observable<Inscricao> {
        return this.http.put<Inscricao>(`${this.inscricao_api_url}/editar-inscricao`,
        {inscricao, inscricaoCompetidor});
    }

    public editarInscricaoSemCadastro(inscricao: Inscricao,
    inscricaoCompetidor : InscricaoCompetidor[], competidores : any[],
    usuarioSemCadastroInscricaoCompetidores : UsuarioSemCadastroInscricaoCompetidor[]) : Observable<Inscricao> {
        return this.http.put<Inscricao>(`${this.inscricao_api_url}/editar-inscricao-sem-cadastro`,
        {inscricao, inscricaoCompetidor, competidores, usuarioSemCadastroInscricaoCompetidores});
    }

    public deleta(id: number): Observable<Inscricao> {
        return this.http.put<Inscricao>(`${this.inscricao_api_url}/${id}`, undefined);
    }

    public buscaInscricoesPorEvento(id : number) : Observable<any>{
        return this.http.get<any>(`${this.inscricao_api_url}-evento/${id}`);
    } 

    public buscarProvaEInscritosPorEvento(id : number) : Observable<any>{
        return this.http.get<any>(`${this.inscricao_api_url}-evento/${id}`);
    } 

    public buscaInscritosPorIdProva(id_prova : number) : Observable<any> {
        return this.http.get<any>(`${this.inscricao_api_url}/inscritos-por-prova/${id_prova}`);
    }

    public buscaPorEventoComFiltro(id : number, limit: number, offset : number, filtro : any) : Observable<any>{
        
        return this.http.get<any>(`${this.inscricao_api_url}/provas-inscritos-por-evento/${id}`,{
            params : {
                'offset' : offset ? offset.toString() : "",
                'limit' : limit ? limit.toString(): "",
                'filtro' : JSON.stringify(filtro)
            }
        });
    }

    public buscaTotalDeProvasPorEvento(id : number) : Observable<any>{
        return this.http.get<any>(`${this.inscricao_api_url}/total-provas-por-evento/${id}`);
    }

    public buscaCadastradorInscricaoPorIdProva(id_prova : number) : Observable<any>{
        return this.http.get<any>(`${this.inscricao_api_url}/cadastradores-por-prova/${id_prova}`);
    }

    public buscaInscritosPorIdProvaComFiltro(prova : any, filtro) : Observable<any>{
       return this.http.get<any>(`${this.inscricao_api_url}/inscritos-por-prova-com-filtro/${prova.id_prova}`, {
            params : {
                'filtro' : JSON.stringify({
                    'pagamento' : filtro.pagamento,
                    'id_cadastrador' : filtro.id_cadastrador,
                    'nao_exigir_cadastro' : prova.divisao.nao_exigir_cadastro,
                    'tipo_inscricao' : filtro.tipo_inscricao})
            }
        });
    }

    public efetuarPagamento(listaInscricaoCompetidor : InscricaoCompetidor[]) : Observable<InscricaoCompetidor[]>{
        return this.http.put<InscricaoCompetidor[]>(`${this.inscricao_api_url}/efetuar-pagamento`,
         listaInscricaoCompetidor);
    }

    public buscaEditarInscricao(id_inscricao : number) : Observable<any>{
        return this.http.get<any>(`${this.inscricao_api_url}/editar/${id_inscricao}`)
    }
    
    public buscaUltimaPorIdCompetidor(id : number) : Observable<Inscricao>{
        return this.http.get<Inscricao>(`${this.inscricao_api_url}/busca-ultima/${id}`);
    }

    public buscaPorIdProvaDraw(id: number, filtro : any) : Observable<any[]>{
        return this.http.get<any[]>(`${this.inscricao_api_url}/busca-por-prova-draw/${id}`,{
            params : {
                filtro : JSON.stringify(filtro)
            }
        })
    }

    public buscaQtdInscricaoProvas(id: any ) : Observable<any>{
        return this.http.get<any>(`${this.inscricao_api_url}/busca-qtd-inscritos-prova/${id}`);
    }
    
}