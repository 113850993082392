import { Component, OnInit, Output, EventEmitter, OnDestroy, Input, Inject } from '@angular/core';
import { Subject } from 'rxjs';
import { CampeonatoService } from '../../../../service/campeonato.service';
import { Campeonato } from '../../../../model/campeonato';
import { ParametrosBusca } from '../../../../model/parametros_busca';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { UtilService } from '../../../../util/util.service';
import { environment } from '../../../../../environments/environment';
import { CampeonatoBuscaService } from '../../campeonatosBusca.service';
import { ActivatedRoute } from '@angular/router';

declare var $: any;

@Component({
    selector: 'search-form-campeonatos',
    templateUrl: './search-form-campeonatos.component.html',
    styleUrls: ['./search-form-campeonatos.component.css']
})
export class SearchFormCampeonatosComponent implements OnInit, OnDestroy {
    
    public filtro: {
        nome: string,
        data_inicial: Date,
        data_final: Date,
        campeonato_finalizado: boolean
    } = {
        nome: null,
        data_inicial: null,
        data_final: null,
        campeonato_finalizado: null
    };
    public formFiltro: FormGroup;

    @Output() onTyping = new EventEmitter<Campeonato[]>();
    @Input() options: ParametrosBusca;

    debounce: Subject<string> = new Subject<string>();
    debounceData: Subject<string> = new Subject<string>();

    constructor(private service: CampeonatoService,
        @Inject(FormBuilder) private formBuilder: FormBuilder,
        public utilService: UtilService,
        private campeonatoBuscaService: CampeonatoBuscaService,
        private activatedRoute: ActivatedRoute,) { 

        this.formFiltro = formBuilder.group({
            nome: [this.filtro.nome, [
              Validators.maxLength(50)
            ]],
            data_inicial: [this.filtro.data_inicial, [
              Validators.maxLength(50)
            ]],
            data_final: [this.filtro.data_final, [
              Validators.maxLength(50)
            ]],
            campeonato_finalizado: [this.filtro.campeonato_finalizado]
        });
    }

    ngOnInit(): void {

        this.utilService.montaDatePickerFiltro("data_inicial");
        this.utilService.montaDatePickerFiltro("data_final");

        this.debounceData
            .subscribe(data => {
                this.service.buscaTodos(null, data)
                    .subscribe(campeonatos => {
                        this.onTyping.emit(campeonatos);
                        this.campeonatoBuscaService.campeonatos = campeonatos;
                    })
            })

            this.filtrar();
    }

    ngOnDestroy(): void {
        this.debounce.unsubscribe();
    }
    

    public filtrar(): void {
        let filtro
        filtro = this.filtro;
        
        let check_ativo = $('#campeonato_ativo').is(":checked");
        let check_finalizado = $('#campeonato_finalizado').is(":checked");

        if(check_ativo && filtro.campeonato_finalizado) {
            filtro.campeonato_finalizado = null;
        } else if (check_ativo) {
            filtro.campeonato_finalizado = false;
        } else if (!check_ativo && !filtro.campeonato_finalizado) {
            filtro.campeonato_finalizado = null;
        }
        
        this.campeonatoBuscaService.filtro = filtro;
        
        this.activatedRoute.queryParams.subscribe(params => {
            this.options.offset = params['offset'];
            this.options.limit = params['limit'];

            this.service.buscaTodosFiltro({
                "filtro": filtro
            }, this.options).subscribe(campeonatos => {
                if (campeonatos) {
                    this.getImgCampeonato(campeonatos);
                    this.getThumbnailCampeonato(campeonatos);
                    //campeonatos.forEach(campeonato => {
                    //    this.getThumbnailExibicao(campeonato, campeonato.imagem_exibicao);
                    //})
                    this.campeonatoBuscaService.campeonatos = campeonatos;
                }
                this.onTyping.emit(campeonatos);
                if(filtro.campeonato_finalizado) {
                }
            })
        })

        /*this.service.buscaTodosFiltro({
            "filtro": filtro
        }).subscribe(campeonatos => {
            if (campeonatos) {
                this.getImgCampeonato(campeonatos);
                this.getThumbnailCampeonato(campeonatos);
                //campeonatos.forEach(campeonato => {
                //    this.getThumbnailExibicao(campeonato, campeonato.imagem_exibicao);
                //})
                this.campeonatoBuscaService.campeonatos = campeonatos;
            }
            this.onTyping.emit(campeonatos);
            if(filtro.campeonato_finalizado) {
            }
        })*/
    }

    private getThumbnailExibicao(campeonato, path){
        if(path){
            campeonato.thumbnail = `${environment.api_url}/file/thumbnailCampeonato/${path}`;
        }
    }
    
    private getThumbnailCampeonato(campeonatos: Campeonato[]){
        campeonatos.forEach(campeonato => {
            campeonato.thumbnail =  this.utilService.buscaThumbnail(campeonato);
        });
    }
    
    private getImgCampeonato(campeonatos: Campeonato[]){
        campeonatos.forEach(campeonato => {
            campeonato.arquivo_imagem =  this.utilService.buscaImagem(campeonato);
        });
    }

    public limpar(): void {
        /*this.filtro = {
            nome: null,
            data_inicial: null,
            data_final: null,
            campeonato_finalizado: null
        };
        $( "#campeonato_ativo" ).prop( "checked", false );
        this.service.buscaTodos().subscribe(campeonatos => {
            this.onTyping.emit(campeonatos)
        })*/
        this.activatedRoute.queryParams.subscribe(params => {
            this.options.offset = params['offset'];
            this.options.limit = params['limit'];

            this.service.buscaPaginado(this.options).subscribe(campeonatos => {
                if (campeonatos) {
                    this.getImgCampeonato(campeonatos);
                    this.getThumbnailCampeonato(campeonatos);
                    //campeonatos.forEach(campeonato => {
                    //    this.getThumbnailExibicao(campeonato, campeonato.imagem_exibicao);
                    //})
                    this.campeonatoBuscaService.campeonatos = campeonatos;
                }
                this.onTyping.emit(campeonatos);
            })
        })
    }

}