import { Component, OnInit, Inject, ElementRef, ViewChild } from '@angular/core';
import { UtilService } from '../../../util/util.service';
import { UsuarioService } from '../../../service/usuario.service';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { Usuario } from '../../../model/usuario';
import { Subject } from 'rxjs';
import { Router, ActivatedRoute } from '@angular/router';
import { Perfil } from '../../../model/perfil';
import { PerfilService } from '../../../service/perfil.service';
import { InscricaoService } from '../../../service/inscricao.service';
import { Inscricao } from '../../../model/inscricao';
import * as cep from 'cep-promise'
import flatpickr from 'flatpickr';
import { Portuguese } from 'flatpickr/dist/l10n/pt';

declare var $: any;
declare var mask: any;
declare var UIkit: any;
@Component({
  selector: 'app-editar-usuario',
  templateUrl: './editar-usuario.component.html',
  styleUrls: ['./editar-usuario.component.css']
})
export class EditarUsuarioComponent implements OnInit {

  public titulo_pagina: string = 'Editar Usuário';
  public formUsuario: FormGroup;
  private usuarios: Usuario[] = [];
  private usuarioInit: Usuario;
  public usuario: Usuario = new Usuario();
  private valueUsuarios = '';
  public numPaginas: number = 0;
  public paginas = [];
  public todosUs: Usuario[] = [];
  public ultimaInscricao : Inscricao;
  private totalUsuarios: Usuario[] = []
  private debounceCompetidores: Subject<string> = new Subject<string>();
  private usuarioPaginacao: {
    limit: number,
    offset: number,
    pagina: number
  } = {
    limit: null,
    offset: null,
    pagina: null
  }
  public filtro: {
    nome: string,
    cpf: string
  } = {
    nome: '',
    cpf: ''
  }
  public perfis : Perfil[] = [];
  public spinner : boolean = false;  
  mostrarSenha: boolean = false;
  @ViewChild('senha') inputSenha: ElementRef;
  public erros = [];
  public senhaValida : boolean = false;
  public email_duplicado: boolean = false;

  constructor(@Inject(UtilService) public utilService: UtilService,
              private usuarioService: UsuarioService,
              @Inject(FormBuilder) private formBuilder: FormBuilder,
              private router: Router,
              @Inject(ActivatedRoute) private route: ActivatedRoute,
              private perfilService : PerfilService,
              private inscricaoService : InscricaoService) { }

  ngOnInit() {
    let id = parseInt(this.route.snapshot.queryParamMap.get("id_usuario"));
    if(!isNaN(id)){
      this.usuario.id_usuario = id;
      this.buscaUsuario();
      this.buscaPerfil();
      $('#login').mask('000.000.000-00');
    }else{
      this.utilService.mensagemErro("Usuário não foi encontrado!");
    }
  }

  public buscaPerfil(){
    this.perfilService.buscaTodos().subscribe(perfil => {
      this.perfis = perfil;
    });
  }  

  public buscaUsuario(){
  this.usuarioService.buscaPorId(this.usuario.id_usuario)
    .subscribe(retorno => {
      this.usuario = retorno;
      this.usuario.data_nascimento = new Date(this.usuario.data_nascimento).toLocaleDateString();
      this.utilService.montaDatePicker('data_nascimento', this.usuario.data_nascimento);
      this.initForm();
      if(retorno.competidor){
        this.buscaUtilmaInscricao();
      }
      $("#inputUsuario").val("");
    });
  }

  public initForm() : void{
    this.formUsuario = this.formBuilder.group({
      nome: [this.usuario.nome, [
        Validators.required,
        Validators.minLength(5),
        Validators.maxLength(100)
      ]],
      apelido: [this.usuario.apelido, [
        Validators.maxLength(50)
      ]],
      handicap: [this.usuario.handicap, [
        Validators.required,
        Validators.min(0),
        Validators.max(10),
        Validators.maxLength(3),
        Validators.minLength(0)
      ]],
      sexo: [this.usuario.sexo, [
        Validators.required,
        Validators.minLength(1),
        Validators.maxLength(1)
      ]],
      cpf: [this.usuario.cpf, [
        Validators.required,
        Validators.minLength(14),
        Validators.maxLength(14)
      ]],
      email:[ this.usuario.email, [
        Validators.required,
        Validators.pattern("^([0-9a-zA-Z]+([_.-]?[0-9a-zA-Z]+)*@[0-9a-zA-Z]+[0-9,a-z,A-Z,.,-]*(.){1}[a-zA-Z]{2,4})+$")
      ]],
      telefone: [this.usuario.telefone, [
        Validators.required,
        Validators.minLength(14),
        Validators.maxLength(15)
      ]],
      competidor: [this.usuario.competidor],
      ativo: [this.usuario.ativo],
      login: [this.usuario.login,[
        Validators.required,
        Validators.minLength(5),
        Validators.maxLength(50)
      ]],
      logradouro: [this.usuario.logradouro, [
        Validators.required,
        Validators.minLength(3),
        Validators.maxLength(50)
      ]],
      numero: [this.usuario.numero, [
        Validators.required,
        Validators.maxLength(50)
      ]],
      cidade: [this.usuario.cidade, [
        Validators.required,
        Validators.minLength(3),
        Validators.maxLength(50)
      ]],
      bairro: [this.usuario.bairro, [
        Validators.required,
        Validators.minLength(3),
        Validators.maxLength(50)
      ]],
      cep: [this.usuario.cep, [
        Validators.required,
        Validators.minLength(9),
        Validators.maxLength(9)
      ]],
      estado: [this.usuario.estado, [
        Validators.required,
        Validators.minLength(2),
        Validators.maxLength(50)
      ]],
      rg: [this.usuario.rg, [
        Validators.required,
        Validators.minLength(5),
        Validators.maxLength(15)
      ]],
      data_nascimento: [this.usuario.data_nascimento, [
        Validators.required,
        Validators.minLength(10),
        Validators.maxLength(10)
      ]]
    });
  }

  public buscaUtilmaInscricao() : void{
    this.inscricaoService.buscaUltimaPorIdCompetidor(this.usuario.id_usuario)
    .subscribe(inscricao => this.ultimaInscricao = inscricao);
  }

  public selecionaPerfil(id_perfil){
    this.usuario.id_perfil = Number(id_perfil);
  }

  public salvar(){
    if(this.formUsuario.valid){
      this.utilService.mensagemConfirmacao("Deseja realmente editar o usuario", (resp) => {
        if (resp) {
          this.pegaForm();  
          this.usuarioService.put(this.usuario.id_usuario, this.usuario)
            .subscribe(retorno => {
              if (retorno) {
                this.utilService.mensagemSucesso("Alteração efetuada com sucesso.", () => { this.voltar() });
              }
            }, (error) => {this.utilService.mensagemErro('Erro - Não foi possivel alterar o usuário!')});
        }
      });
    }else{
      this.utilService.showErrors(this.formUsuario);
    }
  }

  public voltar(){
    this.utilService.irPara(this.router, '/comum/lista-usuarios');
  }

  public pegaForm() : void{
    this.usuario.nome = this.formUsuario.get('nome').value;
    this.usuario.apelido = this.formUsuario.get('apelido').value;
    this.usuario.email = this.formUsuario.get('email').value;
    this.usuario.handicap = Number(this.formUsuario.get('handicap').value);
    this.usuario.sexo = this.formUsuario.get('sexo').value;
    this.usuario.telefone = this.formUsuario.get('telefone').value;
    this.usuario.cpf = this.formUsuario.get('cpf').value;
    this.usuario.ativo = this.formUsuario.get('ativo').value;
  }

  public changeShowPassword() : void{
    this.mostrarSenha = this.mostrarSenha == true ? false : true;
    this.inputSenha.nativeElement.type = this.mostrarSenha ? 'text' : 'password';
  }

  verificaEmail() {
    this.usuarioService.validarEmail(this.usuario.email).subscribe(retorno => {
      this.email_duplicado = retorno;
    });
  }

  public validaSenha() : boolean{
    this.erros = [];
    let retorno = true;
    if(!this.usuario.senha || this.usuario.senha.length <= 0)
      this.erros.push("Senha obrigatório");
    if(this.usuario.senha && this.usuario.senha.length > 100)
      this.erros.push("senha passou máximo de " + 100 + " caracteres");

    if(this.erros.length > 0){
      retorno = false;
    }
    this.senhaValida = retorno;
    return retorno;
  }

  public alterarSenha(){
    this.utilService.mensagemConfirmacao("Deseja realmente alterar a senha?", res => {
      if(res){
        this.usuarioService.alterarSenha(this.usuario.id_usuario, this.usuario.senha)
        .subscribe(retorno => {
          this.utilService.mensagemSucesso("Senha alterar com sucesso!");
          UIkit.modal('#modal-senha').hide();
        }, erro =>{ 
          this.utilService.mensagemErro(erro.error.mensagem);
          UIkit.modal('#modal-senha').hide();
        })
      }
    });
  }

  public buscaCep() {
    if ((this.usuario.cep != null) && (this.usuario.cep.length >= 9)) {
      cep(this.usuario.cep).then((data) => {
        this.usuario.cidade = data.city;
        this.usuario.logradouro = data.street;
        this.usuario.bairro = data.neighborhood;
        this.usuario.estado = data.state;
      }).catch(console.log);
    } else {
      this.usuario.cidade = "";
      this.usuario.logradouro = "";
      this.usuario.bairro = "";
      this.usuario.estado = "";
    }
  }
}
