import { Component, OnInit } from '@angular/core';
import { ResultadoCampeonato } from '../../../model/resultado-campeonato';
import { Paginacao } from '../../../model/paginacao';
import { ResultadoCampeonatoFiltro } from '../../../filter/resultado-campeonato.filter';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { Campeonato } from '../../../model/campeonato';
import { UtilService } from '../../../util/util.service';
import { ResultadoCampeonatoService } from '../../../service/resultado-campeonato.service';
import { CampeonatoService } from '../../../service/campeonato.service';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-resultado-campeonato-list',
  templateUrl: './resultado-campeonato-list.component.html',
  styleUrls: ['../../comum.style.css', './resultado-campeonato-list.component.css']
})
export class ResultadoCampeonatoListComponent implements OnInit {

  private id_campeonato: number;
  public campeonato: Campeonato;
  public resultadosCampeonato: ResultadoCampeonato[] = [];
  public quantidadeRegistros: number = 0;
  public paginacao: Paginacao = new Paginacao();
  public filtro: ResultadoCampeonatoFiltro = new ResultadoCampeonatoFiltro();
  public numPagina: number = 0;
  public debounceResultadoCampeonato: Subject<string> = new Subject();

  constructor(private utilService: UtilService,
              private resultadoCampeonatoService: ResultadoCampeonatoService,
              private campeonatoService: CampeonatoService,
              private router: Router,
              private activatedRoute: ActivatedRoute) { }

  ngOnInit() {
    this.id_campeonato = this.activatedRoute.snapshot.params.id_campeonato;
    this.initPaginacao();
    if(this.id_campeonato && !isNaN(this.id_campeonato)){
      this.filtro.id_campeonato = this.id_campeonato;
      this.buscaCampeonato();
      this.buscaResultadoCampeonatoFiltro();
      this.initDebounceResultadoEvento();
    }
  }

  private initPaginacao(){
    this.paginacao.limit = 8;
    this.paginacao.offset = 0;
    this.paginacao.pagina = 1;
  }

  private getFiltro(){
    this.filtro.limit = this.paginacao.limit;
    this.filtro.offset = this.paginacao.offset;
    this.filtro.pagina = this.paginacao.pagina;
  }

  private initDebounceResultadoEvento(){
    this.debounceResultadoCampeonato.pipe(debounceTime(400))
    .subscribe(titulo => {
      this.filtro.titulo = titulo;
      this.buscaResultadoCampeonatoFiltro();
    });
  }

  public buscaCampeonato(){
    this.campeonatoService.buscaPorId2(this.id_campeonato)
    .subscribe(
      retorno => this.campeonato = retorno,
      erro => this.mensagemErro(erro.error.mensagem)
    );
  }

  public filtroTitulo(titulo: string){
    this.initPaginacao();
    if(titulo && titulo.length >= 3){
      this.debounceResultadoCampeonato.next(titulo);
    }else{
      this.debounceResultadoCampeonato.next(null);
    }
  }

  public buscaResultadoCampeonatoFiltro(){
    this.getFiltro();
    this.resultadoCampeonatoService.buscaPorFiltro(this.filtro)
    .subscribe(
      retorno => {
        this.quantidadeRegistros = retorno.quantidade;
        this.resultadosCampeonato = retorno.resultadosCampeonato;
        this.getArquivoExibicao(this.resultadosCampeonato);
        this.quantidadePagina();
      },
      erro => this.mensagemErro(erro.error.mensagem)
    );
  }

  private quantidadePagina(){
    let paginas = 0;
    if(this.quantidadeRegistros % this.paginacao.limit == 0){
      paginas = (this.quantidadeRegistros / this.paginacao.limit);
    }else{
      paginas = (this.quantidadeRegistros / this.paginacao.limit) + 1;
    }
    this.numPagina = Math.floor(paginas);
  }

  public getPaginacao(paginacao: Paginacao){
    this.paginacao.limit = paginacao.limit;
    this.paginacao.offset = paginacao.offset;
    this.paginacao.pagina = paginacao.pagina;
    this.buscaResultadoCampeonatoFiltro();
  }

  private getArquivoExibicao(resultadosCampeonato: ResultadoCampeonato[]){
    resultadosCampeonato.forEach(resultado => resultado.arquivo_exibicao = this.utilService.buscaPdf(resultado));
  }

  private mensagemErro(mensagem: string){
    this.utilService.mensagemErro("Erro - " + mensagem);
  }

  public excluir(id_resultado_campeonato: number){
    this.resultadoCampeonatoService.excluir(id_resultado_campeonato)
    .subscribe(
      () => {
        this.utilService.mensagemSucesso('Operação realizada com sucesso!');
        this.buscaResultadoCampeonatoFiltro();
      },
      erro => this.mensagemErro(erro.error.mensagem)
    )
  }

  public editar(id_resultado_campeonato: number){
    this.router.navigate(['comum/campeonato/' + this.id_campeonato + '/editar-resultado/' + id_resultado_campeonato]);
  }
}
