import { Component, OnInit, Input, Inject } from "@angular/core";
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { UtilService } from '../../util/util.service';
import { EmailService } from '../../service/email.service';
import { Email } from '../../model/email';

@Component({
    templateUrl: './contato.component.html',
    styleUrls: ['./contato.style.css']
})
export class ContatoComponent {

    email: Email = new Email();

    public formContato: FormGroup;

    constructor(private formBuilder: FormBuilder,
                private utilService: UtilService,
                private service: EmailService) {

        this.formContato = this.formBuilder.group({
            nome_remetente: ['',
                [
                    Validators.required
                ]
            ],
            email_remetente: ['',
                [
                    Validators.required
                ]                
            ],
            mensagem: ['',
                [
                    Validators.required
                ]               
            ]
        });
    }

    envia() {
        if (this.formContato.invalid) {
            this.utilService.showErrors(this.formContato);
            return;
        }
        
        this.utilService.mensagemConfirmacao("Enviar email?", (result) => {
            if (result) {
                this.service.mandaEmailContato(this.email).subscribe(retorno => {
                    this.utilService.mensagemSucesso('Email enviado com sucesso!');
                });
            } 
        });
    }
}