import { RegraAssociacao } from './regra-associacao';

export class AssociacaoCompetidor{
    id_associacao_competidor : number;
    id_usuario : number;
    id_evento : number;
    id_cadastrador : number;
    id_regra_associacao : number;
    data_associacao : string;
    data_modificacao : string;
    data_validacao : string;
    associacao_competidor_paga : boolean;
    regra_associacao : RegraAssociacao;
    nome_competidor : string;
    nome_cadastrador : string;
    email : string;
    telefone : string;
    titulo_evento : string;
}