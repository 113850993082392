import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from '../../environments/environment';
import { Observable } from 'rxjs';
import { Raca } from '../model/raca';
import { RacaFilter } from '../filter/raca.filter';

@Injectable()
export class RacaService {

    private raca_api_url = `${environment.api_url}/racas`;

    constructor(private http: HttpClient){}

    public buscaTodos(): Observable<Raca[]> {
        return this.http.get<Raca[]>(`${this.raca_api_url}`);
    }

    public buscaFiltro(filtro: RacaFilter): Observable<any> {
        return this.http.get<any>(`${this.raca_api_url}-filtro?filtro=${JSON.stringify(filtro)}`);
    }
    
    public buscaPorId(id: number): Observable<Raca> {
        return this.http.get<Raca>(`${this.raca_api_url}/${id}`);
    }

    public insere(raca: Raca): Observable<any> {
        return this.http.post<any>(`${this.raca_api_url}`, raca);
    }

    public altera(id: number, raca: Raca): Observable<Raca> {
        return this.http.put<Raca>(`${this.raca_api_url}/${id}`, raca);
    }

    public deleta(id: number): Observable<any> {
        return this.http.delete<any>(`${this.raca_api_url}/${id}`);
    }

}