import { OnInit, Component, Input, Output, EventEmitter, Inject } from '@angular/core';

import { Campeonato } from '../../../../model/campeonato';
import { Evento } from '../../../../model/evento';
import { Prova } from '../../../../model/prova';
import { Regra } from '../../../../model/regra';
import { UtilService } from '../../../../util/util.service';
import { ProvaService } from '../../../../service/prova.service';

declare var $: any;

@Component({
    selector: 'informacoes-prova',
    templateUrl: './informacoes-prova.component.html',
    styleUrls: ['./informacoes-prova.style.css', './../../inscricoes.style.css']
})
export class InformacoesProvaComponent implements OnInit {

    @Input() campeonato: Campeonato;
    @Input() evento: Evento;
    @Input() prova: Prova;
    @Input() somenteVisualizacao: boolean;
    @Input() listaDeProvasSelecionadas: Prova[];

    private provaSelecionada: Prova;

    public regrasComp1: Regra[] = [];
    public regrasComp2: Regra[] = [];
    public regrasComp3: Regra[] = [];

    @Output() provaSelecionadaEmitter: EventEmitter<Prova> = new EventEmitter<Prova>();

    constructor(@Inject(UtilService) public util: UtilService,
                        public provaService : ProvaService ) { }
    ngOnInit(): void {
       let tipo = this.prova.tipo_prova;
       if(this.prova.divisao.regras.length > 0){
           if(tipo == 1){
            this.regrasComp1 = this.prova.divisao.regras;
           }else{
               
                if(tipo == 2 ){
                    this.regraTipo2();
                }

                if(tipo == 3){
                    this.regraTipo3();
                }
           }
       }
    }

    regraTipo2(){
        let regras = this.prova.divisao.regras;
        regras.forEach(r => {
            if((r.numero_competidor == 1) || (r.numero_competidor == null) ){
                this.regrasComp1.push(r);
            }else{
                this.regrasComp2.push(r);
            }
        });
    }

    regraTipo3(){
        let regras = this.prova.divisao.regras;

        regras.forEach(r => {
            if((r.numero_competidor == 1) || (r.numero_competidor == null) ){
                this.regrasComp1.push(r);
            }

            if( r.numero_competidor == 2){
                this.regrasComp2.push(r);
            }

            if( r.numero_competidor == 3){
                this.regrasComp3.push(r);
            }
        });
    }

    private adicionarProva(prova: Prova): void {

        $(`#checkboxInput${prova.id_prova}`).prop('checked', true);
        this.provaSelecionadaEmitter.emit(prova);
    }

    private removeProva(prova: Prova): void {

        $(`#checkboxInput${prova.id_prova}`).prop('checked', false);
        this.provaSelecionadaEmitter.emit(prova);
    }

    private provaEstaSelecionada(prova: Prova): boolean {
        let retorno = false;
        this.listaDeProvasSelecionadas.forEach(prov => {
            if (prova.id_prova == prov.id_prova) {
                retorno = true;
            }
        });
        return retorno;
    }

    public bloquearOuDesbloquearProva(prova)
    {
        let statusInscricao = prova.inscricao_bloqueada == true ? false : true;
        let mensagem = "Deseja realmente bloquear a prova? ";
        if(!statusInscricao){
            mensagem = "Deseja realmente desbloquear a prova?";
        }

        this.util.mensagemConfirmacao(mensagem, (resp) =>{
            if(resp){
                this.provaService.bloquearInscricao(prova.id_prova, statusInscricao)
                .subscribe(
                    () => {
                        prova.inscricao_bloqueada = statusInscricao;
                        if(statusInscricao){
                            this.removeProva(prova);
                        }
                    },
                    erro => this.util.mensagemErro(erro.error.mensagem)
                );
            }
        });
    }
}