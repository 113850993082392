import { NgModule } from '@angular/core';
import { InscricaoPorProvaComponent } from './inscricao-por-prova.component';
import { CommonModule } from '@angular/common';
import { SelecaoDeProvasPorProvaModule } from './selecao-de-provas/selecao-de-provas-por-prova.module';
import { SelecaoDeCompetidoresPorProvaModule } from './selecao-de-competidores/selecao-de-competidores-por-prova.module';

@NgModule({
    declarations: [InscricaoPorProvaComponent],
    imports: [
        CommonModule,
        SelecaoDeProvasPorProvaModule,
        SelecaoDeCompetidoresPorProvaModule
    ],
    exports: [InscricaoPorProvaComponent]
})
export class InscricaoPorProvaModule {}