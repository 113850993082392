import { OnInit, Component, Input, Inject } from '@angular/core';
import { Router } from '@angular/router';

import { Campeonato } from '../../../../model/campeonato';
import { UtilService } from '../../../../util/util.service';

declare var UIkit: any;

@Component({
    selector: 'informacoes-campeonato',
    templateUrl: './informacoes-campeonato.component.html',
    styleUrls: ['./informacoes-campeonato.style.css']
})
export class InformacoesCampeonatoComponent implements OnInit {

    @Input() campeonato: Campeonato;

    constructor(private utilService: UtilService,
        @Inject(Router) private router: Router) {}

    ngOnInit() {}

    public redireciona() {
        this.utilService.irPara(this.router, `/comum/campeonatos/detalhes/${this.campeonato.id_campeonato}`);        
    }

    public abreImgExpandida() {
        UIkit.modal('#modal-imagem-campeonato').show();
    }
}