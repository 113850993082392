import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';

import { Cavalo } from '../../../model/cavalo';
import { UtilService } from '../../../util/util.service';
import { CavaloService } from '../../../service/cavalo.service';
import { Router, ActivatedRoute } from '@angular/router';
import { UnidadeFederativa } from '../../../model/unidade-federativa';
import { Usuario } from '../../../model/usuario';
import { UsuarioService } from '../../../service/usuario.service';
import { Raca } from '../../../model/raca';
import { UnidadeFederativaService } from '../../../service/unidade-federativa.service';

import {Portuguese} from 'flatpickr/dist/l10n/pt.js';
import flatpickr from 'flatpickr';
import { Subject, fromEvent } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { RacaService } from '../../../service/raca.service';

declare var UIkit: any;
declare var $: any;

@Component({
    selector: 'app-cadastro-cavalo',
    templateUrl: 'cadastro-cavalo.component.html',
    styleUrls: ['./cadastro-cavalo.style.css']
})

export class CadastroCavaloComponent implements OnInit {

    private listaClicada: boolean = false;
    public cavalo: Cavalo = new Cavalo();
    public formCadastro: FormGroup;

    unidadesFederativas: UnidadeFederativa[] = [];
    usuarios: Usuario[] = [];
    racas: Raca[] = [];

    private id_cavalo : number;
    public titulo_pagina : string;

    private debounceProprietarios: Subject<string> = new Subject<string>();
    public valueAutoCompleteProprietario: string = "";
    public proprietariosAutoComplete: Usuario[] = []
    public spinner: boolean = false;

    public autoCompleteProprietario(filtro: string): void {
        this.cavalo.id_proprietario = undefined;
        if (filtro && filtro.length >= 3) {
            this.debounceProprietarios.next(filtro);
            this.setNomeProprietario(filtro);
        } else {
            this.proprietariosAutoComplete = [];
            this.setNomeProprietario(null);
        }
    }
    public selecionaProprietario(proprietario: Usuario): void {
        this.valueAutoCompleteProprietario = `${proprietario.nome}`;
        this.proprietariosAutoComplete = [] as Usuario[];
        this.cavalo.id_proprietario = proprietario.id_usuario;
        this.setNomeProprietario(proprietario.nome);
    }

    private blurAutoComplete(): void {
        this.proprietariosAutoComplete = [] as Usuario[];
        if (!this.cavalo.id_proprietario) {
            this.cavalo.id_proprietario = undefined;
        }
    }

    constructor(private formBuilder: FormBuilder,
                private utilService: UtilService,
                private cavaloService: CavaloService,
                private usuarioService: UsuarioService,
                private unidadeFederativaService: UnidadeFederativaService,
                private router: Router,
                private activatedRoute: ActivatedRoute,
                private racaService : RacaService) {

        this.usuarioService.buscaTodos("").subscribe(usuarios => {
            //console.log('buscando usuario.............................');
            this.usuarios = usuarios;
        })

        this.racas = this.activatedRoute.snapshot.data.racas;

        this.unidadeFederativaService.buscaTodos().subscribe(unidadesFederativas => {
            this.unidadesFederativas = unidadesFederativas;
        });        
    }

    private initForm(){
        this.formCadastro = this.formBuilder.group({
            nome: [this.cavalo.nome, [
                Validators.required,
                Validators.minLength(1),
                Validators.maxLength(255)
            ]],
            nome_proprietario: [this.cavalo.nome_proprietario, [
                Validators.required,
                Validators.minLength(3),
                Validators.maxLength(200)
            ]],
            nascimento: [this.cavalo.nascimento, [
                Validators.required
            ]],
            registro: [this.cavalo.registro, [
                Validators.required
            ]],           
            ativo: [this.cavalo.ativo],
            rsnc: [this.cavalo.rsnc],
            cidade: [this.cavalo.cidade]
        });

        let escopo = this;
        setTimeout(function(){ 
            escopo.montaDatePicker();
            escopo.buscaProprietario();
        }, 1000);
    }

    
    ngOnInit(): void {

        this.id_cavalo = parseInt(this.activatedRoute.snapshot.queryParamMap.get("id_cavalo"));
        if(this.id_cavalo && !isNaN(this.id_cavalo)){
            this.titulo_pagina = 'Editar cavalo';
            this.buscaCavalo();
        }else{
            this.titulo_pagina = 'Cadastro de cavalo';
            this.initForm();
        }
    }
    
    private buscaCavalo(){
        this.cavaloService.buscaPorId(this.id_cavalo).subscribe( cavalo => {
            this.cavalo = cavalo;
            this.valueAutoCompleteProprietario = this.cavalo.nome_proprietario;
            this.initForm();
            if(this.cavalo.proprietario && !this.cavalo.nome_proprietario){
                this.setNomeProprietario(this.cavalo.proprietario.nome);
            }
        })
    }

    private buscaProprietario(){
        this.debounceProprietarios.pipe(debounceTime(300)).subscribe(filtro => {
            this.usuarioService.buscaTodos(filtro).subscribe(proprietarios => {
                //console.log('busca todos proprietario................................................')
                this.proprietariosAutoComplete = proprietarios;
            });
        });
        fromEvent(document.getElementById('inputAutoComplete'), 'blur').pipe(
            debounceTime(100)
        ).subscribe(() => this.blurAutoComplete());
    }

    private montaDatePicker(): void {
        var dataAtual = new Date();
        
        const fp = flatpickr("#data_nascimento",{
            disableMobile: true,
            locale: Portuguese,
            altFormat: 'd/m/Y',
            ariaDateFormat: 'd/m/Y',
            dateFormat: 'd/m/Y',
            maxDate: dataAtual ,
        });
    }

    public insere(): void {
        if (this.formCadastro.invalid) {
            this.utilService.showErrors(this.formCadastro);
            this.utilService.destacarErros(this.formCadastro);
            return;
        }

        if(this.cavalo.id_cavalo){
            this.alterarCavalo();
        }else{
            this.inserirCavalo();
        }
    }

    public inserirCavalo(){
        this.cavalo.site = true;
        this.cavalo.pendente = false;
        this.cavalo.ativo = true;
        this.spinner = true;
        this.cavaloService.insere(this.cavalo).subscribe(res => {
                this.utilService.mensagemSucesso("Cadastro pré aprovado, sujeito a alterações e ou exclusões!");
                this.utilService.irPara(this.router, '/comum/inicio');
                this.spinner = false;
        }, (erro) => {
            this.utilService.mensagemErroComTitulo(erro.titulo, erro.error.mensagem);
            this.spinner = false
        }); 
    }

    public alterarCavalo(){
        this.cavaloService.altera(this.cavalo.id_cavalo, this.cavalo).subscribe(res => {
            this.utilService.mensagemSucesso("Cavalo editado com sucesso!");
            this.utilService.irPara(this.router, '/comum/lista-cavalos')
        });
    }

    public pegaProprietario(event) {
        let id = parseInt(event.target.value);
        this.cavalo.id_proprietario = id;
    }

    public pegaUnidadeFederativa(event) {
        let id = parseInt(event.target.value);
        this.cavalo.id_unidade_federativa = id;
    }

    public pegaSexoSelecionado(event) {
        let sexo = event.target.value;
        this.cavalo.sexo_animal = sexo;
    }

    public pegaRacaSelecionada(event) {
        let id = parseInt(event.target.value);
        this.cavalo.id_raca = id;
    }

    public inserirRaca(eventRaca){
        this.racas.push(eventRaca);
        UIkit.modal('#racaModal').hide();
    }

    public abreModalCadastroRaca() {
        UIkit.modal('#racaModal', { bgClose: false }).show();
    }

    public getNovaRaca(evento){
        if(evento){
            this.getRaca();
            UIkit.modal('#racaModal').hide();
        }
    }

    public getRaca(){
        this.racaService.buscaTodos().subscribe(racas => {
            this.racas = racas;
        });
    }

    public setNomeProprietario(nome : string){
        this.cavalo.nome_proprietario = nome;
        this.formCadastro.controls['nome_proprietario'].setValue(nome);
    }
}