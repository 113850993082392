import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { environment } from '../../environments/environment';
import { RespostaHandicap } from '../model/resposta_handicap';
import { Http } from '@angular/http';

@Injectable()
export class RespostaHandicapService {

  private resposta_handicap_api_url = `${environment.api_url}/resposta-handicap`;

  constructor(private httpClient: HttpClient,
      @Inject(Http) private http: Http) {}

  public buscarTodos(filtro: any): Observable<any> {
    return this.httpClient.post<any>(`${this.resposta_handicap_api_url}/buscar-todos`, filtro);
  }

  public salvar(respostaHandicap: RespostaHandicap) {
    try {
      return this.httpClient.post(`${this.resposta_handicap_api_url}/salvar`, respostaHandicap);
    } catch (error) {
        (error);
      return;
    }
  }

}
