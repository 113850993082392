import { Component, Inject, Output, EventEmitter } from '@angular/core';
import { UtilService } from '../../util/util.service';
import { UsuariosService } from '../usuarios/usuarios.service';
import { PubSub } from 'pubsub-js';
import { Usuario } from '../../model/usuario';
import { UsuarioService } from '../../service/usuario.service';
import { environment } from '../../../environments/environment';
import { UsuarioSemCadastroService } from '../../service/usuario-sem-cadastro.service';

@Component({
    selector: 'app-menu-component',
    templateUrl: './menu.component.html',
    styleUrls: ['./menu.component.css']
})
export class MenuComponent {

    nomeUsuarioLogado: string = '';
    private competidores: Usuario[];
    private idUsuarioLogado: number;

    public qtdVerificacoesPendentes: number;

    constructor(@Inject(UtilService) public util: UtilService,
                private _sharedService: UsuariosService,
                private usuarioService: UsuarioService,
                private usuarioSemCadastroService : UsuarioSemCadastroService) {
        if(this.isLogado) {
            this.nomeUsuarioLogado = this.util.getNome();
        }
    }

    ngOnInit() {
      PubSub.subscribe('QTD', (msg, data) => this.qtdVerificacoesPendentes = data);
      this.verificaQuantidadeDeCompetidoresPendentes();
    }

    isLogado(): boolean {
      return this.util.isLogado();
    }

    connect(): void {
      let source = new EventSource(`${environment.api_url}/usuarios-busca-informacoes`);
      source.onmessage = e => {
        this.qtdVerificacoesPendentes = this.competidores.length;
      };
    }
    
    private verificaQuantidadeDeCompetidoresPendentes(): void {
      this.usuarioService.buscaCompetidoresPendentes().subscribe(usuarios => {
        this.qtdVerificacoesPendentes = usuarios.length;
        this.verificaQuantidadeDeCompetidoresPendentesSemCadastro();
      })
    }

    private verificaQuantidadeDeCompetidoresPendentesSemCadastro() : void {
      this.usuarioSemCadastroService.buscaPendentes()
      .subscribe(usuarios => {
        if(this.qtdVerificacoesPendentes && this.qtdVerificacoesPendentes > 0){
          this.qtdVerificacoesPendentes += usuarios.length;
        }else{
          this.qtdVerificacoesPendentes = usuarios.length;
        }
      });
    }
  
    private verificaNotificacoesDoisDigitos(): boolean {
      return this.qtdVerificacoesPendentes >= 10;
    }
}