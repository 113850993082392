import { NgModule } from '@angular/core';
import { SelecaoDeCompetidoresPorProvaComponent } from './selecao-de-competidores-por-prova.component';
import { CommonModule } from '@angular/common';
import { BuscaDeCompetidoresModule } from '../../../shared/busca-de-competidores/busca-de-competidores.module';
import { ListaDeProvasSelecionadasModule } from '../../../shared/lista-de-provas-selecionadas/lista-de-provas-selecionadas.module';

@NgModule({
    declarations: [SelecaoDeCompetidoresPorProvaComponent],
    imports: [
        CommonModule,
        ListaDeProvasSelecionadasModule,
        BuscaDeCompetidoresModule
    ],
    exports: [SelecaoDeCompetidoresPorProvaComponent]
})
export class SelecaoDeCompetidoresPorProvaModule {}