import { Component, Inject, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';

import { UtilService } from '../../../util/util.service';
import { Campeonato } from '../../../model/campeonato';
import { CampeonatoService } from '../../../service/campeonato.service';
import { Evento } from '../../../model/evento';
import { environment } from '../../../../environments/environment';
import { NoticiaService } from '../../../service/noticia.service';
import { Noticia } from '../../../model/noticia';

import { EventoService } from '../../../service/evento.service';
import { UsuarioService } from '../../../service/usuario.service';
import { Usuario } from '../../../model/usuario';

declare var $: any;
declare var UIkit: any;

@Component({
    selector: 'app-evento-component',
    moduleId: module.id,
    templateUrl: './cadastro-campeonato.component.html',
    styleUrls: ['./cadastro-campeonato.style.css']
})

export class CadastroCampeonatoComponent implements OnInit {

    public campeonato: Campeonato = new Campeonato();
    public formCadastro: FormGroup;
    public criar_noticia: boolean;
    public noticia: Noticia = new Noticia();

    public tituloPagina: string = "Cadastrar campeonato";
    private selectedFile: File = null;
    public imageToShow: any;

    public idCampeonatoReplicado: number;
    private eventosSelecionados: Evento[] = [];

    public organizadores : Usuario[] = [];
    public valueOrganizador = '';
    

    constructor(@Inject(Router) private router: Router,
                @Inject(ActivatedRoute) private route: ActivatedRoute,
                @Inject(FormBuilder) private formBuilder: FormBuilder,
                @Inject(UtilService) private utilService: UtilService,
                @Inject(CampeonatoService) private campeonatoService: CampeonatoService,
                @Inject(NoticiaService) private noticiaService: NoticiaService,
                private eventoService: EventoService,
                private usuarioService : UsuarioService) {

            utilService.mudaTitulo('RSNC Brasil - Cadastro de Campeonato');

            this.formCadastro = formBuilder.group({
            id: [this.campeonato.id_campeonato, []],
            nome: [this.campeonato.nome, [
                Validators.required,
                Validators.minLength(5),
                Validators.maxLength(100)
            ]],
            descricao: [this.campeonato.descricao, [
                Validators.required,
                Validators.minLength(5),
                Validators.maxLength(1000)
            ]],
            data_inicial: [this.campeonato.data_inicial, [
                Validators.required,
                Validators.minLength(10),
                Validators.maxLength(10),
                utilService.validaDataControl
            ]],
            data_final: [this.campeonato.data_final, [
                Validators.required,
                Validators.minLength(10),
                Validators.maxLength(10),
                utilService.validaDataControl
            ]],
            preco_inscricao: [this.campeonato.preco_inscricao, [
                Validators.required,
                Validators.minLength(1),
                Validators.maxLength(7)
            ]],
            id_organizador:[this.campeonato.id_organizador, [
                Validators.required
            ]],
            porcentagem_premiacao: [this.campeonato.porcentagem_premiacao, [
                Validators.required,
                Validators.minLength(1),
                Validators.maxLength(6)
            ]],
            imagem: [this.selectedFile, [
                Validators.required
            ]],
            criar_noticia: [this.criar_noticia],
            maximo_inscricoes : [this.campeonato.maximo_inscricoes,[               
                Validators.minLength(1),
                Validators.maxLength(5)
            ]]
            });
    }

    ngOnInit() {
        this.buscarInformacoesCampeonato();
        this.utilService.montaDatePicker('data_inicial');
        this.utilService.montaDatePicker('data_final');
        this.uploadImagem();
    
    }

    private buscarInformacoesCampeonato(){
        let idCampeonato = parseInt(this.route.snapshot.paramMap.get("id_campeonato"));
        if(idCampeonato){
            this.campeonatoService.buscaPorId2(idCampeonato).subscribe( campeonato => {
               
                this.formCadastro.get('imagem').setErrors(null);
                    this.tituloPagina = 'Editar campeonato';
                    this.campeonato = campeonato;
                    this.valueOrganizador = campeonato.organizador.nome;
                    this.campeonato.imagem_old = this.campeonato.imagem_exibicao;

                    this.campeonato.data_inicial = new Date(this.campeonato.data_inicial).toLocaleDateString();
                    this.campeonato.data_final = new Date(this.campeonato.data_final).toLocaleDateString();
                    this.campeonato.id_organizador = campeonato.id_organizador;
                    this.formCadastro.controls['id_organizador'].setValue(Number(campeonato.id_organizador));
                    this.utilService.montaDatePicker('data_inicial', this.campeonato.data_inicial);
                    this.utilService.montaDatePicker('data_final', this.campeonato.data_final);
                    this.getThumbnailExibicao(this.campeonato);
                },
                err => {
                    this.utilService.irPara(this.router, '/comum/eventos');
                    this.utilService.mensagemErro('Evento não encontrado!');
                });
           
        } else {
            let idCampeonatoReplicado = parseInt(this.route.snapshot.queryParamMap.get('idCampeonatoReplicado'));
            this.idCampeonatoReplicado = idCampeonatoReplicado;
            if (idCampeonatoReplicado) {
                /*this.campeonatoService.buscaPorId(idCampeonatoReplicado).subscribe(campeonato => {
                    this.campeonato = campeonato;
                    this.campeonato.id_campeonato = null;
                    this.campeonato.imagem_old = this.campeonato.imagem_exibicao;

                    this.campeonato.data_inicial = new Date(this.campeonato.data_inicial).toLocaleDateString();
                    this.campeonato.data_final = new Date(this.campeonato.data_final).toLocaleDateString();
                    
                    this.utilService.montaDatePicker('data_inicial', this.campeonato.data_inicial);
                    this.utilService.montaDatePicker('data_final', this.campeonato.data_final);
                })*/

                this.campeonatoService.buscaPorId2(idCampeonatoReplicado).subscribe(campeonato => {
                    this.eventoService.buscaEventosDeUmCampeonato(idCampeonatoReplicado).subscribe(eventos => {
                        this.campeonato.eventos = eventos;
                    });
                    this.campeonato = campeonato;

                    this.campeonato.id_campeonato = null;
                    this.campeonato.imagem_old = this.campeonato.imagem_exibicao;

                    this.campeonato.data_inicial = new Date(this.campeonato.data_inicial).toLocaleDateString();
                    this.campeonato.data_final = new Date(this.campeonato.data_final).toLocaleDateString();
                    
                    this.utilService.montaDatePicker('data_inicial', this.campeonato.data_inicial);
                    this.utilService.montaDatePicker('data_final', this.campeonato.data_final);

                });
            }
        }
    }

    public salvar() {
        this.campeonato.ativo = true;
        if (!this.campeonato.id_campeonato) {
            this.campeonato.campeonato_finalizado = false;
        }
        if (this.formCadastro.invalid) {
            this.utilService.destacarErros(this.formCadastro);
            return false;
        }

        if (this.utilService.converteData(this.campeonato.data_inicial) > this.utilService.converteData(this.campeonato.data_final)) {
            this.utilService.mensagemErro("Data inicial não pode ser maior que a data final");
            return false;
        }

        if(this.campeonato.preco_inscricao == 0) {
            this.utilService.mensagemConfirmacao("Preço da inscrição zerado, deseja prosseguir?", (result) => {
                if (result) {
                    if(this.campeonato.porcentagem_premiacao > 100) {
                        this.utilService.mensagemConfirmacao("Porcentagem de premiação maior que 100%, deseja prosseguir? \n Porcentagem premiação: " + this.campeonato.porcentagem_premiacao + "%", (result) => {
                            if(result) {
                                this.requisicao();
                            }
                        });
                    }
                }
            });
        } else if(this.campeonato.porcentagem_premiacao > 100) {
            this.utilService.mensagemConfirmacao("Porcentagem de premiação maior que 100%, deseja prosseguir? \n Porcentagem premiação: " + this.campeonato.porcentagem_premiacao + "%", (result) => {
                if(result) {
                    this.campeonatoService.salvar(this.campeonato).subscribe(campeonato => {
                        this.requisicao();
                    });
                }
            });
        } else {
            this.requisicao();
        }

    }

    private requisicao() {
        if (this.campeonato.id_campeonato) {
            this.campeonatoService.altera(this.campeonato.id_campeonato, this.campeonato).subscribe(campeonato => {
                this.utilService.mensagemSucesso("Campeonato alterado com sucesso!");
            });
        } else {
            if (this.idCampeonatoReplicado) {
                this.campeonato.id_campeonato = null;
                this.campeonatoService.insere(this.campeonato).subscribe(campeonato => {
                    if (this.eventosSelecionados.length > 0) {
                        this.eventosSelecionados.forEach(evento => {
                            evento.id_evento = null;
                            evento.imagem_exibicao = null;
                            evento.arquivo_imagem = null;
                            evento.imagem_old = null;
                            evento.thumbnail = null;
                            evento.id_campeonato = campeonato.id_campeonato;
                            evento.data_inicial = new Date(evento.data_inicial).toLocaleDateString();
                            evento.data_final = new Date(evento.data_final).toLocaleDateString();
                            evento.data_inicio_inscricoes = new Date(evento.data_inicio_inscricoes).toLocaleDateString();
                            evento.data_fim_inscricoes = new Date(evento.data_fim_inscricoes).toLocaleDateString();
                            this.eventoService.insere(evento).subscribe();
                        })
                    }
                    this.utilService.mensagemSucesso("Campeonato replicado com sucesso!");
                })
            } else {
                this.campeonatoService.insere(this.campeonato).subscribe(campeonato => {

                    if(this.criar_noticia) {
                        this.noticia.titulo = this.campeonato.nome;
                        this.noticia.texto = this.campeonato.descricao;
                        this.noticia.imagem_exibicao = campeonato.imagem_exibicao;
                        this.noticia.id_autor = this.campeonato.id_organizador;
                        this.noticia.id_tipo_noticia = 2;
                        this.noticia.id_tipo_arquivo = 1;
                        this.noticia.id_referencia_noticia = campeonato.id_campeonato;
                        this.noticia.ativa = true;
                        this.noticiaService.criaNoticia(this.noticia).subscribe(noticia => {
                        });
                    }
                    this.utilService.mensagemSucesso("Campeonato criado com sucesso!");
                }, (erro) => {
                    this.utilService.mensagemErro(erro.error.mensagem);
                });
            }
        }
        this.redireciona();
    }

    private redireciona() {
        this.utilService.irPara(this.router, '/comum/campeonatos/', {
            "offset": "0",
            "limit": "5"
        });
    }

    private finalizaCampeonato() {
        if (this.campeonato.campeonato_finalizado) {
            this.campeonato.campeonato_finalizado = false;
        } else {
            this.campeonato.campeonato_finalizado = true;
        }
    }

    private uploadImagem(){
        var bar = <HTMLInputElement>document.getElementById('js-progressbar');
        let uploadURL = this.campeonatoService.upload_imagem_api_url;
        const formCadastro = this;
        
        UIkit.upload('.js-upload', {

            url: uploadURL,
            multiple: false,
            mime: '.jpg, .png, .jpeg, .gif, .bmp, .tif, .tiff|image/*',

            loadStart: function (e) {
                bar.removeAttribute('hidden');
                bar.max = e.total;
                bar.value = e.loaded;
            },

            progress: function (e) {
                bar.max = e.total;
                bar.value = e.loaded;
            },

            loadEnd: function (e) {
                let response = JSON.parse(e.srcElement.response);
                formCadastro.atribuiValorImagem(response);
                bar.max = e.total;
                bar.value = e.loaded;
            },
            
            completeAll: function (e) {
                setTimeout(function () {
                    bar.setAttribute('hidden', 'hidden');
                }, 1000);
            },

            fail: function(){
                alert('Formato de arquivo não suportado');
            }

        });
    }

    public atribuiValorImagem(response: any):void{
        if(this.campeonato.imagem_exibicao && this.campeonato.imagem_exibicao.includes(this.campeonato.nome)){
            this.campeonato.imagem_old = this.campeonato.imagem_exibicao;
        }
        this.campeonato.imagem_exibicao = response.path;
        this.imageToShow = response.img;
    }
    
    private getThumbnailExibicao(campeonato: Campeonato){
        campeonato.thumbnail =  this.utilService.buscaThumbnail(campeonato);
    }

    public selecionaOrganizador(organizador : Usuario){
        this.campeonato.id_organizador = organizador.id_usuario;
        this.valueOrganizador = organizador.nome;
        this.organizadores = [];
    }

    public getOrganizadores(filtro : string){
        if(filtro && filtro.length >= 3){
            this.buscaOrganizador(filtro);
        }else{
            this.organizadores = [];
            this.campeonato.id_organizador = null;
            this.valueOrganizador = '';
        }
    }

    public buscaOrganizador(filtro){
        this.usuarioService.buscaTodos(filtro)
        .subscribe(usuarios => {
            this.organizadores = usuarios
            .filter(usuario => usuario.id_perfil == 1 || usuario.id_perfil ==2);
        });
    }


}
