import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { RegraAssociacao } from '../model/regra-associacao';

@Injectable()
export class RegraAssociacaoService {

    private regra_associacao_api_url = `${environment.api_url}/regras-associacao`;

    constructor(private http: HttpClient) {}

    public buscaTodos(): Observable<RegraAssociacao[]> {
      return this.http.get<RegraAssociacao[]>(`${this.regra_associacao_api_url}-busca-todos`);
    }

    public buscaPorId(id: number): Observable<RegraAssociacao> {
      return this.http.get<RegraAssociacao>(`${this.regra_associacao_api_url}/${id}`);
    }

    public insere(regra_associacao: RegraAssociacao): Observable<any> {
      return this.http.post<any>(`${this.regra_associacao_api_url}/inserir`, regra_associacao);
    }

    public alterar(id: number, regra_associacao: RegraAssociacao): Observable<RegraAssociacao> {
      return this.http.put<RegraAssociacao>(`${this.regra_associacao_api_url}/alterar/${id}`, regra_associacao);
    }

    public deleta(id: number): any {
      return this.http.delete<any>(`${this.regra_associacao_api_url}/${id}`);
    }      
}
