import { OnInit, Component, Output, EventEmitter } from '@angular/core';

@Component({
    selector: 'notifications-organizador',
    templateUrl: './notifications-organizador.component.html',
    styleUrls: ['./notifications-organizador.style.css', '../organizador.style.css']
})
export class NotificationsOrganizadorComponent implements OnInit {
    constructor() {}
    ngOnInit(): void {}
}