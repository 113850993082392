import { Component, OnInit, Inject } from '@angular/core';
import { UtilService } from '../../../util/util.service';
import { ActivatedRoute, Router } from '@angular/router';
import { DestaqueService } from '../../../service/destaque.service';
import { Destaque } from '../../../model/destaque';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { NoticiaService } from '../../../service/noticia.service';

declare var $: any;
declare var UIkit: any;

@Component({
  selector: 'app-cadastro-destaque',
  templateUrl: './cadastro-destaque.component.html',
  styleUrls: ['./cadastro-destaque.component.css']
})

export class CadastroDestaqueComponent implements OnInit {

  public titulo_pagina : string;
  private id_destaque : number;
  public destaque : Destaque = new Destaque();
  public formCadastro : FormGroup;

  private selectedFile: File = null;
  public imageToShow: any;

  public tipoCadastro : boolean;

  constructor(private utilService : UtilService,
              @Inject(ActivatedRoute) private route : ActivatedRoute,
              private destaqueService : DestaqueService,
              @Inject(FormBuilder) private formBuild : FormBuilder,
              private noticiaService : NoticiaService,
              @Inject(Router) private router : Router) { }

  ngOnInit() {
    this.id_destaque = parseInt(this.route.snapshot.queryParamMap.get("id_destaque"));
    if(!isNaN(this.id_destaque)){
      this.tipoCadastro = false;
      this.titulo_pagina = "Editar Destaque";
      this.buscaDestaque();
    }else{
      this.tipoCadastro = true;
      this.titulo_pagina = "Cadastro Destaque";
      this.initForm();
      this.setTipoDestaque(1);
    }
  }

  private buscaDestaque(){
    this.destaqueService.buscaPorId(this.id_destaque).subscribe(retorno => {
      this.destaque = retorno;
      this.destaque.endereco_antigo = new String(this.destaque.endereco).valueOf();
      this.initForm();
      if(this.destaque.tipo_destaque == 1){
        this.destaque.endereco = null;
      }
    })
  }

  private initForm(){
    this.formCadastro = this.formBuild.group({
      titulo : [this.destaque.titulo, [
        Validators.required,
        Validators.minLength(2),
        Validators.maxLength(100)
      ]],
      texto : [this.destaque.texto, [
        Validators.required,
        Validators.minLength(2),
        Validators.maxLength(1000)
      ]],
      endereco : [this.destaque.endereco, [
        Validators.required,
        Validators.minLength(2)
      ]],
      tipo_destaque : [this.destaque.tipo_destaque,[
        Validators.required
      ]]
    });

    this.carregaUploadImagem();
  }

  public tipoImagem(tipo : number){
    this.carregaUploadImagem();
    this.destaque.endereco = null;
    this.imageToShow = null;
    this.setTipoDestaque(tipo);
    
  }
  
  public tipoVideo(tipo : number){
    this.destaque.endereco = null;
    this.imageToShow = null;
    this.setTipoDestaque(tipo);
  }

  private setTipoDestaque(tipo : number){
    this.destaque.tipo_destaque = tipo;
    this.formCadastro.controls['tipo_destaque'].setValue(tipo);
  }

  private carregaUploadImagem(){
    let escopo = this;
    setTimeout(function(){ escopo.uploadImagem() }, 1000);
  }

  private uploadImagem(){
    var bar = <HTMLInputElement>document.getElementById('js-progressbar');
    let uploadURL = this.destaqueService.upload_imagem_api_url;
    const formCadastroDestaque = this;
    
    UIkit.upload('.js-upload', {

      url: uploadURL,
      multiple: false,
      mime: '.jpg, .png, .jpeg, .gif, .bmp, .tif, .tiff|image/*',

      loadStart: function (e) {
        bar.removeAttribute('hidden');
        bar.max = e.total;
        bar.value = e.loaded;
      },

      progress: function (e) {
        bar.max = e.total;
        bar.value = e.loaded;
      },

      loadEnd: function (e) {
        let response = JSON.parse(e.srcElement.response);
        formCadastroDestaque.atribuiValorImagem(response);
        bar.max = e.total;
        bar.value = e.loaded;
      },
      
      completeAll: function (e) {
        setTimeout(function () {
          bar.setAttribute('hidden', 'hidden');
        }, 1000);
      },

      fail: function(){
        alert('Formato de arquivo não suportado');
      }

    });
  }

  public atribuiValorImagem(response: any):void{
    if(this.destaque.endereco && this.destaque.endereco.includes(this.destaque.titulo)){
      this.destaque.endereco_antigo = this.destaque.endereco;
    }
    this.destaque.endereco = response.path;
    this.formCadastro.controls['endereco'].setValue(response.path);
    this.imageToShow = response.img;
  }

  public salvar(){
    let mensagem = this.destaque.tipo_destaque == 1 ?
    "Imagem" : "Vídeo";
    if (this.formCadastro.invalid) {
      this.utilService.destacarErros(this.formCadastro);
      if(!this.destaque.endereco || this.destaque.endereco == ''){
        this.utilService.mensagemInfo("Precisa inserir um arquivo("+mensagem+")!");
      }
      return false;
    }

    if(!this.destaque.endereco || this.destaque.endereco == ''){
      this.utilService.mensagemInfo("Precisa inserir um arquivo("+mensagem+")!");
      return false;
    }

    if(this.destaque.id_destaque){
      this.alterarDestaque();
    }else{
      this.inserirDestaque();
    }    
  }

  public inserirDestaque(){
    this.utilService.mensagemConfirmacao("Deseja realmente salvar o destaque",
    (resp) => {
      if(resp){
        this.destaque.ativo = true;
        this.destaqueService.inserir(this.destaque)
        .subscribe(destaque => {
          this.utilService.mensagemInfo("Destaque cadastrado com sucesso!", () => {
            this.irParaInicio();
          });
        },(erro) => this.utilService.mensagemErro(erro.error.mensagem));
      }
    })
  }

  public alterarDestaque(){
    this.utilService.mensagemConfirmacao("Deseja realmente alterar o destaque?", 
    (resp) => { 
      if(resp){
        this.destaque.ativo = true;
        this.destaqueService.alterar(this.destaque.id_destaque, this.destaque)
        .subscribe(retorno => {
          this.utilService.mensagemInfo("Destaque alterado com sucesso!", () => {
            this.irParaInicio();
          });          
        }, (erro) => this.utilService.mensagemErro(erro.error.mensagem));
      }
    });
  }

  public irParaInicio(){
    this.utilService.irPara(this.router,'/comum/inicio');
  }

}
