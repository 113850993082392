import { Cavalo } from './cavalo';
import { ValidaInscricao } from './valida-inscricao';
import { AssociacaoCompetidor } from './associacao-competidor';
import { UsuarioSemCadastroInscricaoCompetidor } from './usuario-sem-cadastro-inscricao-competidor';

export class Usuario {
    id_usuario: number;
    nome: string;
    apelido: string;
    data_nascimento: string;
    cpf: string;
    rg: string;
    email: string;
    sexo: string;
    cep: string;
    estado: string;
    cidade: string;
    bairro: string;
    logradouro: string;
    numero: string;
    telefone: string;
    competidor: boolean;
    id_perfil: number;
    login: string;
    senha: string;
    handicap: number;
    cavalo: Cavalo = null;
    isApartador: boolean;
    ativo: boolean;
    valor_inscricao : number;
    numero_competidor : number;
    id_inscricao_competidor : number;
    validaProva : ValidaInscricao[] = [];
    associacao_competidor : AssociacaoCompetidor;    
    usuarioSemCadastroInscricaoCompetidor : UsuarioSemCadastroInscricaoCompetidor;
    pendente : boolean;
    semCadastro : boolean;
    excluido : boolean;

    constructor() {
        this.competidor = false;
        this.handicap = 0;
        this.validaProva = [];
        this.cavalo = null;
    }
}