import { Component, Output, EventEmitter, Input } from '@angular/core';

import { Campeonato } from '../../../../model/campeonato';
import { ParametrosBusca } from '../../../../model/parametros_busca';
import { CampeonatoService } from '../../../../service/campeonato.service';

@Component({
    selector: 'order-div-campeonatos',
    templateUrl: './order-div-campeonatos.component.html',
    styleUrls: ['./order-div-campeonatos.component.css']
})
export class OrderDivCampeonatosComponent {

    @Output() onChanging = new EventEmitter<Campeonato[]>();
    @Output() emiteOptions = new EventEmitter<ParametrosBusca>();
    @Input() options: ParametrosBusca;
    public valuesOrdem;
    public valuesLimite;
    public ordena;
    public limita;

    constructor(private service: CampeonatoService){}

}