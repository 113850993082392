import { Component, OnInit, Input, EventEmitter, Output, Inject } from '@angular/core';
import { Router } from '@angular/router';

import { Usuario } from '../../../../model/usuario';
import { Cavalo } from '../../../../model/cavalo';
import { Prova } from '../../../../model/prova';
import { Inscricao } from '../../../../model/inscricao';
import { UtilService } from '../../../../util/util.service';
import { InscricaoService } from '../../../../service/inscricao.service';
import { InscricaoCompetidor } from '../../../../model/inscricao_competidor';
import { InscricaoCompetidorService } from '../../../../service/inscricao-competidor.service';
import { Campeonato } from '../../../../model/campeonato';
import { Evento } from '../../../../model/evento';
import { PubSub } from 'pubsub-js';
import { ProvaService } from '../../../../service/prova.service';
import { UsuarioSemCadastroService } from '../../../../service/usuario-sem-cadastro.service';
import { UsuarioSemCadastroInscricaoCompetidorService } from '../../../../service/usuario-sem-cadastro-inscricao-competidor.service';
import { UsuarioSemCadastroInscricaoCompetidor } from '../../../../model/usuario-sem-cadastro-inscricao-competidor';
import { BuscaDeCompetidoresComponent } from '../../shared/busca-de-competidores/busca-de-competidores.component';
import { RegraAssociacao } from '../../../../model/regra-associacao';
import { AssociacaoCompetidor } from '../../../../model/associacao-competidor';
import { RegraValidadorService } from '../../../../service/regra.validador.service';
import { AssociacaoCompetidorService } from '../../../../service/associacao-competidor';
import { async } from '@angular/core/testing';

@Component({
    selector:'finaliza-inscricao',
    templateUrl: './finaliza-inscricao.component.html',
    styleUrls: ['./finaliza-inscricao.style.css', './../../inscricoes.style.css']
})
export class FinalizaInscricaoComponent implements OnInit {

    @Input() campeonato: Campeonato;
    @Input() evento: Evento;
    @Input() prova: Prova;

    @Input() paginaAnterior: number;
    @Output() paginaEmitter: EventEmitter<number> = new EventEmitter<number>();

    @Input() listaDeCompetidoresInscritos: Usuario[];
    @Input() listaDeCavalosInscritos: Cavalo[];
    @Input() listaDeProvasSelecionadas: Prova[];

    @Output() listaDeProvasSelecionadasEmitter: EventEmitter<Prova[]> = new EventEmitter<Prova[]>();

    public regrasAssociacao : RegraAssociacao[] = [];
    private inscricoesNaoEfetuadas = [];
    private controle: number = 0;
    private provas : string = "";
    private tipoCadastro : boolean;
    private erro = null;
    
    constructor(private utilService: UtilService,
                private inscricaoService: InscricaoService,
                private inscricaoCompetidorService: InscricaoCompetidorService,
                private provaService : ProvaService,
                private associacaoCompetidorService : AssociacaoCompetidorService,
                @Inject(Router) private router: Router,
                private usuarioSemCadastroService : UsuarioSemCadastroService,
                private usuarioSemCadastroInscricaoCompetidorService : UsuarioSemCadastroInscricaoCompetidorService) {}

    ngOnInit(): void {
        this.buscaRegrasAssociacao();
        PubSub.subscribe('FIM_FOREACH', (msg, data) => {
            if(this.inscricoesNaoEfetuadas.length > 0 || this.erro){
                for(let inscricao of this.inscricoesNaoEfetuadas){
                    this.provas += inscricao + "\n";
                }
                let mensagem = "Não foi possivel realizar a(s) inscrição(ões): \n";
                if(this.erro && this.inscricoesNaoEfetuadas.length > 0){
                    mensagem += this.erro + '\n';
                }
                if(this.inscricoesNaoEfetuadas.length > 0){
                    mensagem += "Nessa(s) Prova(s): " + this.provas;
                }
                this.utilService.mensagemErro(mensagem,
                  (result) => {
                    if(result){
                        if(this.listaDeProvasSelecionadas.length > this.inscricoesNaoEfetuadas.length){
                            this.utilService.mensagemSucesso("As outras inscrições foram realizadas com sucesso", (res) =>{
                                this.utilService.irPara(this.router, '/comum/evento/'+this.evento.id_evento);
                            });
                        }else{
                            this.utilService.irPara(this.router, '/comum/evento/'+this.evento.id_evento);
                        }
                    }
                });
            }else{
                let conteudo = "Inscrição realizada com sucesso!\n Deseja fazer mais inscrição?"
                this.utilService.mensagemConfirmacao(conteudo, (resp) =>{
                    if(resp){
                        this.utilService.irPara(this.router, `/comum/evento/${this.evento.id_evento}`);
                    }else{
                        this.irParaInicio();
                    }
                });
            }
        });
    }

    public regressar(): void {
        this.paginaEmitter.emit(this.paginaAnterior);
    }

    private salvaInscricao() {
        let listaQtd = [];
        this.listaDeProvasSelecionadas.forEach(p => listaQtd.push(p));
        let aux : number = listaQtd.length;
        let control = 0;
        if(this.utilService.verificaEncerramentoInscricao(this.evento.data_fim_inscricoes) &&
           !this.utilService.isAdm() && this.evento.id_organizador != this.utilService.getIdLogado()){
            this.utilService.mensagemInfo("Prazo para a inscrição foi encerrado!",
            () => this.irParaInicio());
        }else{
            this.verificaInscricao();
        }
    }

    private verificaInscricao(){
        if(this.inscricoesNaoEfetuadas && this.inscricoesNaoEfetuadas.length > 0){
            let mensagem = null;
            this.inscricoesNaoEfetuadas.forEach(ine => {
                if(mensagem){
                    mensagem += '\n - ' + ine.divisao.nome;
                }else{
                    mensagem = 'Erro nessa(s) prova(s): \n- ' + ine.divisao.nome;
                }
            });
            if(this.listaDeProvasSelecionadas && this.listaDeProvasSelecionadas.length > 0){
                mensagem += '\n Deseja continuar com a(s) outra(s) inscrição(ões) ?';
                this.utilService.mensagemConfirmacao(mensagem, resp => {
                    if(resp){
                        this.chamaAssociarCompetidor();
                        this.salvar();
                    }else{
                        this.irParaInicio();
                    }
                });
            }else{
                this.utilService.mensagemErro(mensagem, () => this.irParaInicio());
            }
        }else{
            this.chamaAssociarCompetidor();
            this.salvar();
            
        }
    }

    private  chamaAssociarCompetidor (){
        this.buscaRegrasAssociacao();
        this.listaDeCompetidoresInscritos.forEach(competidor => {
            this.associarCompetidor(competidor); 
        }); 
    }

    public associarCompetidor(competidor : Usuario){
        if(this.regrasAssociacao.length > 0){
            let idade = this.utilService.getIdadeBaseAnoHipico(competidor.data_nascimento);
            let valida =  new RegraValidadorService(idade, null, null, null, null, null, this.utilService);
            valida.addRegraAssocicao(this.regrasAssociacao);
            let id_regra_associacao = valida.validaAssociacao().id_regra_associacao;
            if(id_regra_associacao){
                this.inserirAssociacaoCompetidor(id_regra_associacao, competidor);
            }else{
                let mensagem = competidor.nome + " não se encaixa em nenhuma categoria de filiação";
                this.utilService.mensagemInfo(mensagem);
            }
        }else{
            this.utilService.mensagemInfo("Não foi encontrado nenhuma categoria de filiação!")
        }
    }

    public inserirAssociacaoCompetidor(id_regra_associacao : number, competidor : Usuario){
        var url_atual = window.location.href;
        var id_evento = url_atual.substring(39)
        let associarCompetidor = new AssociacaoCompetidor();
        associarCompetidor.id_regra_associacao = id_regra_associacao;
        associarCompetidor.id_usuario = competidor.id_usuario;
        associarCompetidor.id_evento =  parseInt(id_evento);
        associarCompetidor.id_cadastrador = this.utilService.getIdLogado();
        associarCompetidor.associacao_competidor_paga = false;
        this.associacaoCompetidorService.insere(associarCompetidor)
        .subscribe(retorno => {
            if(retorno.id_associacao_competidor){
                competidor.associacao_competidor = retorno;
                this.utilService.mensagemSucesso("Filiação do competidor realizada com sucesso!");
            }
        }, () => this.utilService.mensagemErro("Erro - Não foi possivel realizar a filiação do competidor."))
    }

    public async buscaRegrasAssociacao(){
        await this.associacaoCompetidorService.buscaTodosregra()
        .subscribe(retorno => {
            this.regrasAssociacao = retorno;
        });
    }

    private salvar(){
        this.inscricaoService.insere(this.listaDeProvasSelecionadas,
        this.listaDeCompetidoresInscritos, this.utilService.getIdLogado())
        .subscribe( 
            () => PubSub.publish('FIM_FOREACH'),
            erro => this.utilService.mensagemErro(erro.error.mensagem, () => this.irParaInicio())
        );
    }

    public cadastrarUsuario(competidor: any){
        this.usuarioSemCadastroService.inserir(competidor).subscribe();
    }

    public redirecionar(): void {
        this.utilService.mensagemConfirmacao("Confirmar dados do cadastro?",
        (result) => {
            if (result) {
                this.salvaInscricao();
            }
        });
    }

    public emiteListaDeProvasSelecionadas(listaDeProvas: Prova[]): void {
        this.listaDeProvasSelecionadasEmitter.emit(listaDeProvas);
    }

    public inserirInscricaoCompetidor(ic, prova, competidor, index : number){
        let inscricaoCompetidor = new InscricaoCompetidor();
        inscricaoCompetidor.id_inscricao = ic.id_inscricao;
        inscricaoCompetidor.id_competidor = competidor.id_usuario;
        if (competidor.cavalo) {
            inscricaoCompetidor.id_cavalo = competidor.cavalo.id_cavalo;
            inscricaoCompetidor.potro_futuro = prova.divisao.potro_futuro == false ? false :
            competidor.cavalo.potro_futuro == true ? competidor.cavalo.potro_futuro : false;
            
        } else {
            inscricaoCompetidor.id_cavalo = index + 1;
        }
        inscricaoCompetidor.is_apartador = competidor.isApartador; 
        inscricaoCompetidor.inscricao_paga = false; 
        inscricaoCompetidor.tipo_prova = prova.tipo_prova;
        inscricaoCompetidor.handicap_competidor = competidor.handicap ? competidor.handicap : 10;
        inscricaoCompetidor.id_prova = prova.id_prova;

        this.inscricaoCompetidorService.insere(inscricaoCompetidor).subscribe( retorno => {
            if(retorno){
                if(this.tipoCadastro == true){
                    let usuarioSemCadastroInscricaoCompetidor = new UsuarioSemCadastroInscricaoCompetidor();
                    usuarioSemCadastroInscricaoCompetidor.id_inscricao_competidor = retorno.retorno.id_inscricao_competidor;
                    usuarioSemCadastroInscricaoCompetidor.id_usuario = competidor.id_usuario;
                    usuarioSemCadastroInscricaoCompetidor.ativo = true;
                    this.inserirUsuarioSemCadastroInscricaoCompetidor(usuarioSemCadastroInscricaoCompetidor);
                }
            }
        });
    }

    public inserirUsuarioSemCadastroInscricaoCompetidor
    (usuarioSemCadastroInscricaoCompetidor : UsuarioSemCadastroInscricaoCompetidor){
        this.usuarioSemCadastroInscricaoCompetidorService
        .inserir(usuarioSemCadastroInscricaoCompetidor)
        .subscribe();
    }

    public irParaInicio(){
        this.utilService.irPara(this.router, `/comum/inicio`);
    }
}