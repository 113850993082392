export class Noticia {
    id_noticia: number;
    titulo: string;
    texto: string;
    id_autor: number;
    id_tipo_noticia: number;
    id_referencia_noticia: number;
    imagem_exibicao: string;
    data_criacao: string;
    ativa: boolean;
    arquivo_imagem: string;
    imagem_old: string;
    thumbnail: string;
    id_tipo_arquivo: number;
}