import { Component, Output, EventEmitter, OnInit, Input} from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';

import { UtilService } from '../../../util/util.service';
import { Raca } from '../../../model/raca';
import { ProvaRacas } from '../../../model/prova_racas';
import { Prova } from '../../../model/prova';

import { RacaService } from '../../../service/raca.service';
import { ProvaRacasService } from '../../../service/prova-racas.service';

declare var UIkit: any;

@Component({
    selector: 'racas-pontuar-modal',
    templateUrl: 'racas-pontuar-modal.component.html',
    styleUrls: ['./racas-pontuar-modal.style.css']
})
export class RacaPontuarModalComponent implements OnInit {
    
    @Input() id_evento : number;
    @Input() id_divisao: number;

    @Output() racaProvaEmitter = new EventEmitter<ProvaRacas>();
    
    public racas: Raca[] = [];
    public racaSelecionada: Raca;
    public racaProva: ProvaRacas;
    
    public formCadastro: FormGroup;
    
    constructor(private formBuilder: FormBuilder,
                private provaRacasService: ProvaRacasService,
                private racaService: RacaService,
                private utilService: UtilService){

                    this.racaProva = new ProvaRacas();

                    this.formCadastro = this.formBuilder.group({
                        valor_adicional: [this.racaProva.valor_adicional_inscricao, [
                            Validators.required,
                            Validators.minLength(1),
                            Validators.maxLength(8)
                        ]],
                        porcentagem_premiacao: [this.racaProva.porcentagem_premiacao, [
                            Validators.required,
                            Validators.minLength(1),
                            Validators.maxLength(6)
                        ]],
                        incremento_premiacao: [this.racaProva.acrescimo_premiacao, [
                            Validators.required,
                            Validators.minLength(1),
                            Validators.maxLength(8)
                        ]]
                    });
    }

    ngOnInit(){  
       this.getRaca();
    }

    public getRaca(){
        this.racaService.buscaTodos().subscribe(racas => {
            this.racas = racas;
        });
    }

    public getRacaSelecionada(raca: Raca){
        this.racaSelecionada = raca;
        this.racaProva.raca = raca;
        this.racaProva.id_raca = this.racaSelecionada.id_raca;
    }

    public fecharModal(racaProva: ProvaRacas): void {
        this.racaProva = new ProvaRacas();
        this.racaProvaEmitter.emit(racaProva);
        UIkit.modal('#racaPontuarModal').hide();
    }

    public salvaProvaRacas(): void {
        if (this.racaProva.id_prova_racas) { //EDITANDO
            this.utilService.mensagemConfirmacao(`Deseja salvar ${this.racaProva.raca.descricao} ?`, (res) => {
                if (res) {
                  this.provaRacasService.put(this.racaProva.id_prova_racas, this.racaProva).subscribe((racaProva) => {
                    this.utilService.mensagemSucesso(`Raça a pontuar ${racaProva.raca.descricao} editada com sucesso!`);
                    this.fecharModal(racaProva);
                  });
                }else{
                    UIkit.modal('#racaPontuarModal').hide();
                }
            });
        } else { //INSERINDO
            this.racaProva.id_evento = this.id_evento;
            this.racaProva.id_divisao = this.id_divisao;
            this.utilService.mensagemConfirmacao(`Deseja salvar ${this.racaProva.raca.descricao} ?`, (res) => {
                if (res) {
                  this.provaRacasService.post(this.racaProva).subscribe((racaProva) => {
                    this.utilService.mensagemSucesso(`Raça a pontuar ${racaProva.raca.descricao} salva com sucesso!`);
                    this.fecharModal(racaProva);
                  }, erro => this.utilService.mensagemErro(erro.error.mensagem));
                }else{
                    UIkit.modal('#racaPontuarModal').hide();
                }
            });
        }
    }   
    
    public abreModalCadastroRaca() {
        UIkit.modal('#racaModal', {bgClose: false}).show();
    }
    
    public racaGetEmitter(evento){
        this.getRaca();
        UIkit.modal('#racaModal').hide();
    }
}