import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { ListaInscritosComponent } from './lista-inscritos.component';
import { PaginationListaInscritosComponent } from './pagination-lista-inscritos/pagination-lista-inscritos.component';

@NgModule({
    declarations: [ListaInscritosComponent, PaginationListaInscritosComponent],
    imports: [
        CommonModule,
        RouterModule
    ],
    exports: [ListaInscritosComponent]
})
export class ListaInscritosModule {}