import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { Prova } from '../../../../model/prova';
import { Usuario } from '../../../../model/usuario';
import { Cavalo } from '../../../../model/cavalo';
import { ProvaService } from '../../../../service/prova.service';
import { Campeonato } from '../../../../model/campeonato';
import { Evento } from '../../../../model/evento';

@Component({
    selector: "inscricao-por-prova",
    templateUrl: "./inscricao-por-prova.component.html",
    styleUrls: ["./inscricao-por-prova.style.css", './../../inscricoes.style.css']
})
export class InscricaoPorProvaComponent implements OnInit {

    @Input() pagina: number;
    @Output() paginaEmitter: EventEmitter<number> = new EventEmitter<number>();
    @Output() paginaAnteriorEmitter: EventEmitter<number> = new EventEmitter<number>();
    
    @Input() campeonato: Campeonato;
    @Input() evento: Evento;
    @Input() numeroDeInscritos: number;
    
    listaDeProvasDoEvento: Prova[];
    listaDeProvasSelecionadas: Prova[];
    @Input() listaDeProvasSelecionadass: Prova[];
    private listaDeCompetidoresInscritos : Usuario[] = []; 
    
    @Output() listaDeProvasSelecionadasEmitter: EventEmitter<Prova[]> = new EventEmitter<Prova[]>();
    @Output() listaDeCompetidoresInscritosEmitter: EventEmitter<Usuario[]> = new EventEmitter<Usuario[]>();
    @Output() listaDeCavalosInscritosEmitter: EventEmitter<Cavalo[]> = new EventEmitter<Cavalo[]>();

    constructor(private provaService: ProvaService,
                private activatedRoute: ActivatedRoute) {}

    ngOnInit(): void {
        this.buscaTodasAsProvasDeUmEvento();
    }

    private buscaTodasAsProvasDeUmEvento(): void {
        this.provaService.buscaProvasDeUmEvento(this.activatedRoute.snapshot.params.idEvento).subscribe(provas => {
            this.listaDeProvasDoEvento = provas;
            this.listaDeProvasDoEvento.forEach(pro => pro.qtdInscricao = 1);
        })
    }

    private emitePagina(pagina: number): void {
        this.paginaEmitter.emit(pagina);
    } 

    private emitePaginaAnterior(paginaAnterior: number): void {
        this.paginaAnteriorEmitter.emit(paginaAnterior);
    }

    private emiteListaDeProvasSelecionadas(provasSelecionadas: Prova[]): void {
        this.listaDeProvasSelecionadas = provasSelecionadas;
        this.listaDeProvasSelecionadasEmitter.emit(this.listaDeProvasSelecionadas);
    }

    private emiteListaDeCompetidoresInscritos(competidoresSelecionados: Usuario[]): void {
        this.listaDeCompetidoresInscritosEmitter.emit(competidoresSelecionados);
        competidoresSelecionados.forEach(comp => this.listaDeCompetidoresInscritos.push(comp));
        
    }

    private emiteListaDeCavalosInscritos(cavalosSelecionados: Cavalo[]): void {
        this.listaDeCavalosInscritosEmitter.emit(cavalosSelecionados);
    }
}