import { OnInit, Component } from '@angular/core';
import { OrganizadorBuscaService } from '../organizadorBusca.service';

declare var $: any

@Component({
    selector: 'search-organizador',
    templateUrl: './search-organizador.component.html',
    styleUrls: ['./search-organizador.style.css', '../organizador.style.css']
})
export class SearchOrganizadorComponent implements OnInit {
    constructor(private organizadorBuscaService: OrganizadorBuscaService) {

    }
    ngOnInit(): void {}
}