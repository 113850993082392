import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Usuario } from '../model/usuario';
import { UsuarioLogin } from '../model/usuario_login';
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs';
import { ListaInscricoesUsuario } from '../comum/usuarios/lista-inscricoes-usuario/lista-inscricoes-usuario.model';
import { RedefinirSenha } from '../model/redefinir-senha';


@Injectable()
export class UsuarioService {

  private usuario_api_url = `${environment.api_url}/usuarios`;
  private login_api_url = `${environment.api_url}/auth`;

  constructor(private http: HttpClient) { }

  public getHandcap (idUsuarioLogado: string): Observable<any> {
    return this.http.get<any>(`${this.usuario_api_url}/handcap/${idUsuarioLogado}`);//requisiçao
  }

  public logar(usuario: UsuarioLogin): Observable<any> {
    return this.http.post<any>(`${this.usuario_api_url}/login`, usuario);
  }

  public consultar(filtro: any): Observable<any> {
    return this.http.post<any>(`${this.usuario_api_url}/consultar`, filtro);
  }

  public get(id: number): Observable<any> {
    return this.http.get<any>(`${this.usuario_api_url}/${id}`);
  }

  public validarCpf(cpf : string = null) : Observable<boolean>{
    
    return this.http.post<boolean>(`${this.usuario_api_url}-validar-cpf`, { cpf });
  }

  public validarLogin(login : string = null) : Observable<boolean>{
    
    return this.http.post<boolean>(`${this.usuario_api_url}-validar-login`, { login });
  }

  public validarEmail(email : string = null) : Observable<boolean>{
    
    return this.http.post<boolean>(`${this.usuario_api_url}-validar-email`, { email });
  }

  public add(usuario: Usuario): Observable<void> {
    usuario.id_usuario = undefined;
    
    return this.http.post<void>(`${this.usuario_api_url}`, usuario);
  }

  public put(id: number, usuario: Usuario): Observable<Usuario> {
    
    return this.http.put<Usuario>(`${this.usuario_api_url}/${id}`, usuario);
  }

  public delete (id : number) : Observable<any> {
    
    return this.http.put<any>(`${this.usuario_api_url}/delete/${id}`, undefined);
  }

  public toggleAtivo(id: number, is_ativo: boolean): Observable<void> {
    
    return this.http.put<void>(`${this.usuario_api_url}/toggle-ativo/${id}`, {is_ativo: is_ativo});
  }
  
  public cadastrar(usuario: Usuario): Observable<any> {
    
    return this.http.post<any>(`${this.usuario_api_url}/cadastro`, usuario);
  }
  
  public cadastrarParcial(usuario: Usuario): Observable<any> {
    
    return this.http.post<any>(`${this.usuario_api_url}/cadastro-parcial`, usuario);
  }

  public buscaTodos(filtro: string): Observable<Usuario[]> {
    
    return this.http.get<Usuario[]>(`${this.usuario_api_url}?filtro=${filtro}`);
  }

  public buscaComFiltro(filtro, limit : number, offset : number) : Observable<any>{
    
      return this.http.get<any>(`${this.usuario_api_url}-busca-com-filtro`,{
        params : {
          'limit' : limit.toString(),
          'offset' : offset.toString(),
          'filtro' : JSON.stringify({
            'nome' : filtro.nome,
            'cpf' : filtro.cpf,
            'status' : filtro.status
          })
        }
      });
  }

  public buscaOrganizadores(): Observable<Usuario[]>{
    
    return this.http.get<Usuario[]>(`${this.usuario_api_url}-organizadores`);
  }

  public buscaPorId(id: number): Observable<Usuario> {
    
    return this.http.get<Usuario>(`${this.usuario_api_url}/${id}`);
  }

  public buscaCompetidores(): Observable<Usuario[]> {
    
    return this.http.get<Usuario[]>(`${this.usuario_api_url}-competidores`);
  }

  public buscaCompetidoresPorFiltro(filtro : any): Observable<Usuario[]> {
    
    return this.http.get<Usuario[]>(`${this.usuario_api_url}-lista-competidores`, {
      params : {
        filtro : JSON.stringify(filtro)
      }
    });
  }

  public buscaCompetidoresPendentes(): Observable<Usuario[]> {
    
    return this.http.get<Usuario[]>(`${this.usuario_api_url}-competidores-pendentes`);
  }

  public buscarInscricoesQuePoderaoSerCanceladas(id : number,filtro : any) : Observable<any>{
    
    return this.http.get<any>(`${this.usuario_api_url}/inscricoes-que-poderam-ser-canceladas/${id}`,{
      params : {
        'filtro' : JSON.stringify(filtro)
      }
    });
  }

  public verificaOrganizadorEvento(id_evento: number, id_organizador: number): Observable<any> {
    
    return this.http.post<any>(`${this.usuario_api_url}/verifica-organizador-evento`, {id_evento, id_organizador});
  }

  public recuperarSenhaPorEmailLogin(email : string, login : string) : Observable<any> {
    
    return this.http.post<any>(`${this.usuario_api_url}-senha-por-email-login`, { email, login });
  }

  public redefinirSenha(redefinir: RedefinirSenha): Observable<any> {
    
    return this.http.post<any>(`${this.usuario_api_url}/redefinir-senha`, {redefinir});
  }

  public alterarSenha(id_usuario: number, senha: string): Observable<Usuario>{
    
    return this.http.post<Usuario>(`${this.usuario_api_url}/alterar-senha/${id_usuario}`, {senha});
  }

  public competidorAvaliado(id : number, usuario : Usuario) : Observable<Usuario>{
    
    return this.http.put<Usuario>(`${this.usuario_api_url}/competidor-avaliado/${id}`,usuario);
  }

  public exportarCompetidoresXml(){
    
    return this.http.get(`${this.usuario_api_url}/exportar-competidores-xml`, {responseType: 'text'});
  }

  public excluir (id : number) : Observable<any> {
    
    return this.http.put<any>(`${this.usuario_api_url}/excluir/${id}`, undefined);
  }
}
