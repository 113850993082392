import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { NoticiaService } from '../../service/noticia.service';
import { NoticiasBuscaService } from './noticiasBusca.service';

@NgModule({
    declarations: [
    ],
    imports: [
        CommonModule,
        ReactiveFormsModule,
        HttpClientModule
    ],
    providers: [ 
        NoticiaService,
        NoticiasBuscaService
     ]
})
export class NoticiasModule {}