import { NgModule } from '@angular/core';
import { FinalizaInscricaoComponent } from './finaliza-inscricao.component';
import { CommonModule } from '@angular/common';
import { CompetidoresSelecionadosModule } from '../../shared/competidores-selecionados/competidores-selecionados.module';
import { ListaDeProvasSelecionadasModule } from '../../shared/lista-de-provas-selecionadas/lista-de-provas-selecionadas.module';

@NgModule({
    declarations: [FinalizaInscricaoComponent],
    imports: [
        CommonModule,
        CompetidoresSelecionadosModule,
        ListaDeProvasSelecionadasModule
    ],
    exports: [FinalizaInscricaoComponent]
})
export class FinalizaInscricaoModule {}