import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';

import { ModalCadastroRacaComponent } from './modal-cadastro-raca.component';

@NgModule({
  declarations: [ModalCadastroRacaComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule
  ], exports: [ModalCadastroRacaComponent]
})
export class ModalCadastroRacaModule { }
