import { Component, OnInit } from '@angular/core';
import { UtilService } from '../../../util/util.service';
import { ResultadoCampeonatoService } from '../../../service/resultado-campeonato.service';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { ResultadoCampeonato } from '../../../model/resultado-campeonato';
import { Router, ActivatedRoute } from '@angular/router';
import { CampeonatoService } from '../../../service/campeonato.service';
import { Campeonato } from '../../../model/campeonato';

@Component({
  selector: 'app-cadastro-resultado-campeonato',
  templateUrl: './cadastro-resultado-campeonato.component.html',
  styleUrls: ['./cadastro-resultado-campeonato.component.css']
})
export class CadastroResultadoCampeonatoComponent implements OnInit {

  public titulo: string = 'Cadastro de Resultado do Campeonato';
  private id_campeonato: number;
  private id_resultado_campeonato: number;
  public resultadoCampeonato: ResultadoCampeonato = new ResultadoCampeonato();
  public form: FormGroup;
  public campeonato: Campeonato;
  public imageToShow: string;
  public nomeArquivo: string;
  public exibir_arquivo: boolean;
  public arquivoAntigo: string;

  constructor(public utilService: UtilService,
              private resultadoCampeonatoService: ResultadoCampeonatoService,
              private campeonatoService: CampeonatoService,
              private builder: FormBuilder,
              private router: Router,
              private activatedRoute: ActivatedRoute) { }

  ngOnInit() {
    this.id_campeonato = this.activatedRoute.snapshot.params.id_campeonato;
    this.id_resultado_campeonato = this.activatedRoute.snapshot.params.id_resultado_campeonato;
    if(this.id_campeonato && !isNaN(this.id_campeonato)){
      this.buscaCampeonato();
      this.initForm();
    }

    if(this.id_resultado_campeonato){
      this.titulo = 'Editar Resultado do Campeonato';
      this.buscaResultadoCampeonato();
    }
  }

  private buscaCampeonato(){
    this.campeonatoService.buscaPorId2(this.id_campeonato)
    .subscribe(
      retorno => this.campeonato = retorno,
      erro => this.utilService.mensagemErro('Erro - ' + erro.error.mensagem)
    );
  }

  private buscaResultadoCampeonato(){
    this.resultadoCampeonatoService.buscaPorId(this.id_resultado_campeonato)
    .subscribe(
      retorno =>{
        this.resultadoCampeonato = retorno;
        this.arquivoAntigo = this.utilService.buscaPdf(retorno);
        this.resultadoCampeonato.arquivo_old = retorno.arquivo_exibicao;
        this.form.controls['arquivo_exibicao'].setValue(retorno.arquivo_exibicao);
      },
      erro => this.utilService.mensagemErro('Erro - ' + erro.error.mensagem)
    );
  }

  private initForm(){
    this.form = this.builder.group({
      titulo: new FormControl(this.resultadoCampeonato.titulo, [
        Validators.required,
        Validators.minLength(3),
        Validators.maxLength(150)
      ]),
      descricao: new FormControl(this.resultadoCampeonato.descricao, [
        Validators.required,
        Validators.minLength(3)
      ]),
      arquivo_exibicao: new FormControl(this.resultadoCampeonato.arquivo_exibicao, [
        Validators.required,
        Validators.maxLength(200)
      ])
    });
  }

  public fileChange(event : any){
    let file = event.target.files[0];
    this.nomeArquivo = file.name;
    this.upload(file);
  }

  public upload(file){
    let formData = new FormData();
    formData.append('file', file)
    this.utilService.uploadPDF(formData).subscribe(
      retorno => {
        if(this.resultadoCampeonato.arquivo_exibicao && this.resultadoCampeonato.arquivo_exibicao.includes(this.resultadoCampeonato.titulo)){
          this.resultadoCampeonato.arquivo_old = this.resultadoCampeonato.arquivo_exibicao;
        }
        this.setImagemExibicao(retorno.path);
      },
      erro => this.utilService.mensagemErro("Erro - " + erro.error.mensagem)
    );
  }

  public atribuiValorImagem(response: any):void{
    if(this.resultadoCampeonato.arquivo_exibicao && this.resultadoCampeonato.arquivo_exibicao.includes(this.resultadoCampeonato.titulo)){
        this.resultadoCampeonato.arquivo_old = this.resultadoCampeonato.arquivo_exibicao;
    }
    this.setImagemExibicao(response.path);
    this.imageToShow = response.img;
  }

  public setImagemExibicao(valor : string) : void{
    this.exibir_arquivo = false;
    this.resultadoCampeonato.arquivo_exibicao = valor;
    this.form.controls['arquivo_exibicao'].setValue(valor);
  }

  public salvar(){
    if(this.form.invalid){
      this.utilService.showErrors(this.form);
      return;
    }

    this.resultadoCampeonato.id_cadastrador = this.utilService.getIdLogado();
    this.resultadoCampeonato.id_tipo_arquivo = 3;
    if(!this.resultadoCampeonato.id_resultado_campeonato){
      this.resultadoCampeonato.id_campeonato = this.id_campeonato;
      this.inserir();
    }else{
      this.alterar();
    }
  }

  private inserir(){
    this.resultadoCampeonatoService.inserir(this.resultadoCampeonato)
    .subscribe(
      retorno => {
        this.utilService.mensagemSucesso('Cadastro realizado com sucesso!');
        this.irParaDetalhesCampeonato();
      },
      erro => this.utilService.mensagemErro("Erro - " + erro.error.mensagem)
    );
  }

  private alterar(){
    this.resultadoCampeonatoService.alterar(this.resultadoCampeonato.id_resultado_campeonato, this.resultadoCampeonato)
    .subscribe(
      retorno => {
        this.utilService.mensagemSucesso('Operação realizado com sucesso!');
        this.irParaResultados();
      },
      erro => this.utilService.mensagemErro("Erro - " + erro.error.mensagem)
    )
  }

  private irParaDetalhesCampeonato(){
    this.router.navigate(['/comum/campeonatos/detalhes/' + this.id_campeonato]);
  }

  private irParaResultados(){
    this.router.navigate(['/comum/campeonato/resultados/' + this.id_campeonato]);
  }

  public voltar(){
    if(this.resultadoCampeonato.id_resultado_campeonato){
      this.irParaResultados();
    }else{
      this.irParaDetalhesCampeonato();
    }
  }
}
