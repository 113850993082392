import { NgModule } from '@angular/core';
import { EscolhaQuantidadeDeCompetidoresComponent } from './escolha-quantidade-de-competidores.component';
import { CommonModule } from '@angular/common';

@NgModule({
    declarations: [EscolhaQuantidadeDeCompetidoresComponent],
    imports: [
        CommonModule
    ],
    exports: [EscolhaQuantidadeDeCompetidoresComponent]
})
export class EscolhaQuantidadeDeCompetidoresModule {}