import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { ResultadoCampeonato } from '../model/resultado-campeonato';
import { Observable } from 'rxjs';
import { ResultadoCampeonatoFiltro } from '../filter/resultado-campeonato.filter';

@Injectable()
export class ResultadoCampeonatoService {

    private url_api = `${environment.api_url}/resultado-campeonato`;

    constructor(private http: HttpClient){}

    public inserir(resultadoCampeonato: ResultadoCampeonato): Observable<ResultadoCampeonato> {
        return this.http.post<ResultadoCampeonato>(`${this.url_api}`, resultadoCampeonato);
    }

    public buscaPorId(id: number) : Observable<ResultadoCampeonato> {
        return this.http.get<ResultadoCampeonato>(`${this.url_api}/${id}`);
    }

    public buscaPorFiltro(filtro: ResultadoCampeonatoFiltro) : Observable<any> {
        return this.http.post<any>(`${this.url_api}/filtro`, filtro);
    }

    public alterar(id: number, resultadoCampeonato: ResultadoCampeonato) : Observable<ResultadoCampeonato> {
        return this.http.put<ResultadoCampeonato>(`${this.url_api}/${id}`, resultadoCampeonato);
    }

    public excluir(id: number) : Observable<ResultadoCampeonato> {
        return this.http.delete<ResultadoCampeonato>(`${this.url_api}/${id}`);
    }

    public buscaPorIdCampeonato(id: number) : Observable<ResultadoCampeonato[]> {
        return this.http.post<ResultadoCampeonato[]>(`${this.url_api}/por-campeonato`, {id : id});
    }
}