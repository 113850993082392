import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ResultadoCampeonatoRoutingModule } from './resultado-campeonato-routing.module';
import { ResultadoCampeonatoListComponent } from './list/resultado-campeonato-list.component';
import { PaginacaoModule } from '../paginacao/paginacao.module';

@NgModule({
  declarations: [ResultadoCampeonatoListComponent],
  imports: [
    CommonModule,
    ResultadoCampeonatoRoutingModule,
    PaginacaoModule
  ]
})
export class ResultadoCampeonatoModule { }
