import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { OrganizadorComponent } from './organizador.component';

import { PaginationOrganizadorModule } from './pagination-organizador/pagination-organizador.module';
import { SearchOrganizadorModule } from './search-organizador/search-organizador.module';
import { NotificationsOrganizadorModule } from './notifications-organizador/notifications-organizador.module';
import { AccordionEventosOrganizadorModule } from './accordion-eventos-organizador/accordion-eventos-organizador.module';
import { OrganizadorBuscaService } from './organizadorBusca.service';

@NgModule({
    declarations: [OrganizadorComponent],
    imports: [
        CommonModule,
        PaginationOrganizadorModule,
        SearchOrganizadorModule,
        NotificationsOrganizadorModule,
        AccordionEventosOrganizadorModule
    ],
    exports: [OrganizadorComponent],
    providers: [OrganizadorBuscaService]
})
export class OrganizadorModule {}