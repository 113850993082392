import { NgModule } from '@angular/core';
import { InscricaoPorCompetidoresComponent } from './inscricao-por-competidores.component';
import { CommonModule } from '@angular/common';
import { SelecaoDeCompetidoresPorCompetidorModule } from './selecao-de-competidores/selecao-de-competidores-por-competidor.module';
import { SelecaoDeProvasPorCompetidorModule } from './selecao-de-provas/selecao-de-provas-por-competidor.module';

@NgModule({
    declarations: [InscricaoPorCompetidoresComponent],
    imports: [
        CommonModule,
        SelecaoDeProvasPorCompetidorModule,
        SelecaoDeCompetidoresPorCompetidorModule
    ],
    exports: [InscricaoPorCompetidoresComponent]
})
export class InscricaoPorCompetidoresModule {}