import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { BuscaDeCompetidoresComponent } from './busca-de-competidores.component';
import { DirectiveModule } from '../../../../directive/directive.module';
import { TextMaskModule } from 'angular2-text-mask';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

@NgModule({
    declarations: [BuscaDeCompetidoresComponent],
    imports: [
        CommonModule,
        DirectiveModule,
        TextMaskModule,
        ReactiveFormsModule,
        FormsModule,
        RouterModule
    ],
    exports: [BuscaDeCompetidoresComponent]
})
export class BuscaDeCompetidoresModule {}