import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { UtilService } from '../../../util/util.service';
import { RegraRegulamento } from '../../../model/regra-regulamento';
import { RegraRegulamentoService } from '../../../service/regra-regulamento.service';
import { Route } from '@angular/compiler/src/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject, AsyncSubject } from 'rxjs';
import { AngularEditorConfig } from '@kolkov/angular-editor';

@Component({
  selector: 'app-cadastro-regra-regulamento',
  templateUrl: './cadastro-regra-regulamento.component.html',
  styleUrls: ['./cadastro-regra-regulamento.component.css']
})
export class CadastroRegraRegulamentoComponent implements OnInit {

  titulo: string = 'Cadastro Regra Regulamento';
  form: FormGroup;
  regraRegulamento: RegraRegulamento = new RegraRegulamento();
  editorConfig: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: 'auto',
    minHeight: '0',
    maxHeight: 'auto',
    width: '98%',
    minWidth: '0',
    translate: 'yes',
    enableToolbar: true,
    showToolbar: true,
    placeholder: 'Texto aqui.....',
    defaultParagraphSeparator: '',
    defaultFontName: '',
    defaultFontSize: '',
    fonts: [
      {class: 'arial', name: 'Arial'},
      {class: 'times-new-roman', name: 'Times New Roman'},
      {class: 'calibri', name: 'Calibri'},
      {class: 'comic-sans-ms', name: 'Comic Sans MS'}
    ],
    customClasses: [
    {
      name: 'quote',
      class: 'quote',
    },
    {
      name: 'redText',
      class: 'redText'
    },
    {
      name: 'titleText',
      class: 'titleText',
      tag: 'h1',
    },
  ],
  uploadUrl: 'v1/image',
  sanitize: true,
  toolbarPosition: 'top',
};

  constructor(private utilService: UtilService,
              private formBuild: FormBuilder,
              private regraRegulamentoService: RegraRegulamentoService,
              private router: Router,
              private route: ActivatedRoute) { }

  ngOnInit() {
    let idRegraRegulamento = parseInt(this.route.snapshot.params["idRegraRegulamento"]);
    if(!isNaN(idRegraRegulamento)){
      this.titulo = 'Editar Regra Regulamento';
      this.buscaRegraRegulamento(idRegraRegulamento);
    }
    this.initForm();
  }

  private buscaRegraRegulamento(idRegraRegulamento: number){
    this.regraRegulamentoService.buscaPorId(idRegraRegulamento)
    .subscribe(
      retorno => this.regraRegulamento = retorno,
      erro => this.utilService.mensagemErro('Erro - ' + erro.error.mensagem)
    );
  }

  private initForm(){
    this.form = this.formBuild.group({
      titulo: [this.regraRegulamento.titulo,[
          Validators.required,
          Validators.minLength(3),
          Validators.maxLength(150)
      ]],
      texto: [this.regraRegulamento.texto,[
        Validators.required,
        Validators.minLength(3)
      ]],
      ativo: [this.regraRegulamento.ativo]
    });
  }

  public salvar(){
    if(this.regraRegulamento.id_regra_regulamento){
      this.alterarRegraRegulamento();
    }else{
      this.inserirRegraRegulamento();
    }
  }

  private inserirRegraRegulamento(){
    this.regraRegulamento.ativo = true;
    this.regraRegulamentoService.inserir(this.regraRegulamento)
    .subscribe(() => {
      this.utilService.mensagemConfirmacao("Cadastro realizado com sucesso!",
      () => this.irParaListaRegraRegulamento());
    }, erro => this.utilService.mensagemErro('Erro - ' + erro.error.mensagem));
  }

  private alterarRegraRegulamento(){
    this.regraRegulamentoService
    .alterar(this.regraRegulamento.id_regra_regulamento, this.regraRegulamento)
    .subscribe(() => {
      this.utilService.mensagemSucesso("Cadastro atualizado com sucesso!",
      () => this.irParaListaRegraRegulamento());
    }, erro => this.utilService.mensagemErro('Erro - ' + erro.error.mensagem));
  }

  public desativarAtivar(){
    this.regraRegulamento.ativo = !this.regraRegulamento.ativo;
  }

  private irParaListaRegraRegulamento(){
    this.router.navigate(['/comum/lista-regra-regulamento']);
  }
}
