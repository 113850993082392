import {Component, Inject, OnInit} from '@angular/core';
import { Divisao } from '../../model/divisao';
import { Router } from '@angular/router';
import { UtilService } from '../../util/util.service';
import { DivisaoService } from '../../service/divisao.service';
import { FormGroup } from '@angular/forms';
import { ParametrosBusca } from '../../model/parametros_busca';
import { Noticia } from '../../model/noticia';

declare var $: any;

@Component({
  selector: 'app-divisoes-component',
  moduleId: module.id,
  templateUrl: './divisoes.component.html',
  styleUrls: ['./divisoes.style.css']
})

export class DivisoesComponent implements OnInit {

  // ----- Paginação Alteração

  public filtro: {
    nome: string
  } = {
    nome: null
  };

  public formFiltro: FormGroup;
  public parametrosBusca = new ParametrosBusca();
  options: ParametrosBusca = new ParametrosBusca();
  noticias: Noticia[] = [];

  public podeVoltar: boolean;
  public podeProsseguir = true;

  private botoesEsquerda: any[] = new Array(3);
  private botaoCentro: any;
  private botoesDireita: any[] = new Array(3);

  public botoes: any[] = new Array();

  private paginasDireita: number;
  private paginasEsquerda: number;
  private pagina = 1;
  private totalPaginas: number;

  private paginasLaterais = 2;
  private quantidadeRegistros: number;

  // ----- FimPaginaçao

  public divisoes: Divisao[] = [];
  public nomeDivisoes : string[] = [];
  //public pagina = [];
  public numPaginas : number = 0;
  /*public filtro: {
    nome : string
  } = {
    nome : null
  };*/

  public paginacao : {
    limit : number,
    offset : number,
    pagina : number
  } = {
    limit : null,
    offset : null, 
    pagina : null
  };
  
  public totalDivisoes : number;

  constructor(@Inject(Router) private router: Router,
              private divisaoService: DivisaoService,
              public utilService: UtilService) {
        
                this.pagina = 1;
                this.parametrosBusca.pagina = this.pagina;
                this.options.offset = 0;
                this.options.limit = 5;
                this.filtrar();
              }

  ngOnInit() {
    
  }

  public filtrar(): void {
    let filtro
    filtro = this.filtro;

    filtro.nome = $('#nome').val();

    this.options.offset = 0;
    this.options.offset = (this.pagina-1) * this.options.limit;

    this.divisaoService.buscaTodosFiltro({
        "filtro": filtro
    }, this.options).subscribe(divisoes => {
            if (divisoes) {
                this.divisoes = divisoes; 
            }
        });
    this.divisaoService.buscaQuantidadeRegistros({
        "filtro": filtro
    }, this.options).subscribe(quantidade => {
        this.quantidadeRegistros = quantidade[0].count;
        this.geraBotoes();
        this.mudarInfoBtns();
    })
  }

  public limpar(): void {
      this.options.offset = 0;
      this.pagina = 1;

      this.filtro = {
        nome: null
      }

      $('#nome').val('');

      this.divisaoService.buscaTodosFiltro({
          "filtro": this.filtro
      }, this.options).subscribe(divisoes => {
              if (divisoes) {
                  this.divisoes = divisoes;
              }
          });
      
      this.divisaoService.buscaQuantidadeRegistros({
          "filtro": this.filtro
      }, this.options).subscribe(quantidade => {
          this.quantidadeRegistros = quantidade[0].count;
          this.geraBotoes();
          this.mudarInfoBtns();
      })
  }

  private geraBotoes() {
      this.botoes = [];
      for(let i = 0; i < this.quantidadeRegistros/5; i++ ) {
          this.botoes.push(i+1);
      }
      this.totalPaginas = this.botoes.length;
      this.parametrosBusca.totalPaginas = this.totalPaginas;
  }

  public irParaPagina(pagina) :void {
      
      this.options.offset = (pagina-1)*5;

      this.divisaoService.buscaTodosFiltro({
          "filtro": this.filtro
      }, this.options).subscribe(divisoes => {
              if (divisoes) {
                  this.divisoes = divisoes;
              }
          });
  }/* ----- PAGINATION ----- */

  private geraBotoesPaginacao() {
    let paginas = this.parametrosBusca.totalPaginas;
    for(let i=0; i<paginas; i++) {
        this.botoes[i] = i+1;
    }
}

private configurarPagination() {    
    this.podeIrParaPaginaAnterior();
    this.podeIrParaProximaPagina();
}

private mudarInfoBtns() {

    if(this.pagina > 1) {
        this.podeVoltar = true;
    } else {
        this.podeVoltar = false;
    }

    if(this.pagina < this.totalPaginas) {
        this.podeProsseguir = true;
    } else {
        this.podeProsseguir = false;
    }
}

private podeIrParaPaginaAnterior(): void {
    this.podeVoltar = this.parametrosBusca.pagina != 1;
}

private podeIrParaProximaPagina(): void {
  this.podeProsseguir = (this.parametrosBusca.pagina != this.parametrosBusca.totalPaginas) &&
                         (this.parametrosBusca.totalPaginas > 0);
}

reiniciaPagina() {
  this.pagina = 1;
}

public irParaPrimeiraPagina(): void {
  this.pagina = 1;
  this.parametrosBusca.pagina = 1;
  this.parametrosBusca.offset = 0;
  this.configurarPagination();
  this.filtrar();
}

public irParaPaginaAnterior(): void {
  this.pagina--;
  this.parametrosBusca.pagina--;
  this.parametrosBusca.offset -= this.parametrosBusca.limit;
  this.configurarPagination();
  this.filtrar();
}

public pularParaPaginaEspecifica(pagina: number): void {
  this.pagina = pagina;
  this.parametrosBusca.offset = (pagina * this.parametrosBusca.limit) - this.parametrosBusca.limit;
  this.configurarPagination();
  this.filtrar();
}

public irParaProximaPagina(): void {
  this.parametrosBusca.pagina++;
  this.pagina++;
  this.parametrosBusca.offset += this.parametrosBusca.limit;
  this.configurarPagination();
  this.filtrar();
}

public irParaUltimaPagina(): void {
  this.pagina = this.totalPaginas;
  this.parametrosBusca.pagina = this.parametrosBusca.totalPaginas;
  this.parametrosBusca.offset = (this.parametrosBusca.totalPaginas * this.parametrosBusca.limit) - this.parametrosBusca.limit;
  this.configurarPagination();
  this.filtrar();
}

private exibeBotao(botao) {
  let exibe = false;

  if(botao <= this.pagina + this.paginasLaterais 
      && botao >= this.pagina - this.paginasLaterais) {
      exibe = true;
  } else if((this.pagina - this.paginasLaterais) <= 0 && (botao == 4 || botao == 5)) {
      exibe = true;
  } else if((this.pagina + this.paginasLaterais) >= this.totalPaginas 
      && (botao >= (this.totalPaginas - this.paginasLaterais*2))) {
      exibe = true;
  }
  
  return exibe;
}

  public getDivisoes() {
    this.divisaoService.buscaTodos().subscribe(divisoes => {
      this.divisoes = divisoes;
    });
  }

  private redireciona(id_divisao) {
    this.utilService.irPara(this.router, '/comum/divisao/' + id_divisao);
  }

  public editarDivisao(d: Divisao){
    this.utilService.irPara(this.router, '/comum/cadastro-divisao/' + d.id_divisao);
  }

  public excluirDivisao(d: Divisao){
    this.utilService.mensagemConfirmacao('Deseja excluir a divisão ' + d.nome + ' ?', (result) => {
      if(result){
        this.divisaoService.deleta(d.id_divisao).subscribe(
            success =>{
              this.utilService.mensagemSucesso("Exclusão realizada com sucesso!");
              this.filtrar();
            },
            error => {
              this.utilService.mensagemErro(error.error.mensagem);
            }
        );
      }
    });
  }

}
