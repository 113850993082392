import { Prova } from './prova';
import { Raca } from './raca';
import { Evento } from './evento';
import { Divisao } from './divisao';

export class ProvaRacas {
      id_prova_racas: number;
      valor_adicional_inscricao: number;
      porcentagem_premiacao: number;
      acrescimo_premiacao: number;
      correr_separado: boolean;
      nao_pontuar_profissional: boolean;
      id_prova: number;
      id_evento: number;
      id_divisao: number;
      id_raca: number;
      prova: Prova;
      evento: Evento;
      divisao: Divisao;
      raca: Raca;
      correr_tempo_base: boolean;
}