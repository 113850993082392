import { Component, OnInit, Inject } from '@angular/core';
import { UtilService } from '../../util/util.service';
import { RegraAssociacaoService } from '../../service/regra-associacao.service';
import { Router } from '@angular/router';
import { RegraAssociacao } from '../../model/regra-associacao';

@Component({
  selector: 'app-regras-associacao',
  templateUrl: './regras-associacao.component.html',
  styleUrls: ['./regras-associacao.component.css']
})
export class RegrasAssociacaoComponent implements OnInit {

  public regrasAssocicao : RegraAssociacao[] = [];

  constructor(private utilService : UtilService,
              private regraAssocicaoService : RegraAssociacaoService,
              @Inject(Router) private router : Router) { }

  ngOnInit() {
    this.buscaRegras();
  }

  private buscaRegras(){
    this.regraAssocicaoService.buscaTodos().subscribe(retorno => {
      this.regrasAssocicao = retorno;
    });
  }

  public editarRegraAssociacao(regraAssociacao : RegraAssociacao){
    this.utilService.irPara(this.router, '/comum/cadastro-regra-filiacao',{
      "id_regra_associacao" : regraAssociacao.id_regra_associacao
    });
  }

  public excluirRegraAssocicao(regraAssociacao : RegraAssociacao){
    let mensagem = "Deseja realmente remover a regra(" + regraAssociacao.nome + ")?";
    this.utilService.mensagemConfirmacao(mensagem, (resp) => {
      if(resp){
        this.regraAssocicaoService.deleta(regraAssociacao.id_regra_associacao)
        .subscribe(retorno => {
          if(retorno.status > 0 && retorno.mensagem){
            this.utilService.mensagemSucesso(retorno.mensagem);
            this.buscaRegras();
          }else if(retorno.status == 0 && retorno.mensagem){
            this.utilService.mensagemInfo(retorno.mensagem);
          }
        }, () => this.utilService.mensagemErro("Erro - Não foi possivel remover a Regra."));
      }
    })
  }

}
