import {Component, Inject, OnInit} from '@angular/core';
import { Router } from '@angular/router';
import { NoticiaEvento } from '../../model/noticia_evento';
import { Destaque } from '../../model/destaque';
import { DomSanitizer } from '@angular/platform-browser';
import { UtilService } from '../../util/util.service';
import { NoticiaService } from '../../service/noticia.service';
import { Noticia } from '../../model/noticia';
import { DestaqueService } from '../../service/destaque.service';

declare var $: any;
declare var swal: any;

@Component({
  selector: 'app-inicio-component',
  moduleId: module.id,
  templateUrl: './inicio.component.html',
  styleUrls: ['./inicio.style.css']
})

export class InicioComponent implements OnInit {

  public noticiasEventos: NoticiaEvento[] = [];
  public noticias: Noticia[] = [];
  public destaques: Destaque[] = [];

  constructor(@Inject(Router) private router: Router,
              private utilService: UtilService,
              private noticiaService: NoticiaService,
              private destaqueService : DestaqueService) {
  }

  ngOnInit() {
    this.utilService.mudaTitulo('RSNC Brazil');
    this.getNoticiasEventos();
    this.getDestaques();
  }

  private getNoticiasEventos() {
    this.noticiaService.buscaParaExibicao().subscribe(noticias => {
      this.noticias = noticias.filter(n => n.id_tipo_arquivo == 1);
      this.getImgNoticia(noticias);
    });
  }

  private getDestaques() {
    this.destaqueService.buscaTodos().subscribe(retorno => {
      this.destaques = retorno;
      //console.log('retorno dos destaques vendo oq vem ', retorno)
      this.buscaImagens();
    });
  }

  getImgNoticia(noticias: Noticia[]){
    noticias.forEach(noticia => {
      noticia.arquivo_imagem = this.utilService.buscaImagem(noticia);
    });
  }
  
  private redireciona(rota) {
    this.utilService.irPara(this.router, rota);
  }

  public buscaImagens(){
    this.destaques.forEach(destaque => {
      if(destaque.tipo_destaque == 1){
        destaque.endereco = this.utilService.buscaImagem(destaque);
      }
    })
  }
}
