import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';

import { SearchFormCampeonatosComponent } from './components/search-form-campeonatos/search-form-campeonatos.component';
import { OrderDivCampeonatosComponent } from './components/order-div-campeonatos/order-div-campeonatos.component';
import { AccordionCampeonatosComponent } from './components/accordion-campeonatos/accordion-campeonatos.component';
import { PaginationCampeonatosComponent } from './components/pagination-campeonatos/pagination-campeonatos.component';

import { VisualizarCampeonatosPage } from './pages/visualizar-campeonatos/visualizar-campeonatos.page';
import { DetalhesCampeonatoPage } from './pages/detalhes-campeonato/detalhes-campeonato.page';

import { CampeonatosResolver } from './campeonatos.resolver';
import { CampeonatoService } from '../../service/campeonato.service';
import { InformacoesCampeonatoModule } from './shared/informacoes-campeonato/informacoes-campeonato.module';
import { CampeonatoBuscaService } from './campeonatosBusca.service';

@NgModule({
    declarations: [
        SearchFormCampeonatosComponent,
        OrderDivCampeonatosComponent,
        AccordionCampeonatosComponent,
        PaginationCampeonatosComponent,
        
        VisualizarCampeonatosPage,
        DetalhesCampeonatoPage
    ],
    imports: [
        CommonModule,
        ReactiveFormsModule,
        HttpClientModule,
        InformacoesCampeonatoModule
    ],
    providers: [ 
        CampeonatoService,
        CampeonatosResolver,
        CampeonatoBuscaService
     ]
})
export class CampeonatosModule {}