import {Injectable, Inject} from '@angular/core';
import { Observable } from 'rxjs';
// @ts-ignore
import { environment } from 'environments/environment';
import { HttpClient } from '@angular/common/http';
import { Regra } from '../model/regra';

@Injectable()
export class RegraService {

    private regra_api_url = `${environment.api_url}/regras`;

    constructor(private http: HttpClient) {}

    public buscaTodos(filtro: string = null): Observable<Regra[]> {
      return this.http.get<Regra[]>(`${this.regra_api_url}`, {
        params: {
          'filtro': filtro ? filtro.toString() : '',
        } 
      });
    }

    public buscaPorId(id: number): Observable<Regra> {
      return this.http.get<Regra>(`${this.regra_api_url}/${id}`);
    }

    public buscaPorTipoRegra(tipo : number): Observable<Regra[]>{
      return this.http.get<Regra[]>(`${this.regra_api_url}/tipo-regra?tipo=${tipo}`);
    }

    public insere(regra: Regra): Observable<any> {
      return this.http.post<any>(`${this.regra_api_url}`, regra);
    }

    public altera(id: number, regra: Regra): Observable<any> {
      return this.http.put<any>(`${this.regra_api_url}/${id}`, regra);
    }

    public deleta(id: number): any {
      return this.http.delete<any>(`${this.regra_api_url}/${id}`);
    }

    public salvar(regra: Regra) {
      return this.http.post(`${this.regra_api_url}/salvar`, regra);
    }

}
