import { Component, OnInit } from '@angular/core';
import { UtilService } from '../../../../util/util.service';
import { RegraRegulamentoService } from '../../../../service/regra-regulamento.service';
import { Router } from '@angular/router';
import { RegraRegulamento } from '../../../../model/regra-regulamento';
import { FiltroRegraRegulamento } from '../../../../filter/regra-regulamento.filter';
import { Paginacao } from '../../../../model/paginacao';
import { OPERACAO_SUCESSO } from '../../../../util/constantes';

declare var $: any;
@Component({
  selector: 'app-lista-regra-regulamento',
  templateUrl: './lista-regra-regulamento.component.html',
  styleUrls: ['../../../comum.style.css', './lista-regra-regulamento.component.css']
})
export class ListaRegraRegulamentoComponent implements OnInit {

  public regraRegulamentos: RegraRegulamento[] = [];
  public filtro: FiltroRegraRegulamento = new FiltroRegraRegulamento();
  public paginacao: Paginacao = new Paginacao();
  public quantidadeRegistros: number = 0;
  public numPagina: number = 0;

  constructor(private utilService: UtilService,
              private regraRegulamentoService: RegraRegulamentoService,
              private router: Router) { }

  ngOnInit() {
    this.filtro.ativo = true;
    this.initPaginacao();
    this.buscaRegraRegulamentos();
  }

  private initPaginacao(){
    this.paginacao.limit = 20;
    this.paginacao.offset = 0;
    this.paginacao.pagina = 1;
  }

  private getFiltro(){
    this.filtro.limit = this.paginacao.limit;
    this.filtro.offset = this.paginacao.offset;
    this.filtro.pagina = this.paginacao.pagina;
  }

  public buscaRegraRegulamentos(){
    this.getFiltro();
    this.regraRegulamentoService.buscaFiltro(this.filtro)
    .subscribe(retorno => {
      this.regraRegulamentos = retorno.retorno;
      this.quantidadeRegistros = retorno.quantidade;
      this.quantidadePagina();
    });
  }

  public filtrar(titulo: string){
    this.filtro.titulo = titulo;
  }

  public limpar(){
    $('#titulo').val('');
    this.filtro.titulo = null;
    this.buscaRegraRegulamentos();
  }

  public selecionarStatus(status: string){
    this.filtro.ativo = status == 'null' ? null : status == 'true' ? true : false;
    this.initPaginacao();
    this.buscaRegraRegulamentos();
  }

  private quantidadePagina(){
    let paginas = 0;
    if(this.quantidadeRegistros % this.paginacao.limit == 0){
      paginas = (this.quantidadeRegistros / this.paginacao.limit);
    }else{
      paginas = (this.quantidadeRegistros / this.paginacao.limit) + 1;
    }
    this.numPagina = Math.floor(paginas);
  }

  public getPaginacao(paginacao: Paginacao){
    this.paginacao.limit = paginacao.limit;
    this.paginacao.offset = paginacao.offset;
    this.paginacao.pagina = paginacao.pagina;
    this.buscaRegraRegulamentos();
  }

  public irParaEdicao(id_regra_regulamento: number){
    this.utilService.irPara(this.router, '/comum/editar-regra-regulamento/' + id_regra_regulamento);
  }

  public ativar(id_regra_regulamento: number){
    this.utilService.mensagemConfirmacao("Deseja realmente ativar essa Regra Regulamento?",
    resp => {
      if(resp){
        this.desativarAtivar(id_regra_regulamento, true);
      }
    });
  }

  public desativar(id_regra_regulamento: number){
    this.utilService.mensagemConfirmacao("Deseja realmente desativar essa Regra Regulamento?",
    resp => {
      if(resp){
        this.desativarAtivar(id_regra_regulamento, false);
      }
    });
  }

  private desativarAtivar(id: number, status: boolean){
    this.regraRegulamentoService.desativarAtivar(id, status)
    .subscribe(() => {
      this.utilService.mensagemSucesso(OPERACAO_SUCESSO);
      this.buscaRegraRegulamentos();
    }, erro => this.utilService.mensagemErro('Erro - ' + erro.error.mensagem));
  }

  public excluir(id: number){
    this.utilService.mensagemConfirmacao("Dejesa realmente excluir o registro?",
    resp => {
      if(resp){
        this.regraRegulamentoService.excluir(id)
        .subscribe(() => {
          this.utilService.mensagemSucesso(OPERACAO_SUCESSO);
          this.buscaRegraRegulamentos();
        }, erro => this.utilService.mensagemErro('Erro - ' + erro.error.mensagem));
      }
    })
  }
}
