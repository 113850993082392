import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { UsuariosInscritos } from '../../../../model/prova.usuarios-inscritos';
import { Router } from '@angular/router';
import { InscricaoService } from '../../../../service/inscricao.service';

@Component({
  selector: 'app-pagination-lista-inscritos',
  templateUrl: './pagination-lista-inscritos.component.html',
  styleUrls: ['./pagination-lista-inscritos.component.css']
})
export class PaginationListaInscritosComponent implements OnInit {

  
  @Output() onclicking = new EventEmitter<any[]>();
  @Output() emiteUsuariosInscritos = new EventEmitter<UsuariosInscritos>();
  @Output() emiteProvasEInscritos = new EventEmitter<any[]>();
  @Output() atualizaLista = new EventEmitter<any>();
  @Input() usuariosInscritos : UsuariosInscritos;
  @Input() private numPaginas : number = 0;
  @Input() testeEvento : number
  public paginas = [];


  constructor(private route : Router,
              private inscricaoService: InscricaoService) { } 

  
  ngOnInit() {
  }

  ngOnChanges(){
    this.paginas = [];
    for(var i = 1; i <= this.numPaginas; i++){
      this.paginas.push(
        { 'key' : i,
          'paginaValue' : i}
      );
    }
  }

  buscarProvaAnterior(){
    const offset = this.usuariosInscritos.offset;
    if( offset > 0){
      this.usuariosInscritos.offset = this.usuariosInscritos.offset -  this.usuariosInscritos.limit;
      this.usuariosInscritos.pagina--;
      this.atualizaLista.emit(this.usuariosInscritos);
    }
  }

  buscarProximaProva(){
    const offset = Number(this.usuariosInscritos.offset);
    
    if(offset < ((this.numPaginas - 1) * this.usuariosInscritos.limit)){
      this.usuariosInscritos.offset = this.usuariosInscritos.offset + this.usuariosInscritos.limit;
      this.usuariosInscritos.pagina++;
      this.atualizaLista.emit(this.usuariosInscritos);
    }
  }

  pulaPagina(pagina : number){
    
    const offset = this.usuariosInscritos.offset;
    this.usuariosInscritos.offset = (Number(pagina) - 1) * this.usuariosInscritos.limit;

    if(offset < this.usuariosInscritos.offset){
      this.usuariosInscritos.pagina++;
    }
    if(offset > this.usuariosInscritos.offset){
      this.usuariosInscritos.pagina--;
    }

    this.atualizaLista.emit(this.usuariosInscritos);
  }

}
