import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { environment } from '../../environments/environment';
import { Evento } from '../model/evento';
import { ParametrosBusca } from '../model/parametros_busca';
import { UtilService } from '../util/util.service';

@Injectable()
export class EventoService {

  private evento_api_url = `${environment.api_url}/eventos`;
  public upload_imagem_api_url = `${environment.api_url}/file/upload`
  public eventoTemporario: Evento;
  private DEFAULT_IMG = 'evento_default_img.jpg';

  constructor(private http: HttpClient,
    private utilService: UtilService) {}

  public buscaTodos(): Observable<Evento[]> {
    return this.http.get<Evento[]>(`${this.evento_api_url}`);
  }

  public buscaPorId(id: number): Observable<Evento> {
    return this.http.get<Evento>(`${this.evento_api_url}/${id}`);
  }

  //vai ser trocado por buscaEvento
  public buscaPorId2(id: number): Observable<Evento> {
    return this.http.get<Evento>(`${this.evento_api_url}-buscaPorId2/${id}`);
  }

  public insere(evento: Evento) {
    return this.http.post<any>(`${this.evento_api_url}`, evento);
  }

  public buscaEventosDeUmCompetidor(id_usuario : number, filtro : any) : Observable<any>{
    return this.http.get<any>(`${this.evento_api_url}/historico/${id_usuario}`,{
      params :  {filtro : JSON.stringify(filtro)}
    });
  }

  public altera(id: number, evento: Evento): Observable<Evento> {
    return this.http.put<any>(`${this.evento_api_url}/${id}`, evento);
  }

  public deleta(id: number): Observable<any> {
    return this.http.delete<any>(`${this.evento_api_url}/${id}`);
  }

  public setEventoTemporario(evento: Evento) {
    this.eventoTemporario = evento;
  }

  public getEventoTemporario() {
    return this.eventoTemporario;
  }

  public resetEventoTemporario() {
    this.eventoTemporario = null;
  }

  public buscaTodosFiltro(filtro, options: ParametrosBusca): Observable<Evento[]> {
    return this.http.post<Evento[]>(`${this.evento_api_url}/buscar-filtro`, filtro, {
      params: {
        'offset': options.offset.toString(),
        'limit': options.limit.toString()
      }
    });
  }

  //VAI SER TROCADO POR BUSCARFILTRO
  public buscaTodosFiltro2(filtro, options: ParametrosBusca): Observable<Evento[]> {
    return this.http.post<Evento[]>(`${this.evento_api_url}/buscar-filtro-dois`, filtro, {
      params: {
        'offset': options.offset.toString(),
        'limit': options.limit.toString()
      }
    });
  }

  public buscaQuantidadeRegistros(filtro, options: ParametrosBusca): Observable<number> {
    return this.http.post<number>(`${this.evento_api_url}/buscar-quantidade-registros`, filtro, {
      params: {
        'offset': options.offset.toString(),
        'limit': options.limit.toString()
      }
    });
  }

  public exportarXml(id: number): Observable<any> {
    return this.http.get(`${this.evento_api_url}/exportar-xml/${id}`, {responseType: 'text'});
  }

  public buscaEventosPorOrganizador(idOrganizador: number, parametros: ParametrosBusca): Observable<Evento[]> {
    return this.http.get<Evento[]>(`${this.evento_api_url}/busca-eventos-por-id-organizador/${idOrganizador}`, {
      params: {
        parametros: JSON.stringify(parametros),
        is_adm: JSON.stringify(this.utilService.isAdm())
      }
    });
  }

  public buscaTotalRegistrosPorOrganizador(idOrganizador: number): Observable<number> {
    return this.http.get<number>(`${this.evento_api_url}/busca-total-registros-por-id-organizador/${idOrganizador}`, {
      params: {
        is_adm: JSON.stringify(this.utilService.isAdm())
      }
    });
  }

  public buscaEventosDeUmCampeonato(id_campeonato : number) : Observable<Evento[]> { 
    return this.http.get<Evento[]>(`${this.evento_api_url}-do-campeonato/${id_campeonato}`);
  }

  public buscaTotalRegistrosPorIdCampeonato(id_campeonato : number) : Observable<number> {
    return this.http.get<number>(`${this.evento_api_url}/total-eventos-por-campeonato/${id_campeonato}`);
  }

  public buscaEventosDeUmCampeonatoComFiltro(id_campeonato : number, filtro, options: ParametrosBusca) : Observable<Evento[]> {
    return this.http.post<Evento[]>(`${this.evento_api_url}/campeonato-com-filtro/`,filtro,{
      params : {
        'limit' : options.limit.toString(),
        'offset' : options.offset.toString()
      }
    })
  }

  public buscaFinanceiro(filtro : any) : Observable<any>{
    return this.http.get<any>(`${this.evento_api_url}-financeiro?filtro=${JSON.stringify(filtro)}`);
  }

  public buscaFinanceiroPessoal(id_usuario: number, filtro : any) : Observable<any>{
    return this.http.get<any>(`${this.evento_api_url}/financeiro-pessoal/${id_usuario}?filtro=${JSON.stringify(filtro)}`);
  }

  public atualizarStatusFinalizado(id_evento: number, status: boolean) : Observable<Evento>{
    return this.http.put<Evento>(`${this.evento_api_url}/atualizar-finalizado/${id_evento}`, {status: status})
  }

  public buscaEventosPorCompetidorCadastrador(id_usuario : number, filtro : any) : Observable<any>{
    return this.http.get<any>(`${this.evento_api_url}/historico-cadastrador-competidor/${id_usuario}`,{
      params :  {filtro : JSON.stringify(filtro)}
    });
  }

  public buscaAnoHipico() : Observable<any[]> {
    return this.http.get<any[]>(`${this.evento_api_url}-ano-hipico`);
  }

  public buscaPorIdCampeonato(id_campeonato : number) : Observable<Evento[]> { 
    return this.http.get<Evento[]>(`${this.evento_api_url}-por-campeonato/${id_campeonato}`);
  }

  public buscaPorAnoHipico(dataInicio: string, dataFim: string) : Observable<Evento[]> { 
    return this.http.get<Evento[]>(`${this.evento_api_url}-por-ano-hipico`, {
      params: {
        'dataInicio': dataInicio,
        'dataFim': dataFim
      } 
    });
  }

  public finalizaInscricao(id: number, data: string) : Observable<Evento> {
    return this.http.post<Evento>(`${this.evento_api_url}/finaliza-inscricao`, {id: id, data: data});
  }
}
