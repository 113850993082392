import {Component, Inject, OnInit, SecurityContext} from '@angular/core';
import { Router } from '@angular/router';
import { Noticia } from '../../model/noticia';
import { UtilService } from '../../util/util.service';
import { NoticiaService } from '../../service/noticia.service';
import { FormGroup } from '@angular/forms';
import { ParametrosBusca } from '../../model/parametros_busca';
import { NoticiasBuscaService } from './noticiasBusca.service';
import { DomSanitizer } from '@angular/platform-browser';

declare var $: any;

@Component({
  selector: 'app-noticias-component',
  moduleId: module.id,
  templateUrl: './noticias.component.html',
  styleUrls: ['../comum.style.css','./noticias.style.css']
})

export class NoticiasComponent implements OnInit {

  // ----- Paginação Alteração

  public filtro: {
      titulo: string,
      data_criacao: Date,
      ativa : boolean
  } = {
      titulo: null,
      data_criacao: null,
      ativa : true
  };

  public formFiltro: FormGroup;
  public parametrosBusca = new ParametrosBusca();
  options: ParametrosBusca = new ParametrosBusca();
  noticias: Noticia[] = [];

  public podeVoltar: boolean;
  public podeProsseguir = true;
  
  private botoesEsquerda: any[] = new Array(3);
  private botaoCentro: any;
  private botoesDireita: any[] = new Array(3);

  public botoes: any[] = new Array();

  private paginasDireita: number;
  private paginasEsquerda: number;
  private pagina = 1;
  private totalPaginas: number;

  private paginasLaterais = 2;
  private quantidadeRegistros: number;

  // ----- FimPaginaçao

  //public noticias: Noticia[] = [];
  //public filtro: any = {};

  constructor(@Inject(Router) private router: Router,
              private noticiaService: NoticiaService,
              private noticiasBuscaService: NoticiasBuscaService,
              public utilService: UtilService,
              private sanitizer: DomSanitizer) {
        
                this.pagina = 1;
                this.parametrosBusca.pagina = this.pagina;
                this.options.offset = 0;
                this.options.limit = 5;
                this.filtrar();
  }

  ngOnInit() {
    this.utilService.mudaTitulo('RSNC Brazil - Notícias');
  }

  public getNoticias() {
    this.noticiaService.buscaTodos().subscribe(noticias => {
      this.noticias = noticias;      
      this.getImgNoticia(noticias);
    });
  }

  public filtrar(): void {
    let filtro
    filtro = this.filtro;

    filtro.titulo = $('#titulo').val();

    this.options.offset = 0;
    this.options.offset = (this.pagina-1) * this.options.limit;
    
    this.noticiasBuscaService.filtro = filtro;

    this.noticiaService.buscaTodosFiltro({
        "filtro": filtro
    }, this.options).subscribe(noticias => {
            if (noticias) {
                this.getImgNoticia(noticias);
                this.noticias = noticias;
            }
        });
    this.noticiaService.buscaQuantidadeRegistros({
        "filtro": filtro
    }, this.options).subscribe(quantidade => {
        this.quantidadeRegistros = quantidade[0].count;
        this.geraBotoes();
        this.mudarInfoBtns();
    })
  }

  reiniciaPagina() {
    this.pagina = 1;
  }

  public limpar(): void {
      this.options.offset = 0;
      this.pagina = 1;
      
      this.noticiasBuscaService.filtro = {
          titulo: null,
          data_criacao: null
      };

      $('#titulo').val('');

      this.noticiaService.buscaTodosFiltro({
          "filtro": this.noticiasBuscaService.filtro
      }, this.options).subscribe(noticias => {
              if (noticias) {
                  this.getImgNoticia(noticias);
                  this.noticias = noticias;
              }
          });
      
      this.noticiaService.buscaQuantidadeRegistros({
          "filtro": this.noticiasBuscaService.filtro
      }, this.options).subscribe(quantidade => {
          this.quantidadeRegistros = quantidade[0].count;
          this.geraBotoes();
          this.mudarInfoBtns();
      })
  }

  private geraBotoes() {
      this.botoes = [];
      for(let i = 0; i < this.quantidadeRegistros/5; i++ ) {
          this.botoes.push(i+1);
      }
      this.totalPaginas = this.botoes.length;
      this.parametrosBusca.totalPaginas = this.totalPaginas;
  }

  public irParaPagina(pagina) :void {
      
      this.options.offset = (pagina-1)*5;

      this.noticiaService.buscaTodosFiltro({
          "filtro": this.noticiasBuscaService.filtro
      }, this.options).subscribe(noticias => {
              if (noticias) {
                  this.getImgNoticia(noticias);
                  this.noticias = noticias;
              }
          });
  }

  public desativarNoticia(noticia : Noticia){
    let mensagem : string = "Deseja realmente desativar a notícia("+noticia.titulo+")";
    this.utilService.mensagemConfirmacao(mensagem, (resp) => {
        if(resp){
            this.atualizaStatusNoticia(noticia.id_noticia, false);
        }
    });
  }

  public ativarNoticia(noticia : Noticia){
    let mensagem : string = "Deseja realmente ativar a notícia("+noticia.titulo+")";
    this.utilService.mensagemConfirmacao(mensagem, (resp) => {
        if(resp){
            this.atualizaStatusNoticia(noticia.id_noticia, true);
        }
    });
  }

  private atualizaStatusNoticia(id_noticia: number, status: boolean){
    this.noticiaService.ativaDesativa(id_noticia, status)
    .subscribe(retorno => {
        if(retorno){
            this.utilService.mensagemInfo("Operação realizada com sucesso!");
            this.filtrar();
        }else{
            this.utilService.mensagemInfo("Não foi possível realizar a operação!");
        }
    }, (error) =>{this.utilService.mensagemErro("Erro - " + error.error.message)});
  }

  /* ----- PAGINATION ----- */

  private geraBotoesPaginacao() {
      let paginas = this.parametrosBusca.totalPaginas;
      for(let i=0; i<paginas; i++) {
          this.botoes[i] = i+1;
      }
  }

  private configurarPagination() {    
      this.podeIrParaPaginaAnterior();
      this.podeIrParaProximaPagina();
  }

  private mudarInfoBtns() {

      if(this.pagina > 1) {
          this.podeVoltar = true;
      } else {
          this.podeVoltar = false;
      }

      if(this.pagina < this.totalPaginas) {
          this.podeProsseguir = true;
      } else {
          this.podeProsseguir = false;
      }
  }

  private podeIrParaPaginaAnterior(): void {
      this.podeVoltar = this.parametrosBusca.pagina != 1;
  }
  
  private podeIrParaProximaPagina(): void {
    this.podeProsseguir = (this.parametrosBusca.pagina != this.parametrosBusca.totalPaginas) &&
                           (this.parametrosBusca.totalPaginas > 0);
  }

public irParaPrimeiraPagina(): void {
    this.pagina = 1;
    this.parametrosBusca.pagina = 1;
    this.parametrosBusca.offset = 0;
    this.configurarPagination();
    this.filtrar();
}

public irParaPaginaAnterior(): void {
    this.pagina--;
    this.parametrosBusca.pagina--;
    this.parametrosBusca.offset -= this.parametrosBusca.limit;
    this.configurarPagination();
    this.filtrar();
}

public pularParaPaginaEspecifica(pagina: number): void {
    this.pagina = pagina;
    this.parametrosBusca.offset = (pagina * this.parametrosBusca.limit) - this.parametrosBusca.limit;
    this.configurarPagination();
    this.filtrar();
}

public irParaProximaPagina(): void {
    this.parametrosBusca.pagina++;
    this.pagina++;
    this.parametrosBusca.offset += this.parametrosBusca.limit;
    this.configurarPagination();
    this.filtrar();
}

public irParaUltimaPagina(): void {
    this.pagina = this.totalPaginas;
    this.parametrosBusca.pagina = this.parametrosBusca.totalPaginas;
    this.parametrosBusca.offset = (this.parametrosBusca.totalPaginas * this.parametrosBusca.limit) - this.parametrosBusca.limit;
    this.configurarPagination();
    this.filtrar();
}

private exibeBotao(botao) {
    let exibe = false;

    if(botao <= this.pagina + this.paginasLaterais 
        && botao >= this.pagina - this.paginasLaterais) {
        exibe = true;
    } else if((this.pagina - this.paginasLaterais) <= 0 && (botao == 4 || botao == 5)) {
        exibe = true;
    } else if((this.pagina + this.paginasLaterais) >= this.totalPaginas 
        && (botao >= (this.totalPaginas - this.paginasLaterais*2))) {
        exibe = true;
    }
    
    return exibe;
}

  getImgNoticia(noticias: Noticia[]){
    noticias.forEach(noticia => {
        if(noticia.id_tipo_arquivo == 1){
            noticia.arquivo_imagem = this.utilService.buscaImagem(noticia);
        }else if(noticia.id_tipo_arquivo == 3){
            noticia.arquivo_imagem = this.utilService.buscaPdf(noticia);
        }
    });
  }

  private redireciona(id_noticia) {
    this.noticiaService.redirecionaNoticia(id_noticia);
  }

  private redirecionaCampeonato(id_campeonato) {
    this.noticiaService.redirecionaCampeonato(id_campeonato);
  }

  private redirecionaEvento(id_evento) {
    this.noticiaService.redirecionaEvento(id_evento);
  }

  public editarNoticia(noticia: Noticia){
    this.utilService.irPara(this.router, '/comum/editar-noticia/' + noticia.id_noticia);
  }

  public selecionarStatus(status: string){
    this.filtro.ativa = status == "null" ? null : status == "true" ? true : false;
    this.reiniciaPagina();
    this.filtrar();
  }
}
