import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { UsuariosInscritos } from '../../../../model/prova.usuarios-inscritos';
import { UtilService } from '../../../../util/util.service';

@Component({
  selector: 'app-pagination-lista-inscricoes',
  templateUrl: './pagination-lista-inscricoes.component.html',
  styleUrls: ['./pagination-lista-inscricoes.component.css']
})
export class PaginationListaInscricoesComponent implements OnInit {
 @Output() onclicking = new EventEmitter<any[]>();
 @Output() emiteUsuarioInscricoes = new EventEmitter<UsuariosInscritos>();
 @Output() atualizaLista = new EventEmitter<any>();
 @Input() usuarioInscricoes : UsuariosInscritos;
 @Input() numPaginas : number = 0;
 paginas : any[] = [];

  constructor(private router : ActivatedRoute,
              public utilService : UtilService) { }


  ngOnInit() {
  }

  ngOnChanges(){
    this.paginas = [];
   for(var i = 1; i <= this.numPaginas; i++){
      this.paginas.push(
        { 'key' : i,
          'paginaValue' : i}
      );
    }

  }

  buscarProvaAnterior(){
   
    let aux = this.usuarioInscricoes.offset;
    if(aux > 0){
      this.usuarioInscricoes.offset = this.usuarioInscricoes.offset - this.usuarioInscricoes.limit;
      this.usuarioInscricoes.pagina--;
      this.atualizaLista.emit(this.usuarioInscricoes);
    }
  }

  pulaPagina(pagina : number){
    const aux = this.usuarioInscricoes.offset;
    this.usuarioInscricoes.offset = (Number(pagina) - 1) * this.usuarioInscricoes.limit;

    if(aux < this.usuarioInscricoes.offset){
      this.usuarioInscricoes.pagina++;
    }
    if(aux > this.usuarioInscricoes.offset){
      this.usuarioInscricoes.pagina--;
    }
    this.atualizaLista.emit(this.usuarioInscricoes);

  }
  
  buscarProximaProva(){
    let aux = this.usuarioInscricoes.offset;
    if(aux < ((this.numPaginas - 1) * this.usuarioInscricoes.limit)){
      this.usuarioInscricoes.offset = this.usuarioInscricoes.offset + this.usuarioInscricoes.limit;
      this.usuarioInscricoes.pagina++;
      this.atualizaLista.emit(this.usuarioInscricoes);
    }
  }

}
