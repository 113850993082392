import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { environment } from '../../../environments/environment';
import { Evento } from '../../model/evento';
import { ParametrosBusca } from '../../model/parametros_busca';

@Injectable()
export class OrganizadorBuscaService {

    private evento_api_url = `${environment.api_url}/eventos`;
    public upload_imagem_api_url = `${environment.api_url}/file/upload`
    public eventoTemporario: Evento;

    public parametrosBusca: ParametrosBusca = new ParametrosBusca();

    constructor(private http: HttpClient) {}
}
