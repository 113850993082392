import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
import { ListaRacasComponent } from './lista-racas/lista-racas.component';
import { DirectiveModule } from '../../directive/directive.module';
import { PaginacaoModule } from '../paginacao/paginacao.module';

@NgModule({
    declarations: [
        ListaRacasComponent
    ],
    imports: [
        CommonModule,
        BrowserModule,
        DirectiveModule,
        RouterModule,
        PaginacaoModule
    ],
    exports: [ListaRacasComponent],
    providers: []
})
export class RacaModule {}