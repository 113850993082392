import {Inject, Injectable} from '@angular/core';
import {Http} from '@angular/http';

@Injectable()
export class ComumService {

  constructor(@Inject(Http) private http: Http) {

  }

}
