import { Divisao } from './divisao';
import { ProvaRacas } from './prova_racas';
import { ErroRegra } from './erro-regra';

export class Prova{
    id_prova: number;
    data_finalizacao: string;
    prova_finalizada: boolean;
    tipo_prova: number;
    id_divisao: number;
    id_evento: number;
    iniciada: boolean;
    divisao: Divisao;
    valida: boolean = true;
    status_inscricao : boolean;
    qtdInscricao : number;
    preco_inscricao : string;
    inscricao_bloqueada : boolean;
    porcentagem_premiacao : string;
    somatorio_minimo : number;
    somatorio_maximo : number;
    taxa_administrativa : string;
    racasPontuar: ProvaRacas[];
    incremento_premiacao : string;
    numero_maximo_inscricao_competidor : number;
    qtd_maxima_inscricao_dupla : number;
    qtd_maxima_inscricao_trio : number;
    qtd_maxima_inscricao_cavalo : number;
    qtd_maxima_competidor : number;
    limite_inscricao : number;

    draw : boolean;
    regrasNaoAtendidas: ErroRegra[] = [];

    static fromJson(provaJson): Prova {
        let prova = new Prova();

        prova.id_prova = provaJson.id_prova;
        prova.tipo_prova = provaJson.tipo_prova;
        prova.iniciada = provaJson.iniciada;
        prova.prova_finalizada = provaJson.prova_finalizada;
        prova.data_finalizacao = provaJson.data_finalizacao;
        prova.divisao = provaJson.divisao;
        prova.id_evento = provaJson.id_evento;
        prova.status_inscricao = provaJson.status_inscricao;
        prova.qtdInscricao = provaJson.qtdInscricao;
        prova.preco_inscricao = provaJson.preco_inscricao;
        prova.inscricao_bloqueada = provaJson.inscricao_bloqueada;
        prova.somatorio_minimo = provaJson.somatorio_minimo;
        prova.somatorio_maximo = provaJson.somatorio_maximo;
        prova.draw = provaJson.draw;
        prova.regrasNaoAtendidas = [];
        prova.incremento_premiacao = provaJson.incremento_premiacao;
        prova.numero_maximo_inscricao_competidor = provaJson.numero_maximo_inscricao_competidor;
        prova.qtd_maxima_inscricao_dupla = provaJson.qtd_maxima_inscricao_dupla;
        prova.qtd_maxima_inscricao_trio = provaJson.qtd_maxima_inscricao_trio;
        prova.qtd_maxima_inscricao_cavalo = provaJson.qtd_maxima_inscricao_cavalo;
        prova.qtd_maxima_competidor = provaJson.qtd_maxima_competidor;
        prova.limite_inscricao =provaJson.limite_inscricao;
        return prova;
    }

    public addErroRegra(erro: ErroRegra): void {
        if (!this.regrasNaoAtendidas) {
            this.regrasNaoAtendidas = [];
        }
        this.regrasNaoAtendidas.push(erro);
    }

    public removeErroRegra(erro: ErroRegra): void {
        this.regrasNaoAtendidas.splice(this.regrasNaoAtendidas.indexOf(erro), 1);
    }

    public isValida(): boolean {
        return this.regrasNaoAtendidas && this.regrasNaoAtendidas.length > 0;
    }
}