import {NgModule} from '@angular/core';
import {UtilService} from './util.service';
import { UsuarioService } from '../service/usuario.service';

@NgModule({
  declarations: [],
  exports: [],
  providers: [
    UtilService,UsuarioService
  ]
})
export class UtilModule {
}
