import { Component, OnInit } from '@angular/core';
import { UtilService } from './../../../util/util.service';
import { EventoService } from './../../../service/evento.service';
import { Evento } from './../../../model/evento';
import { FotoEventoService } from '../../../service/foto-evento.service';
import { FotoEvento } from '../../../model/foto-evento';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';

declare var UIkit: any;

@Component({
  selector: 'app-cadastro-foto-evento',
  templateUrl: './cadastro-foto-evento.component.html',
  styleUrls: ['../../comum.style.css', './cadastro-foto-evento.component.css']
})
export class CadastroFotoEventoComponent implements OnInit {

  public titulo: string = 'Cadastro Foto(s) do Evento';
  private id_evento: number;
  private id_foto_evento: number;
  public evento: Evento = new Evento();
  public fotoEvento: FotoEvento = new FotoEvento();
  public form: FormGroup;

  constructor(private utilService: UtilService,
              private eventoService: EventoService,
              private fotoEventoService: FotoEventoService,
              private formBuild: FormBuilder,
              private router: Router,
              private activetedRoute: ActivatedRoute) { }

  ngOnInit() {
    this.id_evento = this.activetedRoute.snapshot.params.id_evento;
    this.id_foto_evento = this.activetedRoute.snapshot.params.id_foto_evento;
    this.buscaEvento();
    this.initPagina();
    this.initForm();
  }

  private initPagina(){
    if(this.id_foto_evento && !isNaN(this.id_foto_evento)){
      this.buscaFotoEvento();
      this.titulo = 'Editar Foto(s) do Evento';
    }else{
      this.initFotoEvento();
    }
  }

  private initFotoEvento(){
    this.fotoEvento.id_cadastrador = this.utilService.getIdLogado();
    this.fotoEvento.id_evento = this.id_evento;
  }

  private buscaEvento(){
    this.eventoService.buscaPorId2(this.id_evento)
    .subscribe(
      retorno => this.evento = retorno,
      erro => this.utilService.mensagemErro("Erro - " + erro.error.mensagem)
    );
  }

  private buscaFotoEvento(){
    this.fotoEventoService.buscaPorId(this.id_foto_evento)
    .subscribe(
      retorno => this.fotoEvento = retorno,
      erro => this.utilService.mensagemErro("Erro - " + erro.error.mensagem)
    );
  }

  private initForm(){
    this.form = this.formBuild.group({
      link : new FormControl('', [Validators.required, Validators.minLength(3), Validators.maxLength(200)])
    });
  }

  public salvar(){
    if(this.form.invalid){
      this.utilService.showErrors(this.form);
      return;
    }
    this.fotoEvento.link = this.fotoEvento.link.trim();
    if(this.fotoEvento.id_foto_evento){
      this.alterar();
    }else{
      this.inserir();
    }
  }

  private inserir(){
    this.fotoEventoService.inserir(this.fotoEvento)
    .subscribe(
      () => this.utilService.mensagemSucesso("Cadastro realizado com sucesso!",() => this.irParaEvento()),
      erro => this.utilService.mensagemErro('Erro - ' + erro.error.mensagem)
    );
  }

  private alterar(){
    this.fotoEvento.id_cadastrador = this.utilService.getIdLogado();
    this.fotoEventoService.alterar(this.fotoEvento.id_foto_evento, this.fotoEvento)
    .subscribe(
      () => this.utilService.mensagemSucesso("Operação realizada com sucesso!", () => this.irParaEvento()),
      erro => this.utilService.mensagemErro('Erro - ' + erro.error.mensagem)
    )
  }

  private irParaEvento(){
    this.router.navigate(['comum/evento/' + this.id_evento]);
  }
}
