import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ResultadoEventoRoutingModule } from './resultado-evento-routing.module';
import { ResultadoEventoListComponent } from './list/resultado-evento-list.component';
import { PaginacaoModule } from '../paginacao/paginacao.module';

@NgModule({
  declarations: [ResultadoEventoListComponent],
  imports: [
    CommonModule,
    ResultadoEventoRoutingModule,
    PaginacaoModule
  ]
})
export class ResultadoEventoModule { }
