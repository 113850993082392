import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Paginacao } from '../../../model/paginacao';

@Component({
  selector: 'app-paginacao',
  templateUrl: './paginacao.component.html',
  styleUrls: ['./paginacao.component.css']
})
export class PaginacaoComponent implements OnInit {

  @Input() numPagina: number = 0;
  @Input() paginacao: Paginacao;
  @Output() emitePaginacao = new EventEmitter<Paginacao>();
  public paginas: any[] = [];

  constructor() { }

  ngOnInit() {
  }

  ngOnChanges(){
    this.paginas = [];
    for(let i = 1; i <= this.numPagina; i++) {
      this.paginas.push({
        'key': i,
        'paginaValue': i
      })
    }
  }

  public buscarAnterior() {
    let offset = Number(this.paginacao.offset);
    if (offset != 0) {
      this.paginacao.offset = Number(this.paginacao.offset) - Number(this.paginacao.limit);
      this.paginacao.pagina--;
      this.emitterPaginacao();
    }
  }

  public buscarProximo() {
    let offset = Number(this.paginacao.offset);
    if (offset < ((this.numPagina - 1) * this.paginacao.limit)) {
      this.paginacao.offset = Number(this.paginacao.offset) + Number(this.paginacao.limit);
      this.paginacao.pagina++;
      this.emitterPaginacao();
    }
  }

  public pulaPagina(pagina: any) {
    let offset = this.paginacao.offset;
    this.paginacao.offset = (Number(pagina) - 1) * (this.paginacao.limit);
    if (offset > this.paginacao.offset) {
      this.paginacao.pagina--;
    } else {
      this.paginacao.pagina++;
    }
    this.emitterPaginacao();
  }

  public emitterPaginacao(){
    this.emitePaginacao.emit(this.paginacao);
  }

}
