import { OnInit, Component, Input, Output, EventEmitter } from '@angular/core';
import { Usuario } from '../../../../../model/usuario';
import { Cavalo } from '../../../../../model/cavalo';
import { InscricaoService } from '../../../../../service/inscricao.service';
import { UtilService } from '../../../../../util/util.service';

@Component({
    selector: 'selecao-de-competidores-por-competidor',
    templateUrl: './selecao-de-competidores-por-competidor.component.html',
    styleUrls: ['./selecao-de-competidores-por-competidor.style.css', './../../../inscricoes.style.css']
})
export class SelecaoDeCompetidoresPorCompetidorComponent implements OnInit {

    @Input() pagina: number;
    @Output() paginaEmitter: EventEmitter<number> = new EventEmitter<number>();

    @Input() numeroDeInscritos: number;
    public tipoCadastro: number = 1;

    public listaDeCompetidoresInscritos: Usuario[];

    @Output() proximaPaginaEmitter: EventEmitter<string[]> = new EventEmitter<string[]>();
    @Output() paginaAnteriorEmitter: EventEmitter<string[]> = new EventEmitter<string[]>();
    @Output() listaDeCompetidoresInscritosEmitter: EventEmitter<Usuario[]> = new EventEmitter<Usuario[]>();
    @Output() listaDeCavalosInscritosEmitter: EventEmitter<Cavalo[]> = new EventEmitter<Cavalo[]>();

    constructor(private inscricaoService : InscricaoService,
                private utilService : UtilService) {}
    ngOnInit(): void {
        this.inscricaoService.removerCompetidores();
    }

    public emitirListaDeCompetidoresInscritos(listaDeCompetidoresInscritos: Usuario[]): void {
        this.listaDeCompetidoresInscritos = listaDeCompetidoresInscritos;
        this.listaDeCompetidoresInscritosEmitter.emit(listaDeCompetidoresInscritos);
    }

    public emitirListaDeCavalosInscritos(listaDeCavalosInscritos: Cavalo[]): void {
        this.listaDeCavalosInscritosEmitter.emit(listaDeCavalosInscritos);
    }
    public emitirTipoCadastro(tipoCadastro: number): void {
        this.tipoCadastro = tipoCadastro;
    }

    public prosseguir(pagina: number): void {
        if((this.verificaAssociacaoCompetidor() && this.tipoCadastro == 1) || this.tipoCadastro == 2){
            this.paginaEmitter.emit(pagina);
        }else{
            this.utilService.mensagemInfo("Precisa realizar a filiação do competidor!");
        }
    }

    public regressar(pagina: number): void {
        this.paginaEmitter.emit(pagina);
    }


    public verificaAssociacaoCompetidor(){
        let retorno = true;
        if(this.listaDeCompetidoresInscritos && this.listaDeCompetidoresInscritos.length > 0){
            // se não poder realizar a inscricao do competidor sem cadastro com o competidor normal.
            let valida = this.listaDeCompetidoresInscritos
            .filter( competidor => competidor.associacao_competidor &&  Object.keys(competidor.associacao_competidor).length > 0);
            let competidores = this.listaDeCompetidoresInscritos.length;
            retorno = valida.length == competidores ? true : false;
        }
        return retorno;
    }
}