import { Component, OnInit, Inject } from '@angular/core';
import { UtilService } from '../../../util/util.service';
import { EventoService } from '../../../service/evento.service';
import { Router } from '@angular/router';
import { Inscricao } from '../../../model/inscricao';
import { InscricaoCompetidorService } from '../../../service/inscricao-competidor.service';
import { Evento } from '../../../model/evento';

@Component({
  selector: 'app-financeiro-pessoal',
  templateUrl: './financeiro-pessoal.component.html',
  styleUrls: ['./financeiro-pessoal.component.css']
})
export class FinanceiroPessoalComponent implements OnInit {

  public filtro: {
    tipo: string,
    id_usuario: number,
    id_evento: number
  } = {
    tipo: null,
    id_usuario: null,
    id_evento: null
  }
  public eventoFinanceiro: any[] = [];
  public resultado: any;
  public inscricoes : Inscricao[] = [];
  public nomeCompetidor : string = null;
  public associacoes : any[] = [];
  public valoresInscricoes : any;
  public valoresAssociacao : any;
  public custoIndividual : number = 0;
  public pagoIndividual : number = 0;
  public saldoIndividual : number = 0;
  public id_usuario : number;
  public custoTotal : number = 0;
  public pagoTotal : number = 0;
  public saldoTotal : number = 0;
  public evento: Evento = null;

  constructor(@Inject(Router) private router : Router,
              private utilService: UtilService,
              private eventoService: EventoService,
              private inscricaoCompetidorSerive: InscricaoCompetidorService) { }

  ngOnInit() {
    this.filtro.tipo = 'cadastrador';
    this.filtro.id_usuario = this.utilService.getIdLogado();
    this.nomeCompetidor = this.utilService.getNome();
    this.buscaFinanceiro();
  }

  public buscaFinanceiro() : void{
    this.eventoService.buscaFinanceiroPessoal(this.filtro.id_usuario, this.filtro)
    .subscribe(retorno => {
      this.eventoFinanceiro = retorno.financeiro;
      this.resultado = retorno.resultado;
    }, erro => {
      this.utilService.mensagemErro(erro.error.mensagem);
      this.irParaInicio();
    })
  }

  public selecionarTipo(tipo : string) : void{
    this.filtro.tipo = tipo;
    this.buscaFinanceiro();
  }

  public irParaInicio() : void{
    this.router.navigate(['/comum/inicio']);
  }

  public buscaInscricaoCompetidor(evento: Evento){
    this.evento = evento;
    if(this.filtro.tipo == 'cadastrador'){
      this.buscaPorCadastrador(evento.id_evento);
    }else if(this.filtro.tipo == 'competidor'){
      this.buscaPorCompetidor(evento.id_evento);
    }
  }

  public buscaPorCadastrador(id_evento: number){
    this.inscricaoCompetidorSerive.buscaPorCadastrador(this.filtro.id_usuario, id_evento)
    .subscribe(retorno => {
      this.inscricoes = retorno.inscricoes;
      this.associacoes = retorno.associacoes;
      this.valoresInscricoes = retorno.valoresInscricoes;
      this.valoresAssociacao = retorno.valoresAssociacao;
      this.calculaCustoIndividual(null);
    });
  }

  public buscaPorCompetidor(id_evento: number){
    this.inscricaoCompetidorSerive.buscaPorCompetidor(this.filtro.id_usuario, id_evento)
    .subscribe(retorno => {
      this.inscricoes = retorno.inscricoes;
      this.associacoes = retorno.associacoes;
      this.valoresInscricoes = retorno.valoresInscricoes;
      this.valoresAssociacao = retorno.valoresAssociacao;
      this.calculaCustoIndividual(this.filtro.id_usuario);
    });
  }

  public calculaCustoIndividual(id_usuario : number){
    this.custoIndividual = 0;
    this.pagoIndividual = 0;
    this.saldoIndividual = 0;
    this.inscricoes.forEach(inscricao => {
      let competidor = [];
      if(this.filtro.tipo == 'competidor'){
        competidor = inscricao.competidores.filter( comp => comp.id_usuario == id_usuario);
      }else if(this.filtro.tipo == 'cadastrador'){
        competidor = inscricao.competidores;
      }
      this.custoIndividual += competidor.reduce((x,y) => x + y.custo, 0);
      this.pagoIndividual += competidor.reduce((x,y) => x + y.pago, 0);
    });
    this.pagoTotal = this.pagoIndividual + this.valoresAssociacao.pagoTotal;
    this.custoTotal =  this.custoIndividual + this.valoresAssociacao.custoTotal;
    this.saldoIndividual = this.pagoIndividual - this.custoIndividual;
    this.saldoTotal = this.pagoTotal - this.custoTotal;
  }

  public irParaEditarInscricao(id_inscricao : number){
    this.utilService.irPara(this.router, '/comum/editar-inscricao', {
      "id_inscricao" : id_inscricao
    });
  }

  public verificaEdicaoInscricao(inscricao : any) :boolean {
    let retorno = false;
    let idLogado = this.utilService.getIdLogado();
    if((this.utilService.isAdm() ||
      (idLogado == this.evento.id_organizador) ||
      (idLogado == inscricao.id_cadastrador)) &&
      !(inscricao.draw == true && inscricao.tipo_inscricao == inscricao.tipo_prova)){
      retorno = this.validaEdicaoInscricaoEvento();
    }
    return retorno;
  }

  public validaEdicaoInscricaoEvento(){
    let encerrado = this.utilService.verificaEncerramentoInscricao(this.evento.data_fim_inscricoes);
    let inicioInscricao = this.utilService.verificaInicioInscricao(this.evento.data_inicio_inscricoes);
    let maximoInscricoes = this.utilService
    .verificaMaximoInscricoesEvento(this.evento.maximo_inscricoes, this.evento.quantidade_inscricoes);
    return !((encerrado || maximoInscricoes || !inicioInscricao || this.evento.finalizado) && !this.utilService.isAdm())
  }
}
