import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'inteiro'
})
export class InteiroPipe implements PipeTransform {

  transform(value: string): string {
    if (!value) {
      return '';
    }

    if (value.length !== 11) {
      return value;
    }

    return value.replace(/[^0-9]/gi, '');
  }

}
