import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { SelecaoDeProvasPorProvaComponent } from './selecao-de-provas-por-prova.component';
import { InformacoesProvaModule } from '../../../shared/informacoes-prova/informacoes-prova.module';

@NgModule({
    declarations: [SelecaoDeProvasPorProvaComponent],
    imports: [
        CommonModule,
        RouterModule,
        InformacoesProvaModule
    ],
    exports: [SelecaoDeProvasPorProvaComponent]
})
export class SelecaoDeProvasPorProvaModule {}