export class ResultadoEvento {

    id_resultado_evento: number;
    id_evento: number;
    id_cadastrador: number;
    id_tipo_arquivo: number;
    titulo: string;
    descricao: string;
    arquivo_exibicao: string;
    arquivo_old: string;
    data_criacao: string;
    data_modificacao: string;
}