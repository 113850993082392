import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SelecaoDeProvasPorCompetidorComponent } from './selecao-de-provas-por-competidor.component';
import { CompetidoresSelecionadosModule } from '../../../shared/competidores-selecionados/competidores-selecionados.module';
import { InformacoesProvaModule } from '../../../shared/informacoes-prova/informacoes-prova.module';

@NgModule({
    declarations: [SelecaoDeProvasPorCompetidorComponent],
    imports: [
        CommonModule,
        CompetidoresSelecionadosModule,
        InformacoesProvaModule
    ],
    exports: [SelecaoDeProvasPorCompetidorComponent]
})
export class SelecaoDeProvasPorCompetidorModule {}