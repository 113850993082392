import { Component, OnInit, OnDestroy, Input, Inject } from "@angular/core";
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

import { Treinador } from "../../model/treinador";
import { ActivatedRoute, Router } from '@angular/router';
import { UtilService } from '../../util/util.service';
import { TreinadoresService } from '../../service/treinador.service';

declare var UIkit: any;

@Component({
    selector: "app-treinadores-component",
    templateUrl: "treinadores.component.html",
    styleUrls: ['treinadores.style.css'],
    moduleId: module.id
})
export class TreinadoresComponent implements OnInit, OnDestroy {
    
    treinadores: Treinador[];
    @Input() treinador_selecionado: Treinador;
    filter: string = '';
    debounce: Subject<string> = new Subject<string>();

    constructor(private activatedRoute: ActivatedRoute,
                public utilService: UtilService,
                @Inject(Router) private router: Router,
                private treinadorService: TreinadoresService) {}
    
    ngOnInit(): void {
        this.debounce
            .pipe(debounceTime(150))
            .subscribe(filter => this.filter = filter);
        this.treinadores = this.activatedRoute.snapshot.data.treinadores;
        this.treinador_selecionado = this.treinadores[0];
        this.getTreinadoresThumbnail();
    }

    ngOnDestroy(): void {
        this.debounce.unsubscribe();
    }

    chamaModal(treinador: Treinador) {
        this.treinador_selecionado = treinador;
        UIkit.modal('#modal-treinador',{
            bgClose: false
        }).show();
    }

    getTreinadoresThumbnail(){
        this.treinadores.forEach(treinador => {
            treinador.thumbnail = this.utilService.buscaThumbnail(treinador);
        });
    }

    getTreinadores(){
        
    }

}