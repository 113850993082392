import { Component, OnInit } from '@angular/core';
import { UtilService } from '../../../util/util.service';
import { ResultadoEventoService } from '../../../service/resultado-evento.service';
import { EventoService } from '../../../service/evento.service';
import { Evento } from '../../../model/evento';
import { ResultadoEvento } from '../../../model/resultado-evento';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { ResultadoEventoFiltro } from '../../../filter/resultado-evento.filter';
import { ActivatedRoute, Router } from '@angular/router';
import { Paginacao } from '../../../model/paginacao';

@Component({
  selector: 'app-resultado-evento-list',
  templateUrl: './resultado-evento-list.component.html',
  styleUrls: ['../../comum.style.css', './resultado-evento-list.component.css']
})
export class ResultadoEventoListComponent implements OnInit {

  id_evento: number;
  evento: Evento;
  resultadosEvento: ResultadoEvento[] = [];
  debounceResultadoEvento: Subject<string> = new Subject();
  filtro: ResultadoEventoFiltro = new ResultadoEventoFiltro();
  public paginacao: Paginacao = new Paginacao();
  public quantidadeRegistros: number = 0;
  public numPagina: number = 0;

  constructor(public utilService: UtilService,
              private resultadoEventoService: ResultadoEventoService,
              private eventoService: EventoService,
              private activatedRoute: ActivatedRoute,
              private router: Router) { }

  ngOnInit() {
    this.id_evento = this.activatedRoute.snapshot.params.id_evento;
    if(this.id_evento && !isNaN(this.id_evento)){
      this.filtro.id_evento = this.id_evento;
      this.initPaginacao();
      this.getFiltro();
      this.buscaEvento();
      this.buscaResultadoEventoFiltro();
      this.initDebounceResultadoEvento();
    }
  }

  private initPaginacao(){
    this.paginacao.limit = 8;
    this.paginacao.offset = 0;
    this.paginacao.pagina = 1;
  }

  private getFiltro(){
    this.filtro.limit = this.paginacao.limit;
    this.filtro.offset = this.paginacao.offset;
    this.filtro.pagina = this.paginacao.pagina;
  }

  private initDebounceResultadoEvento(){
    this.debounceResultadoEvento.pipe(debounceTime(400))
    .subscribe(titulo => {
      this.filtro.titulo = titulo;
      this.buscaResultadoEventoFiltro();
    })
  }

  public buscaEvento(){
    this.eventoService.buscaPorId2(this.id_evento)
    .subscribe(
      retorno => this.evento = retorno,
      erro => this.mensagemErro(erro.error.mensagem)
    );
  }

  public filtroTitulo(titulo: string){
    this.initPaginacao();
    if(titulo && titulo.length >= 3){
      this.debounceResultadoEvento.next(titulo);
    }else{
      this.debounceResultadoEvento.next(null);
    }
  }

  public buscaResultadoEventoFiltro(){
    this.getFiltro();
    this.resultadoEventoService.buscaPorFiltro(this.filtro)
    .subscribe(
      retorno => {
        this.quantidadeRegistros = retorno.quantidade;
        this.resultadosEvento = retorno.resultadosEvento;
        this.getArquivoExibicao(this.resultadosEvento);
        this.quantidadePagina();
      },
      erro => this.mensagemErro(erro.error.mensagem)
    );
  }

  private quantidadePagina(){
    let paginas = 0;
    if(this.quantidadeRegistros % this.paginacao.limit == 0){
      paginas = (this.quantidadeRegistros / this.paginacao.limit);
    }else{
      paginas = (this.quantidadeRegistros / this.paginacao.limit) + 1;
    }
    this.numPagina = Math.floor(paginas);
  }

  public getPaginacao(paginacao: Paginacao){
    this.paginacao.limit = paginacao.limit;
    this.paginacao.offset = paginacao.offset;
    this.paginacao.pagina = paginacao.pagina;
    this.buscaResultadoEventoFiltro();
  }

  private getArquivoExibicao(resultadosEvento: ResultadoEvento[]){
    resultadosEvento.forEach(resultado => resultado.arquivo_exibicao = this.utilService.buscaPdf(resultado));
  }

  private mensagemErro(mensagem: string){
    this.utilService.mensagemErro("Erro - " + mensagem);
  }

  public excluir(id_resultado_evento: number){
    this.resultadoEventoService.excluir(id_resultado_evento)
    .subscribe(
      () => {
        this.utilService.mensagemSucesso('Operação realizada com sucesso!');
        this.buscaResultadoEventoFiltro();
      },
      erro => this.mensagemErro(erro.error.mensagem)
    )
  }

  public editar(id_resultado_evento: number){
    this.router.navigate(['comum/evento/' + this.id_evento + '/editar-resultado/' + id_resultado_evento]);
  }
}
