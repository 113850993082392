import { Component, OnInit } from '@angular/core';
import { Cavalo } from '../../../model/cavalo';
import { UtilService } from '../../../util/util.service';
import { CavaloService } from '../../../service/cavalo.service';
import { Moderacao } from '../../../model/moderacao';

@Component({
  selector: 'app-moderacao-cavalo',
  templateUrl: './moderacao-cavalo.component.html',
  styleUrls: ['./moderacao-cavalo.component.css']
})
export class ModeracaoCavaloComponent implements OnInit {

  cavalos: Cavalo[] = [];

  constructor(private utilService: UtilService,
              private cavaloService: CavaloService) { }

  ngOnInit() {
    this.buscaCavaloPendente();
  }

  buscaCavaloPendente(){
    this.cavaloService.buscaPendente()
    .subscribe(
      retorno => this.cavalos = retorno,
      erro => this.utilService.mensagemErro(erro.error.message));
  }

  aprovar(cavalo: Cavalo){
    var moderacao = this.getModeracao();
    moderacao.id = cavalo.id_cavalo;
    moderacao.ativo = true;
    this.moderarCavalo(moderacao, "Cavalo aprovado com sucesso!");
  }

  rejeitar(cavalo: Cavalo){
    var moderacao = this.getModeracao();
    moderacao.id = cavalo.id_cavalo;
    moderacao.ativo = false;
    this.moderarCavalo(moderacao, "Cavalo rejeitado com sucesso!");
  }

  getModeracao(): Moderacao{
    var moderacao = new Moderacao();
    moderacao.pendente = false;
    return moderacao;
  }

  moderarCavalo(moderacao: Moderacao, mensagem: string){
    this.cavaloService.moderar(moderacao)
    .subscribe(retorno => {
      this.utilService.mensagemSucesso(mensagem);
      this.buscaCavaloPendente();
    }, erro => this.utilService.mensagemErro("Erro - " + erro.error.message));
  }

}
