import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpParams } from '@angular/common/http';

import { environment } from '../../environments/environment';
import { Campeonato } from '../model/campeonato';
import { ParametrosBusca } from '../model/parametros_busca';

@Injectable()
export class CampeonatoService {

  private campeonato_api_url = `${environment.api_url}/campeonatos`;
  public upload_imagem_api_url = `${environment.api_url}/file/upload`;
  private DEFAULT_IMG = 'campeonato_default_img.jpg';

  constructor(private http: HttpClient) { }

  public buscaTodos(filtro:string = null, filtroData: string = null): Observable<Campeonato[]> {
    
    return this.http.get<Campeonato[]>(`${this.campeonato_api_url}` , {
      params: {
        'filtro': filtro ? filtro.toString() : '',
        'filtroData': filtroData ? filtroData.toString() : ''
      } 
    });
  };

  public buscaTodosFiltro(filtro, options: ParametrosBusca): Observable<Campeonato[]> {
    return this.http.post<Campeonato[]>(`${this.campeonato_api_url}/buscar-filtro`, filtro, {
      params: {
        'offset': options.offset.toString(),
        'limit': options.limit.toString()
      }
    });
  }

  //vai ser trocado por buscaTodosFiltro
  public buscaTodosFiltro2(filtro, options: ParametrosBusca): Observable<Campeonato[]> {
    return this.http.post<Campeonato[]>(`${this.campeonato_api_url}/buscar-filtro-dois`, filtro, {
      params: {
        'offset': options.offset.toString(),
        'limit': options.limit.toString()
      }
    });
  }

  public buscaQuantidadeRegistros(filtro, options: ParametrosBusca): Observable<number> {
    return this.http.post<number>(`${this.campeonato_api_url}/buscar-quantidade-registros`, filtro, {
      params: {
        'offset': options.offset.toString(),
        'limit': options.limit.toString()
      }
    });
  }

  public buscaPorId(id: number): Observable<Campeonato> {
    return this.http.get<Campeonato>(`${this.campeonato_api_url}/${id}`);
  }

  public buscaCampeonatosDeUmCompetidor(id_usuario : number) : Observable<any>{
    return this.http.get<any>(`${this.campeonato_api_url}/historico/${id_usuario}`);
  }
  
  public insere(campeonato: Campeonato): Observable<any> {
    return this.http.post<any>(`${this.campeonato_api_url}`, campeonato);
  }

  public altera(id: number, campeonato: any): Observable<any> {
    return this.http.put<any>(`${this.campeonato_api_url}/${id}`, campeonato);
  }

  public deleta(id: number): Observable<any> {
    return this.http.delete<any>(`${this.campeonato_api_url}/${id}`);
  }

  public salvar(campeonato: Campeonato): Observable<any> {
    return this.http.post<Campeonato>(`${this.campeonato_api_url}/salvar`, campeonato);
  }

  public buscaPorNome(nome: string): Observable<Campeonato[]> {
    return this.http.post<Campeonato[]>(`${this.campeonato_api_url}/busca-por-nome`, { nome });
  }

  public buscaPaginado(options: ParametrosBusca): Observable<Campeonato[]> {
    return this.http.get<Campeonato[]>(`${this.campeonato_api_url}`, {
      params: {
        'offset': options.offset.toString(),
        'limit': options.limit.toString()
      } 
    });
  }

  /*public uploadImagem(formData: FormData): Observable<any> {
    return this.http.post(`${environment.api_url}/file/upload`, formData);
  }

  public getImageFromService(campeonato: Campeonato) :string {
    if(campeonato.imagem_exibicao){
      return `${environment.api_url}/file/campeonato/${campeonato.imagem_exibicao}`;
    }else{
      return `${environment.api_url}/file/campeonato/${this.DEFAULT_IMG}`;
    }
  }*/

  public buscaCampeonatosAtivos(): Observable<Campeonato[]> {
    return this.http.get<Campeonato[]>(`${environment.api_url}/campeonato-busca-ativos`);
  }

  public buscaPorId2(id : number) : Observable<Campeonato>{
    return this.http.get<Campeonato>(`${this.campeonato_api_url}/editar-campeonato/${id}`);
  }

  public buscaPorAnoHipico(dataInicio: string, dataFim: string): Observable<Campeonato[]> {
    return this.http.get<Campeonato[]>(`${this.campeonato_api_url}-por-ano-hipico` , {
      params: {
        'dataInicio': dataInicio,
        'dataFim': dataFim
      } 
    });
  };

}
