import { Component, OnInit, Inject, ɵConsole } from '@angular/core';
import { UtilService } from '../../../util/util.service';
import { AssociacaoCompetidorService } from '../../../service/associacao-competidor';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { Paginacao } from '../../../model/paginacao';
import { AnoHipico } from '../../../model/ano-hipico';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';

@Component({
  selector: 'app-lista-associados',
  templateUrl: './lista-associados.component.html',
  styleUrls: ['./lista-associados.component.css']
})
export class ListaAssociadosComponent implements OnInit {

  public titulo_pagina : string = "Lista de Filiados";
  private debouncefiliados: Subject<string> = new Subject<string>();
  public associados : any[] = [];
  public anoHipico: AnoHipico = new AnoHipico();
  public filtro : {
    tipo : string,
    nome : string,
    offset : number,
    limit : number,
    statusPagamento : boolean,
    pagina: number,
    ordenar: string,
    tipoOrdenacao: string,
    inicio: string,
    fim: string
  } = {
    tipo : "cadastrador",
    nome : null,
    offset : 0,
    limit : 500,
    statusPagamento : true,
    pagina : 1,
    ordenar : 'nome_competidor',
    tipoOrdenacao: 'ASC',
    inicio: null,
    fim: null
  }
  public carregando : boolean = false;
  public paginacao: Paginacao = new Paginacao();
  public quantidadeRegistro: number = 0;
  public numPagina: number = 0;
  form: FormGroup;

  constructor(private utilService : UtilService,
              private associacaoCompetidorService : AssociacaoCompetidorService,
              @Inject(Router) private router : Router,
              private formBuild: FormBuilder) { }

  ngOnInit() {
    this.anoHipico.inicio = "";
    this.anoHipico.fim = "";
    this.initPaginacao();
    this.initForm();
    this.buscaAssociados();
  }

  private initForm(){
    this.form = this.formBuild.group({
      inicio: new FormControl('',
        [Validators.minLength(4), Validators.maxLength(4)]),
      fim: new FormControl('',
        [Validators.minLength(4), Validators.maxLength(4)]),
      nome:  new FormControl(this.filtro.nome)
    });
  }

  public initPaginacao(){
    this.paginacao.limit = 200;
    this.paginacao.offset = 0;
    this.paginacao.pagina = 1;
  }

  public getFiltro(){
    this.filtro.limit = this.paginacao.limit;
    this.filtro.offset = this.paginacao.offset;
    this.filtro.pagina = this.paginacao.pagina;
  }

  public selecionarTipo(tipo : string){
    this.filtro.tipo = tipo;
    this.initPaginacao();
    this.buscaAssociados();
  }

  public buscaAssociados(){
    this.getFiltro();
    this.associacaoCompetidorService.buscaTodos(this.filtro)
    .subscribe(retorno => {
      this.associados = retorno.retorno;
      this.quantidadeRegistro = retorno.quantidade;
      this.quantidadePagina();
    }, erro => {
      this.utilService.mensagemErro("Erro - " + erro.error.message);
    });
  }

  public limpar(){
    this.anoHipico.inicio = '';
    this.anoHipico.fim = '';
    this.filtro.nome = '';
    this.filtro.inicio = '';
    this.filtro.fim = '';
    this.buscaAssociados();
  }

  public pesquisar(){
    if(!this.validaAno()){
      return;
    }
    this.filtro.inicio = this.anoHipico.inicio ? this.anoHipico.inicio + '-07-01' : this.anoHipico.inicio;
    this.filtro.fim = this.anoHipico.fim ? this.anoHipico.fim + '-07-01' : this.anoHipico.fim;
    this.buscaAssociados();
  }

  private validaAno(){
    if((!this.anoHipico.inicio && this.anoHipico.fim) ||
      (this.anoHipico.inicio && !this.anoHipico.fim)){
      this.utilService.mensagemErro("O inicio e fim precisam está preenchidos.");
      return false;
    }

    if((this.anoHipico.inicio != "" && this.anoHipico.fim != "") &&
     (this.form.controls['inicio'].invalid || this.form.controls['fim'].invalid)){
      this.utilService.mensagemErro("O ano precisa ter 4 digitos.");
      return false;
    }

    if(isNaN(this.form.controls['inicio'].value) || isNaN(this.form.controls['fim'].value)){
      this.utilService.mensagemErro("Só aceita números!");
      return false;
    }

    if(this.anoHipico.inicio && this.anoHipico.fim &&
      (Number(this.anoHipico.inicio) >= Number(this.anoHipico.fim))){
      this.utilService.mensagemErro("O ano fim precisa ser maior que o ano de inicio!");
      return false;
    }
    return true;
  }

  public removerAssociacao(associado : any){
    this.utilService.mensagemConfirmacao("Deseja realmente remover a filiação do(a) " +
    associado.nome_competidor + "?",
    (resp) => {
      if(resp){
        this.associacaoCompetidorService.deleta(associado.id_associacao_competidor)
        .subscribe(
          retorno => this.buscaAssociados(),
          erro => this.utilService.mensagemErro(erro.error.mensagem)
        );
      }
    });
  }

  public selecionarStatusPagamento(statusPagamento: string){
    this.filtro.statusPagamento = statusPagamento == "null" ? null : statusPagamento== 'true' ? true : false;
    this.initPaginacao();
    this.buscaAssociados();
  }

  private quantidadePagina(){
    let paginas = 0;
    if(this.quantidadeRegistro % this.paginacao.limit == 0){
      paginas = (this.quantidadeRegistro / this.paginacao.limit);
    }else{
      paginas = (this.quantidadeRegistro / this.paginacao.limit) + 1;
    }
    this.numPagina = Math.floor(paginas);
  }

  public getPaginacao(paginacao: Paginacao){
    this.paginacao.limit = paginacao.limit;
    this.paginacao.offset = paginacao.offset;
    this.paginacao.pagina = paginacao.pagina;
    this.buscaAssociados();
  }

  public ordenar(coluna: string){
    if (this.filtro.ordenar == coluna) {
      this.filtro.tipoOrdenacao = this.filtro.tipoOrdenacao == 'ASC' ? 'DESC' : 'ASC';
    }else{
      this.filtro.ordenar = coluna;
      this.filtro.tipoOrdenacao = 'ASC';
    }
    this.buscaAssociados();
  }
}
