import { Component, Inject, OnInit, ViewChild, ElementRef } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl, AbstractControl } from '@angular/forms';
import { Router } from '@angular/router';
import { UtilService } from '../../../util/util.service';
import { Usuario } from '../../../model/usuario';
import { RespostaHandicap } from '../../../model/resposta_handicap';
import { PerguntaHandicap } from '../../../model/pergunta_handicap';
import * as cep from 'cep-promise'
import { UsuarioService } from '../../../service/usuario.service';
import { PerguntaHandicapService } from '../../../service/pergunta-handicap.service';
import {Portuguese} from 'flatpickr/dist/l10n/pt.js';
import flatpickr from 'flatpickr';
import { EmailService } from '../../../service/email.service';
import { RespostaPergunta } from '../../../model/resposta-pergunta';
import { RespostaPerguntaService } from '../../../service/resposta-pergunta';


declare var $: any;
declare var swal: any;

@Component({
  selector: 'app-cadastro-usuario-component',
  moduleId: module.id,
  templateUrl: './cadastro-usuario.component.html',
  styleUrls: ['./cadastro-usuario.style.css']
})

export class CadastroUsuarioComponent implements OnInit {
  public usuario: Usuario;
  public formCadastro: FormGroup;
  public handicap: number = 0;
  public formQuestionario: FormGroup;
  public perguntas: PerguntaHandicap[] = [];
  public perguntas2: PerguntaHandicap[] = [];
  public respostas: RespostaHandicap[] = [];
  public respostas2: RespostaHandicap[] = [];
  public mobile = false;

  public mostrarSenha: boolean = false;
  public mostrarConfirmarSenha: boolean = false;
  public filtro: any = {};

  public cpf_duplicado: boolean = false;
  public login_duplicado: boolean = false;
  public email_duplicado: boolean = false;
  public confirmar_email: string;
  public confirmar_senha: string;
  public respostasPergunta : RespostaPergunta[] = [];

  @ViewChild('senha') inputSenha: ElementRef;
  @ViewChild('confirmarSenha') inputConfirmarSenha: ElementRef;

  constructor(@Inject(Router) private router: Router,
    @Inject(FormBuilder) private formBuilder: FormBuilder,
    @Inject(UtilService) public utilService: UtilService,
    @Inject(UsuarioService) private usuarioService: UsuarioService,
    @Inject(PerguntaHandicapService) private perguntaHandicapService: PerguntaHandicapService,
    private emailService: EmailService,
    private respostaPerguntaService : RespostaPerguntaService) {

    utilService.mudaTitulo('RSNC Brasil - Cadastro de usuário');

    this.usuario = new Usuario();

    this.mobile = utilService.isMobile();

    const email = new FormControl('', [
      Validators.required,
      Validators.pattern("^([0-9a-zA-Z]+([_.-]?[0-9a-zA-Z]+)*@[0-9a-zA-Z]+[0-9,a-z,A-Z,.,-]*(.){1}[a-zA-Z]{2,4})+$")
    ]);

    const senha = new FormControl('', [
      Validators.required,
    ]);

    this.formCadastro = formBuilder.group({
      id: [this.usuario.id_usuario, []],
      nome: [this.usuario.nome, [
        Validators.required,
        Validators.minLength(5),
        Validators.maxLength(100)
      ]],
      apelido: [this.usuario.apelido, [
        Validators.maxLength(50)
      ]],
      data_nascimento: [this.usuario.data_nascimento, [
        Validators.required,
        Validators.minLength(10),
        Validators.maxLength(10)
      ]],
      sexo: [this.usuario.sexo, [
        Validators.required
      ]],
      cpf: [this.usuario.cpf, [
        Validators.required,
        Validators.minLength(14),
        Validators.maxLength(14)
      ]
    ],
      rg: [this.usuario.rg, [
        Validators.required,
        Validators.minLength(5),
        Validators.maxLength(15)
      ]],
      email: email,
      confirmar_email: ['', [
        Validators.required,
        Validators.maxLength(100),
        UtilService.confirmar(email)
      ]],
      telefone: [this.usuario.telefone, [
        Validators.required,
        Validators.minLength(14),
        Validators.maxLength(15)
      ]],
      cep: [this.usuario.cep, [
        Validators.required,
        Validators.minLength(9),
        Validators.maxLength(9)
      ]],
      estado: [this.usuario.estado, [
        Validators.required,
        Validators.minLength(2),
        Validators.maxLength(50)
      ]],
      cidade: [this.usuario.cidade, [
        Validators.required,
        Validators.minLength(3),
        Validators.maxLength(50)
      ]],
      bairro: [this.usuario.bairro, [
        Validators.required,
        Validators.minLength(3),
        Validators.maxLength(50)
      ]],
      logradouro: [this.usuario.logradouro, [
        Validators.required,
        Validators.minLength(3),
        Validators.maxLength(50)
      ]],
      numero: [this.usuario.numero, [
        Validators.required,
        Validators.maxLength(50)
      ]],
      login: [this.usuario.login, [
        Validators.required,
        Validators.minLength(5),
        Validators.maxLength(50)
      ]],
      senha: senha,
      confirmar_senha: ['', [
        Validators.required,
        Validators.maxLength(100),
        UtilService.confirmar(senha)
      ]],
      competidor: [this.usuario.competidor]
    });

    this.formQuestionario = formBuilder.group({
    });
  }

  ngOnInit() {
    $("#formQuestionario").hide();
    this.geraListaHandicap();
    this.montaDatePicker();
  }

  proximo() {
    if (this.formCadastro.invalid) {
      this.utilService.destacarErros(this.formCadastro);
      return;
    }
    if(!this.utilService.validaCpf(this.usuario.cpf)){
      this.cpfInvalido();
      return;
    }
    $("#formCadastroUsuario").hide();
    $("#formQuestionario").show();
  }

  validarCPF() {
    this.usuarioService.validarCpf(this.usuario.cpf).subscribe(retorno => {
      this.cpf_duplicado = retorno;
    })
  }

  validarLogin() {
    this.usuarioService.validarLogin(this.usuario.login).subscribe(retorno => {
      this.login_duplicado = retorno;
    })
  }

  validarEmail() {
    this.usuarioService.validarEmail(this.usuario.email).subscribe(retorno => {
      this.email_duplicado = retorno;
    })
  }

  anterior() {
    $("#formQuestionario").hide();
    $("#formCadastroUsuario").show();
  }

  private montaDatePicker(): void {
    const fp = flatpickr("#data_nascimento",{
        disableMobile: true,
        locale: Portuguese,
        altFormat: 'd/m/Y',
        ariaDateFormat: 'd/m/Y',
        dateFormat: 'd/m/Y',
    });

  }

  private cpfInvalido() {
    this.formCadastro.controls['cpf'].setValue(null);
    this.usuario.cpf = null;
    this.utilService.mensagemErro("CPF invalido!");
  }

  salvar() {
    if(!this.utilService.validaCpf(this.usuario.cpf)){
      this.cpfInvalido();
    }else{
      if (!this.usuario.competidor) {
        this.usuario.ativo = true;
        this.usuario.pendente = false;
        this.utilService.mensagemConfirmacao("Enviar cadastro de não competidor?", (result) => {
          if (result) {
            this.usuario.handicap = 0;
            this.cadastrarUsuario("Não competidor cadastrado com sucesso!");
          }
        });
      } else {
        this.usuario.ativo = false;
        this.usuario.pendente = true;
        this.utilService.mensagemConfirmacao("Enviar cadastro competidor?", (result) => {
          if (result) {
            let dataNascimento = this.usuario.data_nascimento.split('/');
            let idadeHipico = this.utilService.
            calculoIdadeUsuarioComBaseAnoHipico(Number(dataNascimento[2]), Number(dataNascimento[1])-1, Number(dataNascimento[0]));
            let idadeAtual = this.getAge(Number(dataNascimento[2]), Number(dataNascimento[1])-1, Number(dataNascimento[0]));
            this.usuario.handicap = this.handicap;
            this.cadastrarUsuario("Competidor enviado para análise!");
          }
        });
      }
    }
  }

  private cadastrarUsuario(msg: string) {
    this.usuarioService
      .cadastrar(this.usuario)
      .subscribe(data => {
        this.respostasPergunta.forEach(rp => rp.id_usuario = data.usuario.id_usuario);
        if(this.respostasPergunta.length > 0 && this.usuario.competidor){
          this.inserirRespostaPergunta(msg);
        }else{
          this.utilService.mensagemSucesso(msg);
          this.redireciona();
        }
      });
  }

  geraListaHandicap() {
    this.getPerguntas();
  }

  atualizaQuestionario(resposta) {
    $("#botaoEnviar").prop("disabled", true);
    this.adicionaRespostaPergunta(resposta);
    this.perguntas.forEach(function (perg) {
      if (perg.id_pergunta_handicap > resposta.id_pergunta) {
        perg.pergunta_oculta = true;
      }
    });
    this.handicap = 0;
    if (resposta.handicap == 0) {
      this.perguntas.find(p => p.id_pergunta_handicap === resposta.id_proxima_pergunta).pergunta_oculta = false;
    } else {
      this.handicap = resposta.handicap;
      this.usuario.handicap = resposta.handicap;
      $("#botaoEnviar").prop("disabled", false);
    }

    if(resposta.id_pergunta == 3 && resposta.resposta == "Não"){
      var respostaPergunta = this.respostasPergunta.filter(rp => rp.id_pergunta == 1 && rp.id_resposta == 2)[0];
      let dataNascimento = this.usuario.data_nascimento.split('/');
      let idadeHipico = this.utilService.
      calculoIdadeUsuarioComBaseAnoHipico(Number(dataNascimento[2]), Number(dataNascimento[1])-1, Number(dataNascimento[0]));
      if(respostaPergunta && idadeHipico <= 13){
        this.handicap = 1;
        this.usuario.handicap = 1;
      }
    }
  }

  handicapInvalido() {
    if (this.handicap == 0) {
      return true;
    }
    return false;
  }

  buscaCep() {
    if ((this.usuario.cep != null) && (this.usuario.cep.length >= 9)) {
      cep(this.usuario.cep)
        .then((data) => {
          this.usuario.cidade = data.city;
          this.usuario.logradouro = data.street;
          this.usuario.bairro = data.neighborhood;
          this.usuario.estado = data.state;
        })
        .catch(console.log);
    } else {
      this.usuario.cidade = "";
      this.usuario.logradouro = "";
      this.usuario.bairro = "";
      this.usuario.estado = "";
    }
  }

  alteraOption(pergunta) {
    let value = (<HTMLSelectElement>document.getElementById('select-pergunta-' + pergunta.id_pergunta)).value;
    let resposta = this.respostas.find(p => p.id_resposta == +value);
    this.atualizaQuestionario(resposta);
  }

  public getPerguntas() {
    this.perguntaHandicapService.buscarTodosResposta(null).subscribe(data => {
      this.perguntas = data;
    });
  }

  changeShowPassword(value: any) {
    this.mostrarSenha = value;
    this.inputSenha.nativeElement.type = this.mostrarSenha ? 'text' : 'password';
  }

  changeShowConfirmPassword(value: any) {
    this.mostrarConfirmarSenha = value;
    this.inputConfirmarSenha.nativeElement.type = this.mostrarConfirmarSenha ? 'text' : 'password';
  }

  private redireciona() {
    this.utilService.irPara(this.router, '/comum/inicio/');
  }

  private redirecionaContato() {
    this.utilService.irPara(this.router, '/comum/contato/');
  }

  public calculoIdadeComBaseNoAnoHipico()
  {
    let retorno = 0;
    let dataNascimento = this.usuario.data_nascimento.split('/');
    retorno = this.utilService.calculoIdadeUsuarioComBaseAnoHipico(Number(dataNascimento[2]), Number(dataNascimento[1])-1, Number(dataNascimento[0]));
    return retorno;
  }

  
  public getAge(year, month, day) {
    var now = new Date()	
    var age = now.getFullYear() - year
    var mdif = now.getMonth() - month + 1 //0=jan	
    
    if(mdif < 0)
    {
        --age
    }
    else if(mdif == 0)
    {
        var ddif = now.getDate() - day
        
        if(ddif < 0)
        {
            --age
        }
    }

     return age
  }

  private adicionaRespostaPergunta(resposta : any){
    let respostaPergunta = new RespostaPergunta();
    respostaPergunta.id_pergunta = resposta.id_pergunta;
    respostaPergunta.id_resposta = resposta.id_resposta_handicap;
    respostaPergunta.sem_cadastro = false;

    if(this.respostasPergunta.length == 0){
      this.respostasPergunta.push(respostaPergunta);
    }else{
      let control = true;
      this.respostasPergunta.forEach(rp => {
        if((rp.id_pergunta == respostaPergunta.id_pergunta) &&
          (rp.id_resposta != respostaPergunta.id_resposta)){
          rp.id_resposta = respostaPergunta.id_resposta;
          control = false;
        }
      });

      if(control){
        this.respostasPergunta.push(respostaPergunta);
      }
    }
  }

  private inserirRespostaPergunta(msg : string){
    this.respostaPerguntaService.inserirLista(this.respostasPergunta)
    .subscribe(retorno =>{
      this.utilService.mensagemSucesso(msg);
      this.redireciona();
    });
  }


}

$(document).ready(function () {
  $('#confirmar_email').on("cut copy paste", function (e) {
    e.preventDefault();
  });
});