import { NgModule } from '@angular/core';
import { DateDirective } from './date.directive';
import { RgDirective } from './rg.directive';
import { CpfPipe } from './cpf.pipe';
import { RgPipe } from './rg.pipe';
import { TelefonePipe } from './telefone.pipe';
import { TelefoneDirective } from './telefone.directive';
import { CpfDirective } from './cpf.directive';
import { CnpjDirective } from './cnpj.directive';
import { CepDirective } from './cep.directive';
import { DarkenOnHoverDirective } from './darken-on-hover.directive';
import { DinheiroDirective } from './dinheiro.directive';
import { InteiroDirective } from './inteiro.directive';
import { InteiroPipe } from './inteiro.pipe';

@NgModule({
  declarations: [
    CepDirective,
    CnpjDirective,
    CpfDirective,
    DateDirective,
    RgDirective,
    TelefoneDirective,
    CpfPipe,
    RgPipe,
    TelefonePipe,
    DarkenOnHoverDirective,
    DinheiroDirective,
    InteiroDirective,
    InteiroPipe
  ],
  exports: [
    CepDirective,
    CnpjDirective,
    CpfDirective,
    DateDirective,
    RgDirective,
    TelefoneDirective,
    CpfPipe,
    RgPipe,
    TelefonePipe,
    DarkenOnHoverDirective,
    DinheiroDirective,
    InteiroPipe,
    InteiroDirective
  ]
})
export class DirectiveModule {
}
