import { Component, Inject, OnInit, Input, OnDestroy, ɵConsole, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { UtilService } from '../../../util/util.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Evento } from '../../../model/evento';
import { EventoService } from '../../../service/evento.service';
import { CampeonatoService } from '../../../service/campeonato.service';
import { Campeonato } from '../../../model/campeonato';
import { Divisao } from '../../../model/divisao';
import { DivisaoService } from '../../../service/divisao.service';
import { ProvaService } from '../../../service/prova.service';
import { EventoRaca } from '../../../model/evento-raca';
import { EventoRacaService } from '../../../service/evento-raca.service';
import { environment } from '../../../../environments/environment';
import { NoticiaService } from '../../../service/noticia.service';
import { Noticia } from '../../../model/noticia';
import { Regra } from '../../../model/regra';
import { RegraService } from '../../../../app/service/regra.service';
import { RegraEventoService } from '../../../../app/service/regra-evento.service';
import { RegraEvento } from '../../../model/regra_evento';
import { RegraModificada } from './regraModificada.model';
import { DivisaoInformacoes } from '../../custom-listbox/DivisaoInformacoes.model';

import { Subject } from 'rxjs';
import { ProvaEditada } from '../../../model/ProvaEditada';
import { CustomListboxComponent } from '../../custom-listbox/custom-listbox.component';
import { ProvaRacasService } from '../../../service/prova-racas.service';
import { ProvaRacas } from '../../../model/prova_racas';

import { PubSub } from 'pubsub-js';

import { Usuario } from '../../../model/usuario';
import { UsuarioService } from '../../../service/usuario.service';
import { Select } from '../../../model/select';

declare var $: any;
declare var UIkit: any;

@Component({
    selector: 'app-evento-component',
    moduleId: module.id,
    templateUrl: './cadastro-evento.component.html',
    styleUrls: ['./cadastro-evento.style.css']
})

export class CadastroEventoComponent implements OnInit {
    @ViewChild(CustomListboxComponent) filho : CustomListboxComponent;

    
    public evento: Evento = new Evento();
    private campeonato: Campeonato = new Campeonato();
    public formCadastro: FormGroup;
    private id_campeonato: number;
    public titulo_pagina = 'Cadastro de evento';
    
    public divisoes: Divisao[] = [];
    public divisoes_evento: Divisao[] = [];
    private divisoes_evento_copia: Divisao[] = [];
    public racas_pontuar: EventoRaca[] = [];
    private id_divisaoSelecionada: number;
    private selectedFile: File = null;
    public imageToShow: any;
    public regrasGerais : Regra[] = [];
    
    private virarNoticia: boolean = false;
    public eventoReplicado: number;
    
    private flagTitulo: boolean = false;
    private tituloAntigo: string;
    
    private regras: Regra[];
    private inputs: any[] = [];
    private inputSelect: any[] = [];
    public regras_evento: RegraEvento[] = [];
    public precoInscricaoEidDivisao : any[] = [];
    
    public isSelected: boolean = false;
    
    private regrasModificadas: RegraModificada[] = [];
    public informacoesDivisao : DivisaoInformacoes [] = [];
    public recebePrecoInscricao: String;
    
    public horario_inicio: string;
    public horario_final: string;
    public horario_inicio_inscricoes: string;
    public horario_final_inscricoes: string;
    
    public provasEditadas : ProvaEditada[] = [];

    public valueOrganizador : string = null;
    public organizadores : Usuario[] = [];
    public erro : boolean = false;

    public selectCampeonatos: any[] = [];
    
    constructor(@Inject(Router) private router: Router,
        @Inject(ActivatedRoute) private route: ActivatedRoute,
        @Inject(FormBuilder) private formBuilder: FormBuilder,
        @Inject(UtilService) public utilService: UtilService,
        @Inject(EventoService) private eventoService: EventoService,
        @Inject(CampeonatoService) private campeonatoService: CampeonatoService,
        @Inject(DivisaoService) private divisaoService: DivisaoService,
        @Inject(ProvaService) private provaService: ProvaService,
        @Inject(EventoRacaService) private eventoRacaService: EventoRacaService,
        private noticiaService: NoticiaService,
        private regraService: RegraService,
        private regraEventoService: RegraEventoService,

        private provaRacasService: ProvaRacasService,
        private usuarioService : UsuarioService) {
            
            utilService.mudaTitulo('RSNC Brasil - Cadastro de evento');
            
        this.formCadastro = formBuilder.group({
            id: [this.evento.id_evento, []],
            titulo: [this.evento.titulo, [
                Validators.required,
                Validators.minLength(5),
                Validators.maxLength(100)
            ]],
            descricao: [this.evento.descricao, [
                Validators.required,
                Validators.minLength(5)
            ]],
            data_inicial: [this.evento.data_inicial, [
                Validators.required
            ]],
            data_final: [this.evento.data_final, [
                Validators.required
            ]],
            data_inicio_inscricoes: [this.evento.data_inicio_inscricoes, [
                Validators.required
            ]],
            data_fim_inscricoes: [this.evento.data_fim_inscricoes, [
                Validators.required
            ]],
            telefone: [this.evento.telefone, [
                Validators.required,
                Validators.minLength(14),
                Validators.maxLength(15)
            ]],
            website: [this.evento.website, [
                Validators.required,
                Validators.minLength(5),
                Validators.maxLength(100)
            ]],
            localizacao: [this.evento.localizacao, [
                Validators.required,
                Validators.minLength(5),
                Validators.maxLength(100)
            ]],
            localizacao_maps: [this.evento.localizacao_maps],
            preco_inscricao: [this.evento.preco_inscricao, [
                Validators.required,
                Validators.minLength(1),
                Validators.maxLength(8)
            ]],
            porcentagem_premiacao: [this.evento.porcentagem_premiacao, [
                Validators.required,
                Validators.max(100)
            ]],
            maximo_incricoes_competidor: [this.evento.maximo_inscricoes_competidor, [
                Validators.minLength(1),
                Validators.maxLength(5)
            ]],
            maximo_inscricoes_duplas: [this.evento.maximo_inscricoes_duplas, [
                Validators.minLength(1),
                Validators.maxLength(5)
            ]],
            maximo_inscricoes_trio: [this.evento.maximo_inscricoes_trio, [
                Validators.minLength(1),
                Validators.maxLength(5)
            ]],
            maximo_inscricoes_cavalo: [this.evento.maximo_inscricoes_cavalo, [
                Validators.minLength(1),
                Validators.maxLength(5)
            ]],
            maximo_inscricoes: [this.evento.maximo_inscricoes],
            tempo_passada: [this.evento.tempo_passada_todos_contra_todos, [
                Validators.min(60),
                Validators.max(100)
            ]],
            maximo_participantes: [this.evento.maximo_inscricoes_todos_contra_todos, [
                Validators.min(1),
                Validators.max(100)
            ]],
            preco_inscricao_todos_contra_todos: [this.evento.preco_inscricao_todos_contra_todos, [
                Validators.maxLength(8)
            ]],
            porcentagem_premiacao_todos_contra_todos: [this.evento.porcentagem_premiacao_todos_contra_todos, [
                Validators.max(100)
            ]],
            incremento_premiacao: [this.evento.incremento_premiacao_todos_contra_todos, [
                Validators.maxLength(8)
            ]],
            quantidade_premiados: [this.evento.quantidade_premiados_todos_contra_todos, [
                Validators.minLength(1),
                Validators.maxLength(5)
            ]],
            imagem: [this.selectedFile, [
                Validators.required
            ]],
            taxa_administrativa : [this.evento.taxa_administrativa,[
                Validators.required,
                Validators.maxLength(8)
            ]],
            id_organizador : [this.evento.id_organizador,[
                Validators.required
            ]],
            incremento_preco : [this.evento.incremento_preco,[
                Validators.maxLength(8)
            ]]
        });

        this.inicializarComponentes();
    }

    private inicializarComponentes() {
        this.buscarInformacoesEvento();
        this.getDivisoes();
        this.buscarRacasPontuar();
        if (this.eventoService.getEventoTemporario()) {
            this.evento = this.eventoService.getEventoTemporario();
            this.eventoService.resetEventoTemporario();
            this.divisoes_evento = this.evento.divisoes;
        }
        this.getRegras();

    }

    private getOrganizador(id_organizador){
        this.usuarioService.buscaPorId(id_organizador).subscribe(organizador => {
            this.evento.nome_organizador = organizador.nome;
        });
    }

    ngOnInit() {
        this.utilService.montaDatePickerHorario('data_inicial');
        this.utilService.montaDatePickerHorario('data_final');
        this.utilService.montaDatePickerHorario('data_inicio_inscricoes');
        this.utilService.montaDatePickerHorario('data_fim_inscricoes');

        this.filho.ngOnInit();
        
        this.uploadImagem();
        this.buscaCampeonatos();
    }

    public mudaDataInicialInscricao(event) {
        let dateInicial = this.utilService.converteNewData(event.target.value);
        let idCampeonato = parseInt(this.route.snapshot.queryParamMap.get("idCampeonato"));
        
        if(!isNaN(dateInicial.getTime())) {
            let dataInicialIncricao = new Date(dateInicial);
            dataInicialIncricao.setDate(dateInicial.getDate() - 15);
            dataInicialIncricao.setHours(10);
    
            let dataFimInscricao = new Date(dateInicial);
            dataFimInscricao.setDate(dateInicial.getDate() - 2);
            dataFimInscricao.setHours(21);
    
            this.evento.data_inicio_inscricoes = new Date(dataInicialIncricao).toLocaleString();
            this.evento.data_inicio_inscricoes = this.utilService.ajustaDataHorario(this.evento.data_inicio_inscricoes);
            this.utilService.montaDatePickerHorario('data_inicio_inscricoes', this.evento.data_inicio_inscricoes);
    
            this.evento.data_fim_inscricoes = new Date(dataFimInscricao).toLocaleString();
            this.evento.data_fim_inscricoes = this.utilService.ajustaDataHorario(this.evento.data_fim_inscricoes);
            this.utilService.montaDatePickerHorario('data_fim_inscricoes', this.evento.data_fim_inscricoes);
        } else {
            this.utilService.montaDatePickerHorario('data_inicio_inscricoes');
            this.utilService.montaDatePickerHorario('data_fim_inscricoes');
        }
    }

    private buscarInformacoesEvento() {
        let idEvento = parseInt(this.route.snapshot.queryParamMap.get("id_evento"));
        let idEventoReplicado = parseInt(this.route.snapshot.queryParamMap.get('idEventoReplicado'));
        let idCampeonato = parseInt(this.route.snapshot.queryParamMap.get("idCampeonato"));
        if(!isNaN(idCampeonato)) {
            this.evento.id_campeonato = idCampeonato;
            this.getCampeonato(idCampeonato);
        }
        
        if (!isNaN(idEvento)) {
            this.provaService.addIdEvento(idEvento);
            this.eventoService.buscaPorId(idEvento).subscribe(
                ev => {
                    this.formCadastro.get('imagem').setErrors(null);
                    this.titulo_pagina = 'Editar evento';
                    this.id_campeonato = ev.id_campeonato;
                    this.evento = ev;
                    this.formCadastro.controls['id_organizador'].setValue(Number(ev.id_organizador));
                    this.evento.imagem_old = this.evento.imagem_exibicao;
                    
                    this.recebePrecoInscricao = String(this.evento.preco_inscricao);
                    this.provaService.addValorEvento(String(this.evento.preco_inscricao),ev.porcentagem_premiacao, ev.taxa_administrativa);

                    this.evento.data_inicial = new Date(this.evento.data_inicial).toLocaleString();
                    this.evento.data_final = new Date(this.evento.data_final).toLocaleString();
                    this.evento.data_inicio_inscricoes = new Date(this.evento.data_inicio_inscricoes).toLocaleString();
                    this.evento.data_fim_inscricoes = new Date(this.evento.data_fim_inscricoes).toLocaleString();

                    this.evento.data_inicial = this.utilService.ajustaDataHorario(this.evento.data_inicial);
                    this.utilService.montaDatePickerHorario('data_inicial', this.evento.data_inicial);

                    this.evento.data_final = this.utilService.ajustaDataHorario(this.evento.data_final);
                    this.utilService.montaDatePickerHorario('data_final', this.evento.data_final);

                    this.evento.data_inicio_inscricoes = this.utilService.ajustaDataHorario(this.evento.data_inicio_inscricoes);
                    this.utilService.montaDatePickerHorario('data_inicio_inscricoes', this.evento.data_inicio_inscricoes);

                    this.evento.data_fim_inscricoes = this.utilService.ajustaDataHorario(this.evento.data_fim_inscricoes);
                    this.utilService.montaDatePickerHorario('data_fim_inscricoes', this.evento.data_fim_inscricoes);

                    this.divisaoService.buscaPorEvento(idEvento).subscribe(divisoes => {
                        this.getDivisoes();                       
                        this.divisoes_evento = divisoes.sort(function (a, b) {
                            return a.nome > b.nome ? 1 : a.nome < b.nome ? -1 : 0;
                        });

                        this.divisoes_evento.forEach(de => {
                            let obj = {
                                id_divisao : de.id_divisao,
                                preco_inscricao : de.preco_inscricao
                            }
                            
                        this.precoInscricaoEidDivisao.push(obj);
                        })

                        this.divisoes_evento.forEach(div => {
                            this.divisoes_evento_copia.push(div)
                          div.status = 0;
                        });
                    });
                    this.getOrganizador(this.evento.id_organizador);
                    //this.getThumbnailExibicao(ev.imagem_exibicao);
                    this.getThumbnailEvento(ev);
                    this.regras_evento = this.evento.regras;
                },
                err => {
                    this.utilService.irPara(this.router, '/comum/eventos');
                    this.utilService.mensagemErro('Evento não encontrado!');
                }
            );
        } else if(!isNaN(idEventoReplicado)){
            this.eventoReplicado = idEventoReplicado;

            this.eventoService.buscaPorId(idEventoReplicado).subscribe(evento => {
                this.evento = evento;
                this.formCadastro.controls['id_organizador'].setValue(Number(evento.id_organizador));
                this.recebePrecoInscricao = String(this.evento.preco_inscricao);
                //this.titulo_pagina = `Replicar evento "${this.evento.titulo}" para o campeonato "${campeonatoEmQueOEventoSeraReplicado.nome}" `;
                this.evento.id_evento = null;
                //this.evento.id_campeonato = idCampeonatoReplicado;
                this.evento.imagem_old = this.evento.imagem_exibicao;

                this.evento.data_inicial = new Date(this.evento.data_inicial).toLocaleString();
                this.evento.data_final = new Date(this.evento.data_final).toLocaleString();
                this.evento.data_inicio_inscricoes = new Date(this.evento.data_inicio_inscricoes).toLocaleString();
                this.evento.data_fim_inscricoes = new Date(this.evento.data_fim_inscricoes).toLocaleString();

                this.evento.data_inicial = this.utilService.ajustaDataHorario(this.evento.data_inicial);
                this.utilService.montaDatePickerHorario('data_inicial', this.evento.data_inicial);

                this.evento.data_final = this.utilService.ajustaDataHorario(this.evento.data_final);
                this.utilService.montaDatePickerHorario('data_final', this.evento.data_final);

                this.evento.data_inicio_inscricoes = this.utilService.ajustaDataHorario(this.evento.data_inicio_inscricoes);
                this.utilService.montaDatePickerHorario('data_inicio_inscricoes', this.evento.data_inicio_inscricoes);
                
                this.evento.data_fim_inscricoes = this.utilService.ajustaDataHorario(this.evento.data_fim_inscricoes);
                this.utilService.montaDatePickerHorario('data_fim_inscricoes', this.evento.data_fim_inscricoes);

                this.divisaoService.buscaPorEvento(idEventoReplicado).subscribe(divisoes => {
                    this.getDivisoes();
                    this.divisoes_evento = divisoes.sort(function (a, b) {
                        return a.nome > b.nome ? 1 : a.nome < b.nome ? -1 : 0;
                    });
                    this.divisoes_evento.forEach(div => {
                        this.divisoes_evento_copia.push(div)
                      div.status = 0;
                    });
                });
                this.getOrganizador(this.evento.id_organizador);
                if (idEventoReplicado) {
                    if(!isNaN(idCampeonato)) {
                        this.getCampeonato(idCampeonato);
                        this.recebePrecoInscricao = String(this.evento.preco_inscricao);//voltar aqui para buscaPorId()
                        this.campeonatoService.buscaPorId2(idCampeonato).subscribe(campeonato => {
                            let campeonatoEmQueOEventoSeraReplicado: Campeonato = null;
                            campeonatoEmQueOEventoSeraReplicado = campeonato;
                            this.titulo_pagina = `Replicar evento "${this.evento.titulo}" para o campeonato "${campeonatoEmQueOEventoSeraReplicado.nome}" `;
                            this.evento.id_campeonato = idCampeonato;
                        });
                    } else {
                        this.titulo_pagina = `Replicar evento "${this.evento.titulo}`;
                    }
                } else {
                    this.getIdCampeonato();
                    if(!isNaN(idCampeonato)) {
                        this.getCampeonato(idCampeonato);
                        this.recebePrecoInscricao = String(this.evento.preco_inscricao);
                    }
                }
            });
        }
    }

    public getDivisoes() {
        this.divisaoService.buscaTodos().subscribe(divisoes => {
            this.divisoes = divisoes.sort(function (a, b) {
                return a.nome > b.nome ? 1 : a.nome < b.nome ? -1 : 0;
            });
            this.divisoes_evento.forEach(divisao_evento => {
                this.divisoes.forEach(divisao => {
                    if (divisao_evento.id_divisao == divisao.id_divisao) {
                        this.divisoes.splice(this.divisoes.indexOf(divisao), 1)
                    }
                })
            })
        });
    }

    private pegaDivisaoSelecionada(event: any) {
        this.id_divisaoSelecionada = parseInt(event.target.value);
    }

    private adicionaDivisao() {
        if (this.id_divisaoSelecionada != null) {
            if (this.divisoes_evento.find(divisao => divisao.id_divisao == this.id_divisaoSelecionada)) {
                this.utilService.mensagemErro('Prova já inserida');
            } else {
                this.divisaoService.buscaPorId(this.id_divisaoSelecionada).subscribe(divisao => {
                    this.divisoes_evento.push(divisao);
                });
            }
        } else {
            this.utilService.mensagemErro('Selecione alguma prova');
        }
    }

    private removeDivisaoEvento(divisao : Divisao) {
        this.divisoes_evento.splice(this.divisoes_evento.indexOf(divisao), 1);
        this.divisoes_evento.push(divisao);
    }

    private redirecionaCriaDivisao() {
        this.evento.divisoes = this.divisoes_evento;
        this.eventoService.setEventoTemporario(this.evento);
        this.utilService.irPara(this.router, '/comum/cadastro-divisao/');
    }

    public selecionaOrganizador(id_organizador){
        this.evento.id_organizador = Number(id_organizador);
        this.formCadastro.controls['id_organizador'].setValue(Number(id_organizador));
        this.organizadores.forEach(organizador => {
            if(id_organizador == organizador.id_usuario){
                this.evento.nome_organizador = organizador.nome;
            }
        });
        this.erro = false;
        this.organizadores = [];
    }

    public getOrganizadores(){
        this.usuarioService.buscaOrganizadores().subscribe(retorno => {
            this.organizadores = retorno;
        });
    }

    public salvar() {
        if (this.formCadastro.invalid) {
            if(this.formCadastro.controls['id_organizador'].invalid){
                this.erro = true;
            }
            this.utilService.destacarErros(this.formCadastro);
            return false;
        }

        let dataInicialEvento = this.utilService.converteNewData(this.evento.data_inicial);
        let dataFinalEvento = this.utilService.converteNewData(this.evento.data_final);
        let dataInicial = new Date(this.campeonato.data_inicial);
        let dataFinal = new Date(this.campeonato.data_final);

        if (dataInicialEvento.getTime() > dataFinalEvento.getTime()) {
            this.utilService.mensagemErro("A data final do evento não pode ser menor que a data inicial do evento.");
            return false;
        }

        if (dataInicialEvento.getTime() < dataInicial.getTime()) {
            this.utilService.mensagemErro("A data inicial do evento não pode ser menor que a data inicial do campeonato.");
            return false;
        }

        if (dataInicialEvento.getTime() > dataFinal.getTime()) {
            this.utilService.mensagemErro("A data inicial do evento não pode ser maior que a data final do campeonato.");
            return false;
        }

        this.requisicao();
    }

    private requisicao() {
        let mensagemConfirmacao: string = this.divisoes_evento.length == 0 ? 'Deseja realmente salvar um evento sem provas?'
        : 'Deseja realmente salvar o evento?';
          
        this.evento.regras = this.regras_evento;

        if(this.divisoes_evento.length != 0 && this.divisoes_evento_copia.length != 0){
            let divi : Divisao[] = [];
            let control : boolean = false;
            this.divisoes_evento_copia.forEach(copia => {
                this.divisoes_evento.forEach(div =>{
                    if(copia.nome == div.nome){
                        control = true;
                    }
                })
                
                if(control == false){
                  copia.status = 2;
                  divi.push(copia);
                }

                control = false;
            });

            divi.forEach(d => {
                this.divisoes_evento.push(d);
            });
        }
        
        this.atualizaDivisao();
        
        this.utilService.mensagemConfirmacao(mensagemConfirmacao, (result) => {
            if (result) {
                this.requisicaoSalvar();
            }
        });        
    }

    public atualizaDivisao(){
        this.divisoes_evento.forEach(p => {
            if(Number(p.preco_inscricao) < 1 || p.preco_inscricao == undefined || p.preco_inscricao == null){
                p.preco_inscricao = String(this.evento.preco_inscricao);                
            }
            if(Number(p.taxa_administrativa) < 1 || p.taxa_administrativa == undefined || p.taxa_administrativa == null){
                p.taxa_administrativa = String(this.evento.taxa_administrativa);
            }
            if(Number(p.porcentagem_premiacao) < 1 || p.porcentagem_premiacao == undefined || p.porcentagem_premiacao == null){
                p.porcentagem_premiacao = String(this.evento.porcentagem_premiacao);
            }

            if(this.evento.regras.length > 0){
                for (let regra of this.evento.regras) {
                    let jsonRegra = JSON.parse(regra.parametros);
                    if((jsonRegra.parametros[0].value != null && jsonRegra.parametros[0].value != undefined)){
                        if(jsonRegra.parametros[0].id == "maximoCompetidoresEvento"){
                            p.qtd_maxima_competidor = p.qtd_maxima_competidor ? p.qtd_maxima_competidor :  jsonRegra.parametros[0].value; 
                            this.evento.maximo_competidores = jsonRegra.parametros[0].value;
                        }else if(jsonRegra.parametros[0].id == "maximoInscricoesCompetidor"){
                            p.numero_maximo_inscricao_competidor = p.numero_maximo_inscricao_competidor ? p.numero_maximo_inscricao_competidor :  jsonRegra.parametros[0].value;
                            this.evento.maximo_inscricoes_competidor = jsonRegra.parametros[0].value; 
                        }else if(jsonRegra.parametros[0].id == "maximoInscricoesDupla" && p.id_tipo_inscricao === 2){
                            p.qtd_maxima_inscricao_dupla = p.qtd_maxima_inscricao_dupla ? p.qtd_maxima_inscricao_dupla :  jsonRegra.parametros[0].value; 
                            this.evento.maximo_inscricoes_duplas = jsonRegra.parametros[0].value;
                        }else if(jsonRegra.parametros[0].id == "maximoCavaloCorreProva"){
                            p.qtd_maxima_inscricao_cavalo = p.qtd_maxima_inscricao_cavalo ? p.qtd_maxima_inscricao_cavalo :  jsonRegra.parametros[0].value;
                            this.evento.maximo_inscricoes_cavalo = jsonRegra.parametros[0].value;
                        }else if(jsonRegra.parametros[0].id == "maximoInscricoesTrio" && p.id_tipo_inscricao === 3){
                            p.qtd_maxima_inscricao_trio = p.qtd_maxima_inscricao_trio ? p.qtd_maxima_inscricao_trio :  jsonRegra.parametros[0].value;
                            this.evento.maximo_inscricoes_trio = jsonRegra.parametros[0].value;
                        }
                    }
                }
            }
        });

        if(this.informacoesDivisao.length > 0){
            this.informacoesDivisao.forEach( d => {
                this.divisoes_evento.forEach(de => {
                    if(d.id_divisao == de.id_divisao){
                        de.preco_inscricao = String(d.preco_inscricao);
                    }else{
                        if(de.preco_inscricao == null || de.preco_inscricao == undefined){
                            de.preco_inscricao = String(this.evento.preco_inscricao);
                        }
                    }
                })
            })
        }
    }

    public selecionaMaximoCompetidores(event) {
        let maximo_competidores = event.target.value;
        this.evento.maximo_competidores = maximo_competidores;
    }

    private requisicaoSalvar() {
        this.evento.divisoes = this.divisoes_evento;
        this.evento.racas_pontuar = this.racas_pontuar;
        this.provasEditadas = this.provaService.getCustomList();
        if (this.evento.id_evento) {
            
            this.eventoService
            .altera(this.evento.id_evento, this.evento)
            .subscribe(evento => {
                
                for (let regra of this.evento.regras) {
                    regra.id_evento = evento.id_evento;
                    this.regraEventoService
                    .insere(regra)
                    .subscribe();
                }
                this.utilService.mensagemSucesso("Evento alterado com sucesso!");

                this.inserirIdEvento(evento.id_evento);
                    this.redireciona();
                }, error => {
                    this.utilService.mensagemErro(error.error.mensagem);
                }
                )
        } else {            
            this.evento.maximo_inscricoes = this.evento.maximo_inscricoes ?
            this.evento.maximo_inscricoes : null;
            this.evento.finalizado = false;
            this.eventoService
                .insere(this.evento)
                .subscribe(retorno => {
                    let evento = retorno.evento;
                    let validacao = retorno.validacao;
                    if(validacao.status && evento){
                        this.salvarIdEventoEmTodasAsRacasAPontuar(evento.id_evento);

                        PubSub.subscribe('atualizacaoIdEvento', () => {
                            for (let prova of evento.provas) {
                                this.provaRacasService
                                    .buscaRacasPontuarPorEventoDivisao(prova.id_evento, prova.id_divisao)
                                    .subscribe(provasRaca => {
                                        for (let provaRaca of provasRaca) {
                                            provaRaca.id_prova = prova.id_prova;
                                            this.provaRacasService.put(provaRaca.id_prova_racas, provaRaca).subscribe();
                                        }
                                    });
                            }
                        });

                        for (let regra of this.evento.regras) {
                            regra.id_evento = evento.id_evento;
                            this.regraEventoService
                                .insere(regra)
                                .subscribe();
                        }
                        
                        if (this.virarNoticia) {
                            
                            let noticia: Noticia = new Noticia();
                            
                            noticia.titulo = this.evento.titulo;
                            noticia.texto = this.evento.descricao;
                            noticia.id_autor = this.evento.id_organizador;
                            noticia.id_tipo_noticia = 3;
                            noticia.id_referencia_noticia = evento.id_evento;
                            noticia.imagem_exibicao = evento.imagem_exibicao;
                            noticia.arquivo_imagem = this.evento.arquivo_imagem;
                            noticia.imagem_old = this.evento.imagem_old;
                            noticia.thumbnail = this.evento.thumbnail;
                            noticia.ativa = true;
                            noticia.id_tipo_arquivo = 1;
                            this.noticiaService.criaNoticia(noticia).subscribe();
                        }
                        
                        if (this.eventoReplicado) {
                            this.utilService.mensagemSucesso("Evento replicado com sucesso!");
                        } else {
                            this.utilService.mensagemSucesso("Evento criado com sucesso!");
                        }

                        this.inserirIdEvento(evento.id_evento);
                        this.redireciona();
                    }else if(!validacao.status && !evento){
                        this.utilService.mensagemErro(validacao.mensagem);
                    }
                    
                }, error => {
                    this.utilService.mensagemErro(error.error.mensagem);
                }
            );
        }
    }

    private redireciona() {
        this.utilService.irPara(this.router, '/comum/eventos/');
    }

    public todasRacasAPontuar: ProvaRacas[] = [];

    public adicionaTodasRacaPontuar(racaPontuar: ProvaRacas) {
        this.todasRacasAPontuar.push(racaPontuar);
    }

    public atualizaProvaEvento(infoDivisao: DivisaoInformacoes) {
        if (infoDivisao.id_prova) {
            for (let racaPontuar of infoDivisao.racasPontuar) {
                if (!racaPontuar.id_prova) {
                    racaPontuar.id_prova = infoDivisao.id_prova;
                    this.provaRacasService.put(racaPontuar.id_prova_racas, racaPontuar).subscribe();
                }
            }
        } else {
            this.divisoes_evento.forEach(div => {
                if (div.id_divisao == infoDivisao.id_divisao) {
                    div.racasPontuar = infoDivisao.racasPontuar;
                }
            })
        }
    }

    public salvarIdEventoEmTodasAsRacasAPontuar(idEvento: number) {
        let total = this.todasRacasAPontuar.length;
        let index = 0;
        for (let racaPontuar of this.todasRacasAPontuar) {
            racaPontuar.id_evento = idEvento;
            this.provaRacasService.put(racaPontuar.id_prova_racas, racaPontuar).subscribe(() => {
                if (++index == total) {
                    PubSub.publish("atualizacaoIdEvento");
                }
            });
        }
    }

    private getIdCampeonato() {
        let idCampeonato = parseInt(this.route.snapshot.queryParamMap.get('idCampeonato'));
        if(!isNaN(this.id_campeonato)){
            this.id_campeonato = idCampeonato;
            this.evento.id_campeonato = idCampeonato;
            this.getCampeonato(idCampeonato);
        }
    }

    private getCampeonato(idCampeonato) {
        //Caso de erro, mudar para buscaPorId()
        this.campeonatoService.buscaPorId2(idCampeonato).subscribe(campeonato => {
            this.campeonato = campeonato;
            if(!this.evento.id_organizador){
                this.evento.id_organizador = campeonato.organizador.id_usuario;
                this.formCadastro.controls['id_organizador'].setValue(Number(campeonato.organizador.id_usuario))
                this.getOrganizador(this.evento.id_organizador);
            }
            this.evento.preco_inscricao = this.evento.preco_inscricao? this.evento.preco_inscricao : campeonato.preco_inscricao; 
            this.evento.porcentagem_premiacao = this.evento.porcentagem_premiacao? this.evento.porcentagem_premiacao : campeonato.porcentagem_premiacao;
            this.evento.maximo_inscricoes = this.evento.maximo_inscricoes? this.evento.maximo_inscricoes : this.campeonato.maximo_inscricoes;
        });
    }

    private buscarRacasPontuar() {
        let idEvento = parseInt(this.route.snapshot.queryParamMap.get("id_evento"));
        if (idEvento) {
            this.eventoRacaService.buscaPorEvento(idEvento).subscribe(racasPontuar => {
                this.racas_pontuar = racasPontuar;
                if(this.racas_pontuar.length > 0){
                    this.racas_pontuar.forEach(rp => {
                        rp.status_raca = 1; //1 -> Sinal que a raça deve ser atualizada ou não fazer nada.
                    });
                }
            });
        }
    }

    private removeRacaPontuar(raca_pontuar) {
       this.racas_pontuar.forEach(rp => {
           if(rp.id_evento_raca == raca_pontuar.id_evento_raca){
               rp.status_raca = 2; // 2-> Sinal que a raça deve ser removida no banco
           }
       });
    }

    public inserirRacaPontuar(eventRaca) {
        let exists = false;
        this.racas_pontuar.forEach(rp => {
            if (rp.id_raca == eventRaca.id_raca) {
                exists = true;
                this.utilService.mensagemInfo('Raça já inserida na lista');
            }
        });
        if (!exists) {
            eventRaca.status_raca = 3; //3 -> Sinal que a raça deve ser inserida no banco
            this.racas_pontuar.push(eventRaca);
        }
    }

    public atribuiValorImagem(response: any): void {
        if (this.evento.imagem_exibicao && this.evento.imagem_exibicao.includes(this.evento.titulo)) {
            this.evento.imagem_old = this.evento.imagem_exibicao;
        }
        this.evento.imagem_exibicao = response.path;
        this.imageToShow = response.img;
    }

    private getThumbnailEvento(evento: Evento){
        evento.arquivo_imagem =  this.utilService.buscaThumbnail(evento);
    }

    /*private getThumbnailExibicao(path){
        if(path){
            this.imageToShow = `${environment.api_url}/file/thumbnailEvento/${path}`;
        }
    }*/

    private createImageFromBlob(image: Blob) {
        let reader = new FileReader();
        reader.addEventListener("load", () => {
            this.imageToShow = reader.result;
        }, false);

        if (image) {
            reader.readAsDataURL(image);
        }
    }

    private uploadImagem(){
        var bar = <HTMLInputElement>document.getElementById('js-progressbar');
        let uploadURL = this.eventoService.upload_imagem_api_url;
        const formCadastro = this;
        
        UIkit.upload('.js-upload', {

            url: uploadURL,
            multiple: false,
            mime: '.jpg, .png, .jpeg, .gif, .bmp, .tif, .tiff|image/*',

            loadStart: function (e) {
                bar.removeAttribute('hidden');
                bar.max = e.total;
                bar.value = e.loaded;
            },

            progress: function (e) {
                bar.max = e.total;
                bar.value = e.loaded;
            },

            loadEnd: function (e) {
                let response = JSON.parse(e.srcElement.response);
                formCadastro.atribuiValorImagem(response);
                bar.max = e.total;
                bar.value = e.loaded;
            },
            
            completeAll: function () {
                setTimeout(function () {
                    bar.setAttribute('hidden', 'hidden');
                }, 1000);
            },

            fail: function(){
                alert('Formato de arquivo não suportado');
            }

        });
    }

    public dispinibilizarParaNoticias() {
        if (this.virarNoticia == true) {
            this.virarNoticia = false;
        } else {
            this.virarNoticia = true;
        }
    }

    private getRegras() {
        this.regraService.buscaTodos("2").subscribe(regras => {
            this.regras = regras;//caso a regra draw for especifica de uma prova e não do evento inteiro
            this.regrasGerais = this.regras.filter(r => r.nome != "Draw");
        });
    }

    public montaRegra() {
        this.isSelected = true;
        let value = (<HTMLSelectElement>document.getElementById('select-regra')).value;
        let regra = this.regras.find(r => r.id_regra == +value);
        let objRegra = JSON.parse(regra.parametros);
        this.inputs = objRegra.parametros;
        this.novaRegra();
    }


    private novaRegra() {
        $("#divRegra").show();
        $("#botaoNovaRegra").hide();
    }

    private cancelarRegra() {
        $("#divRegra").hide();
        $("#botaoNovaRegra").show();
        this.resetaRegra();
    }

    private adicionaRegra() {
        let valores = [];
        let value = (<HTMLSelectElement>document.getElementById('select-regra')).value;
        let regra_evento = Object.assign({}, this.regras.find(r => r.id_regra == +value));
        if (!regra_evento) return;

        regra_evento.parametros = JSON.parse(regra_evento.parametros).parametros;
        this.inputs.forEach(function (i, index) {
            let resposta = {
                "id": i.id,
                "valor": $("#" + i.id).val(),
                "descricao": $("#" + i.id).val(),
                "label": i.label
            }
            if (i.type == "select") {
                resposta.descricao = $("#" + i.id + " option:selected").text();
            }
            valores.push(resposta);
            regra_evento.descricao = regra_evento.descricao.replace("params[" + index + "]", resposta.descricao);
            regra_evento.expressao = regra_evento.expressao.replace("params[" + index + "]", resposta.valor);
            // @ts-ignore
            regra_evento.parametros[index].value = resposta.valor;
        });
        regra_evento.parametros = '{"parametros":' + JSON.stringify(regra_evento.parametros) + '}'
        this.insereOuAtualiza(regra_evento);
        this.resetaRegra();
    }


    private resetaRegra() {
        $('#select-regra').val('default').prop('selected', true);
        this.inputs = [];
    }

    private insereOuAtualiza(regra_evento: any) {
        var foundIndex = this.regras_evento.findIndex(x => x['id_regra'] === regra_evento['id_regra']);
        if (foundIndex >= 0)
            this.regras_evento[foundIndex] = regra_evento;
        else
            this.regras_evento.push(regra_evento)
    }

    private removeRegraEvento(re: RegraEvento) {
        this.regras_evento.splice(this.regras_evento.indexOf(re), 1);
        this.regraEventoService.deleta(re.id_regra_evento).subscribe();
        if (this.regras_evento.length == 0) {
            this.isSelected = false;
        }
    }

    public regraEmitter(evento: RegraModificada) {
        evento.id_evento = this.evento.id_evento;
        this.regrasModificadas.push(evento);
    }

    public informacoesDivisaoEmitter(info : DivisaoInformacoes){
        this.informacoesDivisao.push(info);
    }

    private inserirIdEvento(id_evento){
        if(this.provasEditadas.length > 0){
            this.provasEditadas.forEach(pe => {
                pe.id_evento = id_evento;
                this.provaService.alterarRegraProva(pe)
                    .subscribe(retorno => console.log("retorno", retorno));
            });

        }
    }

    private buscaCampeonatos(){
        this.campeonatoService.buscaCampeonatosAtivos()
        .subscribe(retorno => this.selectCampeonatos = Select.converterParaDropDownDefault(retorno, "nome", "id_campeonato"))
    }

    public selecionaCampeonato(id_campeonato){
        if(!id_campeonato){
            this.evento.id_campeonato = null;
        }else{
            this.evento.id_campeonato = id_campeonato;
            this.getCampeonato(id_campeonato)
        }
    }
}