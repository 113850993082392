import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EditarInscricaoComponent } from './editar-inscricao.component';
import { RouterModule } from '@angular/router';

@NgModule({
    declarations: [EditarInscricaoComponent],
    imports: [
        CommonModule,
        RouterModule
    ],
    exports: [EditarInscricaoComponent]
})
export class EditarInscricaoModule {}