import { OnInit, Component, Input, EventEmitter, Output } from '@angular/core';

import { Prova } from '../../../../../model/prova';
import { Campeonato } from '../../../../../model/campeonato';
import { Evento } from '../../../../../model/evento';
import { VerificaRegraService } from '../../../../../service/verifica-regra.service';

declare var $: any;

@Component({
    selector: 'selecao-de-provas-por-prova',
    templateUrl: './selecao-de-provas-por-prova.component.html',
    styleUrls: ['./selecao-de-provas-por-prova.style.css', './../../../inscricoes.style.css']
})
export class SelecaoDeProvasPorProvaComponent implements OnInit {

    @Input() pagina: number;
    @Output() paginaEmitter: EventEmitter<number> = new EventEmitter<number>();
    
    @Input() numeroDeInscritos: number;
    @Input() campeonato: Campeonato;
    @Input() evento: Evento;
    @Input() listaDeProvasDoEvento: Prova[] = [];
    
    public listaDeProvasSelecionadas: Prova[] = [];

    @Output() listaDeProvasSelecionadasEmitter: EventEmitter<Prova[]> = new EventEmitter<Prova[]>();

    constructor(private verificaRegraService: VerificaRegraService) {}

    ngOnInit(): void {
        this.verificaRegraService.resetaRegras(this.listaDeProvasDoEvento);
        this.listaDeProvasDoEvento = this.listaDeProvasDoEvento
                .filter(prova => prova.tipo_prova == this.numeroDeInscritos);
    }

    public prosseguir(pagina: number): void {
        this.paginaEmitter.emit(pagina);
        this.listaDeProvasSelecionadasEmitter.emit(this.listaDeProvasSelecionadas);
    }

    public regressar(pagina: number): void {
        this.paginaEmitter.emit(pagina);
    }

    private adicionaOuRemoveProvasNaListaDeProvasSelecionadas(prova): void {
        
        if ($(`#checkboxInput${prova.id_prova}`).prop('checked')) {
            this.listaDeProvasSelecionadas.push(prova);
        } else {
            this.listaDeProvasSelecionadas = 
            this.listaDeProvasSelecionadas.filter(prov => prov.id_prova != prova.id_prova);
        }
    }

    private verificaProvasSelecionadas(prova: Prova) {
        if (this.estaNaListaDeProvas(prova)) {
            this.listaDeProvasSelecionadas = this.listaDeProvasSelecionadas.filter(prov => prov.id_prova != prova.id_prova);
        } else {
            this.listaDeProvasSelecionadas.push(prova);
        }
    }

    private estaNaListaDeProvas(prova: Prova): boolean {

        let retorno: boolean = false;
        this.listaDeProvasSelecionadas.forEach(prov => {
           if (prov.id_prova == prova.id_prova) {
               retorno = true;
           }
        })
        return retorno;
    }
}