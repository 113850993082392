import { Injectable } from "@angular/core";
import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { UsuarioSemCadastroInscricaoCompetidor } from '../model/usuario-sem-cadastro-inscricao-competidor';

@Injectable()
export class UsuarioSemCadastroInscricaoCompetidorService{
    private api_usuario_inscricao_competidor : string = `${environment.api_url}` +
    `/usuario-sem-cadastro-inscricao-competidor`;

    constructor(private http : HttpClient){}

    public inserir(usuarioSemCadastro : UsuarioSemCadastroInscricaoCompetidor) : Observable<any>{
        return this.http.post<any>(`${this.api_usuario_inscricao_competidor}/inserir`,usuarioSemCadastro);
    }

    public alterarPorIdInscricaoCompetidor(id : number, usuarioSemCadastro : UsuarioSemCadastroInscricaoCompetidor)
    :Observable<UsuarioSemCadastroInscricaoCompetidor>{
        return this.http.put<UsuarioSemCadastroInscricaoCompetidor>(
            `${this.api_usuario_inscricao_competidor}/alterar-por-inscricao-competidor/${id}`,
            usuarioSemCadastro
        );
    }

    public buscaTodos(filtro : any) : Observable<UsuarioSemCadastroInscricaoCompetidor[]>{
        return this.http.get<UsuarioSemCadastroInscricaoCompetidor[]>
        (`${this.api_usuario_inscricao_competidor}/busca-todos?filtro=${filtro}`);
    }
}