import { Component, Inject, OnInit, ViewChild, ElementRef } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl, AbstractControl } from '@angular/forms';
import { Router } from '@angular/router';
import { UtilService } from '../../../util/util.service';
import { Usuario } from '../../../model/usuario';
import * as cep from 'cep-promise'
import { UsuarioService } from '../../../service/usuario.service';
import { PerguntaHandicapService } from '../../../service/pergunta-handicap.service';
import {Portuguese} from 'flatpickr/dist/l10n/pt.js';
import flatpickr from 'flatpickr';
import { EmailService } from '../../../service/email.service';


declare var $: any;
declare var swal: any;

@Component({
  selector: 'app-cadastro-usuario-parcial-component',
  moduleId: module.id,
  templateUrl: './cadastro-usuario-parcial.component.html',
  styleUrls: ['./cadastro-usuario-parcial.style.css']
})

export class CadastroUsuarioParcialComponent implements OnInit {
  public usuario: Usuario;
  public formCadastro: FormGroup;
  public handicap: number = 1;
  public mobile = false;

  public mostrarSenha: boolean = false;
  public mostrarConfirmarSenha: boolean = false;
  public filtro: any = {};

  public cpf_duplicado: boolean = false;
  public login_duplicado: boolean = false;
  public email_duplicado: boolean = false;

  public confirmar_email: string;
  public confirmar_senha: string;

  @ViewChild('senha') inputSenha: ElementRef;
  @ViewChild('confirmarSenha') inputConfirmarSenha: ElementRef;

  constructor(@Inject(Router) private router: Router,
    @Inject(FormBuilder) private formBuilder: FormBuilder,
    @Inject(UtilService) public utilService: UtilService,
    @Inject(UsuarioService) private usuarioService: UsuarioService,
    private emailService: EmailService) {

    utilService.mudaTitulo('RSNC Brasil - Cadastro de usuário');

    this.usuario = new Usuario();
    this.usuario.handicap = 1;

    this.mobile = utilService.isMobile();

    const email = new FormControl('', [
      Validators.required,
    ]);

    const senha = new FormControl('', [
      Validators.required,
    ]);

    this.formCadastro = formBuilder.group({
      id: [this.usuario.id_usuario, []],
      nome: [this.usuario.nome, [
        Validators.required,
        Validators.minLength(5),
        Validators.maxLength(100)
      ]],
      apelido: [this.usuario.apelido, [
        Validators.maxLength(50)
      ]],
      data_nascimento: [this.usuario.data_nascimento, [
        Validators.required,
        Validators.minLength(10),
        Validators.maxLength(10)
      ]],
      sexo: [this.usuario.sexo, [
        Validators.required
      ]],
      cpf: [this.usuario.cpf, [
        Validators.minLength(14),
        Validators.maxLength(14)
      ]
    ],
      rg: [this.usuario.rg, [
        Validators.minLength(5),
        Validators.maxLength(15)
      ]],
      email: email,
      confirmar_email: ['', [
        Validators.required,
        Validators.maxLength(100),
        UtilService.confirmar(email)
      ]],
      telefone: [this.usuario.telefone, [
        Validators.minLength(14),
        Validators.maxLength(15)
      ]],
      cep: [this.usuario.cep, [
        Validators.minLength(9),
        Validators.maxLength(9)
      ]],
      estado: [this.usuario.estado, [
        Validators.minLength(2),
        Validators.maxLength(50)
      ]],
      cidade: [this.usuario.cidade, [
        Validators.minLength(5),
        Validators.maxLength(50)
      ]],
      bairro: [this.usuario.bairro, [
        Validators.minLength(5),
        Validators.maxLength(50)
      ]],
      logradouro: [this.usuario.logradouro, [
        Validators.minLength(5),
        Validators.maxLength(50)
      ]],
      numero: [this.usuario.numero, [
      ]],
      login: [this.usuario.login, [
        Validators.required,
        Validators.minLength(5),
        Validators.maxLength(50)
      ]],
      senha: senha,
      confirmar_senha: ['', [
        Validators.required,
        Validators.maxLength(100),
        UtilService.confirmar(senha)
      ]],
      competidor: [this.usuario.competidor],
      handicap: [this.usuario.handicap]
    });
  }

  ngOnInit() {
    this.montaDatePicker();
  }

  validarCPF() {
    this.usuarioService.validarCpf(this.usuario.cpf).subscribe(retorno => {
      this.cpf_duplicado = retorno;
    })
  }

  validarLogin() {
    this.usuarioService.validarLogin(this.usuario.login).subscribe(retorno => {
      this.login_duplicado = retorno;
    })
  }

  validarEmail() {
    this.usuarioService.validarEmail(this.usuario.email).subscribe(retorno => {
      this.email_duplicado = retorno;
    })
  }

  private montaDatePicker(): void {
    const fp = flatpickr("#data_nascimento",{
      disableMobile: true,
      locale: Portuguese,
      altFormat: 'd/m/Y',
      ariaDateFormat: 'd/m/Y',
      dateFormat: 'd/m/Y',
    });

}

  salvar() {
    this.usuario.ativo = false;
    this.usuario.pendente = true;
    if (!this.usuario.competidor) {
      this.usuario.handicap = 0;
    }
    this.utilService.mensagemConfirmacao("Enviar cadastro competidor?", (result) => {
      if (result) {
        this.usuario.handicap;
        let link_para_avaliacao = 'http://localhost:4200/comum/competidores-pendentes';
        this.emailService.mandaEmailCadastroCompetidor(this.usuario, link_para_avaliacao).subscribe();                 
        this.usuario.handicap = this.handicap;
        this.cadastrarUsuario("Competidor enviado para análise!");
      }
    });
  }

  private cadastrarUsuario(msg: string) {
    this.usuarioService
    .cadastrarParcial(this.usuario)
    .subscribe(data => {
      this.utilService.mensagemSucesso(msg);
      this.redireciona();
    });
  }

  handicapInvalido() {
    if (this.handicap == 0) {
      return true;
    }
    return false;
  }

  buscaCep() {

    if ((this.usuario.cep != null) && (this.usuario.cep.length >= 9)) {
      cep(this.usuario.cep)
        .then((data) => {
          this.usuario.cidade = data.city;
          this.usuario.logradouro = data.street;
          this.usuario.bairro = data.neighborhood;
          this.usuario.estado = data.state;
        })
        .catch(console.log);
    } else {
      this.usuario.cidade = "";
      this.usuario.logradouro = "";
      this.usuario.bairro = "";
      this.usuario.estado = "";
    }
  }

  changeShowPassword(value: any) {
    this.mostrarSenha = value;
    this.inputSenha.nativeElement.type = this.mostrarSenha ? 'text' : 'password';
  }

  changeShowConfirmPassword(value: any) {
    this.mostrarConfirmarSenha = value;
    this.inputConfirmarSenha.nativeElement.type = this.mostrarConfirmarSenha ? 'text' : 'password';
  }

  private redireciona() {
    this.utilService.irPara(this.router, '/comum/inicio/');
  }

  public calculoIdadeComBaseNoAnoHipico()
  {
    let retorno = 0;
    let dataNascimento = this.usuario.data_nascimento.split('/');
    retorno = this.utilService.calculoIdadeUsuarioComBaseAnoHipico(Number(dataNascimento[2]), Number(dataNascimento[1])-1, Number(dataNascimento[0]));
    // let idadeAtual = this.getAge(Number(dataNascimento[2]), Number(dataNascimento[1])-1, Number(dataNascimento[0]));

    return retorno;
  }

  public getAge(year, month, day) {
    var now = new Date()	
    var age = now.getFullYear() - year
    var mdif = now.getMonth() - month + 1 //0=jan	
    
    if(mdif < 0)
    {
        --age
    }
    else if(mdif == 0)
    {
        var ddif = now.getDate() - day
        
        if(ddif < 0)
        {
            --age
        }
    }

     return age
  }

}


$(document).ready(function () {
  $('#confirmar_email').on("cut copy paste", function (e) {
    e.preventDefault();
  });
});