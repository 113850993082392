import { NgModule } from '@angular/core';
import { ModalReplicarEventoComponent } from './modal-replicar-evento.component';
import { CommonModule } from '@angular/common';

@NgModule({
    declarations: [ModalReplicarEventoComponent],
    imports: [
        CommonModule
    ],
    exports: [ModalReplicarEventoComponent]
})
export class ModalReplicarEventoModule {}