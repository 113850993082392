import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

import { Usuario } from '../../model/usuario';
import { UsuarioService } from '../../service/usuario.service';

@Injectable()
export class UsuariosResolver implements Resolve<Observable<Usuario[]>> {

    constructor(private service: UsuarioService) { }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<Usuario[]> {

        return this.service.buscaCompetidoresPendentes();
    }
}