import { Component, OnInit, Inject } from '@angular/core';
import { UtilService } from '../../../util/util.service';
import { DestaqueService } from '../../../service/destaque.service';
import { Router } from '@angular/router';
import { Destaque } from '../../../model/destaque';
import { DestaqueFilter } from '../../../filter/destaque.filter';
import { Paginacao } from '../../../model/paginacao';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

declare var UIkit: any;

@Component({
  selector: 'app-lista-destaques',
  templateUrl: './lista-destaques.component.html',
  styleUrls: ['../../comum.style.css', './lista-destaques.component.css']
})

export class ListaDestaquesComponent implements OnInit {

  public destaques : Destaque[] = [];
  public imagemModal: any;
  private debounceDestaques: Subject<string> = new Subject<string>();
  public filtro: DestaqueFilter = new DestaqueFilter();
  public paginacao: Paginacao = new Paginacao();
  public numPagina: number = 0;
  public quantidadeRegistro: number = 0;
  
  constructor(private utilService : UtilService,
              private destaqueService : DestaqueService,
              @Inject(Router) private router : Router) { }

  ngOnInit() {
    this.filtro.status = true;
    this.initPaginacao();
    this.initDebounce();
    this.buscaDestaques();
  }

  private initDebounce(){
    this.debounceDestaques.pipe(debounceTime(300))
    .subscribe(titulo => {
      this.filtro.titulo = titulo;
      this.buscaDestaques();
    });
  }

  private initPaginacao(){
    this.paginacao.offset = 0;
    this.paginacao.limit = 30;
    this.paginacao.pagina = 1;
  }

  private getFiltro(){
    this.filtro.offset = this.paginacao.offset;
    this.filtro.limit = this.paginacao.limit;
    this.filtro.pagina = this.paginacao.pagina;
  }

  public filtroTitulo(titulo: string){
    this.initPaginacao();
    this.debounceDestaques.next(titulo);
  }

  public selecionarStatus(status: string){
    this.filtro.status = status == 'null' ? null : status == 'true' ? true : false;
    this.initPaginacao();
    this.buscaDestaques();
  }

  public selecionarTipoArquivo(tipo: string){
    this.filtro.tipoArquivo = tipo == 'null' ? null : tipo == '1' ? 1 : 2;
    this.initPaginacao();
    this.buscaDestaques();
  }

  private buscaDestaques(){
    this.getFiltro();
    this.destaqueService.buscaFiltro(this.filtro)
    .subscribe(retorno => {
      this.destaques = retorno.retorno;
      this.quantidadeRegistro = retorno.quantidade;
      this.quantidadePagina();
      this.buscaImagens();
    });
  }

  public buscaImagens(){
    this.destaques.forEach( destaque => {
      if(destaque.tipo_destaque == 1){
        destaque.endereco = this.utilService.buscaImagem(destaque);
      }
    });
  }

  private abreModalImagem(event){
    this.imagemModal = event.endereco;
    UIkit.modal('#modal-media-evento').show();
  }

  public editarDestaque(id_destaque : number){
    this.utilService.irPara(this.router,'/comum/cadastro-destaque',{
      'id_destaque' : id_destaque
    });
  }

  public removerDestaque(id_destaque : number){
    this.utilService.mensagemConfirmacao('Deseja realmente desativar o destaque?',
    (res) => {
      if(res){
        this.atulizarStatus(id_destaque, false);
      }
    });
  }

  private quantidadePagina(){
    let paginas = 0;
    if(this.quantidadeRegistro % this.paginacao.limit == 0){
      paginas = (this.quantidadeRegistro / this.paginacao.limit);
    }else{
      paginas = (this.quantidadeRegistro / this.paginacao.limit) + 1;
    }
    this.numPagina = Math.floor(paginas);
  }

  public getPaginacao(paginacao: Paginacao){
    this.paginacao.limit = paginacao.limit;
    this.paginacao.offset = paginacao.offset;
    this.paginacao.pagina = paginacao.pagina;
    this.buscaDestaques();
  }

  public ativarDestaque(id_destaque: number){
    this.utilService.mensagemConfirmacao('Deseja realmente ativar o destaque?',
    (res) => {
      if(res){
        this.atulizarStatus(id_destaque, true);
      }
    });
  }

  private atulizarStatus(id_destaque: number, status: boolean){
    this.destaqueService.atualizarStatus(id_destaque, status)
    .subscribe(() => {
      this.utilService.mensagemSucesso("Operação realizada com sucesso!");
      this.buscaDestaques();
    }, erro => this.utilService.mensagemErro("Erro - " + erro.error.message));
  }

}
