import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";

import { TreinadoresComponent } from "./treinadores.component";
import { FiltrarTreinadorPorNome } from "./filtrar-treinador-por-nome.pipe";
import { TreinadorModalModule } from './treinador-modal/treinador-modal.module';
import { TreinadoresService } from '../../service/treinador.service';
import { TreinadoresResolver } from './treinadores.resolver';
import { CadastroTreinadorModule } from '../formularios/cadastro-treinador/cadastro-treinador.module';

@NgModule({
    declarations: [ TreinadoresComponent, FiltrarTreinadorPorNome ],
    imports: [ 
        CommonModule, 
        TreinadorModalModule,
        CadastroTreinadorModule
    ],
    providers: [ TreinadoresService, TreinadoresResolver ]
})
export class TreinadoresModule {}