import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';

import { UtilService } from '../../../../../util/util.service';
import { Raca } from '../../../../../model/raca';
import { RacaService } from '../../../../../service/raca.service';
import { ProvaRacas } from '../../../../../model/prova_racas';
import { ProvaRacasService } from '../../../../../service/prova-racas.service';

declare var UIkit: any;

@Component({
  selector: 'modal-racas-pontuar',
  templateUrl: './modal-racas-pontuar.component.html',
  styleUrls: ['./modal-racas-pontuar.component.css']
})
export class ModalRacasPontuarComponent implements OnInit {

  @Output() public respostaRaca = new EventEmitter();

  public racas: Raca[] = [];
  public racaProva: ProvaRacas = new ProvaRacas();

  public formCadastro: FormGroup;

  constructor(private formBuilder: FormBuilder,
              private racaService: RacaService,
              private provaRacasService: ProvaRacasService,
              private utilService: UtilService) {

    this.formCadastro = this.formBuilder.group({

        valor_adicional: [this.racaProva.valor_adicional_inscricao, [
          Validators.required,
          Validators.minLength(1),
          Validators.maxLength(8)
        ]],
        porcentagem_premiacao: [this.racaProva.porcentagem_premiacao, [
          Validators.required,
          Validators.minLength(1),
          Validators.maxLength(6)
        ]],
        incremento_premiacao: [this.racaProva.acrescimo_premiacao, [
          Validators.required,
          Validators.minLength(1),
          Validators.maxLength(8)
        ]]
        
    });

    this.racaProva.id_raca = null;
  }

  ngOnInit() {
    this.racaService.buscaTodos().subscribe(racas => {
      this.racas = racas;
    });
  }

  public getRacaSelecionada(raca: Raca) {
    this.racaProva.id_raca = raca.id_raca;
  }

  public salvarRacaProva(): void {

    this.utilService.mensagemConfirmacao(`Deseja salvar ${this.racaProva.raca.descricao} ?`, (res) => {
      if (res) {
        this.provaRacasService.post(this.racaProva).subscribe((racaProva) => {
          this.utilService.mensagemSucesso(`Raça a pontuar ${racaProva.raca.descricao} salva com sucesso!`);
          UIkit.modal('#racaPontuarModal').hide();
        });
      }
    });

  }

  public abreModalCadastroRaca() {
    UIkit.modal('#racaModal', {bgClose: false}).show();
  }

}
