import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { Destaque } from '../model/destaque'
import { DestaqueFilter } from '../filter/destaque.filter';

@Injectable()
export class DestaqueService{
    
    private destaque_url_api = `${environment.api_url}/destaques`;
    public upload_imagem_api_url = `${environment.api_url}/file/upload`;
    private DEFAULT_IMG = 'destaque_default_img.jpg';

    constructor(private http : HttpClient){}

    public uploadImagem(formData: FormData): Observable<any> {
        return this.http.post(`${environment.api_url}/upload-imagem`, formData);
    }

    public inserir(destaque : Destaque) : Observable<Destaque>{
        return this.http.post<Destaque>(`${this.destaque_url_api}/inserir`, destaque);
    }

    public deleta(id_destaque : number) : Observable<any>{
        return this.http.put<any>(`${this.destaque_url_api}/${id_destaque}`, undefined);
    }

    public buscaPorId(id_destaque : number) : Observable<Destaque>{
        return this.http.get<Destaque>(`${this.destaque_url_api}/${id_destaque}`);
    }

    public buscaTodos() : Observable<Destaque[]>{
        return this.http.get<Destaque[]>(`${this.destaque_url_api}-busca-todos`);
    }

    public alterar(id_destaque : number, destaque : Destaque) : Observable<Destaque[]>{
        return this.http.put<Destaque[]>(`${this.destaque_url_api}/alterar/${id_destaque}`, destaque);
    }

    public buscaFiltro(filtro: DestaqueFilter) : Observable<any>{
        return this.http.get<any>(`${this.destaque_url_api}-busca-filtro?filtro=${JSON.stringify(filtro)}`);
    }

    public atualizarStatus(id_destaque: number, status: boolean) : Observable<Destaque[]>{
        return this.http.put<Destaque[]>(`${this.destaque_url_api}/atualizar-status/${id_destaque}`,{status: status});
    }
}