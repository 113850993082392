import { Component, OnInit, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { UtilService } from '../../../util/util.service';
import { RegraService } from '../../../service/regra.service';
import { Regra } from '../../../model/regra';
import { Associacao } from '../../../model/associacao';
import { RegraAssociacao } from '../../../model/regra-associacao';
import { RegraAssociacaoService } from '../../../service/regra-associacao.service';
import { Router, ActivatedRoute } from '@angular/router';

declare var $: any;

@Component({
  selector: 'app-cadastro-associacao',
  templateUrl: './cadastro-associacao.component.html',
  styleUrls: ['./cadastro-associacao.component.css']
})
export class CadastroAssociacaoComponent implements OnInit {

  public regras : Regra[] = [];
  public inputs: any[] = [];
  public inputSelect: any[] = [];
  public regras_associacao = new Array();
  public associacao : Associacao = new Associacao();
  public formCadastro: FormGroup;
  public regraAssociacao : RegraAssociacao;
  public regrasAssociacao : RegraAssociacao[] = [];
  public tituloPagina : string = "Cadastro Regra Filiação";

  constructor(private utilService : UtilService,
              private regraService : RegraService,
              private regraAssociacaoService : RegraAssociacaoService,
              @Inject(FormBuilder) private formBuilder: FormBuilder,
              @Inject(Router) private router : Router,
              @Inject(ActivatedRoute) private route : ActivatedRoute) { }

  ngOnInit() {
    let id_regra_associacao = parseInt(this.route.snapshot.queryParamMap.get("id_regra_associacao"));
    if(!isNaN(id_regra_associacao)){
      this.tituloPagina = "Editar Regra Filiação";
      this.buscaRegraAssociacao(id_regra_associacao);
    }
    this.initCadastro();
  }

  public initCadastro(){
    this.formCadastro = this.formBuilder.group({
      nome : [this.associacao.nome, [
        Validators.required,
        Validators.minLength(3),
        Validators.maxLength(30)
      ]],
      idade_minima : [this.associacao.idade_minima,[
        Validators.required,
        Validators.min(0)
      ]],
      idade_maxima : [this.associacao.idade_maxima,[
        Validators.required,
        Validators.min(0)
      ]],
      valor : [this.associacao.valor, [
        Validators.required,
        Validators.min(0)
      ]]
    })
  }

  public buscaRegraAssociacao(id_regra_associacao : number){
    this.regraAssociacaoService.buscaPorId(id_regra_associacao).subscribe(retorno => {
      if(retorno && retorno.id_regra_associacao){
        this.associacao.nome = retorno.nome;
        this.associacao.id_associacao = retorno.id_regra_associacao;
        let parametros : any[] = JSON.parse(retorno.parametros).parametros;
        parametros.forEach(parametro => {
          if(parametro.id === "idadeMinima"){
            this.associacao.idade_minima = Number(parametro.value);
          }else if(parametro.id === "idadeMaxima"){
            this.associacao.idade_maxima = Number(parametro.value);
          }else if(parametro.id === "valorAssociacao"){
            this.associacao.valor = Number(parametro.value);
          }
        });
      }else{
        this.utilService.mensagemInfo("Regra de filiação não encontrada");
      }
    });
  }

  public salvar(){
    if(this.formCadastro.invalid){
      this.utilService.destacarErros(this.formCadastro);
      return false;
    }

    if(this.associacao.idade_minima >= this.associacao.idade_maxima){
      this.utilService
      .mensagemInfo("A idade máxima tem que ser maior, que a idade minima");
      return false;
    }

    this.montarRegraAssociacao();
    if(this.associacao.id_associacao){
      this.regraAssociacao.id_regra_associacao = this.associacao.id_associacao;
      this.alterarAssocicao();
    }else{
      this.salvarRegraAssociacao();
    }
  }

  private montarRegraAssociacao(){
   this.regraAssociacao = new RegraAssociacao();
   this.regraAssociacao.nome = this.associacao.nome;
   this.regraAssociacao.regra = 'Entre '+this.associacao.idade_minima+' e ' +
    this.associacao.idade_maxima +' anos - valor ' + this.associacao.valor;

    let idadeMinima = '{"parametros":[{"id":"idadeMinima","label":"Idade mínima","value":"' +
    this.associacao.idade_minima+'","type":"int"}';

    let idadeMaxima = '{"id":"idadeMaxima","label":"Idade máxima","value":"'+ 
    this.associacao.idade_maxima + '","type":"int"}';

    let valorAssociacao = '{"id":"valorAssociacao","label":"Valor","value":"' +
    this.associacao.valor + '","type":"number"}]}';

    this.regraAssociacao.expressao = 'idade >= ' + this.associacao.idade_minima +
    ' && idade <= ' + this.associacao.idade_maxima;
    this.regraAssociacao.parametros = idadeMinima + ',' + idadeMaxima + ',' + valorAssociacao;

    this.regraAssociacao.descricao = this.regraAssociacao.nome + " - R$ " + this.associacao.valor;
  }

  private salvarRegraAssociacao(){
    this.regraAssociacaoService.insere(this.regraAssociacao)
    .subscribe(retorno => {
      let regraAssociacao = retorno.regraAssociacao;
      let validacao = retorno.validacao;
      if(regraAssociacao && regraAssociacao.id_regra_associacao && validacao.status){
        this.utilService.mensagemSucesso("Regra de filiação cadastrada com sucesso!", () => {
          this.irParaInicio();
        });
      }else{
        this.utilService.mensagemErro(validacao.mensagem);
      }
    }, () => this.utilService.mensagemErro("Erro - Não foi possivel editar a regra."));
  }

  private alterarAssocicao(){
    this.regraAssociacaoService.alterar(this.regraAssociacao.id_regra_associacao,
    this.regraAssociacao).subscribe(retorno => {
      if(retorno && retorno.id_regra_associacao){
        this.utilService.mensagemSucesso("Regra de Filiação alterada com sucesso!", () => {
          this.irParaInicio();
        });
      }
    }, () => this.utilService.mensagemErro("Erro - Não foi possivel editar a regra."));
  }

  private irParaInicio(){
    this.utilService.irPara(this.router, '/comum/inicio');
  }
}
