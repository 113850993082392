import { ErroRegra } from './erro-regra';

export class ValidaInscricao{
    id_prova : Number;
    erros : ErroRegra[];
    posicao : Number;
    status : Boolean;

    constructor(){
        this.erros = [];
    }
}