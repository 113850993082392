import {Component, Inject, OnInit} from '@angular/core';
import { UtilService } from '../../util/util.service';

declare var $: any;

@Component({
  selector: 'app-quem-somos-component',
  moduleId: module.id,
  templateUrl: './quem-somos.component.html',
  styleUrls: ['./quem-somos.style.css']
})

export class QuemSomosComponent implements OnInit {

  constructor(private utilService: UtilService) {}

  ngOnInit() {
  }

}
