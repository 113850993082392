import { Injectable } from '@angular/core';
import { Subject } from 'rxjs/Subject';
@Injectable()
export class UsuariosService {

    private emitChangeSource = new Subject<any>();
    changeEmitted$ = this.emitChangeSource.asObservable();
    emitChange(change: any) {
        this.emitChangeSource.next(change);
    }

    private emitChangeSource2 = new Subject<any>();
    changeEmitted$2 = this.emitChangeSource2.asObservable();
    emitChange2(change: any) {
        this.emitChangeSource2.next(change);
    }
    
}