import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { environment } from '../../../environments/environment';
import { Evento } from '../../model/evento';
import { ParametrosBusca } from '../../model/parametros_busca';

@Injectable()
export class EventoBuscaService {
    
    public eventos: Evento[] = [];
    public paginas: number;
    options: ParametrosBusca = new ParametrosBusca();
    
    public filtro: {
        titulo: string,
        data_inicial: Date,
        data_final: Date
    } = {
        titulo: null,
        data_inicial: null,
        data_final: null
    };

    constructor(private http: HttpClient) {
        this.options.offset = 0;
        this.options.limit = 5;
    }
}
