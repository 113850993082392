import { Component, Output, EventEmitter, Input } from '@angular/core';

import { Campeonato } from '../../../../model/campeonato';
import { ParametrosBusca } from '../../../../model/parametros_busca';
import { CampeonatoService } from '../../../../service/campeonato.service';
import { ActivatedRoute, Router } from '@angular/router';
import { CampeonatoBuscaService } from '../../campeonatosBusca.service';

@Component({
    selector: 'pagination-campeonatos',
    templateUrl: './pagination-campeonatos.component.html',
    styleUrls: ['./pagination-campeonatos.style.css']
})
export class PaginationCampeonatosComponent {

    @Output() onClicking = new EventEmitter<Campeonato[]>();
    @Output() emiteOptions = new EventEmitter<ParametrosBusca>();
    @Input() options: ParametrosBusca;

    public paginas = [
        {
            key: 'key1',
            paginaValue: '1'
        },
        {
            key: 'key2',
            paginaValue: '2'
        },
        {
            key: 'key3',
            paginaValue: '3'
        },
        {
            key: 'key4',
            paginaValue: '4'
        }
    ]

    constructor(private service: CampeonatoService,
        private route: Router,
        private campeonatoBuscaService: CampeonatoBuscaService) { }

    buscaCampeonatosAnteriores() {
        if (this.options.offset != 0) {
            this.options.offset = Number(this.options.offset) - Number(this.options.limit);
            this.options.pagina--;
            this.route.navigate(['/comum/campeonatos'], {
                queryParams: {
                    offset: this.options.offset,
                    limit: this.options.limit
                }
            });
        }
    }

    buscaProximosCampeonatos() {
        this.options.offset = Number(this.options.offset) + Number(this.options.limit);
        this.options.pagina++;
        this.route.navigate(['/comum/campeonatos'], {
            queryParams: {
                offset: this.options.offset,
                limit: this.options.limit
            }
        });

    }

    pulaPagina(pagina: string) {
        this.options.offset = (Number(pagina) * this.options.limit) - this.options.limit;
        this.options.pagina++;
        this.route.navigate(['/comum/campeonatos'], {
            queryParams: {
                offset: this.options.offset,
                limit: this.options.limit
            }
        });
    }
}