import {Routes, RouterModule} from '@angular/router';
import {ComumComponent} from './comum/comum.component';
import {MODULE_ROUTES_COMUM} from './comum/comum.route';

const appRoutes: Routes = [
  {path: 'comum', component: ComumComponent, children: MODULE_ROUTES_COMUM },
  {path: '**', redirectTo: 'comum'}
];

export const routing = RouterModule.forRoot(appRoutes);
