import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { Http } from '@angular/http';
import { RespostaPergunta } from '../model/resposta-pergunta';

@Injectable()
export class RespostaPerguntaService {

  private resposta_handicap_api_url = `${environment.api_url}/resposta-pergunta`;

  constructor(private httpClient: HttpClient,
      @Inject(Http) private http: Http) {}

  public inserirLista(respostaPergunta: RespostaPergunta[]) : Observable<RespostaPergunta[]>{
    return this.httpClient.post<RespostaPergunta[]>
    (`${this.resposta_handicap_api_url}/inserir-lista`, respostaPergunta);
  }

}
