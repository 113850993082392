import { OnInit, Component, Output, EventEmitter, Input } from '@angular/core';
import { Campeonato } from '../../../../model/campeonato';
import { Evento } from '../../../../model/evento';

@Component({
    selector: 'escolha-tipo-de-prova',
    templateUrl: './escolha-tipo-de-prova.component.html',
    styleUrls: ['./escolha-tipo-de-prova.style.css', './../../inscricoes.style.css']
})
export class EscolhaTipoDeProvaComponent implements OnInit {

    @Input() campeonato: Campeonato;
    @Input() evento: Evento;

    @Input() pagina: number;

    @Output() paginaEmitter: EventEmitter<number> = new EventEmitter<number>();

    constructor() {}
    ngOnInit(): void {}

    public prosseguir(pagina: number): void {
        this.paginaEmitter.emit(pagina);
    }

    public regressar(): void {
        this.paginaEmitter.emit(0);
    }
}