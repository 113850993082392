import {Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Perfil } from '../model/perfil';

@Injectable()
export class PerfilService{

    private url_perfil = `${environment.api_url}/perfis`;

    constructor(private http : HttpClient){}

    buscaTodos() : Observable<Perfil[]>{
        return this.http.get<Perfil[]>(`${this.url_perfil}`);
    }

}