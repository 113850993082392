import { NgModule } from '@angular/core';
import { ListaDeProvasSelecionadasComponent } from './lista-de-provas-selecionadas.component';
import { CommonModule } from '@angular/common';
import { InformacoesProvaModule } from '../informacoes-prova/informacoes-prova.module';

@NgModule({
    declarations: [ListaDeProvasSelecionadasComponent],
    imports: [
        CommonModule,
        InformacoesProvaModule
    ],
    exports: [ListaDeProvasSelecionadasComponent]
})
export class ListaDeProvasSelecionadasModule {}