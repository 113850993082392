import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { InscricoesComponent } from './inscricoes.component';
import { EscolhaQuantidadeDeCompetidoresModule } from './components/escolha-quantidade-de-competidores/escolha-quantidade-de-competidores.module';
import { EscolhaTipoDeProvaModule } from './components/escolha-tipo-de-prova/escolha-tipo-de-prova.module';
import { InscricaoPorProvaModule } from './components/inscricao-por-prova/inscricao-por-prova.module';
import { InscricaoPorCompetidoresModule } from './components/inscricao-por-competidores/inscricao-por-competidores.module';
import { FinalizaInscricaoModule } from './components/finaliza-inscricao/finaliza-inscricao.module';
import { BuscaDeCompetidoresModule } from './shared/busca-de-competidores/busca-de-competidores.module';
import { RegraEventoService } from './../../service/regra-evento.service';
import { EditarInscricaoModule } from './components/editar-inscricao/editar-inscricao.module';

@NgModule({
    declarations: [InscricoesComponent],
    imports: [
        CommonModule,
        EscolhaQuantidadeDeCompetidoresModule,
        EscolhaTipoDeProvaModule,
        InscricaoPorProvaModule,
        InscricaoPorCompetidoresModule,
        FinalizaInscricaoModule,
        BuscaDeCompetidoresModule,
        EditarInscricaoModule
    ],
    exports: [InscricoesComponent],
    providers: [RegraEventoService]
})
export class InscricoesModule {}