import {Inject, Injectable} from '@angular/core';
import {Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot} from '@angular/router';
import {UtilService} from '../util/util.service';

@Injectable()
export class AuthGuardLogado implements CanActivate {

  constructor(private router: Router,
              private utilService: UtilService) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (this.utilService.isLogado()) {
      // Logado com perfil válido
      return true;
    }

    if(!this.utilService.isLogado()) {
      // nao está logado, redireciona para o login
      this.utilService.mensagemErroComTitulo('Acesso negado', 'Você não está autorizado à acessar esta funcionalidade, ' +
        'por favor, efetue login e tente novamente', () => {
        this.utilService.clearCookies();
        this.router.navigate(['/comum/login'], { queryParams: { returnUrl: state.url }});
      });
    }
    
    return false;
  }
}