import { Injectable } from '@angular/core';
import { UtilService } from '../util/util.service';
import { Prova } from '../model/prova';
import { Usuario } from '../model/usuario';
import { Regra } from '../model/regra';
import { RegraValidadorService } from './regra.validador.service';
import { CavaloService } from './cavalo.service';
import { ValidaInscricao } from '../model/valida-inscricao';
import { ErroRegra } from '../model/erro-regra';
import { InscricaoService } from './inscricao.service';

@Injectable()
export class VerificaRegraService {
    public qtdInscricao : number = 0;
    public somatorioHandicapCompetidor : number = 0;

    constructor(private utilService: UtilService,
                private cavaloService : CavaloService,
                private inscricaoService : InscricaoService 
                ) {}

    public verificaRegras(listaDeProvas: Prova[], listaDeCompetidores: Usuario[]): void {
        this.somatorioHandicapCompetidor = 0;
        let potroFuturo : boolean = false;
        let quantidadeCavalo : number = 0;
        for (let index = 0; index < listaDeCompetidores.length; index++) {
            this.somatorioHandicapCompetidor += listaDeCompetidores[index].handicap;
            if(listaDeCompetidores[index].cavalo){
                quantidadeCavalo++;
                potroFuturo = listaDeCompetidores[index].cavalo.potro_futuro == true ? true : potroFuturo;
            }
        }
        // potroFuturo = potroFuturo == true && quantidadeCavalo == listaDeCompetidores.length ? true : false;
        
        listaDeProvas.forEach(async prova => {
            let erroRegra : ErroRegra = null;
            prova.valida = true;
            prova.regrasNaoAtendidas = [];
            listaDeCompetidores.forEach(competidor => {
                competidor.validaProva = [];
                if(competidor.cavalo){
                    this.qtdInscricao = 0;
                    competidor.cavalo.inscricoes.forEach( inscricao => {
                        if(inscricao.id_prova == prova.id_prova){
                            this.qtdInscricao = inscricao.qtd_inscricao;
                        }
                    });
                    
                    if(competidor.cavalo.potro_futuro == true && 
                       competidor.cavalo.inscricoes_potro_futuro.length > 0){
                        let potroFuturos = competidor.cavalo.inscricoes_potro_futuro
                        .filter(pt => pt.id_prova == prova.id_prova);
                        if(potroFuturos.length > 0){
                            erroRegra = this.buscaErroRegra("Cavalo " + competidor.cavalo.nome +
                            " já está cadastrado como potro futuro",
                            "Possui potro futuro na prova");
                        }
                    }
                }              

                this.valida(prova, competidor);
            });            

            if (listaDeCompetidores.length == 0) {
                prova.valida = true;
                prova.regrasNaoAtendidas = [this.buscaErroRegra("Sem Competidor para ser avaliado",
                "Competidor")];
            }else{
                prova.regrasNaoAtendidas = this.validaProva(listaDeCompetidores, prova);
                if( prova.somatorio_maximo && (prova.somatorio_maximo > 0)){
                    if(this.somatorioHandicapCompetidor > prova.somatorio_maximo){
                        prova.regrasNaoAtendidas
                        .push(this.buscaErroRegra("O limite máximo do somatório de handicap foi ultrapassado!",
                         "Somatório de Handicap"));
                        prova.valida = false;
                    }
                }

                if(prova.divisao.potro_futuro == true){
                    if(potroFuturo == false){
                        prova.regrasNaoAtendidas
                        .push(this.buscaErroRegra("Nenhum cavalo está inscrito como potro futuro!",
                         "Cavalo potro Futuro"));
                        prova.valida = false;
                    }

                    if(erroRegra){
                        prova.regrasNaoAtendidas
                        .push(erroRegra);
                    }
                }
            }
            //validando quantidade limite de inscricoes prova
            if((prova.limite_inscricao != null) && (prova.draw == false)){
               this.validaLimiteInscricao(prova);                
            }
        });
    }

    //manutenção ponto 1
     async validaLimiteInscricao(prova) {
        await this.inscricaoService.buscaQtdInscricaoProvas(prova.id_prova).subscribe(retorno => {
            if(prova.limite_inscricao <= retorno ){
                var erroRegra = new ErroRegra;
                erroRegra.mensagem = "o limite de inscrições para essa prova ja foi atingido ";
                prova.regrasNaoAtendidas.push(erroRegra);
            }            
        });
        
        
    }

    private buscaErroRegra(mensagem : string, descricao : string) : ErroRegra{
        let erroRegra : ErroRegra = new ErroRegra();
        erroRegra.mensagem = mensagem;
        erroRegra.descricao = descricao;
        return erroRegra;
    }

    private atendeRegra(competidor: Usuario, regras: Regra[]): ErroRegra[] {
        //Aqui pega a idade normal
        //let idade = this.utilService.getIdade(competidor.data_nascimento);
        let data_comp = new Date(competidor.data_nascimento);
        //Se a validação da regra for com base no ano hipico, só descomentar o codigo aqui.
        let idade = this.utilService.calculoIdadeUsuarioComBaseAnoHipico(data_comp.getFullYear(), data_comp.getMonth(), data_comp.getDate());
        let handicap = competidor.handicap;
        let sexo = competidor.sexo;
        let cavalo = competidor.cavalo;
        let numeroCompetidor = competidor.numero_competidor;

        return new RegraValidadorService(idade, handicap, sexo, cavalo, this.qtdInscricao, numeroCompetidor, this.utilService)
                    .addRegras(regras)
                    .valida();
    }

    public resetaRegras(listaDeProvas: Prova[]) {
        listaDeProvas.forEach(prova => {
            prova.valida = true;
            prova.regrasNaoAtendidas = [];
        });
    }

    private valida(prova : Prova, competidor : Usuario){
        let control = false;
        let posicao = competidor.numero_competidor;

        for(let i = 0; i < prova.tipo_prova; i++){
            if(!control){
                competidor.numero_competidor = i + 1;
                let errosDeValidacao = this.atendeRegra(competidor, prova.divisao.regras);
                let valida = prova.valida && errosDeValidacao.length == 0;

                let validaProva = new ValidaInscricao();
                validaProva.id_prova = prova.id_prova;
                validaProva.posicao = competidor.numero_competidor;
                validaProva.status = true;

                if(!valida){
                    validaProva.erros = errosDeValidacao;
                    validaProva.status = false;
                }else if(prova.qtd_maxima_inscricao_cavalo && (prova.qtd_maxima_inscricao_cavalo <= this.qtdInscricao)){
                    validaProva.erros = [{
                        mensagem : "O limite máximo de inscrição do cavalo foi ultrapassado!",
                        descricao: "Máximo de inscrição por cavalo"
                    }];
                    validaProva.status = false;
                }
                competidor.validaProva.push(validaProva);
            }
        }
        competidor.numero_competidor = posicao;
    }

    private validaProva(competidores : Usuario[], prova : Prova){
        let regras : ErroRegra[] = [];
        if(prova.tipo_prova === 1 ||
          (prova.tipo_prova > 1 && prova.draw == true && competidores.length == 1)){
            regras = this.individual(competidores);
        }else if((prova.tipo_prova === 2) || 
                (prova.tipo_prova > 2 && prova.draw == true && competidores.length == 2)){
            regras = this.dupla(competidores);
        }else if(prova.tipo_prova === 3 ){
            regras = this.trio(competidores);
        }
        return regras;
    }
    
    private individual(competidores : Usuario[]){
        let regras : ErroRegra[] = [];
        let control = false;
        competidores.forEach(competidor => {
            competidor.validaProva.forEach(valida => {
                if(valida.status){
                    control = true;       
                }else{
                    valida.erros.forEach(erro => {
                        erro.mensagem = `O Competidor ${competidor.nome} não cumpre a regra: ${erro.descricao}`;
                        regras.push(erro)
                    });            
                }
            })
        });

        if(control){
            regras = [];
        }

        return regras;
    }

    private dupla(competidores : Usuario[]){
        let regras : ErroRegra[] = [];
        let control = false;

        competidores[0].validaProva.forEach( validaUm => {
            if(validaUm.status == true){
                competidores[1].validaProva.forEach(validaDois => {
                    if(validaDois.status == true && validaUm.posicao != validaDois.posicao){
                        control = true;
                    }
                });

            }
        });

        if(!control){
            let regra = new ErroRegra();
            regra.mensagem = "Competidor(es) ou Cavalo(s) não cumpre(m) a(s) regra(s)";
            regra.descricao = "erro";
            regras.push(regra);
        }
        
        return regras;
    }

    private trio(competidores : Usuario[]){
        let regras : ErroRegra[] = [];
        let control = false;

        competidores[0].validaProva.forEach( validaUm => {
            if(validaUm.status){
                competidores[1].validaProva.forEach(validaDois => {
                    if(validaDois.status && validaUm.posicao != validaDois.posicao){
                        competidores[2].validaProva.forEach(validaTres => {
                            if(validaTres.status && validaUm.posicao != validaTres.posicao && 
                               validaDois.posicao != validaTres.posicao){
                                control = true;
                            }
                        })
                    }
                });
            }
        });

        if(!control){
            let regra = new ErroRegra();
            regra.mensagem = "Competidor(es) não cumpre(m) a(s) regra(s)";
            regra.descricao = "erro";
            regras.push(regra);
        }

        return regras;
    }
}