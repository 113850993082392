import { Component, OnInit, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import flatpickr from 'flatpickr';
import { Portuguese } from 'flatpickr/dist/l10n/pt';
import { UtilService } from '../../../util/util.service';
import { PerguntaHandicapService } from '../../../service/pergunta-handicap.service';
import { PerguntaHandicap } from '../../../model/pergunta_handicap';
import { Usuario } from '../../../model/usuario';
import { RespostaHandicap } from '../../../model/resposta_handicap';
import { UsuarioSemCadastroService } from '../../../service/usuario-sem-cadastro.service';

declare var $ : any;

@Component({
  selector: 'app-cadastro-usuario-sem-handicap',
  templateUrl: './cadastro-usuario-sem-handicap.component.html',
  styleUrls: ['./cadastro-usuario-sem-handicap.component.css']
})

export class CadastroUsuarioSemHandicapComponent implements OnInit {

  public usuario : Usuario = new Usuario();
  public formCadastro : FormGroup;
  public formQuestionario: FormGroup;
  public perguntas : PerguntaHandicap[] = [];
  public respostas: RespostaHandicap[] = [];
  public handicap: number = 0;

  constructor(public utilService : UtilService,
              @Inject(Router) private router: Router,
              @Inject(ActivatedRoute) private route: ActivatedRoute,
              @Inject(FormBuilder) private formBuilder : FormBuilder,
              private perguntaHandicapService : PerguntaHandicapService,
              private usuarioSemCadastroService : UsuarioSemCadastroService) { }

  ngOnInit() {
    let id = parseInt(this.route.snapshot.queryParamMap.get("id_usuario"));
    if(!isNaN(id)){
      this.usuario.id_usuario = id;
      this.buscaUsuario();
    }
    this.initForm();
    this.initFormHandicap();
    this.montaDatePicker();
    this.getPerguntas();
  }

  public initForm(){
    this.formCadastro = this.formBuilder.group({
      nome : [this.usuario.nome,[
        Validators.required,
        Validators.minLength(5),
        Validators.maxLength(100)
      ]],
      data_nascimento : [this.usuario.data_nascimento,[
        Validators.required,
        Validators.minLength(10),
        Validators.maxLength(10)
      ]],
      telefone : [this.usuario.telefone,[
        Validators.required,
        Validators.minLength(14),
        Validators.maxLength(15)
      ]],
      sexo : [this.usuario.sexo,[Validators.required]],
      ativo : [this.usuario.ativo]
    });

    this.initFormHandicap();
  }

  public buscaUsuario(){
    this.usuarioSemCadastroService.buscaPorId(this.usuario.id_usuario)
    .subscribe(retorno => {
      this.usuario = retorno;
      this.usuario.data_nascimento = new Date(this.usuario.data_nascimento)
      .toLocaleString().split(" ")[0];
    });
  }

  public initFormHandicap(){
    $("#formQuestionario").hide();
    $("#calculadoHandicap").hide();
    this.formQuestionario = this.formBuilder.group({
    });
  }

  public getPerguntas() {
    this.perguntaHandicapService.buscarTodosResposta(null).subscribe(data => {
      this.perguntas = data;
    });
  }

  private montaDatePicker(): void {
    const fp = flatpickr("#data_nascimento",{
        disableMobile: true,
        locale: Portuguese,
        altFormat: 'd/m/Y',
        ariaDateFormat: 'd/m/Y',
        dateFormat: 'd/m/Y',
    });
  }

  public proximo() {
    if(this.formCadastro.invalid) {
      this.utilService.destacarErros(this.formCadastro);
      return;
    }
    $("#formCadastroUsuario").hide();
    $("#formQuestionario").show();
    $("#calculadoHandicap").show();
  }

  public anterior() {
    $("#formQuestionario").hide();
    $("#calculadoHandicap").hide();
    $("#formCadastroUsuario").show();
  }

  public atualizaQuestionario(resposta) {
    $("#botaoEnviar").prop("disabled", true);

    this.perguntas.forEach(function (perg) {
      if (perg.id_pergunta_handicap > resposta.id_pergunta) {
        perg.pergunta_oculta = true;
      }
    });
    this.handicap = 0;
    if (resposta.handicap == 0) {
      this.perguntas.find(p => p.id_pergunta_handicap === resposta.id_proxima_pergunta).pergunta_oculta = false;
    } else {
      this.handicap = resposta.handicap;
      this.usuario.handicap = resposta.handicap;
      $("#botaoEnviar").prop("disabled", false);
    }
  }

  public handicapInvalido() {
    if (this.handicap == 0) {
      return true;
    }
    return false;
  }

  public alteraOption(pergunta) {
    let value = (<HTMLSelectElement>document.getElementById('select-pergunta-' + pergunta.id_pergunta)).value;
    let resposta = this.respostas.find(p => p.id_resposta == +value);
    this.atualizaQuestionario(resposta);
  }

  salvar() {
    if(!this.usuario.id_usuario){
      this.inserir();
    }else{
      this.alterar()
    }
  }

  public inserir(){
    this.utilService.mensagemConfirmacao("Enviar cadastro competidor?", (result) => {
      if (result) {
        this.usuario.ativo = false;
        this.usuario.handicap = null;
        this.usuario.pendente = true;        
        this.usuarioSemCadastroService.inserir(this.usuario)
        .subscribe(usuario => {
          this.utilService.mensagemInfo("Cadastro realizado com sucesso!",
          ()=> this.redireciona());
        },erro => this.utilService.mensagemErro(erro.error.mensagem));
      }
    });
  }

  public alterar(){
    this.utilService.mensagemConfirmacao("Deseja realmente alterar o cadastro do competidor?",
    (result) => {
      if (result) {
        this.usuario.excluido = false;
        this.usuarioSemCadastroService.alterar(this.usuario.id_usuario, this.usuario)
        .subscribe(usuario => {
          this.utilService.mensagemInfo("Cadastro alterado com sucesso!",
          ()=> this.utilService.irPara(this.router, '/comum/lista-competidores-sem-handicap'));
        },erro => this.utilService.mensagemErro(erro.error.mensagem));
      }
    });
  }

  public erro(mensagem : string){
    this.utilService.mensagemErro(mensagem, () => {
      this.redireciona();
    });
  }

  public getAge(year, month, day) {
    var now = new Date()	
    var age = now.getFullYear() - year
    var mdif = now.getMonth() - month + 1 //0=jan	
    
    if(mdif < 0)
    {
      --age
    }
    else if(mdif == 0)
    {
      var ddif = now.getDate() - day
      
      if(ddif < 0)
      {
        --age
      }
    }
    return age
  }

  private redireciona() {
    this.utilService.irPara(this.router, '/comum/inicio');
  }

}
