import { Component, OnInit } from '@angular/core';
import { Usuario } from '../../../../model/usuario';
import { Subject } from 'rxjs';
import { UsuarioSemCadastroFilter } from '../../../../filter/usuario-sem-cadastro.filter';
import { debounceTime } from 'rxjs/operators';
import { UsuarioSemCadastroService } from '../../../../service/usuario-sem-cadastro.service';
import { UtilService } from '../../../../util/util.service';

@Component({
  selector: 'app-lista-competidores-sem-cadastro',
  templateUrl: './lista-competidores-sem-cadastro.component.html',
  styleUrls: ['./lista-competidores-sem-cadastro.component.css']
})
export class ListaCompetidoresSemCadastroComponent implements OnInit {
  
  public usuarios: Usuario[] = [];
  public debounce: Subject<string> = new Subject<string>();
  public filtro: UsuarioSemCadastroFilter = new UsuarioSemCadastroFilter();
  public limit: number = 300;
  public offset: number = 0;
  public pagina: number = 1;
  public numPagina: number;
  public paginas: any[] = [];
  
  constructor(private usuarioSemCadastroService: UsuarioSemCadastroService,
              public utilService: UtilService) { }

  ngOnInit() {
    this.initFiltro();
    this.buscaUsuario();
    this.buscaUsuarioFiltro(this.filtro.nome);
  }

  public initFiltro(){
    this.filtro.ativo = true;
    this.filtro.excluido = false;
  }

  private buscaUsuario(){
    this.debounce.pipe(debounceTime(1000))
    .subscribe(filtro => {
      this.filtro.nome = filtro;
      this.usuarioSemCadastroService.buscaFiltro(this.filtro, this.limit, this.offset)
      .subscribe(retorno => {
        this.usuarios = retorno.usuarios;
        this.numPagina = retorno.quantidade;
        this.paginacao();
      });
    });
  }

  public getUsuario(filtro){
    this.debounce.next(filtro);
  }

  public buscaUsuarioFiltro(filtro: string){
    this.offset = 0;
    this.getUsuario(filtro);
  }

  public selecionarTipoStatus(status: string){
    this.filtro.ativo = status == "true" ? true : false;
    this.filtro.excluido = false;
    this.buscaUsuarioFiltro(this.filtro.nome);
  }

  public selecionarExcluido(){
    this.filtro.excluido = true;
    this.filtro.ativo = null;
    this.buscaUsuarioFiltro(this.filtro.nome);
  }

  public excluirUsuario(usuario: Usuario){
    this.utilService
    .mensagemConfirmacao("Deseja realmente excluir " + usuario.nome + "?",
    (res) => {
      if(res){
        this.usuarioSemCadastroService.excluir(usuario.id_usuario)
        .subscribe(
          retorno => {
            this.utilService.mensagemSucesso("Competidor excluído com sucesso!");
            this.getUsuario(this.filtro.nome);
          },
          erro => this.utilService.mensagemErro(erro.error.mensagem)
        );
      }
    })
  }

  public paginacao(){
    let aux = Number(this.numPagina);
    this.paginas = [];
    if (aux % this.limit > 0) {
      aux = (aux / this.limit) + 1;
    } else {
      aux = aux / this.limit;
    }
    this.numPagina = Math.floor(aux);
    for(let i = 1; i <= this.numPagina; i++) {
      this.paginas.push({
        'key': i,
        'paginaValue': i
      })
    }
  }

  buscarHistoricoAnterior() {
    let aux = Number(this.offset);
    if (aux != 0) {
      this.offset = Number(this.offset) - Number(this.limit);
      this.getUsuario(this.filtro.nome);
    }
  }

  buscarProximoHistorico() {
    let aux = Number(this.offset);
    if (aux < this.numPagina) {
      this.offset = Number(this.offset) + Number(this.limit);
      this.pagina++;
      this.getUsuario(this.filtro.nome);
    }
  }

  pulaPagina(pagina: any) {
    let aux = this.offset;
    this.offset = (Number(pagina) - 1) * (this.limit);
    if (aux > this.offset) {
      this.pagina--;
    } else {
      this.pagina++;
    }
    this.getUsuario(this.filtro.nome);
  }
}
