import { Component, EventEmitter, IterableDiffers, Input, Output, Inject, AfterContentInit, OnInit, AfterViewInit} from '@angular/core';
import { FormGroup, FormBuilder} from '@angular/forms';
import { DualListComponent } from 'angular-dual-listbox';

import { Regra } from '../../../app/model/regra';
import { Divisao } from '../../model/divisao';
import { DivisaoInformacoes } from './DivisaoInformacoes.model';
import { ProvaEditada } from '../../model/ProvaEditada';
import { ProvaRacas } from '../../../app/model/prova_racas';

import { UtilService } from '../../util/util.service';
import { RegraService } from '../../../app/service/regra.service';
import { ProvaService } from '../../service/prova.service';
import { ProvaRacasService } from '../../../app/service/prova-racas.service';
import { Evento } from '../../model/evento';

import { DivisaoService } from '../../service/divisao.service';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

declare var UIkit: any;

@Component({
	selector: 'custom-listbox',
	templateUrl: './custom-listbox.component.html',
	styleUrls: ['./custom-listbox.component.css']
})
export class CustomListboxComponent  extends DualListComponent implements AfterViewInit, AfterContentInit, OnInit {

	@Input() sourceName = '';
	@Input() targetName = '';

	@Output() selectChange = new EventEmitter();
	@Output() regraEmitter = new EventEmitter<any>();
	@Output() informacoesDivisaoEmitter = new EventEmitter<DivisaoInformacoes>();

	public formRegraModificada: FormGroup;
	private formRe: FormGroup;
	private infoDivisao = new DivisaoInformacoes();

	@Input() divisoes_evento : Divisao[];
	@Input() divisoes : Divisao[];
	@Input() id_evento : number;

	@Input() maximo_competidores : number;
	@Input() idDivisaoSelecionada: number;
	@Input() precoInscricaoEidDivisao : any[];

	@Input() evento: Evento;

	public provasEditadas: any[] = [];
	public provasEvento : ProvaEditada[] =[];

	public adicionarDivisao : Divisao[] = [];
	public removerDivisao : Divisao[] = [];

	public provas : any[] = []

	regras : Regra[] = [];

	click : boolean = false;
	valorClick : number = 0;
	valorDraw : number = 0;
	valor: boolean = false;
	preco : number = 0;
	precoInscricao : string;
	@Input() recebePrecoInscricao;

	public tituloProvaEdicao: string;

	public racasPontuar: ProvaRacas[] = [];
	
	public filtroNome : string = null;
	//public divisoesSemFiltro :  Divisao[] = [];

	private debounceFiltroDivisao: Subject<string> = new Subject<string>();
	@Output() filtroEmitter: EventEmitter<any> = new EventEmitter();

	constructor(differs:IterableDiffers,
				public utilService: UtilService,
				@Inject(FormBuilder) private formBuilder: FormBuilder,
				public regraService : RegraService,
				private provaService : ProvaService,
				private provaRacasService: ProvaRacasService,
				private divisaoService : DivisaoService) {

		super(differs);
		
		this.buscaRegras();

	}

	
	ngAfterContentInit(){
		this.precoInscricao = this.recebePrecoInscricao;
	}

	ngAfterViewInit(){
		this.removeDuplicidade();
	}
	
	ngOnInit(){
		this.debounceFiltroDivisao.pipe(debounceTime(300)).subscribe(filtro => {
			this.divisaoService.buscaDivisoesFiltrado(filtro.toString()).subscribe(divisoes => {
				this.divisoes = divisoes;
				this.divisoes_evento.forEach(divisao_evento => {
					this.divisoes.forEach(divisao => {
						if (divisao_evento.id_divisao == divisao.id_divisao) {
						    this.divisoes.splice(this.divisoes.indexOf(divisao), 1)
						}
					})
				})
			});
		});

		this.precoInscricao = this.recebePrecoInscricao;
		
		if(this.id_evento == null || this.id_evento == undefined){
			let id =this.provaService.getIdEvento();
			this.getProvasPorEvento(id);
			
		}else{
			this.getProvasPorEvento(this.id_evento);
		}		
	}

	public getDivisoesFiltro(filtro: string): void {
		if (filtro && filtro.length >= 3) {
		    	this.debounceFiltroDivisao.next(filtro);
		} else {
			this.filtroEmitter.emit('filtroLimpo');
		}
	  }

	public removeDuplicidade(){
		let aux = [];

		//if(this.divisoes_evento.length > 0 && this.divisoes.length > 0){
		this.divisoes_evento.forEach(de => {
			this.divisoes.forEach(d=> {
				if(de.id_divisao == d.id_divisao){
					aux.push(d);
				}
			})
		});

		if(aux.length > 0){

			aux.forEach(a => {
				this.divisoes.splice(this.divisoes.indexOf(a),1);
			});
		}
	}

	public filtroDivisao(){
		if(this.filtroNome != null && this.filtroNome != ''){
			this.divisoes = this.divisoes.filter( divisao => divisao.nome.toUpperCase() == this.filtroNome.toUpperCase());
		}
	}

	public semFiltro(){
		this.filtroNome = '';
		this.getDivisoes();
	}

	public getDivisoes(){
		this.divisaoService.buscaTodos().subscribe(divisoes => {
            this.divisoes = divisoes.sort(function (a, b) {
                return a.nome > b.nome ? 1 : a.nome < b.nome ? -1 : 0;
            });
            this.divisoes_evento.forEach(divisao_evento => {
                this.divisoes.forEach(divisao => {
                    if (divisao_evento.id_divisao == divisao.id_divisao) {
                        this.divisoes.splice(this.divisoes.indexOf(divisao), 1)
                    }
                })
            })
        });
	}

	public getProvasPorEvento(id_evento){
		this.provaService.buscaProvasDeUmEvento(id_evento).subscribe(retorno => {
			this.provas = retorno;
		});
	}

	initDivisoes(){
		this.confirmed.list.forEach(d =>{
			let provaEditada = new ProvaEditada();
			provaEditada.id_divisao = d._id;
			provaEditada.preco_inscricao = this.recebePrecoInscricao;

			if(this.divisoes_evento.length > 0){
				this.divisoes_evento.forEach(de => {
					if(de.id_divisao == d._id){
						provaEditada.preco_inscricao = de.preco_inscricao;
					}
				});
			}

			this.provasEvento.push(provaEditada);

		});
	}

	public initDivicoesComIdEvento(){
		this.confirmed.list.forEach(d =>{
			let provaEditada = new ProvaEditada();
			provaEditada.id_divisao = d._id;
			provaEditada.preco_inscricao = this.recebePrecoInscricao;
			this.provaService.buscaInformacoesProvaIdDivisaoEvento(d._id, this.id_evento)
				.subscribe( retorno => {
					if(this.divisoes_evento.length > 0){
						this.divisoes_evento.forEach(de => {
							if(de.id_divisao == d._id){
								provaEditada.preco_inscricao = de.preco_inscricao;
							}
						});
					}
					this.provasEvento.push(provaEditada);
				});

		});
	}

	removeListaProva(){
		this.available.list.forEach(d => {
			this.provasEvento.map(p => {
				if(p.id_divisao == d._id){
					let provaEditada : ProvaEditada;
					provaEditada = p;
					this.provasEvento.splice(this.provasEvento.indexOf(provaEditada),1);
					this.provaService.removeCustom(provaEditada);
				}
			});

		});
	}

	public pegaDivisao(divisao : Divisao){
		this.infoDivisao = new DivisaoInformacoes();
		this.infoDivisao.racasPontuar = [];
		this.idDivisaoSelecionada = divisao.id_divisao;
		this.tituloProvaEdicao = divisao.nome;
		//this.removeDuplicidade();
		//utilizando serviço compartilhado para poder carregar informações
		//do componente cadastro evento, pois, as informações estavam chegando com atraso
		this.preco = this.provaService.getValorEvento();
		let porcentagem_premiacao = this.provaService.getPorcentagemPremiacao();
		let taxa_administrativa = this.provaService.getTaxaAdministrativa();
		this.precoInscricao = this.precoInscricao != '' ? this.precoInscricao : String(this.preco);
		let control = false;
		this.initInfoDivisao();
		if(this.id_evento != null && this.provas.length == 0){
			this.getProvasPorEvento(this.id_evento);
		}

		//verifica se a divisão selecionada é uma prova,
		//se for uma prova, pega as informações dela para poder 
		//carregar a poup
		if(this.provas.length > 0){
			this.provas.forEach(p => {
				if(p.id_divisao == divisao.id_divisao){
					control = true;
					this.infoDivisao.id_divisao = p.id_divisao;
					this.infoDivisao.id_prova = p.id_prova;
					this.infoDivisao.preco_inscricao = p.preco_inscricao;
					this.infoDivisao.porcentagem_premiacao = p.porcentagem_premiacao != null? p.porcentagem_premiacao : porcentagem_premiacao;
					this.infoDivisao.taxa_administrativa = p.taxa_administrativa;
					this.infoDivisao.somatorio_minimo =  p.somatorio_minimo;
					this.infoDivisao.somatorio_maximo =  p.somatorio_maximo;
					this.infoDivisao.draw = p.draw;
					this.infoDivisao.numero_maximo_inscricao_competidor = p.numero_maximo_inscricao_competidor;
					this.infoDivisao.qtd_maxima_inscricao_cavalo = p.qtd_maxima_inscricao_cavalo;
					this.infoDivisao.qtd_maxima_inscricao_dupla = p.qtd_maxima_inscricao_dupla;
					this.infoDivisao.qtd_maxima_inscricao_trio = p.qtd_maxima_inscricao_trio;
					this.infoDivisao.racasPontuar = p.racasPontuar;
					this.infoDivisao.incremento_premiacao = p.incremento_premiacao;
					this.infoDivisao.maximo_competidores = p.qtd_maxima_competidor;
					this.infoDivisao.tipo_inscricao = p.tipo_prova;
					this.infoDivisao.inscricao_bloqueada = p.inscricao_bloqueada;
					this.infoDivisao.limite_inscricao = p.limite_inscricao;
				}
			});
		}

		if(!control){
			this.divisoes_evento.forEach(div => {
				if(div.id_divisao === divisao.id_divisao){
					this.infoDivisao.id_divisao = div.id_divisao;
					this.infoDivisao.id_prova = null;
					this.infoDivisao.preco_inscricao = this.recebePrecoInscricao != null? this.recebePrecoInscricao : 0;
					this.infoDivisao.porcentagem_premiacao = porcentagem_premiacao != null? String(porcentagem_premiacao) : '0';
					this.infoDivisao.somatorio_minimo =  div.somatorio_minimo;
					this.infoDivisao.somatorio_maximo =  div.somatorio_maximo;
					this.infoDivisao.qtd_maxima_inscricao_cavalo = div.qtd_maxima_inscricao_cavalo;
					this.infoDivisao.draw = div.draw == true ? true : false;
					this.infoDivisao.numero_maximo_inscricao_competidor = div.numero_maximo_inscricao_competidor;
					this.infoDivisao.qtd_maxima_inscricao_dupla = div.qtd_maxima_inscricao_dupla;
					this.infoDivisao.qtd_maxima_inscricao_trio = this.infoDivisao.qtd_maxima_inscricao_trio;
					this.infoDivisao.taxa_administrativa = div.taxa_administrativa? div.taxa_administrativa : String(taxa_administrativa);
					this.infoDivisao.racasPontuar = div.racasPontuar;
					this.infoDivisao.incremento_premiacao = div.incremento_premiacao != null ? div.incremento_premiacao : '0';
					this.infoDivisao.maximo_competidores = this.evento.maximo_competidores;
					this.infoDivisao.tipo_inscricao = div.id_tipo_inscricao;
					this.infoDivisao.limite_inscricao = div.limite_inscricao;
				}
			});
		}

		this.carregarPopup();
	}

	public abreModalRacasPontuar() {
		UIkit.modal('#racaPontuarModal',{bgClose : false} ).show();
	}

	public carregarPopup(){
		this.formRegraModificada = this.formBuilder
		.group(this.regras.reduce((o, key) => {
			let valor : any;
			if(key.parametros_id === "maximoInscricoesCompetidor"){
				valor = this.infoDivisao.numero_maximo_inscricao_competidor;
			}else if(key.parametros_id === "draw"){
				valor = this.infoDivisao.draw;
			}else if(key.parametros_id === "maximoInscricoesDupla"){
				valor = this.infoDivisao.qtd_maxima_inscricao_dupla;
			}else if(key.parametros_id === "maximoCavaloCorreProva"){
				valor = this.infoDivisao.qtd_maxima_inscricao_cavalo;
			}else if(key.parametros_id ==="maximoCompetidoresEvento"){
				valor = this.infoDivisao.maximo_competidores;
			}else if(key.parametros_id ==="maximoInscricoesTrio"){
				valor = this.infoDivisao.qtd_maxima_inscricao_trio;
			}

			if(key.nome == 'valorDraw'){
				return {...o, [key.parametros_id] : valor}
			}else{
				return {...o, [key.parametros_id] : valor}
			}
		}, {}));
	}

	public selecionado(divisao , tipo){
		let select = false;

		if(tipo == 1){
			this.adicionarDivisao.forEach(d => {
				if(d.id_divisao == divisao.id_divisao){
					select = true;
				}
			});
			return select;
		}else if(tipo == 2){
			this.removerDivisao.forEach(d => {
				if(d.id_divisao == divisao.id_divisao){
					select = true;
				}
			});

			return select;
		}
	}

	setValor(){
		this.valorClick++;

		if(this.valorClick % 2 == 0){
			this.click = false;
			this.valor = false;
		}else{
			this.click = true;
		}
	}

	setValorDraw(){
		this.valorDraw++;
		if(this.valorDraw % 2 == 0){
			this.valor = false;
		}else{
			this.valor = true;
		}
	}

	setPrecoInscricao(valor){
		this.infoDivisao.preco_inscricao = valor;
	}

	buscaRegras(){
		this.regraService.buscaPorTipoRegra(2).subscribe((regra : Regra[]) => {
			this.regras = regra;
			this.formRegraModificada = this.formBuilder
			.group(this.regras.reduce((o, key) => {
				if(key.nome == 'valorDraw'){
					return {...o, [key.parametros_id] : this.recebePrecoInscricao}
				}else{
					return {...o, [key.parametros_id] : null}
				}
			}, {}));

			this.regras.sort((a, b) => {return a.nome.localeCompare(b.nome);});
		});


	}

	initInfoDivisao(){
		this.infoDivisao.id_divisao = 0;
		this.infoDivisao.nome = "";
		this.infoDivisao.porcentagem_premiacao = '';
		this.infoDivisao.preco_inscricao = "";
		this.infoDivisao.taxa_administrativa = "";
		this.infoDivisao.incremento_premiacao = '';
		this.infoDivisao.tipo_inscricao = null;
		this.infoDivisao.numero_maximo_inscricao_competidor = null;
		this.infoDivisao.qtd_maxima_inscricao_dupla = null;
		this.infoDivisao.qtd_maxima_inscricao_trio = null;
		this.infoDivisao.id_prova = null;
		this.infoDivisao.inscricao_bloqueada = null;
		this.infoDivisao.limite_inscricao = null;
	}
	

	private enviarRegra() {
		this.todasRacasPontuarAdicionadas.pop();
		this.todasRacasPontuarRemovidas.pop();
		//variavel para controle de divisões, a qual indica se uma divisao
		//já virou um prova ou não.
		let control = false;
		if(this.provas.length > 0){
			this.provas.forEach(p => {
				if(this.infoDivisao.id_divisao == p.id_divisao){
					control = true;
					p.preco_inscricao = this.infoDivisao.preco_inscricao;
					p.porcentagem_premiacao = this.infoDivisao.porcentagem_premiacao;
					p.somatorio_maximo = this.infoDivisao.somatorio_maximo;
					p.somatorio_minimo = this.infoDivisao.somatorio_minimo;
					p.taxa_administrativa = this.infoDivisao.taxa_administrativa;
					p.incremento_premiacao = this.infoDivisao.incremento_premiacao;
					p.limite_inscricao = this.infoDivisao.limite_inscricao;
					p = this.setValorRegra(p);
					this.provaService.altera(p.id_prova, p).subscribe(retorno => retorno);
				}
			});
		}

		if(!control){
			this.divisoes_evento.forEach(d => {
				if(d.id_divisao == this.infoDivisao.id_divisao){
					d.preco_inscricao = this.infoDivisao.preco_inscricao;
					d.porcentagem_premiacao = this.infoDivisao.porcentagem_premiacao == undefined? null : this.infoDivisao.porcentagem_premiacao;
					d.somatorio_maximo = this.infoDivisao.somatorio_maximo;
					d.somatorio_minimo = this.infoDivisao.somatorio_minimo;
					d.taxa_administrativa = this.infoDivisao.taxa_administrativa;
					d.status_atualizacao = true;
					d.incremento_premiacao = this.infoDivisao.incremento_premiacao;
					d.limite_inscricao = this.infoDivisao.limite_inscricao;
					d = this.setValorRegra(d);
				}
			})
		}
		
		this.finaliza();
		this.buscaRegras();

		this.utilService.mensagemSucesso(`Divisão ${this.infoDivisao.nome} alterada com sucesso!`);
		UIkit.modal('#modal-sections').hide();
	}

	//d -> Pode ser prova ou divisao
	public setValorRegra(d){
		this.regras.forEach(regra => {
			if(regra.nome != 'valorDraw'){
				let valor =  this.formRegraModificada.get(regra.parametros_id).value;
				if(regra.parametros_id === "maximoInscricoesCompetidor"){
					d.numero_maximo_inscricao_competidor = valor;
				}else if(regra.parametros_id === "draw"){
					d.draw = valor;
				}else if(regra.parametros_id === "maximoInscricoesDupla"){
					d.qtd_maxima_inscricao_dupla = valor;
				}else if(regra.parametros_id === "maximoCavaloCorreProva"){
					d.qtd_maxima_inscricao_cavalo = valor;
				}else if(regra.parametros_id === "maximoCompetidoresEvento"){
					d.qtd_maxima_competidor = valor;
				}else if(regra.parametros_id === "maximoInscricoesTrio"){
					d.qtd_maxima_inscricao_trio = valor;
				}
			}

		});
		return d;
	}

	public enviaInformacoesParaService(){
		this.informacoesDivisaoEmitter.emit(this.infoDivisao);
	}

	private finaliza(){
		this.formRegraModificada = this.formBuilder
				.group(this.regras.reduce((o, key) => {
					if(key.nome == 'valorDraw'){
						return {...o, [key.parametros_id] : this.recebePrecoInscricao}
					}else{
						return {...o, [key.parametros_id] : null}
					}
				}, {}));
		this.valorClick = 0;
		this.valorDraw = 0;
		this.valor = false;
		this.click = false;
	}

	public pegarDivisao(divisao){
		let index = false;
		this.adicionarDivisao.forEach(d => {
			if(d.id_divisao == divisao.id_divisao){
				index = true;
			}
		});

		if(index){
			this.adicionarDivisao.splice(this.adicionarDivisao.indexOf(divisao),1);
		}else{
			this.adicionarDivisao.push(divisao);
		}
	}

	public adicionarDivisaoEvento(){
		if(this.adicionarDivisao.length > 0){
			this.adicionarDivisao.forEach(div => {
				this.divisoes_evento.push(div);
				this.divisoes.splice(this.divisoes.indexOf(div),1);
			});
		}
		
		this.adicionarDivisao = [];
	}

	public retirarDivisao(divisao){
		let index = false;
		this.removerDivisao.forEach(d => {
			if(d.id_divisao == divisao.id_divisao){
				index = true;
			}
		});

		if(index){
			this.removerDivisao.splice(this.removerDivisao.indexOf(divisao),1);
		}else{
			this.removerDivisao.push(divisao);
		}
	}

	public retirarDivisaoEvento(){
		if(this.removerDivisao.length > 0){
			this.removerDivisao.forEach(div => {
				this.divisoes.push(div);
				this.divisoes_evento.splice(this.divisoes_evento.indexOf(div),1);
			});
		}
		
		this.removerDivisao = [];
	}

	public adicionarTodasDivisoesEvento(){
		if(this.divisoes.length > 0){
			this.divisoes.forEach(div => this.pegarDivisao(div));
			this.adicionarDivisaoEvento();
		}
	}

	public removerTodasDivisoesEvento(){
		if(this.divisoes_evento.length > 0){
			this.divisoes_evento.forEach(div => this.retirarDivisao(div));
			this.retirarDivisaoEvento();
		}
	}

	@Output() racaPontuarEmitter = new EventEmitter<ProvaRacas>();
	@Output() infoDivisaoEmitter = new EventEmitter<DivisaoInformacoes>();
	private todasRacasPontuarAdicionadas: ProvaRacas[] = [];
	private todasRacasPontuarRemovidas: ProvaRacas[] = [];

	public pegaRacaProvaAdicionada(racaProva: ProvaRacas): void {
		if (!this.infoDivisao.racasPontuar) {
			this.infoDivisao.racasPontuar = [];
		}
		this.todasRacasPontuarAdicionadas.push(racaProva);
		this.infoDivisao.racasPontuar.push(racaProva);
		this.infoDivisaoEmitter.emit(this.infoDivisao);
		this.racaPontuarEmitter.emit(racaProva);
	}

	public removeRacaPontuar(racaProva: ProvaRacas): void {
		this.todasRacasPontuarRemovidas.push(racaProva);
		this.utilService.mensagemConfirmacao(`Deseja remover a raça a pontuar ${racaProva.raca.descricao} ?`, (result) => {
			if (result) {
				this.provaRacasService.delete(racaProva.id_prova_racas).subscribe( () => {
					this.utilService.mensagemSucesso(`Raça ${racaProva.raca.descricao} removida com sucesso!`);
					this.infoDivisao.racasPontuar.splice(this.infoDivisao.racasPontuar.indexOf(racaProva), 1);
				});
			}
		});
	}

	public cancelarEdicaoProva(): void {
		this.todasRacasPontuarAdicionadas.forEach(racaPontuar => {
			this.infoDivisao.racasPontuar.forEach(raca => {
				if (raca.id_prova_racas == racaPontuar.id_prova_racas) {
					this.infoDivisao.racasPontuar.splice(this.infoDivisao.racasPontuar.indexOf(raca), 1);
				}
			})
			this.provaRacasService.delete(racaPontuar.id_prova_racas).subscribe();
		});

		this.todasRacasPontuarRemovidas.forEach(racaPontuar => {
			if (racaPontuar.id_divisao == this.infoDivisao.id_divisao) {
				this.provaRacasService.post(racaPontuar).subscribe(racaProva => {
					this.infoDivisao.racasPontuar.push(racaProva);
				});
			}
		});

		this.todasRacasPontuarRemovidas.pop();
	}

	public bloquearDesbloquearInscricao(){
		let mensagem = this.infoDivisao.inscricao_bloqueada ? "desbloquear" : "bloquear";
		this.utilService.mensagemConfirmacao("Deseja realmente " + mensagem + " inscrição?",
		(resp) => {
			if(resp){
				this.provaService.bloquearInscricao(this.infoDivisao.id_prova, !this.infoDivisao.inscricao_bloqueada)
				.subscribe(
					() => {
						this.infoDivisao.inscricao_bloqueada = !this.infoDivisao.inscricao_bloqueada;
						this.getProvasPorEvento(this.id_evento);
						this.utilService.mensagemSucesso("Operação realizada com sucesso!");
					},
					erro => this.utilService.mensagemErro("Erro - " + erro.error.mensagem)
				);
			}
		});
	}
}
