import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';

import { environment } from '../../environments/environment';
import { Email } from '../model/email';
import { Usuario } from '../model/usuario';

@Injectable()
export class EmailService {

    private email_api_url = `${environment.api_url}/email`;

    constructor(private http: HttpClient) {}

    public mandaEmailContato(email: Email): Observable<any> {
        return this.http.post<any>(`${this.email_api_url}/contato`, email);
    }

    public mandaEmailCadastroCompetidor(usuario: Usuario, link: string): Observable<any> {
        return this.http.post<any>(`${this.email_api_url}/cadastro-competidor`, {usuario, link});
    }
}