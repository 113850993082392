import { OnInit, Component, Input, Output, EventEmitter, AfterContentInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { Usuario } from '../../../../../model/usuario';
import { Prova } from '../../../../../model/prova';
import { ProvaService } from '../../../../../service/prova.service';
import { VerificaRegraService } from '../../../../../service/verifica-regra.service';
import { Campeonato } from '../../../../../model/campeonato';
import { Evento } from '../../../../../model/evento';
import { UtilService } from '../../../../..//util/util.service';
import { InscricaoService } from '../../../../../service/inscricao.service';
import { InscricaoCompetidorService } from '../../../../../service/inscricao-competidor.service';

declare var $: any;

@Component({
    selector: 'selecao-de-provas-por-competidor',
    templateUrl: './selecao-de-provas-por-competidor.component.html',
    styleUrls: ['./selecao-de-provas-por-competidor.style.css', './../../../inscricoes.style.css']
})
export class SelecaoDeProvasPorCompetidorComponent implements OnInit, AfterContentInit {

    @Input() campeonato: Campeonato;
    @Input() evento: Evento;

    @Input() pagina: number;
    @Output() paginaEmitter: EventEmitter<number> = new EventEmitter<number>();
    @Output() paginaAnteriorEmitter: EventEmitter<number> = new EventEmitter<number>();

    @Input() listaDeCompetidoresInscritos: Usuario[];
    @Input() numeroDeInscritos: number;
    
    public listaDeProvasDoEvento: Prova[] = [];
    public listaDeProvasSelecionadas: Prova[] = [];
    private competidores : Usuario[] = [];
    
    @Output() listaDeProvasSelecionadasEmitter: EventEmitter<Prova[]> = new EventEmitter<Prova[]>();

    constructor(private provaService: ProvaService,
                private activatedRoute: ActivatedRoute,
                private verificaRegraService: VerificaRegraService,
                private utilService : UtilService,
                private inscricaoService : InscricaoService,
                private inscricaoCompetidorService : InscricaoCompetidorService) {}
    
    ngOnInit(): void {
        this.initCompetidores();
        this.popularProvasPorCompetidor();
    }

    ngAfterContentInit(){
        this.competidores = this.inscricaoService.getCompetidores();
    }

    private initCompetidores(){
        if(this.listaDeCompetidoresInscritos.length > 0){
            this.listaDeCompetidoresInscritos.forEach(competidor => competidor.valor_inscricao = 0);
        } 
    }

    private adicinarInscricao(prova){
        let obj = {
            id_prova : prova.id_prova,
            competidor : this.competidores,
            id_evento : prova.id_evento
        }
        this.provaService.buscaInformacoesPorProvaECompetidor(obj)
        .subscribe(retorno => {
            if(prova.qtdInscricao < retorno.valor && retorno.valor > 0){
                if(prova.qtdInscricao == -1){
                    prova.qtdInscricao = 0;
                    prova.qtdInscricao = 1;
                } else {
                    prova.qtdInscricao++;
                }

            }else{
                prova.qtdInscricao = retorno.valor;
                if(retorno.valor == 0){
                    let mensagem = retorno.mensagem ? retorno.mensagem : "Já atingiu o limite de inscrição desse(s) competidor(es)!";
                    this.utilService.mensagemInfo(mensagem, (res) =>{
                        if(res){
                            this.listaDeProvasSelecionadas = this.listaDeProvasSelecionadas
                                                        .filter(prov => prov.id_prova != prova.id_prova);
                            $(`#checkboxInput${prova.id_prova}`).prop('checked', false);
                        }else{
                            prova.qtdInscricao = undefined;
                            this.listaDeProvasSelecionadas = this.listaDeProvasSelecionadas
                                                        .filter(prov => prov.id_prova != prova.id_prova);
                            $(`#checkboxInput${prova.id_prova}`).prop('checked', false);
                        }
                    });
                }else{
                    if(retorno == prova.qtdInscricao){
                        this.utilService.mensagemInfo("Já atingiu o limite de inscrição desse(s) competidor(es)!");
                    }
                }
            }
        });
    }

    removeInscricao(prova){
       if(prova.qtdInscricao > 1){
           prova.qtdInscricao--;
       }
    }

    public verificaListaDeProvasSelecionadas(){
        return !(this.listaDeProvasSelecionadas.length > 0);
    }

    private validacaoInicialDasInscricoes(prova){

        let obj = {
            id_prova : prova.id_prova,
            competidor : this.competidores,
            id_evento : prova.id_evento
        }

        this.provaService.buscaInformacoesPorProvaECompetidor(obj)
        .subscribe(retorno => {
            if(prova.qtdInscricao == undefined || prova.qtdInscricao == null){
                prova.qtdInscricao = -1;
            }else if(retorno.valor == 0){
                prova.qtdInscricao = retorno.valor;
            }else if(retorno.valor > 0){
                prova.qtdInscricao = -1;
            }
        });
    }

    private popularProvasPorCompetidor(): void {
        
        this.provaService.buscaProvasDeUmEvento(this.activatedRoute.snapshot.params.idEvento).subscribe(provas => {
            this.listaDeProvasDoEvento = provas;
            this.listaDeProvasDoEvento.forEach(p => this.validacaoInicialDasInscricoes(p));
            let tipoCadastro = this.provaService.pegaTipoCadastro();
            this.listaDeProvasDoEvento = this.listaDeProvasDoEvento.filter(prova => prova.divisao.nao_exigir_cadastro == tipoCadastro);
            let filtroProvas : Prova[] = [];
            this.listaDeProvasDoEvento.forEach(prova => {
                if(this.numeroDeInscritos == 1 && (prova.tipo_prova == this.numeroDeInscritos || prova.draw == true)){
                    filtroProvas.push(prova);
                }else if(prova.tipo_prova == this.numeroDeInscritos){
                    filtroProvas.push(prova);
                }
            });
            this.listaDeProvasDoEvento = filtroProvas;
            this.verificaRegraService.verificaRegras(this.listaDeProvasDoEvento, this.listaDeCompetidoresInscritos);
            this.organizaListaDeProvasPelasRegras();
        })
    }
    
    public validaSomatorioHandcap(competidores : Usuario[], provas : Prova[]){
        
    }

    private organizaListaDeProvasPelasRegras(): void {
        this.listaDeProvasDoEvento.sort((provaA, provaB) => {
            let a: number = 0;
            let b: number = 0;
            if (provaA.regrasNaoAtendidas != null && provaA.regrasNaoAtendidas.length > 0){
                a = 1;
            } 
            if (provaB.regrasNaoAtendidas != null && provaA.regrasNaoAtendidas.length > 0) {
                b = 1;
            } 
            return a - b;
        });
    }

    private calculaValorDaInscricao(){

        let idProvasQtdInscricao = this.listaDeProvasSelecionadas.map(p => {
            return {
                id_prova : p.id_prova,
                qtdInscricao : p.qtdInscricao
            }
        });
        let id_evento = this.evento.id_evento;
        this.listaDeCompetidoresInscritos.forEach(comp => {
            this.inscricaoCompetidorService.buscaValorDaInscricao(idProvasQtdInscricao, comp, id_evento)
            .subscribe(valor => {
                comp.valor_inscricao = valor;
            });
        });
    }

    private adicionaOuRemoveProvasNaListaDeProvasSelecionadas(prova): void {
        if ($(`#checkboxInput${prova.id_prova}`).prop('checked')) {
            this.listaDeProvasSelecionadas.push(prova);
             this.adicinarInscricao(prova);
        } else {
            prova.qtdInscricao = -1;
            this.listaDeProvasSelecionadas = 
            this.listaDeProvasSelecionadas.filter(prov => prov.id_prova != prova.id_prova);
        }
    }

    private verificaProvasSelecionadas(prova: Prova) {
        if (this.estaNaListaDeProvas(prova)) {
            this.listaDeProvasSelecionadas = this.listaDeProvasSelecionadas.filter(prov => prov.id_prova != prova.id_prova);
        } else {
            this.listaDeProvasSelecionadas.push(prova);
        }
    }

    private estaNaListaDeProvas(prova: Prova): boolean {

        let retorno: boolean = false;
        this.listaDeProvasSelecionadas.forEach(prov => {
           if (prov.id_prova == prova.id_prova) {
               retorno = true;
           }
        })
        return retorno;
    }

    public prosseguir(pagina: number): void {
        this.calculaValorDaInscricao();
        this.paginaAnteriorEmitter.emit(this.pagina);
        this.paginaEmitter.emit(pagina);
        this.listaDeProvasSelecionadasEmitter.emit(this.listaDeProvasSelecionadas);
    }

    public regressar(pagina: number): void {
        this.paginaEmitter.emit(pagina);
    }

}