import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { PaginationOrganizadorComponent } from './pagination-organizador.component';

@NgModule({
    declarations: [PaginationOrganizadorComponent],
    imports: [
        CommonModule
    ],
    exports: [PaginationOrganizadorComponent]
})
export class PaginationOrganizadorModule {}