import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';

import { environment } from '../../environments/environment';
import { Prova } from '../model/prova';
import { map } from 'rxjs/operators';
import { RegraModificada } from '../comum/formularios/cadastro-evento/regraModificada.model';
import { Divisao } from '../model/divisao';
import { ProvaEditada } from '../model/ProvaEditada';

@Injectable()
export class ProvaService {

  private prova_api_url = `${environment.api_url}/provas`;

  constructor(private http: HttpClient) {}

  private provaEditadas : ProvaEditada[] = [];
  //foi criado para facilitar a consulta do componete custon list
  private id_evento : number = - 1;
  private valor : number = 0;
  private porcentagem_premiacao : number = 0;
  private taxa_administrativa : number = 0;
  private provas : Prova[] = [];
  private tipoCadastro : boolean = false;

  public addIdEvento(id){
    this.id_evento = id;
  }

  public addProvas(provas : Prova[])
  {
    this.provas = [];
    this.provas = provas;
  }

  public getProvas()
  {
    let provas = this.provas;
    return provas;
  }

  public addValorEvento(valor, porcentagem, taxa_administrativa){
    this.valor = valor;
    this.porcentagem_premiacao = porcentagem;
    this.taxa_administrativa = taxa_administrativa;
  }

  public adicionarTipoCadastro(valor : boolean){
    this.tipoCadastro = valor;
  }

  public pegaTipoCadastro(){
    return this.tipoCadastro;
  }

  public getIdEvento(){
    return this.id_evento;
  }

  public getValorEvento(){
    return this.valor;
  }

  public getPorcentagemPremiacao(){
    return this.porcentagem_premiacao;
  }

  public getTaxaAdministrativa(){
    return this.taxa_administrativa;
  }

  public addObjetoCustom(provaEditada)
  {
    if(this.provaEditadas.length == 0){
      this.provaEditadas.push(provaEditada);
    }else{
      let aux = false;
      this.provaEditadas.forEach(cl =>{
        if(cl.id_divisao == provaEditada.id_divisao){
          cl.regras = [];
          cl.regras = provaEditada.regras;
          aux = true;
        }
      });

      if(!aux){
        this.provaEditadas.push(provaEditada);
      }

    }
  }

  public removeCustom(custom)
  {
    this.provaEditadas.splice(this.provaEditadas.indexOf(custom),1);
  }

  public removeTodos()
  {
    this.provaEditadas = [];
  }

  public getCustomList()
  {
    return this.provaEditadas;
  }

  public buscaTodos(): Observable<Prova[]> {
    return this.http.get<Prova[]>(`${this.prova_api_url}`)
      .pipe(map( (prova: Prova[]) => {
        return prova.map( p => {
          return Prova.fromJson(p);
        })
      }));
  }

  public buscaPorId(id: number): Observable<Prova> {
    return this.http.get<Prova>(`${this.prova_api_url}/${id}`)
      .pipe(map( (prova: Prova) => {
          return Prova.fromJson(prova);
        }));
  }

  public insere(prova: Prova): Observable<any> {
    return this.http.post<any>(`${this.prova_api_url}`, prova);
  }

  public altera(id: number, prova: Prova): Observable<any> {
    return this.http.put<any>(`${this.prova_api_url}/${id}`, prova);
  }

  public deleta(id: number): any {
    return this.http.delete<any>(`${this.prova_api_url}/${id}`);
  }

  public salvar(prova: Prova) {
    return this.http.post(`${this.prova_api_url}/salvar`, prova);
  }

  public buscaProvasDeUmEvento(idEvento: number): Observable<Prova[]> {
    return this.http.get<Prova[]>(`${this.prova_api_url}/busca-provas-de-um-evento/${idEvento}`);
  }

  public buscaInformacoesProvaPorId(id_prova: number){
    return this.http.get<any>(`${this.prova_api_url}/informacoes-prova/${id_prova}`);
  }

  public alterarRegraProva(regra){
    return this.http.post<any>(`${this.prova_api_url}/alterar-regra-prova`,regra);
  }

  public buscaTotalDeProvasRealizadaPorUmUsuario(id_usuario : number) : Observable<any>{
    return this.http.get<any>(`${this.prova_api_url}/busca-total-de-provas-por-usuario/${id_usuario}`);
  }

  public buscaInformacoesPorProvaECompetidor(obj) : Observable<any>{
    return this.http.get<any>(`${this.prova_api_url}/informacoes-prova-competidor`, {
      params : {
        'obj' : JSON.stringify({
          'id_prova': obj.id_prova,
          'competidor': obj.competidor,
          'id_evento' : obj.id_evento
        })
      }
    });
  }

  public buscaProvasDeUmUsuarioPorId(id : number, limit: number, offset: number, filtro) : Observable<any>{
    return this.http.get<any>(`${this.prova_api_url}/busca-provas-de-um-usuario/${id}`,{
      params: {
        'offset': offset.toString(),
        'limit': limit.toString(),
        'filtro' : JSON.stringify({
          'data': filtro.data,
          'copa': filtro.copa
        })
      }
    });
  }

  public bloquearInscricao(id_prova : number, statusInscricao) : Observable<any>{
    return this.http.get<any>(`${this.prova_api_url}/status-inscricao-prova/${id_prova}`,{
      params : {
        'statusInscricao' : statusInscricao.toString()
      }
    });
  }

  public buscaInformacoesProvaIdDivisaoEvento(id_divisao, id_evento) : Observable<any[]>{
    return this.http.get<any[]>(`${this.prova_api_url}/busca-provas-por-divisao-evento`,{
      params : {
        'id_divisao' : id_divisao.toString(),
        'id_evento' : id_evento.toString()
      }
    });
  }

  public revalidaCompetidores(objeto : any) : Observable<any>{
    return this.http.get<any>(`${this.prova_api_url}-revalida-competidores`,{
      params : {
        objeto : JSON.stringify(objeto)
      }
    });
  }

  public buscaPorIdCadastradorCompetidor(id : number, limit: number, offset: number, filtro) : Observable<any>{
    return this.http.get<any>(`${this.prova_api_url}/busca-por-cadastrador-competidor/${id}`,{
      params: {
        'offset': offset.toString(),
        'limit': limit.toString(),
        'filtro' : JSON.stringify({
          'data': filtro.data,
          'copa': filtro.copa
        })
      }
    });
  }
}
