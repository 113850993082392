import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';

import { environment } from '../../environments/environment';
import { RegraDivisao } from '../model/regra_divisao';

@Injectable()
export class RegraDivisaoService {

    private regra_divisao_api_url = `${environment.api_url}/regras-divisao`;

    constructor(private http: HttpClient) {}

    public buscaTodos(): Observable<RegraDivisao[]> {
      return this.http.get<RegraDivisao[]>(`${this.regra_divisao_api_url}`);
    }

    public buscaPorId(id: number): Observable<RegraDivisao> {
      return this.http.get<RegraDivisao>(`${this.regra_divisao_api_url}/${id}`);
    }

    public insere(regra_divisao: RegraDivisao): Observable<any> {
      return this.http.post<any>(`${this.regra_divisao_api_url}`, regra_divisao);
    }

    public altera(id: number, regra_divisao: RegraDivisao): Observable<any> {
      return this.http.put<any>(`${this.regra_divisao_api_url}/${id}`, regra_divisao);
    }

    public deleta(id: number): any {
      return this.http.delete<any>(`${this.regra_divisao_api_url}/${id}`);
    }

    /**
     * Deleta todas as regras relacionadas a uma divisao
     * @param id_divisao 
     */
    public deletaRegrasDivisao(id_divisao: number): any {
      return this.http.delete<any>(`${this.regra_divisao_api_url}/deleta-regras-divisao/${id_divisao}`);
    }

    public salvar(regra_divisao: RegraDivisao) {
      return this.http.post(`${this.regra_divisao_api_url}/salvar`, regra_divisao);
    }

    public buscarRegrasPorDivisao(id_divisao: number): Observable<any> {
      return this.http.get(`${this.regra_divisao_api_url}/buscar-por-divisao/${id_divisao}`)
    }
      
}
