import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { ProvaHistorico } from '../../../model/prova.historico';
import { Router } from '@angular/router';
import { UtilService } from '../../../util/util.service';


@Component({
  selector: 'app-pagination-historico',
  templateUrl: './pagination-historico.component.html',
  styleUrls: ['./pagination-historico.component.css']
})
export class PaginationHistoricoComponent implements OnInit {

  @Output() onClicking = new EventEmitter<any[]>();
  @Output() emiteProvaHistorico = new EventEmitter<ProvaHistorico>();
  @Input() provaHistorico: ProvaHistorico;
  @Input() numPagina: number = 0;
  public paginas = []
  maximo: number;


  constructor(private route: Router,
              public util: UtilService) {}

  ngOnInit() {
  }

  ngOnChanges(){
    for(let i = 1; i <= this.numPagina; i++) {
      this.paginas.push({
        'key': i,
        'paginaValue': i
      })
    }
  }

  buscarHistoricoAnterior() {
    let aux = Number(this.provaHistorico.offset);
    if (aux != 0) {
      this.provaHistorico.offset = Number(this.provaHistorico.offset) - Number(this.provaHistorico.limit);
      this.provaHistorico.pagina--;
      this.route.navigate(['/comum/historico'], {
        queryParams: {
          offset: this.provaHistorico.offset,
          limit: this.provaHistorico.limit
        }
      });
    }
  }

  buscarProximoHistorico() {
    let aux = Number(this.provaHistorico.offset);
    if (aux < ((this.numPagina - 1) * this.provaHistorico.limit)) {
      this.provaHistorico.offset = Number(this.provaHistorico.offset) + Number(this.provaHistorico.limit);
      this.provaHistorico.pagina++;
      this.route.navigate(['/comum/historico'], {
        queryParams: {
          offset: this.provaHistorico.offset,
          limit: this.provaHistorico.limit
        }
      });
    }
  }

  pulaPagina(pagina: any) {
    let aux = this.provaHistorico.offset;
    this.provaHistorico.offset = (Number(pagina) - 1) * (this.provaHistorico.limit);
    if (aux > this.provaHistorico.offset) {
      this.provaHistorico.pagina--;
    } else {
      this.provaHistorico.pagina++;
    }
    this.route.navigate(['/comum/historico'], {
      queryParams: {
        offset: this.provaHistorico.offset,
        limit: this.provaHistorico.limit
      }
    });

  }
}
