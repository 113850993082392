export class Treinador {
    id_treinador: number;
    nome: string;
    local: string;
    cidade: string;
    estado: string;
    sigla_estado: string;
    id_unidade_federativa: number;
    telefone: string;
    email: string;
    observacoes: string;
    imagem_exibicao: string;
    arquivo_imagem: any;
    imagem_old: any;
    thumbnail: any;
    nome_old: any;
}