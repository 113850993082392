import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AccordionEventosOrganizadorComponent } from './accordion-eventos-organizador.component';

@NgModule({
    declarations: [AccordionEventosOrganizadorComponent],
    imports: [
        CommonModule
    ],
    exports: [AccordionEventosOrganizadorComponent]
})
export class AccordionEventosOrganizadorModule {}