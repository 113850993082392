import { Component, Inject, OnInit, Input } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { UtilService } from '../../../util/util.service';
import { Divisao } from '../../../model/divisao';
import { DivisaoService } from '../../../service/divisao.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { RegraService } from '../../../service/regra.service';
import { Regra } from '../../../model/regra';
import { RegraDivisaoService } from '../../../service/regra-divisao.service';
import { RacaService } from '../../../service/raca.service';
import { TipoInscricao } from '../../../model/tipo_inscricao';
import { TipoInscricaoService } from '../../../service/tipo-inscricao.service';
import { Raca } from '../../../model/raca';

declare var $: any;

@Component({
    selector: 'app-evento-component',
    moduleId: module.id,
    templateUrl: './cadastro-divisao.component.html',
    styleUrls: ['./cadastro-divisao.style.css']
})

export class CadastroDivisaoComponent implements OnInit {

    items = Array.from({ length: 100000 }).map((_, i) => `Item #${i}`);

    public divisao: Divisao = new Divisao();
    public regras: Regra[] = [];
    public regrasCompDois : Regra[] = [];
    public regrasCompTres : Regra[] = [];
    public regras_divisao = new Array();
    public regras_competidor2 = new Array();
    public regras_competidor3 = new Array();
    public inputs: any[] = [];
    public inputsCompDois : any[] = [];
    public inputsCompTres : any[] = [];
    public filtro: any = {};
    public formCadastro: FormGroup;
    public inputSelect: any[] = [];
    public inputSelectCompDois : any[] = [];
    public inputSelectCompTres : any[] = [];
    public racas : Raca[] = [];
    public qtdCompetidor : number[] = [];
    public tipoComp : number = 0;
    
    public controlSelect : boolean = true;
    public duplicidadeNome : boolean = false;

    nrSelect

    public tiposInscricao: TipoInscricao[];

    constructor(@Inject(Router) private router: Router,
        @Inject(ActivatedRoute) private route: ActivatedRoute,
        @Inject(FormBuilder) private formBuilder: FormBuilder,
        @Inject(UtilService) private utilService: UtilService,
        @Inject(DivisaoService) private divisaoService: DivisaoService,
        @Inject(RegraDivisaoService) private regraDivisaoService: RegraDivisaoService,
        @Inject(RegraService) private regraService: RegraService,
        @Inject(RacaService) private racaService: RacaService,
        @Inject(TipoInscricaoService) private tipoInscricaoService: TipoInscricaoService) {

        utilService.mudaTitulo('RSNC Brasil - Cadastro de divisão');

        this.formCadastro = formBuilder.group({
            id: [this.divisao.id_divisao, []],
            nome: [this.divisao.nome, [
                Validators.required,
                Validators.minLength(1),
                Validators.maxLength(300)
            ]],
            nao_pontuar: [this.divisao.nao_pontuar],
            nao_premiar: [this.divisao.nao_premiar],
            nao_exigir_cadastro: [this.divisao.nao_exigir_cadastro],
            tempo_divisao: [this.divisao.tempo_divisao,[
                Validators.required
            ]],
            rebatedor_apartador: [this.divisao.rebatedor_apartador],
            potro_futuro: [this.divisao.potro_futuro],
            somatorio_minimo : [this.divisao.somatorio_minimo,[
                Validators.maxLength(3)
            ]],
            somatorio_maximo : [this.divisao.somatorio_maximo,[
                Validators.maxLength(3)
            ]],
            tempo_diferencia : [this.divisao.tempo_diferencia,[
                Validators.min(0),
                Validators.max(10)
            ]]
        });
    }

    ngOnInit() {
        this.getRegras();
        this.buscarInformacoesDivisao();
        this.buscaTiposInscricao();
        this.getRacas();
    }

    public getRacas(){
        this.racaService.buscaTodos().subscribe(retorno => this.racas = retorno);
    }

    private buscaTiposInscricao() {
        this.tipoInscricaoService.buscaTodos().subscribe(tiposInscricao => {
            this.tiposInscricao = tiposInscricao;
        })
    }

    private buscarInformacoesDivisao(){
        let idDivisao = parseInt(this.route.snapshot.paramMap.get("idDivisao"));
        if(idDivisao){
            this.divisaoService.buscaPorId(idDivisao).subscribe(d => {
                this.divisao = d;
                let qtdTipos = d.id_tipo_inscricao;
                this.montaSelecaoRegrasCompetidor(qtdTipos);
                this.controlSelect = true;
            });
            this.regraDivisaoService.buscarRegrasPorDivisao(idDivisao).subscribe(r => {
                let re_divi = r;
                re_divi.forEach( rd => {
                    if( rd.numero_competidor == 1 ){
                        this.regras_divisao.push(rd);
                    }

                    if(rd.numero_competidor == 2 ){
                        this.regras_competidor2.push(rd);
                    }

                    if(rd.numero_competidor == 3){
                        this.regras_competidor3.push(rd);
                    }
                })
            });
        }
    }

    public validarNomeDivisao(){
        this.divisaoService.validarNome(this.divisao.nome).subscribe(retorno => this.duplicidadeNome =  retorno)
    }

    public salvar() {
        
        if (this.formCadastro.valid) {
            this.divisao.nao_pontuar = this.divisao.nao_pontuar ? true : false;
            this.divisao.nao_premiar = this.divisao.nao_premiar ? true : false;
            this.divisao.nao_exigir_cadastro = this.divisao.nao_exigir_cadastro ? true : false;
            this.divisao.tempo_divisao = this.divisao.tempo_divisao;
            this.divisao.rebatedor_apartador = this.divisao.rebatedor_apartador ? true : false;
            this.divisao.potro_futuro = this.divisao.potro_futuro ? true : false;
            this.divisao.id_raca = this.divisao.id_raca;
            this.divisao.tempo_diferencia = this.divisao.tempo_diferencia;
            this.divisao.ativo = true;
            if(this.divisao.id_divisao){
                this.requisicaoAlterar();
            }else{
                this.requisicaoSalvar();
            }
        }
    }

    private requisicaoSalvar() {

        this.somaRegras(this.divisao.id_tipo_inscricao);

        this.divisaoService.salvar(this.divisao)
        .subscribe(retorno => {
                let divisao = retorno.divisao;
                let validacao = retorno.validacao;
                if(validacao.status && divisao){
                    this.utilService.mensagemSucesso("Divisão criada com sucesso!");
                    this.resetaFormulario();
                }else{
                    this.utilService.mensagemErro(validacao.mensagem);
                }
            },
            error => {
                this.utilService.mensagemErro(error.error.mensagem);
            }
        );
    }

    private requisicaoAlterar(){
        this.somaRegras(this.divisao.id_tipo_inscricao);
        this.divisaoService.altera(this.divisao.id_divisao, this.divisao).subscribe(
            success => {
                this.utilService.mensagemSucesso("Divisão alterada com sucesso!");
                this.resetaFormulario();
            },
            error => {
                this.utilService.mensagemErro(error.error.mensagem);
            }
        );
    }

    public somaRegras(tipo){
        let todasRegras : Regra[] = [];
        tipo = String(tipo);
        switch(tipo){
            case '1' :  this.regras_divisao.forEach(r => {
                            r.numero_competidor = 1;
                            todasRegras.push(r);
                        });

                        this.divisao.regras = todasRegras;
                        break;

            case '2' :  this.regras_divisao.forEach(r => {
                            r.numero_competidor = 1;
                            todasRegras.push(r);
                         });
                        
                        this.regras_competidor2.forEach(r2 => {
                          r2.numero_competidor = 2;
                          todasRegras.push(r2);
                        });

                        this.divisao.regras = todasRegras;
                        break;

            case '3' : this.regras_divisao.forEach(r => {
                            r.numero_competidor = 1;
                            todasRegras.push(r);
                        });

                        this.regras_competidor2.forEach(r2 => {
                            r2.numero_competidor = 2;
                            todasRegras.push(r2);
                        });
                       
                        this.regras_competidor3.forEach(r3 => {
                            r3.numero_competidor = 3;
                            todasRegras.push(r3);
                        });

                        this.divisao.regras = todasRegras;
                        break;
        }

    }

    private resetaFormulario(){
        this.regras_divisao = new Array();
        this.divisao = new Divisao();
        this.resetaRegra(0);
        this.redireciona();
    }

    public selecionaTipoRaca(event){
        let idRaca = event.target.value;
        this.divisao.id_raca = idRaca;

    }

    private redireciona() {
        this.utilService.voltarParaChamador(this.router, ['/comum/divisoes/']);
    }

    private getRegras() {
        this.regraService.buscaTodos("1").subscribe(regras => {
            this.regras = regras;
            this.regrasCompDois = regras;
            this.regrasCompTres = regras;
        });
    }

    private novaRegra(tipo) {
        if(tipo == 1){
            $("#divRegra").show();
            $("#botaoNovaRegra").hide();
        }

        if(tipo == 2){
            $("#divRegraDois").show();
            $("#botaoNovaRegra").hide();
        }

        if(tipo == 3){
            $("#divRegraTres").show();
            $("#botaoNovaRegra").hide();
        }
       
    }

    private cancelarRegra(valor) {
       /* $("#divRegra").hide();
        $("#botaoNovaRegra").show();*/
        valor = Number(valor);

        if(valor == 1){
            $("#divRegra").show();
            $("#botaoNovaRegra").hide();
        }

        if(valor == 2){
            $("#divRegraDois").show();
            $("#botaoNovaRegra").hide();
        }

        if(valor == 3){
            $("#divRegraTres").show();
            $("#botaoNovaRegra").hide();
        }
        
        this.resetaRegra(valor);
    }

    public montaRegra() {
        let value = (<HTMLSelectElement>document.getElementById('select-regra')).value;
        let regra = this.regras.find(r => r.id_regra == +value);
        let objRegra = JSON.parse(regra.parametros);
        this.inputs = objRegra.parametros;
        this.inputs.forEach(i => {
            if (i.type == "select") {
                this.montaSelect(i.id, 1);
            }
        });
        this.novaRegra(1);
    }

    public montarCompetidor2(){
        let value = (<HTMLSelectElement>document.getElementById('select-regra-competidor-dois')).value;
        let regra2 = this.regrasCompDois.find(r => r.id_regra == +value);
        let objRegra2 = JSON.parse(regra2.parametros);
        this.inputsCompDois  = objRegra2.parametros;
        this.inputsCompDois .forEach(i => {
            if (i.type == "select") {
                this.montaSelect(i.id, 2);
            }
        });

        this.novaRegra(2);
    }

    public montaRegraCompetidor3(){
        let value = (<HTMLSelectElement>document.getElementById('select-regra-competidor-tres')).value;
        let regra = this.regrasCompTres.find(r => r.id_regra == +value);
        let objRegra = JSON.parse(regra.parametros);
        this.inputsCompTres = objRegra.parametros;
        this.inputsCompTres.forEach(i => {
            if (i.type == "select") {
                this.montaSelect(i.id, 3);
            }
        });

        this.novaRegra(3);
    }

    private montaSelect(id, tipo) {

        if(tipo == 1){
            this.inputSelect = [];
            if (id == "idRaca") {
                this.racaService.buscaTodos().subscribe(data => {
                    let cavalos = data;
                    cavalos.forEach(c => {
                        this.inputSelect.push({ id: c.id_raca, descricao: c.descricao });
                    })
                })
            } else if (id == "sexo") {
                this.inputSelect.push({ id: 'f', descricao: 'Feminino'});
                this.inputSelect.push({ id: 'm', descricao: 'Masculino'});
            }else if(id == "potroFuturo"){
                this.inputSelect.push({ id: 'true', descricao: 'Sim'});
                // this.inputSelect.push({ id: 'false', descricao: 'Nao'});
            }
        }

        if( tipo == 2){
            this.inputSelectCompDois = [];
            if (id == "idRaca") {
                this.racaService.buscaTodos().subscribe(data => {
                    let cavalos = data;
                    cavalos.forEach(c => {
                        this.inputSelectCompDois.push({ id: c.id_raca, descricao: c.descricao });
                    })
                })
            } else if (id == "sexo") {
                this.inputSelectCompDois.push({ id: 'f', descricao: 'Feminino'});
                this.inputSelectCompDois.push({ id: 'm', descricao: 'Masculino'});
            } else if(id == "potroFuturo"){
                this.inputSelectCompDois.push({ id: 'true', descricao: 'Sim'});
                // this.inputSelectCompDois.push({ id: 'false', descricao: 'Nao'});
            }
        }

        if(tipo == 3){
            this.inputSelectCompTres = [];
            if (id == "idRaca") {
                this.racaService.buscaTodos().subscribe(data => {
                    let cavalos = data;
                    cavalos.forEach(c => {
                        this.inputSelectCompTres.push({ id: c.id_raca, descricao: c.descricao });
                    })
                })
            } else if (id == "sexo") {
                this.inputSelectCompTres.push({ id: 'f', descricao: 'Feminino'});
                this.inputSelectCompTres.push({ id: 'm', descricao: 'Masculino'});
            }else if(id == "potroFuturo"){
                this.inputSelectCompTres.push({ id: 'true', descricao: 'Sim'});
                // this.inputSelectCompTres.push({ id: 'false', descricao: 'Nao'});
            }
        }
    }

    private resetaRegra(valor) {
        
        if(valor == 0){
            $('#select-regra').val('default').prop('selected', true);
            $('#select-regra-competidor-dois').val('default').prop('selected', true);
            $('#select-regra-competidor-tres').val('default').prop('selected', true);
            this.inputs = [];
            this.inputsCompDois = [];
            this.inputsCompTres = [];
        }

        if(valor == 1){
            $('#select-regra').val('default').prop('selected', true);
            this.inputs = [];
        }

        if(valor == 2){
            $('#select-regra-competidor-dois').val('default').prop('selected', true);
            this.inputsCompDois = [];
        }

        if(valor == 3){
            $('#select-regra-competidor-tres').val('default').prop('selected', true);
            this.inputsCompTres = [];
        }
        
    }

    

    private adicionaRegra(tipo) {
        tipo = Number(tipo);
        let valores = [];
        let value = null;
        let regra_divisao = null;

        if( tipo == 1 ){
            value = (<HTMLSelectElement>document.getElementById('select-regra')).value;
            regra_divisao = Object.assign({}, this.regras.find(r => r.id_regra == +value));
        }

        if(tipo == 2){
            value = (<HTMLSelectElement>document.getElementById('select-regra-competidor-dois')).value;
            regra_divisao = Object.assign({}, this.regrasCompDois.find(r => r.id_regra == +value));
        }

        if(tipo == 3){
            value = (<HTMLSelectElement>document.getElementById('select-regra-competidor-tres')).value;
            regra_divisao = Object.assign({}, this.regrasCompTres.find(r => r.id_regra == +value));
        }

        if (!regra_divisao) return;

       
        regra_divisao.parametros = JSON.parse(regra_divisao.parametros).parametros;

        if( tipo == 1 ){
            this.inputs.forEach(function (i, index) {
                let resposta = {
                    "id": i.id,
                    "valor": $("#" + i.id).val(),
                    "descricao": $("#" + i.id).val(),
                    "label": i.label
                }
                if (i.type == "select") {
                    resposta.descricao = $("#" + i.id + " option:selected").text();
                }
                valores.push(resposta);
                regra_divisao.descricao = regra_divisao.descricao.replace("params[" + index + "]", resposta.descricao);
                
                let valor = i.id == 'sexo' || i.id == 'potroFuturo' ? JSON.stringify(resposta.valor) : resposta.valor;
                regra_divisao.expressao = regra_divisao.expressao.replace("params[" + index + "]", valor);
                // @ts-ignore
                regra_divisao.parametros[index].value = resposta.valor;
            });
        }

        if( tipo == 2 ){
            this.inputsCompDois.forEach(function (i, index) {
                let resposta = {
                    "id": i.id,
                    "valor": $("#" + i.id).val(),
                    "descricao": $("#" + i.id).val(),
                    "label": i.label
                }
                if (i.type == "select") {
                    resposta.descricao = $("#" + i.id + " option:selected").text();
                }
                valores.push(resposta);
                let valor = i.id == 'sexo' || i.id == 'potroFuturo' ? JSON.stringify(resposta.valor) : resposta.valor;
                regra_divisao.descricao = regra_divisao.descricao.replace("params[" + index + "]", resposta.descricao);
                regra_divisao.expressao = regra_divisao.expressao.replace("params[" + index + "]", valor);
                // @ts-ignore
                regra_divisao.parametros[index].value = resposta.valor;
            });
        }

        if( tipo == 3){
            this.inputsCompTres.forEach(function (i, index) {
                let resposta = {
                    "id": i.id,
                    "valor": $("#" + i.id).val(),
                    "descricao": $("#" + i.id).val(),
                    "label": i.label
                }
                if (i.type == "select") {
                    resposta.descricao = $("#" + i.id + " option:selected").text();
                }
                valores.push(resposta);
                let valor = i.id == 'sexo' || i.id == 'potroFuturo' ? JSON.stringify(resposta.valor) : resposta.valor;
                regra_divisao.descricao = regra_divisao.descricao.replace("params[" + index + "]", resposta.descricao);
                regra_divisao.expressao = regra_divisao.expressao.replace("params[" + index + "]", valor);
                // @ts-ignore
                regra_divisao.parametros[index].value = resposta.valor;
            });
        }

        regra_divisao.parametros = '{"parametros":' + JSON.stringify(regra_divisao.parametros) + '}'
        this.insereOuAtualiza(regra_divisao, tipo);
        this.resetaRegra(tipo);
    }

    public removeRegraDivisao(rd, tipo) {
        tipo = Number(tipo);
        if(tipo == 1){
            this.regras_divisao.splice(this.regras_divisao.indexOf(rd), 1);
        }

        if(tipo == 2 ){
            this.regras_competidor2.splice(this.regras_competidor2.indexOf(rd), 1);
        }

        if(tipo == 3 ){
            this.regras_competidor3.splice(this.regras_competidor3.indexOf(rd), 1);
        }
    }


    private insereOuAtualiza(regra_divisao: any, tipo : number) {
        if(tipo == 1){
            this.regras_divisao = this.realizaOperacaoRegra(this.regras_divisao, regra_divisao);
        }else if(tipo == 2){
            this.regras_competidor2 = this.realizaOperacaoRegra(this.regras_competidor2, regra_divisao);
        }else if(tipo == 3){
            this.regras_competidor3 = this.realizaOperacaoRegra(this.regras_competidor3, regra_divisao);
        }
    }

    private realizaOperacaoRegra(regra, regra_divisao){
        let foundIndex = false;

        for (let index = 0; index < regra.length; index++) {
            if(JSON.parse(regra[index].parametros).parametros[0].id == JSON.parse(regra_divisao.parametros).parametros[0].id){
                regra[index] = regra_divisao;
                foundIndex = true;
            }
        }

        if(!foundIndex){
            regra.push(regra_divisao);
        }

        return regra;
    }

    public selecionaTipoInscricao(evento) {
        let tipo = evento.target.value;
        this.qtdCompetidor = [];
        this.tipoComp = 0;
        this.regras_competidor2 = [];
        this.regras_competidor3 = [];
        this.regras_divisao = [];
        $("#competido").val("Competidor");
        this.montaSelecaoRegrasCompetidor(tipo);
        this.divisao.id_tipo_inscricao = tipo;
        this.controlSelect = true;
    }

    public montaSelecaoRegrasCompetidor(numero){
        for(let i = 1; i <= numero; i++){
            this.qtdCompetidor.push(i);
        }
    }

    public getRegrasParaCompetidor(tipo){
        this.controlSelect = false;
        this.tipoComp = Number(tipo);
    }

}
