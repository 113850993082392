import { Component, Input, OnInit, OnChanges, Output, EventEmitter } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { Campeonato } from '../../../../model/campeonato';
import { ParametrosBusca } from '../../../../model/parametros_busca';
import { CampeonatoService } from '../../../../service/campeonato.service';
import { environment } from '../../../../../environments/environment';
import { UtilService } from '../../../../util/util.service';
import { CampeonatoBuscaService } from '../../campeonatosBusca.service';

@Component({
    templateUrl: './visualizar-campeonatos.page.html',
    styleUrls: ['./visualizar-campeonatos.page.css']
})
export class VisualizarCampeonatosPage implements OnInit {


    campeonatos: Campeonato[];
    options: ParametrosBusca = new ParametrosBusca();

    @Output() onTyping = new EventEmitter<Campeonato[]>();

    public paginas = [
    ]

    constructor(private activatedRoute: ActivatedRoute, 
                private service: CampeonatoService,
                public utilService: UtilService,
                private route: Router,
                private campeonatoBuscaService: CampeonatoBuscaService) { }

    ngOnInit(): void {

        /*this.activatedRoute.queryParams.subscribe(params => {
            this.options.offset = params['offset'];
            this.options.limit = params['limit'];

            this.service.buscaPaginado(this.options).subscribe(res => {
                this.campeonatos = res;
                this.getImgCampeonato(this.campeonatos);
                this.getThumbnailCampeonato(this.campeonatos);
                //this.campeonatos.forEach(campeonato => {
                //    this.getThumbnailExibicao(campeonato, campeonato.imagem_exibicao);
                //})
            })
        })*/
        
        this.activatedRoute.queryParams.subscribe(params => {
            this.options.offset = params['offset'];
            this.options.limit = params['limit'];

            this.service.buscaTodosFiltro({
                "filtro": this.campeonatoBuscaService.filtro
            }, this.options).subscribe(campeonatos => {
                if (campeonatos) {
                    this.getImgCampeonato(campeonatos);
                    this.getThumbnailCampeonato(campeonatos);
                    //campeonatos.forEach(campeonato => {
                    //    this.getThumbnailExibicao(campeonato, campeonato.imagem_exibicao);
                    //})
                    this.campeonatoBuscaService.campeonatos = campeonatos;
                }
                this.onTyping.emit(campeonatos);
                if(this.campeonatoBuscaService.filtro.campeonato_finalizado) {
                }
            })
        })

        this.paginas = [
            {
                key: 'key1',
                paginaValue: '1'
            },
            {
                key: 'key2',
                paginaValue: '2'
            },
            {
                key: 'key3',
                paginaValue: '3'
            },
            {
                key: 'key4',
                paginaValue: '4'
            }
        ]
    }

    /*private getThumbnailExibicao(campeonato, path){
        if(path){
            campeonato.thumbnail = `${environment.api_url}/file/thumbnailCampeonato/${path}`;
        }
    }*/
    
    private getImgCampeonato(campeonatos: Campeonato[]){
        campeonatos.forEach(campeonato => {
            campeonato.arquivo_imagem =  this.utilService.buscaImagem(campeonato);
        });
    }
    
    private getThumbnailCampeonato(campeonatos: Campeonato[]){
        campeonatos.forEach(campeonato => {
            campeonato.thumbnail =  this.utilService.buscaThumbnail(campeonato);
        });
    }

    buscaCampeonatosAnteriores() {
        if (this.options.offset != 0) {
            this.options.offset = Number(this.options.offset) - Number(this.options.limit);
            this.options.pagina--;
            this.route.navigate(['/comum/campeonatos'], {
                queryParams: {
                    offset: this.options.offset,
                    limit: this.options.limit
                }
            });
        }
    }

    buscaProximosCampeonatos() {
        this.options.offset = Number(this.options.offset) + Number(this.options.limit);
        this.options.pagina++;
        this.route.navigate(['/comum/campeonatos'], {
            queryParams: {
                offset: this.options.offset,
                limit: this.options.limit
            }
        });

    }

    pulaPagina(pagina: string) {
        this.options.offset = (Number(pagina) * this.options.limit) - this.options.limit;
        this.options.pagina++;
        this.route.navigate(['/comum/campeonatos'], {
            queryParams: {
                offset: this.options.offset,
                limit: this.options.limit
            }
        });
    }
}