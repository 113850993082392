import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';

import { ModalRacasPontuarModule } from './modal-racas-pontuar/modal-racas-pontuar.module';
import { ModalEdicaoDivisaoComponent } from './modal-edicao-divisao.component';
import { NgxMaskModule } from 'ngx-mask';
import { NgxCurrencyModule } from 'ngx-currency';

@NgModule({
  declarations: [ModalEdicaoDivisaoComponent],
  imports: [
    CommonModule,
    ModalRacasPontuarModule,
    FormsModule,
    ReactiveFormsModule,
    NgxMaskModule,
    NgxCurrencyModule
  ], exports: [ModalEdicaoDivisaoComponent]
})
export class ModalEdicaoDivisaoModule { }
