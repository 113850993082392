import { OnInit, Component, Input, Inject } from '@angular/core';

import { Evento } from '../../../model/evento';
import { UtilService } from '../../../util/util.service';
import { Router } from '@angular/router';

@Component({
    selector: 'accordion-eventos-organizador',
    templateUrl: './accordion-eventos-organizador.component.html',
    styleUrls: ['./accordion-eventos-organizador.style.css', '../organizador.style.css']
})
export class AccordionEventosOrganizadorComponent implements OnInit {

    @Input() eventosOrganizados: Evento[];

    constructor(private utilService: UtilService,
        @Inject(Router) private router: Router) {
    }
    
    ngOnInit(): void {
    }

    redireciona(id_evento){
        this.utilService.irPara(this.router, `/comum/evento/${id_evento}`);  
    }
}