import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';
import {HttpModule} from '@angular/http';
import {ReactiveFormsModule, FormsModule} from '@angular/forms';
import {MODULE_COMPONENTS_COMUM, MODULE_ENTRYCOMPONENTS_COMUM, MODULE_SERVICES_COMUM} from './comum.route';
import {UtilModule} from '../util/util.module';
import { DirectiveModule } from '../directive/directive.module';
import { FormulariosModule } from './formularios/formularios.module';
import { TreinadoresModule } from './treinadores/treinadores.module';
import { CampeonatosModule } from './campeonatos/campeonatos.module';
// @ts-ignore
import { TextMaskModule } from 'angular2-text-mask';
import { NgxMaskModule } from 'ngx-mask';
import { NgxCurrencyModule } from "ngx-currency";
import { MenuComponent } from './menu/menu.component';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { ModalReplicarEventoModule } from './eventos/modal-replicar/modal-replicar-evento.module';
import { UsuariosModule } from './usuarios/usuarios.module';
import { InscricoesModule } from './inscricoes/inscricoes.module';
import { ListaInscritosModule } from './evento/lista-inscritos/lista-inscritos.module';
import { RodapeModule } from './rodape/rodape.module';
import { PaginationHistoricoComponent } from './historico/pagination-historico/pagination-historico.component';
import { OrganizadorModule } from './organizador/organizador.module';
import { EventosModule } from './eventos/eventos.module';
import { NoticiasModule } from './noticias/noticias.module';
import { ModalEdicaoDivisaoModule } from './formularios/cadastro-evento/modal-edicao-divisao/modal-edicao-divisao.module';
import { FinanceiroModule } from './evento/financeiro/financeiro.module';
import { RegrasAssociacaoComponent } from './regras-associacao/regras-associacao.component';
import { AssociadosModule } from './associados/associados.module';
import { ListaDestaquesComponent } from './destaques/lista-destaques/lista-destaques.component';
import { ListaCavalosComponent } from './cavalo/lista-cavalos/lista-cavalos.component';
import { UsuarioModule } from './usuario/usuario.module';
import { HistoricoGeralModule } from './historico-geral/historico-geral.module';
import { RacaModule } from './raca/raca.module';
import { PaginacaoModule } from './paginacao/paginacao.module';
import { ModeracaoModule } from './moderacao/moderacao.module';
import { RegraRegulamentoModule } from './regra-regulamento/regra-regulamento.module';
import { ResultadoEventoModule } from './resultado-evento/resultado-evento.module';
import { ResultadoCampeonatoModule } from './resultado-campeonato/resultado-campeonato.module';
import { ResultadoGeralModule } from './resultado-geral/resultado-geral.module';

@NgModule({
  imports: [
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    HttpModule,
    UtilModule,
    DirectiveModule,
    FormulariosModule,
    TreinadoresModule,
    CampeonatosModule,
    EventosModule,
    NoticiasModule,
    TextMaskModule,
    RouterModule.forChild([]),
    NgxMaskModule,
    NgxCurrencyModule,
    ScrollingModule,
    ModalReplicarEventoModule,
    UsuariosModule,
    InscricoesModule,
    ListaInscritosModule, 
    RodapeModule,
    OrganizadorModule,
    ModalEdicaoDivisaoModule,
    FinanceiroModule,
    AssociadosModule,
    UsuarioModule,
    HistoricoGeralModule,
    RacaModule,
    PaginacaoModule,
    ModeracaoModule,
    RegraRegulamentoModule,
    ResultadoEventoModule,
    ResultadoCampeonatoModule,
    ResultadoGeralModule
  ],
  declarations: [
    MODULE_COMPONENTS_COMUM,
    MenuComponent,
    PaginationHistoricoComponent,
    RegrasAssociacaoComponent,
    ListaDestaquesComponent,
    ListaCavalosComponent
  ],
  exports: [
    MenuComponent
  ],
  entryComponents: [
    MODULE_ENTRYCOMPONENTS_COMUM
  ],
  providers: [MODULE_SERVICES_COMUM]
})

export class ComumModule {

}


