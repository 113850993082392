import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { environment } from '../../environments/environment';
import { EventoRaca } from '../model/evento-raca';

@Injectable()
export class EventoRacaService {

    private evento_api_url = `${environment.api_url}/evento-raca`;

    constructor(private http: HttpClient) {}

    public buscaPorEvento(id_evento: number): Observable<EventoRaca[]> {
        return this.http.get<EventoRaca[]>(`${this.evento_api_url}/${id_evento}`);
      }
}