import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { Prova } from '../../model/prova';
import { Usuario } from '../../model/usuario';
import { Cavalo } from '../../model/cavalo';
import { Campeonato } from '../../model/campeonato';
import { Evento } from '../../model/evento';
import { CampeonatoService } from '../../service/campeonato.service';
import { EventoService } from '../../service/evento.service';
import { RegraEventoService } from '../../service/regra-evento.service';
import { RegraEvento } from '../../model/regra_evento';
import { ProvaService } from '../../service/prova.service';
import { UtilService } from '../../util/util.service';

@Component({
    selector: 'app-inscricoes-component',
    templateUrl: './inscricoes.component.html',
    styleUrls: ['./inscricoes.style.css']
})
export class InscricoesComponent implements OnInit {

    public pagina: number = 0;
    public paginaAnterior: number;
    private usuario_logado: Usuario;
    public numeroDeInscritos: number;

    public campeonato: Campeonato;
    public evento: Evento;

    public listaDeCompetidoresInscritos: Usuario[];
    public listaDeCavalosInscritos: Cavalo[];
    public listaDeProvasSelecionadas: Prova[] = [];
    
    constructor(private activatedRoute: ActivatedRoute,
                private campeonatoService: CampeonatoService,
                private eventoService: EventoService,
                private regraEventoService: RegraEventoService,
                private provaService : ProvaService,
                private utilService: UtilService,
                private router: Router) {}
    
    ngOnInit(): void {
        this.usuario_logado = this.activatedRoute.snapshot.data.usuario;
        this.buscaEvento();
    }

    public tipoProva(numero : number){
        let provasComFiltro : Prova[] = [];
        this.listaDeProvasSelecionadas.forEach(prova => {
            if(numero == 1 && (prova.tipo_prova == numero || prova.draw == true )){
                provasComFiltro.push(prova);
            }else if(prova.tipo_prova == numero){
                provasComFiltro.push(prova);//
            }
        });
        this.listaDeProvasSelecionadas = provasComFiltro;
        this.numeroDeInscritos = numero;
    }

    private buscaRegras(id_evento: number): void {
        this.regraEventoService.buscarRegrasPorEvento(id_evento).subscribe(regras => {
            this.evento.regras = regras;
        })
    }

    private buscaCampeonato(): void {
        if(this.evento.id_campeonato) {
            this.campeonatoService.buscaPorId(this.evento.id_campeonato).subscribe(campeonato => {
                this.campeonato = campeonato;
            })
        }
    }

    private buscaEvento(): void {
        this.eventoService.buscaPorId(this.activatedRoute.snapshot.params.idEvento).subscribe(evento => {
            let encerrado = this.utilService.verificaEncerramentoInscricao(evento.data_fim_inscricoes);
            let inicioInscricao = this.utilService.verificaInicioInscricao(evento.data_inicio_inscricoes);
            let maximoInscricoes = this.utilService
            .verificaMaximoInscricoesEvento(evento.maximo_inscricoes, evento.quantidade_inscricoes);
            if((encerrado || maximoInscricoes || !inicioInscricao || evento.finalizado) &&
               !this.utilService.isAdm() && evento.id_organizador != this.utilService.getIdLogado()){
                let mensagem = encerrado ? 'O período de inscrição foi encerrado!' :
                maximoInscricoes ? 'O evento atingiu o limite máximo de inscrição!' :
                !inicioInscricao ? 'Fora do prazo de inscrição!' : 'O evento foi finalizado!';
                this.pagina = -1;
                this.erroInscricao(mensagem);
            }else{
                this.evento = evento;
                this.listaDeProvasSelecionadas = evento.provas;
                this.listaDeProvasSelecionadas.forEach(pro => pro.qtdInscricao = 1);
                this.buscaRegras(this.evento.id_evento);
                this.buscaCampeonato();
            }
        }, erro => this.utilService.mensagemErro(erro.error.mensagem));
    }

    private erroInscricao(mensagem: string){
        this.utilService.mensagemErro(mensagem,
        () => this.router.navigate(['/comum/inicio']));
    }
}
