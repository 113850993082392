import { Cavalo } from './cavalo';
import { Usuario } from './usuario';
import { Inscricao } from './inscricao';

export class InscricaoCompetidor {
    id_inscricao_competidor: number;
    id_inscricao: number;
    inscricao: Inscricao;
    id_competidor: number;
    competidor: Usuario;
    id_cavalo: number;
    cavalo: Cavalo;
    is_apartador: boolean;
    inscricao_paga: boolean;
    handicap_competidor : number;
    tipo_prova : number;
    id_prova : number;
    potro_futuro : boolean;
    id_competidorSemDraw : [];
    statusInscricao : boolean;
}