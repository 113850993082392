import { Component, OnInit } from '@angular/core';
import { Raca } from '../../../../model/raca';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { UtilService } from '../../../../util/util.service';
import { RacaService } from '../../../../service/raca.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Portuguese } from 'flatpickr/dist/l10n/pt';
import flatpickr from 'flatpickr';

@Component({
  selector: 'app-cadastro-raca',
  templateUrl: './cadastro-raca.component.html',
  styleUrls: ['./cadastro-raca.component.css', '../../../comum.style.css']
})
export class CadastroRacaComponent implements OnInit {

  public titulo_pagina: string = "Cadastro Raça";
  public raca: Raca = new Raca();
  public formCadastro: FormGroup;
  
  constructor(private formBuilder: FormBuilder,
              private utilService: UtilService,
              private racaService: RacaService,
              private route: ActivatedRoute,
              private router: Router) { }

  ngOnInit() {
    let id_raca = parseInt(this.route.snapshot.queryParamMap.get("idRaca"))
    if(!isNaN(id_raca)){
      this.titulo_pagina = "Editar Raça"
      this.raca.id_raca = id_raca;
      this.getRaca();
    }
    this.initForm();
  }

  public montaDatePickerFiltro(): void {
    const fp = flatpickr("#" + 'data_criacao',{
        locale: Portuguese,
        altFormat: 'd/m/Y',
        ariaDateFormat: 'd/m/Y',
        dateFormat: 'd/m/Y',
    });
}

  public getRaca(): void{
    this.racaService.buscaPorId(this.raca.id_raca)
    .subscribe(
      raca => {
        this.raca = raca;
        this.raca.data_criacao = new Date(this.raca.data_criacao).toLocaleString();
        this.raca.data_modificacao = new Date(this.raca.data_modificacao).toLocaleString();
        this.raca.data_criacao = this.utilService.ajustaDataHorario(this.raca.data_criacao);
        this.raca.data_modificacao = this.utilService.ajustaDataHorario(this.raca.data_modificacao);
        this.utilService.montaDatePickerHorario('data_criacao', this.raca.data_criacao);
        this.utilService.montaDatePickerHorario('data_modificacao', this.raca.data_modificacao);
      },
      erro => this.utilService.mensagemErro("Erro - " + erro.error.message)
    );
  }

  public initForm(): void {
    this.formCadastro = this.formBuilder.group({
      descricao: [this.raca.descricao, [
        Validators.required,
        Validators.minLength(1),
        Validators.maxLength(200)
      ]],
      abreviacao: [this.raca.abreviacao, [
        Validators.required,
        Validators.minLength(1),
        Validators.maxLength(5)
      ]],
      data_criacao: [{value: this.raca.data_criacao, disabled: true},[]],
      data_modificacao: [{value: this.raca.data_modificacao, disabled: true},[]]
    });
  }

  public salvar(): void {
    if (this.formCadastro.invalid) {
      this.utilService.showErrors(this.formCadastro);
      this.utilService.destacarErros(this.formCadastro);
      return;
    }

    if(this.raca.id_raca){
      this.alteraRaca();
    }else{
      this.insereRaca();
    }
  }

  public insereRaca(): void {
    this.utilService.mensagemConfirmacao("Confirmar cadastro de Raça?", (result) => {
      if (result) {                
        this.racaService.insere(this.raca).subscribe( res => {
          this.utilService.mensagemSucesso("Raça cadastrada com sucesso!");
          this.irPara("/comum/inicio");
        }, (erro) => this.utilService.mensagemErro(erro.error.mensagem))
      }
    });  
  }

  public alteraRaca(): void{
    this.utilService.mensagemConfirmacao("Deseja realmente alterar a Raça?",
    resp => {
      if(resp){
        this.racaService.altera(this.raca.id_raca, this.raca)
        .subscribe(raca => {
          this.utilService.mensagemSucesso("Raça alterada com sucesso!");
          this.irPara("/comum/lista-racas");
        }, erro => this.utilService.mensagemErro("Erro - " + erro.error.message));
      }
    });
  }

  public irPara(rota: string){
    this.utilService.irPara(this.router, rota);
  }
}
