import {Route} from '@angular/router';
import { InicioComponent } from './inicio/inicio.component';
import { QuemSomosComponent } from './quem-somos/quem-somos.component';
import { InicioService } from '../service/inicio.service';
import { QuemSomosService } from './quem-somos/quem-somos.service';
import { ContatoComponent } from './contato/contato.component';
import { EventosComponent } from './eventos/eventos.component';
import { CampeonatosComponent } from './campeonatos/campeonatos.component';
import { NoticiasComponent } from './noticias/noticias.component';
import { NoticiaComponent } from './noticia/noticia.component';
import { EventoComponent } from './evento/evento.component';
import { FormularioLoginComponent } from './formularios/formulario-login/formulario-login.component';
import { CadastroUsuarioComponent } from './formularios/cadastro-usuario/cadastro-usuario.component';
import { CadastroTreinadorComponent } from './formularios/cadastro-treinador/cadastro-treinador.component';
import { TreinadoresComponent } from './treinadores/treinadores.component';
import { UsuarioService } from '../service/usuario.service';
import { TreinadoresResolver } from './treinadores/treinadores.resolver';
import { NoticiaService } from '../service/noticia.service';
import { EventoService } from '../service/evento.service';
import { CampeonatoService } from '../service/campeonato.service';
import { CadastroCampeonatoComponent } from './formularios/cadastro-campeonato/cadastro-campeonato.component';
import { CadastroEventoComponent } from './formularios/cadastro-evento/cadastro-evento.component';
import { VisualizarCampeonatosPage } from './campeonatos/pages/visualizar-campeonatos/visualizar-campeonatos.page';
import { CampeonatosResolver } from './campeonatos/campeonatos.resolver';
import { DetalhesCampeonatoPage } from './campeonatos/pages/detalhes-campeonato/detalhes-campeonato.page';
import { CavaloService } from '../service/cavalo.service';
import { ProvaService } from '../service/prova.service';
import { DivisaoService } from '../service/divisao.service';
import { RespostaHandicapService } from '../service/resposta-handicap.service';
import { PerguntaHandicapService } from '../service/pergunta-handicap.service';
import { RegraService } from '../service/regra.service';
import { CadastroDivisaoComponent } from './formularios/cadastro-divisao/cadastro-divisao.component';
import { DivisoesComponent } from './divisoes/divisoes.component';
import { RegraDivisaoService } from '../service/regra-divisao.service';
import { EmailService } from '../service/email.service';
import { RacaService } from '../service/raca.service';
import { InscricoesComponent } from './inscricoes/inscricoes.component';
import { InscricaoService } from '../service/inscricao.service';
import { UsuarioInscricaoResolver } from './inscricoes/inscricoes.resolver';
import { InscricaoCompetidorService } from '../service/inscricao-competidor.service';
import { CadastroCavaloComponent } from './formularios/cadastro-cavalo/cadastro-cavalo.component';
import { RacaCavaloResolver } from './formularios/cadastro-cavalo/racaCavalo.resolver';
import { UnidadeFederativaService } from '../service/unidade-federativa.service';
import { CadastroRacaModalComponent } from '../comum/formularios/cadastro-raca/cadastro-raca-modal.component'
import { AuthGuard } from '../auth/auth';
import { UsuariosComponent } from './usuarios/usuarios.component';
import { UsuariosResolver } from './usuarios/usuarios.resolver';
import { EventoRacaService } from '../service/evento-raca.service';
import { RacaPontuarModalComponent } from './formularios/racas-pontuar/racas-pontuar-modal.component';
import { VerificaRegraService } from '../service/verifica-regra.service';
import { ListaInscritosComponent } from './evento/lista-inscritos/lista-inscritos.component';
import { CadastroNoticiaComponent } from './formularios/cadastro-noticia/cadastro-noticia.component';
import { TipoInscricaoService } from '../service/tipo-inscricao.service';
import { AuthGuardOrganizador } from '../auth/authOrganizador';
import { OrganizadorComponent } from './organizador/organizador.component';
import { HistoricoComponent } from './historico/historico.component';
import { ListaInscricoesUsuarioComponent } from './usuarios/lista-inscricoes-usuario/lista-inscricoes-usuario.component';
import { CustomListboxComponent } from './custom-listbox/custom-listbox.component';
import { CavaloComponent } from './cavalo/cavalo.component';
import { EditarUsuarioComponent } from './usuarios/editar-usuario/editar-usuario.component';
import { CadastroUsuarioParcialComponent } from './formularios/cadastro-usuario-parcial/cadastro-usuario-parcial.component';
import { ProvaRacasService } from '../service/prova-racas.service';
import { AuthGuardLogado } from '../auth/authLogado';
import { UsuarioSemCadastroService } from '../service/usuario-sem-cadastro.service';
import { RedefinirSenhaComponent } from './formularios/redefinir-senha/redefinir-senha.component';
import { PerfilService } from '../service/perfil.service';
import { EditarInscricaoComponent } from './inscricoes/components/editar-inscricao/editar-inscricao.component';
import { FinanceiroComponent } from './evento/financeiro/financeiro.component';
import { CadastroAssociacaoComponent } from './formularios/cadastro-associacao/cadastro-associacao.component';
import { RegraAssociacaoService } from '../service/regra-associacao.service';
import { RegrasAssociacaoComponent } from './regras-associacao/regras-associacao.component';
import { AssociacaoCompetidorService } from '../service/associacao-competidor';
import { ListaAssociadosComponent } from './associados/lista-associados/lista-associados.component';
import { EditarAssociadoComponent } from './associados/editar-associado/editar-associado.component';
import { DestaqueService } from '../service/destaque.service';
import { CadastroDestaqueComponent } from './formularios/cadastro-destaque/cadastro-destaque.component';
import { ListaDestaquesComponent } from './destaques/lista-destaques/lista-destaques.component';
import { ListaCavalosComponent } from './cavalo/lista-cavalos/lista-cavalos.component';
import { CadastroUsuarioSemHandicapComponent } from './formularios/cadastro-usuario-sem-handicap/cadastro-usuario-sem-handicap.component';
import { UsuarioSemCadastroInscricaoCompetidorService } from '../service/usuario-sem-cadastro-inscricao-competidor.service';
import { RespostaPerguntaService } from '../service/resposta-pergunta';
import { FinanceiroPessoalComponent } from './usuario/financeiro-pessoal/financeiro-pessoal.component';
import { ListaCompetidoresComponent } from './usuarios/lista-competidores/lista-competidores.component';
import { ListaCompetidoresSemCadastroComponent } from './usuarios/lista-competidores-sem-cadastro/lista-competidores-sem-cadastro/lista-competidores-sem-cadastro.component';
import { ListaUsuariosComponent } from './usuarios/lista-usuarios/lista-usuarios/lista-usuarios.component';
import { HistoricoGeralComponent } from './historico-geral/historico-geral.component';
import { ListaRacasComponent } from './raca/lista-racas/lista-racas.component';
import { CadastroRacaComponent } from './formularios/cadastro-raca/cadastro/cadastro-raca.component';
import { ModeracaoCavaloComponent } from './moderacao/moderacao-cavalo/moderacao-cavalo.component';
import { CadastroRegraRegulamentoComponent } from './formularios/cadastro-regra-regulamento/cadastro-regra-regulamento.component';
import { RegraRegulamentoService } from '../service/regra-regulamento.service';
import { ListaRegraRegulamentoComponent } from './regra-regulamento/lista-regra-regulamento/lista-regra-regulamento/lista-regra-regulamento.component';
import { CadastroFotoEventoComponent } from './formularios/cadastro-foto-evento/cadastro-foto-evento.component';
import { FotoEventoService } from '../service/foto-evento.service';
import { CadastroResultadoEventoComponent } from './formularios/cadastro-resultado-evento/cadastro-resultado-evento.component';
import { ResultadoEventoService } from '../service/resultado-evento.service';
import { ResultadoEventoListComponent } from './resultado-evento/list/resultado-evento-list.component';
import { CadastroResultadoCampeonatoComponent } from './formularios/cadastro-resultado-campeonato/cadastro-resultado-campeonato.component';
import { ResultadoCampeonatoService } from '../service/resultado-campeonato.service';
import { ResultadoCampeonatoListComponent } from './resultado-campeonato/list/resultado-campeonato-list.component';
import { ResultadoGeralListComponent } from './resultado-geral/list/resultado-geral-list.component';


export const MODULE_ROUTES_COMUM: Route[] = [
  {path: 'inicio', component: InicioComponent},
  {path: 'quem-somos', component: QuemSomosComponent},
  {path: 'contato', component: ContatoComponent},
  {path: 'login', component: FormularioLoginComponent},
  {path: 'eventos', component: EventosComponent},
  {path: 'evento/:id_evento', component: EventoComponent},
  {path: 'evento/:id_evento/lista_inscritos', component: ListaInscritosComponent, canActivate : [AuthGuardOrganizador]},
  {path: 'cadastro-evento', component: CadastroEventoComponent, canActivate: [AuthGuard]},
  {path: 'editar-evento', component: CadastroEventoComponent, canActivate: [AuthGuard]},
  {path: 'noticias', component: NoticiasComponent},
  {path: 'noticia/:id_noticia', component: NoticiaComponent},
  {path: 'cadastro-usuario', component: CadastroUsuarioComponent},
  {path: 'cadastro-usuario-parcial', component: CadastroUsuarioParcialComponent},
  {path: 'cadastro-usuario-sem-handicap', component: CadastroUsuarioSemHandicapComponent, canActivate : [AuthGuardOrganizador]},
  {path: 'treinadores', component: TreinadoresComponent, resolve: { treinadores: TreinadoresResolver }},
  {path: 'cadastro-treinador', component: CadastroTreinadorComponent, canActivate: [AuthGuard]},
  {path: 'editar-treinador/:idTreinador', component: CadastroTreinadorComponent, canActivate: [AuthGuard]},
  {path: 'cadastro-campeonato', component: CadastroCampeonatoComponent, canActivate: [AuthGuard]},
  {path: 'editar-campeonato/:id_campeonato', component: CadastroCampeonatoComponent, canActivate: [AuthGuard]},
  {path: 'campeonatos', component: CampeonatosComponent},
  {path: 'campeonatos/:params', component: VisualizarCampeonatosPage, resolve: { campeonatos: CampeonatosResolver }},
  {path: 'campeonatos/detalhes/:idCampeonato', component: DetalhesCampeonatoPage},
  {path: 'cadastro-cavalo', canActivate: [AuthGuardLogado], component: CadastroCavaloComponent, resolve: { racas: RacaCavaloResolver }},
  {path: 'editar-cavalo', canActivate: [AuthGuard], component: CadastroCavaloComponent, resolve: { racas: RacaCavaloResolver }},
  {path: 'cadastro-divisao', component: CadastroDivisaoComponent, canActivate: [AuthGuard]},
  {path: 'cadastro-divisao/:idDivisao', component: CadastroDivisaoComponent, canActivate: [AuthGuard]},
  {path: 'divisoes', component: DivisoesComponent},
  {path: 'inscricoes/:idEvento', component: InscricoesComponent, canActivate: [AuthGuardLogado], resolve: { usuario: UsuarioInscricaoResolver }},
  {path: 'competidores-pendentes', component: UsuariosComponent, canActivate: [AuthGuard], resolve: { usuarios: UsuariosResolver }},
  {path: 'cadastro-noticia', component: CadastroNoticiaComponent, canActivate: [AuthGuard]},
  {path: 'editar-noticia/:id_noticia', component: CadastroNoticiaComponent, canActivate: [AuthGuard]},
  {path: 'historico', component: HistoricoComponent, canActivate: [AuthGuardLogado]},
  {path: 'organizador', component: OrganizadorComponent, canActivate: [AuthGuardOrganizador]},
  {path: 'lista-inscricoes', component: ListaInscricoesUsuarioComponent, canActivate: [AuthGuardLogado]},
  {path: 'lista-competidores', component: ListaCompetidoresComponent, canActivate : [AuthGuard]},
  {path : 'editar-usuario', component : EditarUsuarioComponent, canActivate : [AuthGuard]},
  {path: 'redefinir-senha', component: RedefinirSenhaComponent, canActivate : [AuthGuardLogado]},
  {path: 'editar-inscricao', component: EditarInscricaoComponent, canActivate : [AuthGuardLogado]},
  {path: 'financeiro-evento', component: FinanceiroComponent, canActivate : [AuthGuardOrganizador]},
  {path: 'financeiro', component: FinanceiroPessoalComponent, canActivate : [AuthGuardLogado]},
  {path: 'cadastro-regra-filiacao', component: CadastroAssociacaoComponent, canActivate : [AuthGuard]},
  {path: 'regras-filiacao', component: RegrasAssociacaoComponent, canActivate : [AuthGuard]},
  {path: 'lista-filiados', component: ListaAssociadosComponent, canActivate : [AuthGuard]},
  {path: 'editar-filiado', component: EditarAssociadoComponent, canActivate : [AuthGuard]},
  {path: 'cadastro-destaque', component: CadastroDestaqueComponent, canActivate : [AuthGuard]},
  {path: 'lista-destaques', component: ListaDestaquesComponent, canActivate : [AuthGuard]},
  {path: 'lista-cavalos', component: ListaCavalosComponent, canActivate : [AuthGuard]},
  {path: 'lista-competidores-sem-handicap', component: ListaCompetidoresSemCadastroComponent, canActivate : [AuthGuard]},
  {path: 'lista-usuarios', component: ListaUsuariosComponent, canActivate : [AuthGuard]},
  {path: 'historico-geral', component: HistoricoGeralComponent, canActivate : [AuthGuard]},
  {path: 'lista-racas', component: ListaRacasComponent, canActivate : [AuthGuard]},
  {path: 'cadastro-raca', component: CadastroRacaComponent, canActivate: [AuthGuard]},
  {path: 'editar-raca', component: CadastroRacaComponent, canActivate: [AuthGuard]},
  {path: 'moderacao-cavalos', component: ModeracaoCavaloComponent, canActivate: [AuthGuard]},
  {path: 'cadastro-regra-regulamento', component: CadastroRegraRegulamentoComponent, canActivate: [AuthGuard]},
  {path: 'editar-regra-regulamento/:idRegraRegulamento', component: CadastroRegraRegulamentoComponent, canActivate: [AuthGuard]},
  {path: 'lista-regra-regulamento', component: ListaRegraRegulamentoComponent, canActivate: [AuthGuardLogado]},
  {path: 'evento/:id_evento/cadastro-foto-evento', component: CadastroFotoEventoComponent, canActivate: [AuthGuard]},
  {path: 'evento/:id_evento/editar-foto-evento/:id_foto_evento', component: CadastroFotoEventoComponent, canActivate: [AuthGuard]},
  {path: 'evento/:id_evento/cadastro-resultado', component: CadastroResultadoEventoComponent, canActivate: [AuthGuard]},
  {path: 'evento/:id_evento/editar-resultado/:id_resultado_evento', component: CadastroResultadoEventoComponent, canActivate: [AuthGuard]},
  {path: 'evento/resultados/:id_evento', component: ResultadoEventoListComponent, canActivate: [AuthGuardLogado]},
  {path: 'campeonato/cadastro-resultado/:id_campeonato', component: CadastroResultadoCampeonatoComponent, canActivate: [AuthGuard]},
  {path: 'campeonato/:id_campeonato/editar-resultado/:id_resultado_campeonato', component: CadastroResultadoCampeonatoComponent, canActivate: [AuthGuard]},
  {path: 'campeonato/resultados/:id_campeonato', component: ResultadoCampeonatoListComponent, canActivate: [AuthGuardLogado]},
  {path: 'resultado-geral', component: ResultadoGeralListComponent, canActivate: [AuthGuardLogado]},
  {path: '', redirectTo: 'inicio', pathMatch: 'full'}
];

export const MODULE_COMPONENTS_COMUM = [
  InicioComponent,
  QuemSomosComponent,
  ContatoComponent,
  EventosComponent,
  EventoComponent,
  NoticiasComponent,
  NoticiaComponent,
  CampeonatosComponent,
  CadastroUsuarioComponent,
  CadastroUsuarioParcialComponent,
  CadastroCampeonatoComponent,
  CadastroEventoComponent,
  CadastroCavaloComponent,
  CadastroDivisaoComponent,
  DivisoesComponent,
  CadastroRacaModalComponent,
  RacaPontuarModalComponent,
  CadastroNoticiaComponent,
  HistoricoComponent,
  CustomListboxComponent,
  CavaloComponent,
  CadastroDestaqueComponent,
  CadastroUsuarioSemHandicapComponent
];

export const MODULE_SERVICES_COMUM = [
  InicioService,
  QuemSomosService,
  NoticiaService,
  EventoService,
  UsuarioService,
  CampeonatoService,
  CampeonatosResolver,
  CavaloService,
  ProvaService,
  DivisaoService,
  RespostaHandicapService,
  PerguntaHandicapService,
  DivisaoService,
  RegraService,
  RegraDivisaoService,
  EmailService,
  RacaService,
  UsuarioInscricaoResolver,
  InscricaoService,
  InscricaoCompetidorService,
  RacaCavaloResolver,
  UnidadeFederativaService,
  EventoRacaService,
  VerificaRegraService,
  NoticiaService,
  TipoInscricaoService,
  ProvaRacasService,
  UsuarioSemCadastroService,
  PerfilService,
  RegraAssociacaoService,
  AssociacaoCompetidorService,
  DestaqueService,
  UsuarioSemCadastroInscricaoCompetidorService,
  RespostaPerguntaService,
  RegraRegulamentoService,
  FotoEventoService,
  ResultadoEventoService,
  ResultadoCampeonatoService
];

export const MODULE_ENTRYCOMPONENTS_COMUM = [
];
